import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const VideoAnswer = ({ code }) => {
    return (
        <Link
            to={`/video/answer?code=${code}`}
            rel="noreferrer"
            target="_blank"
        >
            Click Here
        </Link>
    );
};

VideoAnswer.propTypes = {
    code: PropTypes.string,
};

export default VideoAnswer;

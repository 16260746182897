import React from 'react';
import PropTypes from 'prop-types';

import "../../styles/toggleBtn.scss";

const SwitchBtn = (props) => {
	const { onChange, checked = false } = props;

	return (
		<label className="switch" {...props}>
			<input type="checkbox" checked={checked} onChange={onChange} />
			<span className="slider round"></span>
		</label>
	);
};

SwitchBtn.propTypes = {
	onChange: PropTypes.func,
	checked: PropTypes.bool
}

export default SwitchBtn;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import "../../styles/driveDetails.scss";
import { workFunctionData } from "../workflows/newDriveData";

const DriveDetails = (props) => {
    const { driveDetails, bots, fetchBots } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedWorkFunction, setSelectedWorkFunction] = useState("");
    const [assessmentName, setAssessmentName] = useState("");
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

    useEffect(() => {
        if (
            driveDetails.productId &&
            Object.prototype.hasOwnProperty.call(
                driveDetails.productId,
                "workfunction"
            ) &&
            driveDetails.productId.workfunction.length > 0
        ) {
            setSelectedWorkFunction(
                driveDetails.productId.workfunction[0].value
            );
        }

        if (
            bots.length === 0 &&
            driveDetails.productId &&
            driveDetails.productId.isCustom
        ) {
            fetchBots({ navigate });
        }
    }, [driveDetails]);

    useEffect(() => {
        if (driveDetails.productId) {
            if (driveDetails.productId.isCustom) {
                let botName;
                bots.forEach((bot) => {
                    if (bot.productId === driveDetails.productId.customId) {
                        botName = bot.name;
                    }
                });
                if (botName) {
                    setAssessmentName(botName);
                } else {
                    setAssessmentName("");
                }
            } else {
                setAssessmentName("");
            }
        }
    }, [bots]);

    return (
        <div className="driveDetails">
            <div
                className="userListTitleContainer"
                style={{ padding: 0, marginTop: 0 }}
            >
                <div className="inner-div-details">
                    <h2>Drive Details</h2>
                </div>
            </div>
            <div
                style={{
                    background: "#fff",
                    borderRadius: 33,
                    boxShadow: "7px 9px 9px rgba(0, 0, 0, 0.25)",
                    padding: mediaQueryMobile.matches ? "1rem" : 40,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: mediaQueryMobile.matches ? 16 : 40,
                    }}
                >
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: "400",
                            color: "#8b8b8b",
                            wordBreak: "break-all",
                        }}
                    >
                        {location.state.workflowName}
                    </div>
                    <div
                        style={{
                            fontSize: 12,
                            fontWeight: "600",
                            color: "#8b8b8b",
                        }}
                    >
                        Date:{" "}
                        {new Date(
                            driveDetails.createdDate
                        ).toLocaleDateString()}
                    </div>
                </div>
                <div style={{ fontSize: 15, color: "#999", fontWeight: "700" }}>
                    {driveDetails.description}
                </div>
                {assessmentName !== "" ? (
                    <div
                        style={{
                            color: "#999",
                            fontWeight: "700",
                            fontSize: 15,
                            marginTop: 40,
                        }}
                    >
                        Assessment Name: {assessmentName}
                    </div>
                ) : null}
                {driveDetails.productId &&
                driveDetails.productId.workfunction ? (
                    <>
                        <div
                            style={{
                                color: "676767",
                                fontWeight: "700",
                                fontSize: 18,
                                margintop: 40,
                            }}
                        >
                            Role: {driveDetails.productId.role}
                        </div>
                        {driveDetails.productId &&
                        Object.prototype.hasOwnProperty.call(
                            driveDetails.productId,
                            "workfunction"
                        ) &&
                        driveDetails.productId.workfunction.length > 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    marginTop: mediaQueryMobile.matches
                                        ? 16
                                        : 40,
                                    marginBottom: mediaQueryMobile.matches
                                        ? 16
                                        : 40,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "700",
                                        fontSize: 16,
                                        marginBottom: 10,
                                    }}
                                >
                                    Critical work function:{" "}
                                </div>
                                {driveDetails.productId &&
                                driveDetails.productId.workfunction
                                    ? driveDetails.productId.workfunction.map(
                                          (item) => (
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      marginBottom: 10,
                                                      width: mediaQueryMobile.matches
                                                          ? "100%"
                                                          : "auto",
                                                  }}
                                                  key={item.value}
                                              >
                                                  <div
                                                      style={{
                                                          marginLeft:
                                                              mediaQueryMobile.matches
                                                                  ? 0
                                                                  : 20,
                                                          width: mediaQueryMobile.matches
                                                              ? "100%"
                                                              : "auto",
                                                          padding: "5px 10px",
                                                          borderRadius:
                                                              mediaQueryMobile.matches
                                                                  ? 8
                                                                  : 20,
                                                          fontSize: 14,
                                                          color:
                                                              item.value ===
                                                              selectedWorkFunction
                                                                  ? "#fff"
                                                                  : "#000",
                                                          backgroundColor:
                                                              item.value ===
                                                              selectedWorkFunction
                                                                  ? "#479BD2"
                                                                  : "#F5F5F5",
                                                          cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                          setSelectedWorkFunction(
                                                              item.value
                                                          )
                                                      }
                                                      key={item.value}
                                                  >
                                                      {item.label}
                                                  </div>
                                                  {item.weightage ? (
                                                      <div
                                                          style={{
                                                              marginLeft: 10,
                                                              padding:
                                                                  "5px 10px",
                                                              borderRadius: 20,
                                                              fontSize: 14,
                                                              color:
                                                                  item.value ===
                                                                  selectedWorkFunction
                                                                      ? "#fff"
                                                                      : "#000",
                                                              backgroundColor:
                                                                  item.value ===
                                                                  selectedWorkFunction
                                                                      ? "#479BD2"
                                                                      : "#F5F5F5",
                                                              cursor: "pointer",
                                                          }}
                                                      >
                                                          {item.weightage}%
                                                      </div>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                          )
                                      )
                                    : null}
                            </div>
                        ) : null}
                        {driveDetails.productId &&
                        driveDetails.productId.keyTask &&
                        selectedWorkFunction ? (
                            <div
                                style={{
                                    backgroundColor: "#F8F8F8",
                                    padding: mediaQueryMobile.matches
                                        ? "1rem"
                                        : "20px 24px",
                                    borderRadius: mediaQueryMobile.matches
                                        ? 33
                                        : 40,
                                }}
                            >
                                {Object.prototype.hasOwnProperty.call(
                                    driveDetails.productId.keyTask,
                                    driveDetails.productId.role
                                )
                                    ? driveDetails.productId.keyTask[
                                          driveDetails.productId.role
                                      ][selectedWorkFunction].map((item) => {
                                          return selectedWorkFunction.length >
                                              3 ? (
                                              <div
                                                  className="keyTask"
                                                  key={item}
                                              >
                                                  {item}
                                              </div>
                                          ) : (
                                              <div
                                                  className="keyTask"
                                                  key={item}
                                              >
                                                  {
                                                      workFunctionData[
                                                          driveDetails.productId
                                                              .role
                                                      ][
                                                          selectedWorkFunction
                                                      ].keyTasks.filter(
                                                          (kt) =>
                                                              item === kt.value
                                                      )[0].label
                                                  }
                                              </div>
                                          );
                                      })
                                    : driveDetails.productId.keyTask[
                                          driveDetails.productId.jobTitle
                                      ][selectedWorkFunction].map((item) => {
                                          return selectedWorkFunction.length >
                                              3 ? (
                                              <div
                                                  className="keyTask"
                                                  key={item}
                                              >
                                                  {item}
                                              </div>
                                          ) : (
                                              <div
                                                  className="keyTask"
                                                  key={item}
                                              >
                                                  {
                                                      workFunctionData[
                                                          driveDetails.productId
                                                              .jobTitle
                                                      ][
                                                          selectedWorkFunction
                                                      ].keyTasks.filter(
                                                          (kt) =>
                                                              item === kt.value
                                                      )[0].label
                                                  }
                                              </div>
                                          );
                                      })}
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        </div>
    );
};

DriveDetails.propTypes = {
    driveDetails: PropTypes.object,
    bots: PropTypes.array,
    fetchBots: PropTypes.func,
};

export default DriveDetails;

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getWorkflowList, getChatEventsList } from '../selectors/app';
import { fetchWorkflows as fetchWorkflowsRequest, fetchChatEvents as fetchChatEventsRequest } from '../actions/app';

const BehaviourAnalytics = (props) => {
    const { workflowList, fetchWorkflows, fetchChatEvents, chatEventsList } = props;

    const [quesArr, setQuesArr] = useState({});

    useEffect(() => {
        fetchWorkflows();
    }, []);

    const onChangeWorkflow = (e) => {
        fetchChatEvents({ workflowId: e.target.value });
    }

    useEffect(() => {
        const quesObj = {};
        chatEventsList.forEach((cal) => {
            if(cal.test && cal.test.userEvents) {
                cal.test.userEvents.forEach((ue) => {
                    if(ue.eventData && ue.eventData.qid) {
                        if(!quesObj[ue.eventData.qid]) {
                            quesObj[ue.eventData.qid] = {};
                            quesObj[ue.eventData.qid]['optionsClick'] = 0;
                            quesObj[ue.eventData.qid]['confirmClick'] = 0;
                            quesObj[ue.eventData.qid]['exhibitClick'] = 0;
                            quesObj[ue.eventData.qid]['supportClick'] = 0;
                            quesObj[ue.eventData.qid]['submitProgressClick'] = 0;
                            quesObj[ue.eventData.qid]['submitEndClick'] = 0;
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionsClick']++;
                            }
                            if(ue.eventType === 'clickSend' || ue.eventType === 'clickConfirm' || ue.eventType === 'clickExcelSubmit') {
                                quesObj[ue.eventData.qid]['confirmClick']++;
                            }
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionClick']++;
                            }
                            if(ue.eventType === 'clickSupport') {
                                quesObj[ue.eventData.qid]['supportClick']++;
                            }
                            if(ue.eventType === 'clickSubmitProgress') {
                                quesObj[ue.eventData.qid]['submitProgressClick']++;
                            }
                            if(ue.eventType === 'clickChatSubmit') {
                                quesObj[ue.eventData.qid]['submitEndClick']++;
                            }
                        } else {
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionsClick']++;
                            }
                            if(ue.eventType === 'clickSend' || ue.eventType === 'clickConfirm' || ue.eventType === 'clickExcelSubmit') {
                                quesObj[ue.eventData.qid]['confirmClick']++;
                            }
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionClick']++;
                            }
                            if(ue.eventType === 'clickSupport') {
                                quesObj[ue.eventData.qid]['supportClick']++;
                            }
                            if(ue.eventType === 'clickSubmitProgress') {
                                quesObj[ue.eventData.qid]['submitProgressClick']++;
                            }
                            if(ue.eventType === 'clickChatSubmit') {
                                quesObj[ue.eventData.qid]['submitEndClick']++;
                            }
                        }
                    }
                });
            }
        });
        setQuesArr({ ...quesObj });
    }, [chatEventsList]);

    const onChangeCandidate = (e) => {
        const quesObj = {};
        const newCel = chatEventsList.filter(ce => ce._id === e.target.value);
        newCel.forEach((cal) => {
            if(cal.test && cal.test.userEvents) {
                cal.test.userEvents.forEach((ue) => {
                    if(ue.eventData && ue.eventData.qid) {
                        if(!quesObj[ue.eventData.qid]) {
                            quesObj[ue.eventData.qid] = {};
                            quesObj[ue.eventData.qid]['optionsClick'] = 0;
                            quesObj[ue.eventData.qid]['confirmClick'] = 0;
                            quesObj[ue.eventData.qid]['exhibitClick'] = 0;
                            quesObj[ue.eventData.qid]['supportClick'] = 0;
                            quesObj[ue.eventData.qid]['submitProgressClick'] = 0;
                            quesObj[ue.eventData.qid]['submitEndClick'] = 0;
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionsClick']++;
                            }
                            if(ue.eventType === 'clickSend' || ue.eventType === 'clickConfirm' || ue.eventType === 'clickExcelSubmit') {
                                quesObj[ue.eventData.qid]['confirmClick']++;
                            }
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionClick']++;
                            }
                            if(ue.eventType === 'clickSupport') {
                                quesObj[ue.eventData.qid]['supportClick']++;
                            }
                            if(ue.eventType === 'clickSubmitProgress') {
                                quesObj[ue.eventData.qid]['submitProgressClick']++;
                            }
                            if(ue.eventType === 'clickChatSubmit') {
                                quesObj[ue.eventData.qid]['submitEndClick']++;
                            }
                        } else {
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionsClick']++;
                            }
                            if(ue.eventType === 'clickSend' || ue.eventType === 'clickConfirm' || ue.eventType === 'clickExcelSubmit') {
                                quesObj[ue.eventData.qid]['confirmClick']++;
                            }
                            if(ue.eventType === 'clickOptions') {
                                quesObj[ue.eventData.qid]['optionClick']++;
                            }
                            if(ue.eventType === 'clickSupport') {
                                quesObj[ue.eventData.qid]['supportClick']++;
                            }
                            if(ue.eventType === 'clickSubmitProgress') {
                                quesObj[ue.eventData.qid]['submitProgressClick']++;
                            }
                            if(ue.eventType === 'clickChatSubmit') {
                                quesObj[ue.eventData.qid]['submitEndClick']++;
                            }
                        }
                    }
                });
            }
        });
        setQuesArr({ ...quesObj });
    }

    return (
        <div className="mainbar" style={{ display: "flex", flexDirection: "column", width: "80vw", minHeight: "100vh" }}>
            <div style={{ padding: 40, fontSize: 24, fontWeight: 'bold', background: '#fff' }}>Behaviour Analytics</div>
            <div style={{ display: 'flex', marginTop: 25, justifyContent: 'space-between' }}>
                <select onChange={onChangeWorkflow}>
                    <option>Select Workflow</option>
                    {
                        workflowList.map((wf) => (
                            <option key={wf._id} value={wf._id}>{wf.name}</option>
                        ))
                    }
                </select>
                <select onChange={onChangeCandidate}>
                    <option>Select Candidate</option>
                    {
                        chatEventsList.map((cel) => (
                            <option key={cel._id} value={cel._id}>{cel.name}</option>
                        ))
                    }
                </select>
                {/* <div onClick={onClickShowEvents}>Reset</div> */}
            </div>
            <div style={{ marginTop: 25 }}>
                <table border={1}>
                    <thead>
                        <tr>
                            <th style={{ padding: 5 }}>Question No</th>
                            <th style={{ padding: 5 }}>Options Click</th>
                            <th style={{ padding: 5 }}>Confirm Click</th>
                            <th style={{ padding: 5 }}>Exhibit Click</th>
                            <th style={{ padding: 5 }}>Support Click</th>
                            <th style={{ padding: 5 }}>Submit Progress Click</th>
                            <th style={{ padding: 5 }}>Submit End Click</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(quesArr).map((qa) => (
                                <tr key={qa}>
                                    <td style={{ padding: 5 }}>{qa}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].optionsClick}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].confirmClick}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].exhibitClick}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].supportClick}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].submitProgressClick}</td>
                                    <td style={{ padding: 5 }}>{quesArr[qa].submitEndClick}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

BehaviourAnalytics.propTypes = {
    workflowList: PropTypes.array,
    fetchWorkflows: PropTypes.func,
    fetchChatEvents: PropTypes.func,
    chatEventsList: PropTypes.array
}

const mapStateToProps = createStructuredSelector({
    workflowList: getWorkflowList(),
    chatEventsList: getChatEventsList()
});

const mapDispatchToProps = (dispatch) => ({
    fetchWorkflows: () => dispatch(fetchWorkflowsRequest()),
    fetchChatEvents: (data) => dispatch(fetchChatEventsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BehaviourAnalytics);

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { fetchCompanyDetails, updateCompanyFeatures } from '../../actions/app';
import { getCompanyDetails } from '../../selectors/app';
import Snackbar from '../../components/common/Snackbar';
import { companyDetailsType, featureType } from './ManageFeatures.types';
import styles from './ManageFeatures.module.css';

const ManageFeatures = ({ companyDetails }: {
    companyDetails: companyDetailsType;
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [companyFeatures, setCompanyFeatures] = useState<featureType>({});
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

    useEffect(() => {
        dispatch(fetchCompanyDetails({ companyId: location.state.company._id }));
    }, []);

    useEffect(() => {
        const newCompanyFeatures = { ...companyDetails.defaultFeatures };
        Object.keys(newCompanyFeatures).forEach((key) => {
            if (Number.isInteger(companyDetails.features[key])) {
                newCompanyFeatures[key] = companyDetails.features[key];
            }
            else if(typeof companyDetails.features[key] === 'boolean') {
                newCompanyFeatures[key] = companyDetails.features[key] ? 2 : 0;
            }
        });
        setCompanyFeatures({ ...newCompanyFeatures });
    }, [companyDetails]);

    const saveChanges = () => {
        const data = {
            features: companyFeatures,
            companyId: location.state.company._id
        };

        dispatch(updateCompanyFeatures(data));
        setSuccessSnackOpen(true);
        setSnackSuccessMsg('Company features updated successfully');
    }

    const handleFeatureNumberChange = (value: string, feature: string) => {
        const newPlanFeatures: featureType  = { ...companyFeatures };

        if (value) {
            newPlanFeatures[feature] = parseInt(value, 10);
        } else {
            newPlanFeatures[feature] = 0;
        }
        setCompanyFeatures({ ...newPlanFeatures });
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div>
                    <div className={styles.heading}>Company Features</div>
                    <div className={styles.subHeading}>
                        {companyDetails.name || ''}
                    </div>
                </div>
                <div
                    className={styles.saveButton}
                    onClick={saveChanges}
                >
                    Save Changes
                </div>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.tableHeaderContainer}>
                    <div className={classNames(styles.tableHeader)}>S. No.</div>
                    <div className={classNames(styles.tableHeader, styles.flex4)}>Name</div>
                    <div className={classNames(styles.tableHeader, styles.flex2)}>Action</div>
                </div>
                <TableContent
                    data={companyFeatures}
                    handleFeatureNumberChange={handleFeatureNumberChange}
                />
            </div>
            <Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={() => setSuccessSnackOpen(false)} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success" />
        </div>
    )
}

const TableContent = ({
    data,
    handleFeatureNumberChange,
}: {
    data: featureType,
    handleFeatureNumberChange: Function;
}) => {
    return (
        <div>
            {Object.keys(data).map((feature, i) => (
                    <div className={styles.tableContentContainer} key={i}>
                        <div className={styles.tableContent}>{i + 1}</div>
                        <div className={classNames(styles.tableHeader, styles.flex4)}>{feature}</div>
                        <div className={classNames(styles.tableHeader, styles.flex2)}>
                            {!feature.includes('Count') ?
                                <select value={data[feature]} onChange={(e) => handleFeatureNumberChange(e.target.value, feature)}>
                                    <option value={2}>Active</option>
                                    <option value={1}>Locked</option>
                                    <option value={0}>Inactive</option>
                                </select>
                                : <input
                                    type='number'
                                    onChange={(e) => handleFeatureNumberChange(e.target.value, feature)}
                                    value={data[feature]}
                                />
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    companyDetails: getCompanyDetails()
});

export default connect(mapStateToProps, null)(ManageFeatures);

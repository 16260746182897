import React, { useEffect, useReducer } from "react";
import logoDesktop from "../../assets/Logo.png";
import logoMobile from "../../assets/logo-white.png";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Button from "../common/Button";
import axios from "../../utils/axios";
import { useNavigate, useSearchParams } from "react-router-dom";

export function SignInLayout({ children }) {
    const mediaQueryMobile = window.matchMedia("(max-width: 600px)");
    return (
        <div className="signinWrapper">
            <div className="signinForm">
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "400px",
                            margin: "auto",
                            maxWidth: "80%",
                        }}
                    >
                        <div className="logoContainer">
                            <img
                                src={
                                    mediaQueryMobile.matches
                                        ? logoMobile
                                        : logoDesktop
                                }
                                alt="logo"
                                className="logoImage"
                            />
                            <h1
                                style={{
                                    fontSize: "30px",
                                    color: "#008095",
                                    marginBottom: "15px",
                                    fontWeight: "800",
                                }}
                                className="clientDashboard"
                            >
                                Client Dashboard
                            </h1>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
            <div className="singinBg">
                <div className="signinContainer">
                    <div className="signinRightText">
                        <h1>Assess more</h1>
                        <h1>Interview less</h1>
                        <h1>Hire better</h1>
                        <h2 className="efficientHiring">
                            Your efficient hiring toolkit
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

SignInLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

const statusList = {
    loading: "LOADING",
    error: "ERROR",
    success: "SUCCESS",
    idle: "IDLE",
};

function reducer(state, action) {
    switch (action.type) {
        case statusList.loading: {
            return { ...state, status: statusList.loading };
        }
        case statusList.success: {
            return {
                ...state,
                status: statusList.success,
                data: action.payload,
            };
        }
        case statusList.error: {
            return { ...state, status: statusList.error, data: action.payload };
        }
        default: {
            return state;
        }
    }
}

function usePost(url, options) {
    const [state, dispatch] = useReducer(reducer, {
        error: null,
        data: null,
        status: statusList.idle,
    });

    const submit = async (payload) => {
        try {
            dispatch({ type: statusList.loading });
            const response = await axios.get(
                `${url}?domain=${payload}`,
                options
            );
            dispatch({ type: statusList.success, payload: response.data });
            return response.data;
        } catch (error) {
            dispatch({
                type: statusList.error,
                payload: error?.message || "something went wrong!",
            });
        }
    };

    const isLoading = state.status === statusList.loading;

    return [submit, { ...state, isLoading }];
}

export function SSO() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectTo = searchParams.get("redirectTo");
    const [submit, { isLoading }] = usePost("/auth/sso/redirect-url");

    useEffect(() => {
        if (redirectTo) {
            localStorage.setItem("ssoRedirect", redirectTo);
        }
    }, [redirectTo]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const email = form.get("email");
        const payload = email.includes("@") ? email.split("@")[1] : email;
        submit(payload)
            .then((res) => {
                if (res?.redirectUrl) {
                    // window.open(res.redirectUrl);
                    window.location.href = res.redirectUrl;
                }
                e.target.reset();
            })
            .catch((err) => {
                e.target.reset();
            });
    };

    return (
        <SignInLayout>
            <Stack gap={3}>
                <Stack>
                    <h2>Continue with SSO</h2>
                    <p>Enter your email address or organization domain</p>
                </Stack>
                <Stack component="form" gap={0.5} onSubmit={handleSubmit}>
                    <label htmlFor="domain" className="inputLabel">
                        Domain name*
                    </label>
                    <input
                        required
                        type="text"
                        id="domain"
                        className="signinInput"
                        placeholder="Enter your email or organization domain"
                        name="email"
                    />
                    <Stack
                        sx={{ alignSelf: "flex-end", marginTop: "0.5rem" }}
                        direction="row"
                        gap={0.5}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            className="signinBtn"
                            id="testLoginFlow-selector-3"
                            style={{
                                maxWidth: "fit-content",
                                padding: "0.3rem 1.5rem",
                                backgroundColor: "transparent",
                            }}
                            onClick={() => navigate(-1)}
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className="signinBtn"
                            type="submit"
                            id="testLoginFlow-selector-3"
                            disabled={isLoading}
                            style={{
                                maxWidth: "fit-content",
                                padding: "0.3rem 1.5rem",
                            }}
                        >
                            {isLoading ? "Loading..." : "Continue"}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </SignInLayout>
    );
}

import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../common/Button";

import "./BpadAddApplicant.scss";

import DeleteIcon from "../../assets/Icons/Trash-prescreen.png";

export default function BpadInvite({ sendData, closeModal }) {
  const [inputs, setInputs] = useState(Array(5).fill({ name: "", email: "" }));
  const [loading, setLoading] = useState(false);

  const handleInputChange = (i, value, type) => {
    setInputs((prev) =>
      prev.map((inp, index) => {
        if (index === i) {
          if (type === "name") return { ...inp, name: value };
          else if (type === "email") return { ...inp, email: value };
        }

        return inp;
      })
    );
  };

  const handleAddInput = () => {
    const addInput = {
      name: "",
      email: "",
    };
    const newInputs = [...inputs];
    newInputs.push(addInput);
    setInputs([...newInputs]);
  };

  const handleDeleteInput = (i) => {
    setInputs(inputs.filter((_, index) => index !== i));
  };

  const onSuccess = () => {
    setLoading(false);
    setInputs(Array(5).fill({ name: "", email: "" }));
    closeModal();
  }

  const onError = () => {
    setLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    setLoading(true);
    const filteredInputs = inputs.filter(item => (item.name !== '' && item.email !== ''));
    sendData(filteredInputs, onSuccess, onError);
  };

  return (
    <div className="tooltip">
      <h2>Invite Individuals</h2>
      <form className="bpadForm" onSubmit={handleSubmit}>
        <div className="bpadTableGrid">
          <p className="firstColumn bpadTitleBold">Sr. No</p>
          <p className="bpadTitleBold">Name</p>
          <p className="bpadTitleBold">Email ID</p>
        </div>
        {inputs.map((inp, index) => {
          return (
            <div key={index} className="bpadTableGridWrapper">
              <div className="bpadTableGrid">
                <p className="firstColumn">{index + 1}.</p>
                <input
                  type="text"
                  value={inp.name}
                  placeholder="Enter name"
                  className="bpadInputs"
                  onChange={(e) =>
                    handleInputChange(index, e.target.value, "name")
                  }
                />
                <input
                  type="email"
                  value={inp.email}
                  placeholder="Enter email"
                  className="bpadInputs"
                  onChange={(e) =>
                    handleInputChange(index, e.target.value, "email")
                  }
                />
              </div>
              <div className="bpadFormActionBtns">
                {index === inputs.length - 1 && (
                  <div className="add-question-btn" onClick={handleAddInput} />
                )}
                {inputs.length > 1 && (
                  <img
                    src={DeleteIcon}
                    className="delete-question-btn"
                    onClick={() => handleDeleteInput(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div className="bpadFormBtnsBox">
          <Button
            size="small"
            variant="contained"
            style={{
              marginRight: 10,
              fontWeight: "bold",
              letterSpacing: "0.01em",
              borderRadius: "25px",
              padding: "8px 20px",
              fontSize: 14,
              backgroundColor: "#f0f0f0",
              color: "#000",
            }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            size="small"
            variant="contained"
            style={{
              fontWeight: "bold",
              letterSpacing: "0.01em",
              borderRadius: "25px",
              padding: "8px 20px",
              fontSize: 14,
            }}
            disabled={loading}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

BpadInvite.propTypes = {
  sendData: PropTypes.func,
  closeModal: PropTypes.func
};

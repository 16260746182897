import React from 'react';
import ScissorIcon from '../../assets/scissors.png';
import { Document, Image, Page, Text, View, Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
    ]
});

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: '30pt',
        fontFamily: 'Open Sans',
        fontSize: 10,
        lineHeight: 1.5,
    },
    page1: {
        position: 'relative',
        padding: '30pt',
        fontFamily: 'Open Sans',
        fontSize: 10,
        lineHeight: 1.5,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20pt',
    },
    invoiceHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    invoiceBox : {
        marginRight: '30pt',
    },
    invoiceHeaders: {
        fontWeight: 'bold',
    },
    invoiceValues: {
        marginBottom: '8pt',
    },
    trimBox: {
        marginTop: 'auto',
        borderTop: '1pt dashed #000',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '5pt',
        paddingBottom: '40pt'
    },
    trimIcon: {
        position: 'absolute',
        top: '-8.5pt',
        left: '5pt',
        width: '16pt',
        height: '16pt',
    },
    paymentBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1pt solid #ccc',
    },
    paymentBoxValues: {
        // marginLeft: 24,
        textAlign: 'center',
    },
    addressBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    paymentBoxTitle: {
        fontWeight: 'bold',
        width: '120pt',
    },
    paymentLastBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    amountBox: {
        height: '24pt',
    },
    tableHeader: {
        fontWeight: 'bold',
        textAlign: 'right'
    },
    footer: {
        position: 'absolute',
        bottom: '30pt',
        left: '30pt',
        fontSize: 8,
    }
});

const Invoice = () => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Text style={{ fontSize: '32pt', fontWeight: '500' }}>INVOICE</Text>
                        <Text>Ula</Text>
                    </View>
                    <View>
                        <Image src={`${process.env.REACT_APP_STORAGE_BUCKET_URL}website_videos/consulting/logo.png`} style={{ width: '200pt', height: '70pt', marginLeft: 'auto' }} />
                        <View style={styles.invoiceHeader}>
                            <View style={styles.invoiceBox}>
                                <Text style={styles.invoiceHeaders}>Invoice Date</Text>
                                <Text style={styles.invoiceValues}>28 Apr 2022</Text>
                                <Text style={styles.invoiceHeaders}>Invoice Number</Text>
                                <Text style={styles.invoiceValues}>INV-22040005</Text>
                                <Text style={styles.invoiceHeaders}>Reference</Text>
                                <Text style={styles.invoiceValues}>Attn: Finance</Text>
                            </View>
                            <View>
                                <Text>HIREQUOTIENT</Text>
                                <Text>TECHNOLOGIES PTE. LTD.</Text>
                                <Text>67 Ayer Rajah Crescent</Text>
                                <Text>#02-10/17</Text>
                                <Text>SINGAPORE 139950</Text>
                                <Text>SINGAPORE</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ marginTop: '50pt' }}>
                    <View style={{ padding: '4pt 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #000' }}>
                        <View style={{ width: '50%' }}>
                            <Text style={{ fontWeight: 'bold' }}>Description</Text>
                        </View>
                        <View style={{ width: '16%' }}>
                            <Text style={styles.tableHeader}>Quantity</Text>
                        </View>
                        <View style={{ width: '16%' }}>
                            <Text style={styles.tableHeader}>Unit Price</Text>
                        </View>
                        <View style={{ width: '16%' }}>
                            <Text style={styles.tableHeader}>Amount USD</Text>
                        </View>
                    </View>
                    <View style={{ padding: '4pt 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                        <View style={{ width: '50%' }}>
                            <Text>HireQuotient pilot</Text>
                        </View>
                        <View style={{ width: '16%', textAlign: 'right' }}>
                            <Text>1.00</Text>
                        </View>
                        <View style={{ width: '16%', textAlign: 'right' }}>
                            <Text>500.00</Text>
                        </View>
                        <View style={{ width: '16%', textAlign: 'right' }}>
                            <Text>500.00</Text>
                        </View>
                    </View>
                    <View style={{ padding: '4pt 0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ width: '50%' }}></View>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '50%', borderBottom: '1px solid #000' }}>
                            <View style={{ width: '16%'}}>
                            </View>
                            <View style={{ width: '35%', textAlign: 'right' }}>
                                <Text>Subtotal</Text>
                            </View>
                            <View style={{ width: '16%', textAlign: 'right' }}>
                                <Text>500.00</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ width: '50%' }}></View>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '50%' }}>
                            <View style={{ width: '16%'}}>
                            </View>
                            <View style={{ width: '35%', textAlign: 'right', fontWeight: 'bold' }}>
                                <Text>TOTAL USD</Text>
                            </View>
                            <View style={{ width: '16%', textAlign: 'right', fontWeight: 'bold' }}>
                                <Text>500.00</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ border: '1px solid #000', width: '60%', margin: '40pt 0' }}>
                    <View style={{ padding: '4pt 16pt', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', borderBottom: '1px solid #000' }}>
                        <View style={{ marginRight: '16pt', width: '200pt' }} wrap>
                            <Text style={{ fontWeight: 'bold' }}>For GST reporting purposes:</Text>
                            <Text style={{ fontWeight: 'bold' }}>* Total GST equivalent to SGD 0.00</Text>
                            <Text>1 SGD = 0.723615 USD</Text>
                        </View>
                        <View>
                            <Text style={{ fontWeight: 'bold' }}>Amount SGD</Text>
                        </View>
                    </View>
                    <View style={{ margin: '0 16pt', borderBottom: '1px solid #000', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ marginRight: '16pt', width: '200pt', textAlign: 'right' }}>
                            <Text>Subtotal</Text>
                        </View>
                        <View>
                            <Text>690</Text>
                        </View>
                    </View>
                    <View style={{ margin: '0 16pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ marginRight: '16pt', width: '200pt', textAlign: 'right' }}>
                            <Text style={{ fontWeight: 'bold' }}>Total SGSD</Text>
                        </View>
                        <View>
                            <Text style={{ fontWeight: 'bold' }}>690</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={{ fontWeight: 'bold', fontSize: '12pt' }}>Due Date: 28 May 2022</Text>
                    <Text style={{ marginBottom: '10pt' }}>Kindly make payments via bank transfer to:</Text>
                    <Text>Account name: HireQuotient Technologies Pte Ltd</Text>
                    <Text>Account number: 0724424981</Text>
                    <Text>Name of Beneficiary Bank: DBS Bank</Text>
                    <Text>Bank Name: DBS Bank Ltd Singapore</Text>
                    <Text>DBS SWIFT Bank Identifier Code (BIC): DBSSSGSG</Text>
                </View>
                <View style={styles.footer} fixed>
                    <Text>Company Registration No: 202132244D. Registered Office: 67 Ayer Rajah Crescent #02-10/17, Singapore, 139950, Singapore.</Text>
                </View>
            </Page>
            <Page size="A4" style={styles.page1}>
                <View style={styles.trimBox}>
                    <Image src={ScissorIcon} style={styles.trimIcon} />
                    <View>
                        <Text style={{ fontSize: '28pt', fontWeight: '500' }}>PAYMENT ADVICE</Text>
                        <View style={styles.addressBox}>
                            <Text>To:</Text>
                            <View style={{ marginLeft: 4 }}>
                                <Text>HIREQUOTIENT TECHNOLOGIES PTE. LTD.</Text>
                                <Text>67 Ayer Rajah Crescent #02-10/17</Text>
                                <Text>SINGAPORE 139950</Text>
                                <Text>SINGAPORE</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={styles.paymentBox}>
                            <View style={styles.paymentBoxTitle}>
                                <Text>Customer</Text>
                                <Text>Invoice Number</Text>
                            </View>
                            <View style={styles.paymentBoxValues}>
                                <Text>Ula</Text>
                                <Text>INV-22040005</Text>
                            </View>
                        </View>
                        <View style={styles.paymentBox}>
                            <Text style={styles.paymentBoxTitle}>Amount Due Date</Text>
                            <View style={styles.paymentBoxValues}>
                                <Text style={{fontWeight: 'bold'}}>500.00</Text>
                                <Text>28 May 2022</Text>
                            </View>
                        </View>
                        <View style={styles.paymentLastBox}>
                            <Text style={styles.paymentBoxTitle}>Amount Enclosed</Text>
                            <View style={styles.paymentBoxValues}>
                                <View style={styles.amountBox}></View>
                                <View style={{ fontSize: 8, borderTop: '1px solid #000', paddingTop: 4 }}>
                                    <Text>Enter the amount you are paying above</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Text>Company Registration No: 202132244D. Registered Office: 67 Ayer Rajah Crescent #02-10/17, Singapore, 139950, Singapore.</Text>
                </View>
            </Page>
        </Document>
    )
}

export default Invoice;
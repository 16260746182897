import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "../../styles/CustomModal.scss";

const Modal = ({
    children,
    open,
    onClose,
    classNames = {},
    styles = {},
    center = false,
    closeOnOverlayClick = true,
    ...rest
}) => {
    const modalRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (e.target === modalRef.current) {
            onClose();
        }
    };

    useEffect(() => {
        if (open) {
            modalRef.current.style = "display: block";
            if (closeOnOverlayClick) {
                document.addEventListener("click", handleOutsideClick);
            }
        } else {
            modalRef.current.style = "display: none";
        }

        if (closeOnOverlayClick) {
            return () => {
                document.removeEventListener("click", handleOutsideClick);
            };
        }
    }, [open]);

    return (
        <div className="reactCustomModal" ref={modalRef} {...rest}>
            <div className="reactCustomModal__overlay" />
            <div
                className={`${
                    Object.keys(classNames).length > 0 &&
                    Object.prototype.hasOwnProperty.call(
                        classNames,
                        "container"
                    )
                        ? classNames.container
                        : ""
                } reactCustomModal__container ${
                    center ? "reactCustomModal__center" : ""
                } `}
                style={
                    Object.keys(styles).length > 0 &&
                    Object.prototype.hasOwnProperty.call(styles, "container")
                        ? styles.container
                        : {}
                }
            >
                <div
                    className={`${
                        Object.keys(classNames).length > 0 &&
                        Object.prototype.hasOwnProperty.call(
                            classNames,
                            "modal"
                        )
                            ? classNames.modal
                            : ""
                    } reactCustomModal__content`}
                    style={
                        Object.keys(styles).length > 0 &&
                        Object.prototype.hasOwnProperty.call(styles, "modal")
                            ? styles.modal
                            : {}
                    }
                >
                    <div
                        className={`${
                            Object.keys(classNames).length > 0 &&
                            Object.prototype.hasOwnProperty.call(
                                classNames,
                                "closeIcon"
                            )
                                ? classNames.closeIcon
                                : ""
                        } reactCustomModal__close`}
                        style={
                            Object.keys(styles).length > 0 &&
                            Object.prototype.hasOwnProperty.call(
                                styles,
                                "closeIcon"
                            )
                                ? styles.closeIcon
                                : {}
                        }
                        onClick={onClose}
                    >
                        <svg viewBox="0 0 36 36" fill="currentColor">
                            <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
                        </svg>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    other: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    classNames: PropTypes.object,
    styles: PropTypes.object,
    center: PropTypes.bool,
    closeOnOverlayClick: PropTypes.bool,
};

export default Modal;

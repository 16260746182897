import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCompanyFeatures, getCurrentWalkthroughStep, getCurrentWalkthroughType } from '../../selectors/app';
import { updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest, updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest } from '../../actions/app';

import '../../styles/walkthroughOverlay.scss';

const WalkthroughOverlay = (props) => {
    const { onClickDone, showWalkthroughBackBtn = true, showWalkthroughNextBtn, currentWalkthroughStep, updateCurrentWalkthroughStep, currentWalkthroughType, updateCurrentWalkthroughType, companyFeatures, onClickBack, showWalkthroughBackBtnOther = false } = props;

    const [stepMessage, setStepMessage] = useState('');
    const [stepMessageTop, setStepMessageTop] = useState(0);
    const [stepMessageRight, setStepMessageRight] = useState(0);
    const [leftArrow, setLeftArrow] = useState(false);
    const [rightArrow, setRightArrow] = useState(false);
    const [topArrow, setTopArrow] = useState(false);
    const [bottomArrow, setBottomArrow] = useState(false);
    const [stepCountMessage, setStepCountMessage] = useState('');

    useEffect(() => {
        if (currentWalkthroughType === 'workflow') {
            let elem = document.getElementById(`workflowStep${currentWalkthroughStep}`);
            if (currentWalkthroughStep === 6) {
                elem.scrollIntoView();
            }
            if (currentWalkthroughStep === 7) {
                if (!elem) {
                    elem = document.getElementById('testNewDriveFlow-selector-5');
                }
                elem.scrollIntoView();
            }
            if (currentWalkthroughStep === 9) {
                elem = document.getElementById('quickViewFlow-selector-8');
            }
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            let elemSkills = document.getElementsByClassName(`skillsRangeContainer`)[0];
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to add a new drive to your<br />dashboard');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(175);
                    setStepMessageRight(20);
                    break;
                case 2: setStepMessage("Start creating your first assessment<br />(or choose one of the default<br />assessments from above)");
                    setTopArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(window.innerHeight/4.5 - rect.y);
                    setStepMessageRight(rect.x);
                    break;
                case 3: setStepMessage('Add a name and brief description for<br />your drive');
                    setStepMessageTop(rect.y - 100);
                    setStepMessageRight(rect.x);
                    break;
                case 4: setStepMessage('Select the role for which you want to<br />create the assessment');
                    setBottomArrow(true)
                    setLeftArrow(false);
                    setStepMessageTop(rect.y - 130);
                    setStepMessageRight(rect.x);
                    break;
                case 5: setStepMessage(elemSkills ? 'Select Functional Skills and<br />Add Weightage to the core skills accordingly.' : 'De-select Critical Work Functions<br />and Key Tasks that are not relevant');
                    setLeftArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(rect.y - 125);
                    setStepMessageRight(rect.x);
                    break;
                case 6: setStepMessage('Add advanced customisations<br />like your own video questions<br />(upto 2) and customising<br />the problem statement to suit<br />your context');
                    setBottomArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y - 180);
                    setStepMessageRight(rect.x);
                    break;
                case 7: setStepMessage('<div style="text-align: center"><strong>Done !</strong><br />Click here to save your customisations</div>');
                    setLeftArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(rect.y - 120);
                    setStepMessageRight(window.innerWidth - rect.x - 250);
                    break;
                case 8: setStepMessage('You can see the created Recruitment Drive from here. You can click on View now');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    break;
                case 9: setStepMessage('To view your customised assessment, copy<br />the chatbot link from here and paste it in a new tab');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(rect.y + 70);
                    setStepMessageRight(rect.x - 750);
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'invite') {
            let rect;
            let rectSidebar;
            let elem = document.getElementById(`inviteStep${currentWalkthroughStep}`);
            let elemSidebar = document.getElementById('manageInvitation-selector-1');
            if (currentWalkthroughStep === 4) {
                elem = document.getElementById('testInviteRemindTemplateFlow-selector-6');
                elem.scrollIntoView();
            }
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            if(elemSidebar) rectSidebar = elemSidebar.getBoundingClientRect();
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 11');
                    break;
                case 2: setStepMessage("Go to the 'Manage Invitation' tab<br />from the side panel here");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(rectSidebar.y - 30);
                    setStepMessageRight(rectSidebar.x + window.innerWidth/2);
                    setStepCountMessage('Step 2 of 11');
                    break;
                case 3: setStepMessage('Customise the invitation email template<br />by adding your company logo and<br />changing the mail content to suit your<br />company style');
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(330);
                    setStepMessageRight((window.innerWidth/2) + 250);
                    setStepCountMessage('Step 3 of 11');
                    break;
                case 4: setStepMessage('Click here to save the<br />invitation email template');
                    setTopArrow(false);
                    setLeftArrow(false);
                    setBottomArrow(true)
                    setStepMessageRight(rect.x + 280);
                    setStepMessageTop(rect.y - 120);
                    setStepCountMessage('Step 4 of 11');
                    break;
                case 5: setStepMessage("Add individual candidate from here");
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(rect.y + 70);
                    setStepMessageRight(rect.x - 4*window.innerWidth/5);
                    setStepCountMessage('Step 5 of 11');
                    break;
                case 6: setStepMessage('Fill the details of the candidate');
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rect.y + 20);
                    setStepMessageRight(rect.x - 360);
                    setStepCountMessage('Step 6 of 11');
                    break;
                case 7: setStepMessage('Click here to add the candidate');
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 70);
                    setStepMessageRight(rect.x - 350);
                    setStepCountMessage('Step 7 of 11');
                    break;
                case 8: setStepMessage('You can choose to have a screening<br />invitation template from here');
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 70);
                    setStepMessageRight(rect.x + 100);
                    setStepCountMessage('Step 8 of 11');
                    break;
                case 9: setStepMessage('You can choose to have a pre-screening<br />stage from here. Choose the created pre-<br />screening bot and customised email<br />template afteer enabling from toggle<br />switch');
                    setTopArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(rect.y + 60);
                    setStepMessageRight(rect.x - 480);
                    setStepCountMessage('Step 9 of 11');
                    break;
                case 10: setStepMessage('If you want to remind candidates to<br >attempt the assessment, you can schedule<br />the auto-reminder mails from here. You<br />can also allow recurring reminders to be<br />sent at a defined time period.');
                    setTopArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(rect.y - 200);
                    setStepMessageRight(rect.x - 620);
                    setStepCountMessage('Step 10 of 11');
                    break;
                case 11: setStepMessage('You can set a deadline for submission,<br />after which the link will expire. It can be<br />later modified at candidate level.');
                    setTopArrow(false);
                    setBottomArrow(true);
                    setStepMessageTop(rect.y - 150);
                    setStepMessageRight(rect.x - 620);
                    setStepCountMessage('Step 11 of 11');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'evaluate') {
            let elem = currentWalkthroughStep > 4 ? document.getElementById(`evaluateStep${currentWalkthroughStep}`) : null;
            let elemAnalyse = document.getElementsByClassName(`evaluateStep${currentWalkthroughStep}`)[0];
            let elemSidebar = document.getElementById('reviewApplicantsFlow-selector-1');
            let rect;
            let rectAnalyse;
            let rectSidebar;
            if(elem) {
                rect = elem.getBoundingClientRect();
            }
            if(elemSidebar) {
                rectSidebar = elemSidebar.getBoundingClientRect();
            }
            if(elemAnalyse) {
                rectAnalyse = elemAnalyse.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 9');
                    break;
                case 2: setStepMessage("Go to the 'Review Applicants' tab<br />from the side panel here");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(rectSidebar.y - 20);
                    setStepMessageRight(rectSidebar.x + window.innerWidth/2);
                    setStepCountMessage('Step 2 of 9');
                    break;
                case 3: setStepMessage('The total scores for all<br />submissions are reflected here.<br />You can sort these scores to see<br />the top performing candidates');
                    setTopArrow(true);
                    setRightArrow(false);
                    setStepMessageTop(250);
                    setStepMessageRight(window.innerWidth/4);
                    setStepCountMessage('Step 3 of 9');
                    break;
                case 4: setStepMessage("View the reports, score their videos &<br />view their transcripts by clicking on<br />'Analyse' button. You will also be<br />provided with an Interviewer's Guide<br />educating you on the basis for the<br />questions");
                    setTopArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(rectAnalyse.y - 20);
                    setStepMessageRight(rectAnalyse.x - 1.4*window.innerWidth/3);
                    setStepCountMessage('Step 4 of 9');
                    break;
                case 5: setStepMessage("View overall report and analyse<br />the candidate's performance");
                    setTopArrow(true);
                    setRightArrow(false);
                    setStepMessageTop(window.innerHeight/3.8);
                    setStepMessageRight(window.innerWidth/1.4);
                    setStepCountMessage('Step 5 of 9');
                    break;
                case 6: setStepMessage("Check out the video answers<br />submitted by the candidates and<br />deep dive into further metrics");
                    setTopArrow(true);
                    setStepMessageTop(rect.y + 80);
                    setStepMessageRight(rect.x + window.innerWidth/3);
                    setStepCountMessage('Step 6 of 9');
                    break;
                case 7: setStepMessage("Get access to answers of all the<br />single/multiple choice questions");
                    setTopArrow(true);
                    setStepMessageTop(rect.y + 80);
                    setStepMessageRight(rect.x + window.innerWidth/11);
                    setStepCountMessage('Step 7 of 9');
                    break;
                case 8: setStepMessage(companyFeatures['interviewersGuide'] ? "View literature review on<br />all the assessment basics" : "View and analyse all the free text answers<br />in addition to the video answers");
                    setTopArrow(true);
                    setStepMessageTop(rect.y + 80);
                    setStepMessageRight(rect.x - window.innerWidth/10);
                    setStepCountMessage('Step 8 of 9');
                    break;
                case 9: setStepMessage("You are all set now to<br />shortlist and reject candidates<br />from here!");
                    setTopArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(200);
                    setStepMessageRight((window.innerWidth/2) - 400);
                    setStepCountMessage('Step 9 of 9');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'prescreening') {
            let rect;
            const elem = document.getElementById(`prescreeningAddBot${currentWalkthroughStep}`);
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to create a pre-screening bot.<br /><br /><span style="text-decoration: underline">Note:</span> Pre-screening bots are meant to ensure<br />candidates qualify the basic criteria, like educational<br />qualifications and past experience. Only the candidates<br />who clear the criteria will be sent the screening skill<br />assessment.');
                    setTopArrow(true);
                    setStepMessageTop(rect.y + 80);
                    setStepMessageRight(rect.x - 4.1*window.innerWidth/5);
                    setStepCountMessage('Step 1 of 10');
                    break;
                case 2: setStepMessage("Add the name to the pre-<br />screening bot");
                    setTopArrow(false);
                    setLeftArrow(false);
                    setRightArrow(true);
                    setStepMessageTop(rect.y - 20);
                    setStepMessageRight(rect.x + 780);
                    setStepCountMessage('Step 2 of 10');
                    break;
                case 3: setStepMessage("Add brief description about<br />your company");
                    setRightArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rect.y);
                    setStepMessageRight(rect.x - 500);
                    setStepCountMessage('Step 3 of 10');
                    break;
                case 4: setStepMessage("You can add your first question<br />here.");
                    setStepMessageTop(rect.y - 20);
                    setStepMessageRight(rect.x - 100);
                    setStepCountMessage('Step 4 of 10');
                    break;
                case 5: setStepMessage("Select the type of question from the<br />dropdown. Irrespective of whether it<br />is single or multi correct, you can<br />choose as many acceptable answers as<br />possible (there's no one correct<br />answers herre, right!)");
                    setStepMessageTop(rect.y - 20);
                    setStepMessageRight(rect.x - 700);
                    setStepCountMessage('Step 5 of 10');
                    break;
                case 6: setStepMessage("Start adding the options here. Press<br />'Enter/ Return' to go to the next<br />option.");
                    setStepMessageTop(rect.y - 25);
                    setStepMessageRight(rect.x - 60);
                    setStepCountMessage('Step 6 of 10');
                    break;
                case 7: setStepMessage("Choose the options that will be<br />acceptable for consideration. If a<br />candidate selects at least one option<br />which is not 'Acceptable', they will not<br />move ahead to the next screening<br />round.");
                    setStepMessageTop(rect.y - 30);
                    setStepMessageRight(rect.x - 790);
                    setStepCountMessage('Step 7 of 10');
                    break;
                case 8: setStepMessage("Use this icon to add the next<br />question");
                    setLeftArrow(true);
                    setBottomArrow(false);
                    setStepMessageTop(rect.y - 30);
                    setStepMessageRight(rect.x - 940);
                    setStepCountMessage('Step 8 of 10');
                    break;
                case 9: setStepMessage("Once you've added all<br />questions, options and<br />selected the question type<br />and acceptable answers,<br />click on 'Create'.");
                    setLeftArrow(false);
                    setBottomArrow(true);
                    if(rect) {
                        setStepMessageTop(rect.y - 190);
                        setStepMessageRight(rect.x - 730);
                    }
                    setStepCountMessage('Step 9 of 10');
                    break;
                case 10: setStepMessage("You can see the created pre-<br />screening bot here. You can<br />edit from here, and also send<br />a test email to yourself to<br />see the bot interface from<br />candidate's side.");
                    setLeftArrow(false);
                    setTopArrow(true);
                    setRightArrow(false);
                    setStepMessageRight(180);
                    setStepMessageTop(330);
                    setStepCountMessage('Step 10 of 10');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'createjob') {
            let elem = document.getElementById(`createjobStep${currentWalkthroughStep}`);
            let elem2 = document.getElementById('quickViewFlow-selector-9');
            let rect;
            let rect2;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            if(elem2) {
                rect2 = elem2.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 7');
                    break;
                case 2: setStepMessage("You can create a job portal where candidates can<br />directly apply. Once applied, they will get the<br />assessment for the next stage");
                    setTopArrow(true);
                    setStepMessageTop(rect2.y + 80);
                    setStepMessageRight(rect2.x - 4*window.innerWidth/5);
                    setStepCountMessage('Step 2 of 7');
                    break;
                case 3: setStepMessage("Add the name of the role and<br />it's brief description here");
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rect.y + 100);
                    setStepMessageRight(rect.x - 300);
                    setStepCountMessage('Step 3 of 7');
                    break;
                case 4: setStepMessage("Upload the relevant JD to support<br />your job opening. In case you <br />don't have a JD, click<br />here to create a new JD now");
                    setLeftArrow(false);
                    setStepMessageTop(rect.y - 170);
                    setStepMessageRight(rect.x);
                    setStepCountMessage('Step 4 of 7');
                    break;
                case 5: setStepMessage("We recommend you to add the<br />pre-screening stage to the flow.<br />Candidates who apply will instantly<br />get a pre-screening bot. Only<br />if they meet the required criteria<br />they will move forward and receive<br />the screening assessment.");
                    setStepMessageTop(rect.y - 230);
                    setStepMessageRight(rect.x);
                    setStepCountMessage('Step 5 of 7');
                    break;
                case 6: setStepMessage("Create the link from here");
                    elem.scrollIntoView();
                    setStepMessageTop(rect.y - 100);
                    setStepMessageRight(rect.x + 220);
                    setStepCountMessage('Step 6 of 7');
                    break;
                case 7: setStepMessage("You can get the generated<br />link from here. Copy the link<br />and paste it on Linkedin and<br />other job postings.");
                    setLeftArrow(false);
                    setBottomArrow(true);
                    if (rect) {
                        setStepMessageTop(rect.y - 170);
                        setStepMessageRight(rect.x);
                    } else {
                        setStepMessageTop(450);
                    }
                    setStepCountMessage('Step 7 of 7');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'sendemails') {
            const elem = document.getElementById(`sendEmails${currentWalkthroughStep}`);
            let rect;
            if(elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 4');
                    break;
                case 2: setStepMessage("If you want to view all shortlisted and<br />rejected candidates, and send out<br />customised mails to inform them of the<br />outcome, click here.");
                    setTopArrow(false);
                    setLeftArrow(true)
                    setStepMessageTop(300);
                    setStepMessageRight(window.innerWidth - 650);
                    setStepCountMessage('Step 2 of 4');
                    break;
                case 3: setStepMessage("You can send the email from 'Action'<br />column below, or click here to send<br />respective emails to all shortlisted and<br />rejected candidates at once.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(170);
                    setStepMessageRight(rect.x - window.innerWidth/1.8);
                    setStepCountMessage('Step 3 of 4');
                    break;
                case 4: setStepMessage("Select the category(s) where<br />you want to send the email and<br />the custom email template from<br />dropdown. You're good to send<br />emails! ");
                    setTopArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(250);
                    setStepMessageRight(window.innerWidth/2 - 600);
                    setStepCountMessage('Step 4 of 4');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'whitelabelling') {
            let elem = document.getElementById(`whitelabellingStep${currentWalkthroughStep}`);
            let sidebar = document.getElementById('settingsFlow-selector-1');
            let rect;
            let rectSidebar;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            if(sidebar) {
                rectSidebar = sidebar.getBoundingClientRect();
            }
            if (currentWalkthroughStep === 3) {
                elem.scrollIntoView();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 5');
                    break;
                case 2: setStepMessage("Click here to change the settings of the<br />drive.");
                    setBottomArrow(false);
                    setLeftArrow(true);
                    setStepMessageTop(rectSidebar.y - 20);
                    setStepMessageRight(rectSidebar.x + 730);
                    setStepCountMessage('Step 2 of 5');
                    break;
                case 3: setStepMessage("To change the logo in the dashboard<br />and the virtual interviewer interface,<br />click 'Change logo' from here.");
                    setBottomArrow(true);
                    setLeftArrow(false);
                    setTopArrow(false);
                    setStepMessageTop(rect.y - 150);
                    setStepMessageRight(rect.x - 250);
                    setStepCountMessage('Step 3 of 5');
                    break;
                case 4: setStepMessage("You can upload the relevant logo here");
                    setBottomArrow(false);
                    setTopArrow(true);
                    setStepMessageTop(rect.y + 120);
                    setStepMessageRight(rect.x + 100);
                    setStepCountMessage('Step 4 of 5');
                    break;
                case 5:
                    setStepMessage("Click here to save your changes");
                    setStepMessageTop(rect.y + 70);
                    setStepMessageRight(rect.x - 350);
                    setStepCountMessage('Step 5 of 5');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'automaticemails') {
            let elem = document.getElementById(`automaticemailsStep${currentWalkthroughStep}`);
            let sidebar = document.getElementById('settingsFlow-selector-1');
            if (currentWalkthroughStep === 3 || currentWalkthroughStep === 4) {
                elem.scrollIntoView();
            }
            let rect;
            let rectSidebar;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            if(sidebar) {
                rectSidebar = sidebar.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 4');
                    break;
                case 2: setStepMessage("Go to this tab for selecting drive-specific<br />settings, in this case 'Post-assessment emails'.");
                    setLeftArrow(true);
                    setStepMessageTop(rectSidebar.y - 20);
                    setStepMessageRight(rectSidebar.x + 690);
                    setStepCountMessage('Step 2 of 4');
                    break;
                case 3: setStepMessage("You can enable this feature to trigger<br />automatic emails to candidates post<br />they have completed the assessment.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 60);
                    setStepMessageRight(rect.x + 80);
                    setStepCountMessage('Step 3 of 4');
                    break;
                case 4: setStepMessage("Click here to customise the email - add<br />your company logo, change the body<br />and make it completely customised!");
                    setStepMessageTop(rect.y + 60);
                    setStepMessageRight(rect.x - 840);
                    setStepCountMessage('Step 4 of 4');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'videoproctoring') {
            let elem = document.getElementById(`videoproctoringStep${currentWalkthroughStep}`);
            let sidebar = document.getElementById('settingsFlow-selector-1');
            if (currentWalkthroughStep === 3) {
                elem.scrollIntoView();
            }
            let rect;
            let rectSidebar;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            if(sidebar) {
                rectSidebar = sidebar.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage('Click here to open the recruitment drive');
                    setTopArrow(true);
                    setStepMessageTop(320);
                    setStepMessageRight(60);
                    setStepCountMessage('Step 1 of 3');
                    break;
                case 2: setStepMessage("Go to this tab for selecting drive-specific<br />settings, in this case 'Video proctoring'.");
                    setLeftArrow(true);
                    setStepMessageTop(rectSidebar.y - 20);
                    setStepMessageRight(rectSidebar.x + 730);
                    setStepCountMessage('Step 2 of 3');
                    break;
                case 3: setStepMessage("Enable the feature from the toggle button on the right.<br />If video proctoring is enabled, candidates' videos will be<br />recorded during they are attempting the assessemnt and<br />flagged off by our AI based technology if any red flag is<br />predicted.");
                    setTopArrow(true);
                    setLeftArrow(false);
                    setStepMessageTop(rect.y + 100);
                    setStepMessageRight(rect.x - 100);
                    setStepCountMessage('Step 3 of 3');
                    break;
                default: setStepMessage('');
            }
        } else if (currentWalkthroughType === 'team') {
            let elem = currentWalkthroughStep > 2 ? document.getElementById('teamStep2') : document.getElementById(`teamStep${currentWalkthroughStep}`);
            let rect;
            if (elem) {
                rect = elem.getBoundingClientRect();
            }
            switch (currentWalkthroughStep) {
                case 0: setStepMessage('');
                    break;
                case 1: setStepMessage("To add your team members to the<br />dashboard, go to 'Manage Team' from<br />here");
                    setLeftArrow(true);
                    setBottomArrow(false);
                    if (rect) {
                        setStepMessageTop(rect.y - 20);
                    }
                    setStepMessageRight(window.innerWidth - 650);
                    setStepCountMessage('Step 1 of 5');
                    break;
                case 2: setStepMessage("Add the name of the team member<br />you want to invite.");
                    setLeftArrow(false);
                    setBottomArrow(true);
                    if (rect) {
                        setTimeout(() => {
                            setStepMessageTop(rect.y - 130);
                        }, 100)
                        setStepMessageRight(rect.x + 450);
                    }
                    setStepCountMessage('Step 2 of 5');
                    break;
                case 3: setStepMessage("Add their email ID");
                    if(rect) {
                        setStepMessageTop(rect.y - 110);
                        setStepMessageRight(rect.x + 250);
                    }
                    setStepCountMessage('Step 3 of 5');
                    break;
                case 4: setStepMessage("Set access level of the member to be invited:<br />Admin: Has authority to perform actions like<br />inviting, shortlisting etc<br />Member: Only has authority to view lists, but not<br />perform any function<br />Bias-free: Has all admin rights, but in an<br />unbiased way (personal identification<br />information is masked)");
                    if(rect) {
                        setStepMessageTop(rect.y - 250);
                        setStepMessageRight(rect.x - 100);
                    }
                    setStepCountMessage('Step 4 of 5');
                    break;
                case 5: setStepMessage("All done! Invite the member<br />by clicking here. They shall<br />receive an email from<br />no-reply@hirequotient.com<br />swith the dashboard credentials.");
                    if(rect) {
                        setStepMessageTop(rect.y - 190);
                        setStepMessageRight(rect.x - 300);
                    }
                    setStepCountMessage('Step 5 of 5');
                    break;
                default: setStepMessage('');
            }
        }
    }, [currentWalkthroughStep, currentWalkthroughType]);

    const onClickExit = () => {
        updateCurrentWalkthroughStep(0);
        updateCurrentWalkthroughType('');
    }

    return (
        <div className='walkthrough-overlay' style={{ backgroundColor: currentWalkthroughStep === 2 && currentWalkthroughType === 'workflow' && 'transparent' }}>
            {
                stepMessage !== '' ? (
                    <div className='step-box' style={{ top: stepMessageTop, right: stepMessageRight }}>
                        <div style={{ fontSize: 12, marginBottom: 5 }}>{stepCountMessage}</div>
                        <div dangerouslySetInnerHTML={{ __html: stepMessage }} />
                        {
                            leftArrow ? (
                                <div className='left-arrow' />
                            ) : null
                        }
                        {
                            topArrow ? (
                                <div className='top-arrow' />
                            ) : null
                        }
                        {
                            bottomArrow ? (
                                <div className='bottom-arrow' />
                            ) : null
                        }
                        {
                            rightArrow ? (
                                <div className='right-arrow' />
                            ) : null
                        }
                    </div>
                ) : null
            }
            <div style={{ display: 'flex', padding: '20px 50px', justifyContent: 'space-between', width: '100%' }}>
                <div>
                    {
                        showWalkthroughBackBtnOther && currentWalkthroughStep > 1 &&
                            <div
                                style={{
                                    padding: '8px 32px',
                                    fontWeight: '600',
                                    backgroundColor: '#479BD2',
                                    color: '#fff',
                                    borderRadius: 40,
                                    cursor: 'pointer',
                                    marginBottom: 16,
                                    width: 'fit-content'
                                }}
                                onClick={onClickBack}
                            >
                                Back
                            </div>
                    }
                    <div
                        id="guidedWalkthroughFlow-selector-6"
                        style={{ padding: '8px 32px', fontWeight: '600', backgroundColor: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }}
                        onClick={onClickExit}
                    >
                        Exit Walkthrough
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        showWalkthroughBackBtn && currentWalkthroughStep > 1 &&
                            <div
                                style={{
                                    padding: '8px 32px',
                                    fontWeight: '600',
                                    backgroundColor: '#479BD2',
                                    color: '#fff',
                                    borderRadius: 40,
                                    cursor: 'pointer',
                                    marginRight: 16
                                }}
                                onClick={onClickBack}
                            >
                                Back
                            </div>
                    }
                    {
                        showWalkthroughNextBtn && currentWalkthroughStep < 10 ? (
                            <>
                                <div
                                    style={{
                                        padding: '8px 32px',
                                        fontWeight: '600',
                                        background: '#479BD2',
                                        color: '#fff',
                                        borderRadius: 40,
                                        cursor: 'pointer'
                                    }}
                                    onClick={onClickDone}
                                    id="guidedWalkthroughFlow-selector-7"
                                >
                                    Next
                                </div>
                                {/* <div style={{ marginTop: 10, color: '#fff', fontWeight: '600' }}>(Go to next step)</div> */}
                            </>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

WalkthroughOverlay.propTypes = {
    onClickExit: PropTypes.func,
    onClickDone: PropTypes.func,
    showWalkthroughNextBtn: PropTypes.bool,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
    updateCurrentWalkthroughType: PropTypes.func,
    companyFeatures: PropTypes.object,
    onClickBack: PropTypes.func,
    showWalkthroughBackBtn: PropTypes.bool,
    showWalkthroughBackBtnOther: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
    companyFeatures: getCompanyFeatures(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType()
});

const mapDispatchToProps = (dispatch) => ({
    updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) => dispatch(updateCurrentWalkthroughTypeRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughOverlay);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../../styles/select.scss';

const Select = (props) => {
	const { selectedValue, dropdownData, onChangeSelect, emptyValue, styles = {}, dropdownAddItem, classNames = {}, label, id, dropdownId } = props;

	const [showDropdown, setShowDropdown] = useState(false);

	const dropdownRef = useRef();
	const dropdownContentRef = useRef();

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	const onClickSelectItem = (val) => {
		setShowDropdown(false);
		onChangeSelect(val);
	}

	return (
		<div className={`select ${Object.keys(classNames).length > 0 && Object.prototype.hasOwnProperty.call(classNames, 'root') ? classNames.root : ''}`} ref={dropdownRef} style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'root') ? styles.root : {}} id={id ? id : ''}>
			<div className={`wrapper ${emptyValue ? 'empty' : ''} ${showDropdown ? 'open' : ''} ${selectedValue === "" || selectedValue === undefined || selectedValue === null || (selectedValue && typeof selectedValue === 'string' && selectedValue.toLowerCase().includes('select')) ? 'label-selected' : 'value-selected'}`} onClick={toggleDropdown} style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'wrapper') ? styles.wrapper : {}}>{selectedValue === "" || selectedValue === undefined || selectedValue === null ? label === undefined || label === "" || label === null ? 'Select' : label : selectedValue}</div>
			{
				showDropdown ? (
					<div className='dropdown' ref={dropdownContentRef} style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'dropdown') ? styles.dropdown : {}}>
						{
							dropdownAddItem && dropdownAddItem.text !== '' ? (
								<Link style={{ display: 'block', textDecoration: 'none' }} className='dropdown-item' to={dropdownAddItem.link} target="_blank">{dropdownAddItem.text}</Link>
							) : ''
						}
						{
							dropdownData.map((dd) => (
								<div className='dropdown-item' onClick={() => onClickSelectItem(dd)} id={dropdownId ? dropdownId : ''} key={dd.value}>{dd.label}</div>
							))
						}
					</div>
				) : ''
			}
			{selectedValue !== "" && selectedValue !== undefined && selectedValue !== null ? <label className="select-floating-label">{label}</label> : ''}
		</div>
	)
}

Select.propTypes = {
	selectedValue: PropTypes.string,
	dropdownData: PropTypes.array,
	onChangeSelect: PropTypes.func,
	emptyValue: PropTypes.bool,
	dropdownAddItem: PropTypes.object,
	classNames: PropTypes.object,
	styles: PropTypes.object,
	label: PropTypes.string,
	id: PropTypes.string,
	dropdownId: PropTypes.string
}

export default Select;
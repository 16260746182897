import React from 'react';
import PropTypes from 'prop-types';

import loadingGif from '../../assets/loading.gif';

const Loader = ({ size = 50, style }) => {
	return (
		<div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', ...style }}>
			<img src={loadingGif} width={size} height={size} alt='loading-gif' />
		</div>
	)
}

Loader.propTypes = {
	size: PropTypes.number,
	style: PropTypes.object
}

export default Loader;

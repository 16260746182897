import React from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarSuperAdmin from "./components/sidebar/SidebarSuperAdmin";
import Sidebar1 from "./components/sidebar/Sidebar1";
import Sidebar from "./components/sidebar/Sidebar";

function PageLayout({ sidebarType, children, locationCheck }) {
    const location = useLocation();
    const navigate = useNavigate();

    if (locationCheck && !location.state) {
        navigate("/workflows");
        return <></>;
    }

    return (
        <>
            {sidebarType === "SUPER_ADMIN" ? (
                <SidebarSuperAdmin />
            ) : sidebarType === "CLOSED" ? (
                <Sidebar1 />
            ) : sidebarType === "OPENED" ? (
                <Sidebar />
            ) : null}
            {children}
        </>
    );
}

PageLayout.propTypes = {
    sidebarType: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    locationCheck: PropTypes.bool,
};

export default PageLayout;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
    Route,
    useLocation,
    useNavigate,
    Routes,
    Navigate,
} from "react-router-dom";
import { TourProvider } from "@reactour/tour";

import Analytics from "./containers/Analytics";
import Applicant from "./containers/Applicant";
import AllApplicants from "./containers/AllApplicants";
import BehaviourAnalytics from "./containers/BehaviourAnalytics";
import Bots from "./containers/Bots";
import Company from "./containers/Company";
import Feedback from "./containers/Feedback";
import ReviewedApplicants from "./containers/ReviewedApplicants";
import PageLayout from "./PageLayout";
import ManageBots from "./containers/ManageBots";
import ManageFeatures from "./containers/ManageFeatures/ManageFeatures.tsx";
import ManageCompanyUsers from "./containers/ManageCompanyUsers";
import ManageNotifications from "./containers/ManageNotifications";
import ManageWorkflows from "./containers/ManageWorkflows";
import MyAccount from "./containers/MyAccount";
import ManageInvitations from "./containers/ManageInvitations";
import ManageSkillCodes from "./containers/ManageSkillCodes";
import PreScreeningDashboard from "./containers/PreScreeningDashboard";
import QuickMenu from "./containers/QuickMenu";
import RestartTest from "./containers/RestartTest";
import ReviewApplicants from "./containers/ReviewApplicants";
import SaasPlanDetails from "./containers/SaasPlanDetails";
import SaasPlans from "./containers/SaasPlans";
import Settings from "./containers/Settings";
import SignIn from "./containers/SignIn";
import Snackbar from "./components/common/Snackbar";
import SuperUser from "./containers/SuperUser";
import SupportMsgs from "./containers/SupportMsgs";
import Team from "./containers/Team";
import TrialUsers from "./containers/TrialUsers";
import Workflow from "./containers/Workflow";
import VideoSubmission from "./containers/VideoSubmission";
import VideoApplicant from "./containers/VideoApplicant";
import Integrations from "./containers/Integrations";
import LeverIntegration from "./containers/LeverIntegration";
import ZohoIntegration from "./containers/ZohoIntegration";
import ZohoAuthorization from "./containers/ZohoAuthorization";
import { getErrorMsg, getSuccessMsg, getUserPlan } from "./selectors/app";
import {
    resetSuccessMsg as resetSuccessMsgRequest,
    resetErrorMsg as resetErrorMsgRequest,
    fetchSaasPlanDetails as fetchSaasPlanDetailsRequest,
} from "./actions/app";
import VideoPage from "./components/VideoPage.jsx";
import { SSO } from "./components/sso/SSO.jsx";
import SSOComplete from "./components/sso/SSOComplete.jsx";

const opositeSide = {
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right",
};

const doArrow = (position, verticalAlign, horizontalAlign) => {
    if (!position || position === "custom") {
        return {};
    }

    const side = 30;
    const isVertical = position === "top" || position === "bottom";
    const width = isVertical ? side : side * 0.866;
    const height = isVertical ? side * 0.866 : side;
    const color = "rgba(33, 95, 129, 1)";
    const spaceFromSide = 10;
    const obj = isVertical
        ? {
            borderLeft: `${width / 2}px solid transparent`, // CSS Triangle width
            borderRight: `${width / 2}px solid transparent`, // CSS Triangle width
            [`border${position[0].toUpperCase()}${position.substring(
                1
            )}`]: `${height}px solid ${color}`, // CSS Triangle height
            [isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
                width + spaceFromSide, // space from side
            [opositeSide[position]]: -height,
        }
        : {
            borderTop: `${height / 2}px solid transparent`, // CSS Triangle width
            borderBottom: `${height / 2}px solid transparent`, // CSS Triangle width
            [`border${position[0].toUpperCase()}${position.substring(
                1
            )}`]: `${width}px solid ${color}`, // CSS Triangle height
            [isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
                height + spaceFromSide, // space from side
            [opositeSide[position]]: -width,
        };

    return {
        "&::after": {
            content: "''",
            width: 0,
            height: 0,
            position: "absolute",
            ...obj,
        },
    };
};

const styles = {
    popover: (base, state) => {
        return {
            ...base,
            background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
            borderRadius: 40,
            paddingLeft: 35,
            paddingRight: 35,
            paddingTop: 20,
            paddingBottom: 20,
            maxWidth: 600,
            margin: 30,
            ...doArrow(
                state.position,
                state.verticalAlign,
                state.horizontalAlign
            ),
        };
    },
};

const AppRouter = (props) => {
    const {
        resetSuccessMsg,
        successMsg,
        userPlan,
        fetchSaasPlanDetails,
        errorMsg,
        resetErrorMsg,
    } = props;

    const refObj = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const [greenSnackOpen, setGreenSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [shouldShowTour, setShouldShowTour] = useState(
        localStorage.getItem("userName") ? false : true
    );
    const [notification, setNotification] = useState({});
    const [listening, setListening] = useState(false);

    useEffect(() => {
        if (!listening) {
            const events = new EventSource(
                `${process.env.REACT_APP_BASE_SERVER_URL_FULL
                }/events/${localStorage.getItem("email")}`
            );

            events.onmessage = (event) => {
                const parsedData = JSON.parse(event.data);

                if (!Array.isArray(parsedData)) {
                    setNotification(parsedData);
                }
            };

            setListening(true);
        }
    }, [listening]);

    useEffect(() => {
        sessionStorage.clear();

        if (userPlan.name) {
            fetchSaasPlanDetails({
                planName: userPlan.name,
            });
        }
    }, []);

    const handleGreenSnackClick = () => {
        setGreenSnackOpen(true);
    };

    const handleGreenSnackClose = () => {
        setGreenSnackOpen(false);
        resetSuccessMsg();
    };

    const handleErrorSnackClick = () => {
        setErrorSnackOpen(true);
    };

    const handleErrorSnackClose = () => {
        setErrorSnackOpen(false);
        resetErrorMsg();
    };

    useEffect(() => {
        if (!successMsg) return;

        handleGreenSnackClick();
    }, [successMsg]);

    useEffect(() => {
        if (!errorMsg) return;

        handleErrorSnackClick();
    }, [errorMsg]);

    const onBeforeClose = () => {
        if (location.pathname !== "workflows") {
            navigate("/workflows");
        }
    };

    return (
        <>
            <TourProvider
                steps={[]}
                showNavigation={false}
                showCloseButton={false}
                showButtons={false}
                showNumber={false}
                disableInteraction={true}
                showBadge={false}
                onClickMask={() => { }}
                styles={styles}
                position="bottom"
                startAt={0}
                beforeClose={onBeforeClose}
            >
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/signin" replace />}
                    />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signin/sso" element={<SSO />} />
                    <Route path="/signin/sso/complete" element={<SSOComplete />} />
                    <Route
                        exact
                        path="/video/proctoring"
                        element={<VideoPage />}
                    />
                    <Route
                        exact
                        path="/video/answer"
                        element={<VideoPage />}
                    />
                    <Route path="/forgot-password" element={<SignIn />} />
                    <Route
                        path="/companies"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <Company />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/bots"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <Bots />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/user-plans"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <SaasPlans />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/user-plans/:userPlanId"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <SaasPlanDetails />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/manage-notifications"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageNotifications />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/superUser"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <SuperUser />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/feedback"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <Feedback />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/support-msgs"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <SupportMsgs />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/workflows"
                        element={
                            <PageLayout sidebarType="CLOSED">
                                <Workflow
                                    shouldShowTour={shouldShowTour}
                                    setShouldShowTour={setShouldShowTour}
                                    notification={notification}
                                    refObj={refObj}
                                />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/analytics"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <Analytics notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/workflows/:workflowId"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <QuickMenu notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/applicant/:id"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <Applicant notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/video-applicant/:id"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <VideoApplicant notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/applicants"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <AllApplicants notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/new-applicants"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <ManageInvitations
                                    notification={notification}
                                />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/review-applicants"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <ReviewApplicants notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <Settings notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/finalized"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <ReviewedApplicants
                                    notification={notification}
                                />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/video-submission"
                        element={
                            <PageLayout
                                sidebarType="OPENED"
                                locationCheck={true}
                            >
                                <VideoSubmission notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/team"
                        element={
                            <PageLayout sidebarType="CLOSED">
                                <Team notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/my-account"
                        element={
                            <PageLayout sidebarType="CLOSED">
                                <MyAccount notification={notification} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/prescreening/dashboard"
                        element={
                            <PageLayout sidebarType="CLOSED">
                                <PreScreeningDashboard
                                    notification={notification}
                                />
                            </PageLayout>
                        }
                    />
                    <Route path="/jd-users" element={<TrialUsers />} />
                    <Route
                        path="/manage-bots"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageBots />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/manage-features"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageFeatures />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/behaviour-analytics"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <BehaviourAnalytics />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/restart-test"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <RestartTest />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/manage-skill-codes"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageSkillCodes />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/manage-company-users"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageCompanyUsers />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/manage-workflows"
                        element={
                            <PageLayout sidebarType="SUPER_ADMIN">
                                <ManageWorkflows />
                            </PageLayout>
                        }
                    />
                    <Route path="/lever" element={<LeverIntegration />} />
                    <Route path="/zoho" element={<ZohoIntegration />} />
                    <Route
                        path="/zoho-authorization/:status"
                        element={<ZohoAuthorization />}
                    />
                    <Route
                        path="/integrations"
                        element={
                            <PageLayout sidebarType="CLOSED">
                                <Integrations />
                            </PageLayout>
                        }
                    />
                </Routes>
            </TourProvider>
            <Snackbar
                open={greenSnackOpen}
                autoHideDuration={2500}
                onClose={handleGreenSnackClose}
                msg={successMsg}
                type="success"
            />
            <Snackbar
                open={errorSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrorSnackClose}
                msg={errorMsg}
                type="error"
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    successMsg: getSuccessMsg(),
    errorMsg: getErrorMsg(),
    userPlan: getUserPlan(),
});

const mapDispatchToProps = (dispatch) => ({
    resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
    resetErrorMsg: () => dispatch(resetErrorMsgRequest()),
    fetchSaasPlanDetails: (data) => dispatch(fetchSaasPlanDetailsRequest(data)),
});

AppRouter.propTypes = {
    successMsg: PropTypes.string,
    errorMsg: PropTypes.string,
    userPlan: PropTypes.object,
    resetSuccessMsg: PropTypes.func,
    resetErrorMsg: PropTypes.func,
    fetchSaasPlanDetails: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);

import { percentageColor } from "../constants/reports";

export const getBucket = (score) => {
    if (score < 25) return 0;
    else if (score < 60) return 1;
    else return 2;
};

export const doughnutData = (value) => {
    return {
        labels: [],
        datasets: [
            {
                data: [value, 100 - value],
                borderColor: ["lightgrey", "lightgrey"],
                backgroundColor: [
                    percentageColor[getBucket(value)],
                    "lightgrey",
                ],
                scale: {
                    scaleLabel: {
                        display: true,
                        labelString: "",
                    },
                },
            },
        ],
    };
};

export const getJobRole = (productId) => {
    let jobRole = "consulting";

    if (Object.prototype.hasOwnProperty.call(productId, "role")) {
        if (productId.role.toLowerCase().includes("sale")) {
            jobRole = "sdr";
        } else if (productId.role.toLowerCase().includes("consultant")) {
            jobRole = "consulting";
        } else if (productId.role.toLowerCase().includes("product")) {
            jobRole = "product";
        } else if (productId.role.toLowerCase().includes("marketing")) {
            jobRole = "marketing";
        } else if (
            productId.role.toLowerCase().includes("proj") ||
            productId.role.toLowerCase().includes("chatbotrazorswe")
        ) {
            jobRole = "project";
        } else if (productId.role.toLowerCase().includes("business")) {
            jobRole = "business";
        } else if (productId.role.toLowerCase().includes("chatbotdhlap")) {
            jobRole = "finance";
        } else if (productId.role.toLowerCase().includes("chatbotdhlar")) {
            jobRole = "accountReceievables";
        } else {
            jobRole = "consulting";
        }
    } else if (Object.prototype.hasOwnProperty.call(productId, "customId")) {
        if (
            productId.customId.toLowerCase().includes("chatquestionproae") ||
            productId.customId.toLowerCase().includes("sale") ||
            productId.customId.toLowerCase().includes("sdr") ||
            productId.customId.toLowerCase().includes("tangerine") ||
            productId.customId.toLowerCase().includes("awesome") ||
            productId.customId.toLowerCase().includes("chatae")
        ) {
            jobRole = "sdr";
        } else if (
            productId.customId
                .toLowerCase()
                .includes("investment analyst 2 - assura") ||
            productId.customId
                .toLowerCase()
                .includes("corporate finance 1 -  investment mna") ||
            productId.customId
                .toLowerCase()
                .includes("investment analyst 1 - innovacom") ||
            productId.customId
                .toLowerCase()
                .includes("Defence Merit Scholar - Policy Office") ||
            productId.customId.toLowerCase().includes("brightstep") ||
            productId.customId.toLowerCase().includes("consultant")
        ) {
            jobRole = "consulting";
        } else if (
            productId.customId.toLowerCase().includes("chatpm") ||
            productId.customId.toLowerCase().includes("product")
        ) {
            jobRole = "product";
        } else if (productId.customId.toLowerCase().includes("marketing")) {
            jobRole = "marketing";
        } else if (
            productId.customId.toLowerCase().includes("proj") ||
            productId.customId.toLowerCase().includes("chatbotrazorswe")
        ) {
            jobRole = "project";
        } else if (productId.customId.toLowerCase().includes("chatbotdhlap")) {
            jobRole = "finance";
        } else if (productId.customId.toLowerCase().includes("business")) {
            jobRole = "business";
        } else if (productId.customId.toLowerCase().includes("chatbotdhlar")) {
            jobRole = "accountReceievables";
        }
    } else {
        if (
            productId.toLowerCase().includes("sale") ||
            productId.toLowerCase().includes("sdr") ||
            productId.toLowerCase().includes("tangerine") ||
            productId.toLowerCase().includes("awesome") ||
            productId.toLowerCase().includes("chatae")
        ) {
            jobRole = "sdr";
        } else if (
            productId.toLowerCase().includes("investment analyst 2 - assura") ||
            productId
                .toLowerCase()
                .includes("corporate finance 1 -  investment mna") ||
            productId
                .toLowerCase()
                .includes("investment analyst 1 - innovacom") ||
            productId.includes("Defence Merit Scholar - Policy Office") ||
            productId.toLowerCase().includes("brightstep") ||
            productId.toLowerCase().includes("consultant")
        ) {
            jobRole = "consulting";
        } else if (
            productId.toLowerCase().includes("chatpm") ||
            productId.toLowerCase().includes("product")
        ) {
            jobRole = "product";
        } else if (productId.toLowerCase().includes("marketing")) {
            jobRole = "marketing";
        } else if (
            productId.toLowerCase().includes("proj") ||
            productId.toLowerCase().includes("chatbotrazorswe")
        ) {
            jobRole = "project";
        } else if (productId.toLowerCase().includes("business")) {
            jobRole = "business";
        } else if (productId.toLowerCase().includes("chatbotdhlap")) {
            jobRole = "finance";
        } else if (productId.toLowerCase().includes("chatbotdhlar")) {
            jobRole = "accountReceievables";
        }
    }

    return jobRole;
};

export const getCandidateStatus = (status) => {
    if (status == "Complete") {
        return "Submitted";
    } else {
        if (status === "In Progress") {
            return "Opened<br/>(0% completed)";
        } else {
            if (isNaN(status)) {
                return status;
            } else if (status > 0) {
                return `${status}%<br/>completed`;
            } else {
                return "Opened<br/>(0% completed)";
            }
        }
    }
};

import React, { useMemo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "../../utils/axios";
import Button from "../../components/common/Button";
import IconButton from "../../components/common/IconButton";
import Modal from "../../components/common/CustomModal";
import Snackbar from "../../components/common/Snackbar";
import { redirectUnauthorisedUser } from "../../helper/helperFunction";

import "../../styles/react-draft-wysiwyg.css";
import "../../styles/manageInvitations/addInviteEmail.scss";
import "react-quill/dist/quill.snow.css";
import WalkthroughOverlay from "../common/WalkthroughOverlay";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const AddInviteEmail = (props) => {
    const {
        field,
        currentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughStep,
        showWalkthrough,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [modalopen, setModalOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [snackErr, setSnackErr] = useState("");
    const [snackSuccess, setSnackSuccess] = useState("");
    const [name, setName] = useState(field.name ? field.name : "");
    const [img, setImg] = useState(field.image ? field.image : "");
    const [file, setFile] = useState(
        field.image
            ? process.env.REACT_APP_STORAGE_BUCKET_URL + field.image
            : ""
    );
    const [subject, setsubject] = useState(field.subject ? field.subject : ""); //subject
    const [path, setPath] = useState(field.image ? field.image : ""); //subject
    const [buttonText, setbuttonText] = useState(
        field.button ? field.button : ""
    ); //buttonText
    const [preEditorState, setPreEditorState] = useState(
        props.field.body ? props.field.body : ""
    );
    const [postEditorState, setPostEditorState] = useState(
        props.field.body2 ? props.field.body2 : ""
    );
    const [saveEmailLoading, setSaveEmailLoading] = useState(false);

    const onCloseModal = () => {
        setModalOpen(false);
        setSaveEmailLoading(false);
    };

    const onSnackClose = () => setSnackOpen(false);

    const onSnackOpen = () => setSnackOpen(true);

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleErrSnackClose = () => {
        setErrSnackOpen(false);
    };

    const onOpenModal = () => {
        setModalOpen(true);
        if (
            currentWalkthroughStep === 3 &&
            currentWalkthroughType === "invite" &&
            showWalkthrough
        )
            updateCurrentWalkthroughStep(currentWalkthroughStep + 1);
    };

    const getRenderTemplate = () => {
        return `<div style="width:100%;background:#F5F5F5">
					<div style="width:500px;margin:auto;background:#fff;padding:20px;">
		  				<div style="width: 500px;text-align: center;">
							<img src="${process.env.REACT_APP_STORAGE_BUCKET_URL}${path}" style=" max-width: 40%; height: auto;"/>
		  				</div>
		  				<p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
						<br/>
		  				<div style="text-align: center;margin-top:15px;;margin-bottom:15px;">
			 				<a href="{{link}}" target="_blank" style="padding:6px 20px;border-radius:10em;background:#7823DC;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;">${buttonText}</a>
		  				</div>
		  				<br/>
						{{deadline}}
		  				<p><span style="font-size:16px; text-align: left;">${postEditorState}</span></p>
		  				<p><span style="font-size:12px;overflow-wrap: break-word;"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at support@hirequotient.com.</span></p>
					</div>
				</div>`;
    };

    const updateEmail = () => {
        if (
            currentWalkthroughStep === 4 &&
            currentWalkthroughType === "invite" &&
            showWalkthrough
        ) {
            updateCurrentWalkthroughStep(currentWalkthroughStep + 1);
            onCloseModal();
        }

        let data = {
            render: getRenderTemplate(),
            body: preEditorState,
            body2: postEditorState,
            image: file,
            subject: subject,
            button: buttonText,
        };

        var formData = new FormData();
        formData.append("workflowId", location.state.workflowId);
        formData.append("key", props.type);
        formData.append("index", props.index);
        formData.append("image", img);
        formData.append("path", path);
        formData.append("name", name);
        formData.append("body", data["body"]);
        formData.append("body2", data["body2"]);
        formData.append("subject", subject);
        formData.append("button", buttonText);
        formData.append("render", data["render"]);
        setSaveEmailLoading(true);
        axios
            .post("/workflow/setemail", formData, {
                "Content-Type": "multipart/form-data",
            })
            .then(() => {
                onCloseModal();
                onSnackOpen();
                setSnackSuccess("Email Template saved successfully");
                props.setTempChange(props.tempChange + 1);
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
                handleErrSnackClick();
                setSnackErr("Could not update email at the moment");
                onCloseModal();
            });
    };

    function getBase64(file1) {
        var reader = new FileReader();
        var reader2 = new FileReader();
        reader.readAsArrayBuffer(file1);
        reader2.readAsDataURL(file1);
        reader.onload = function () {
            //  setImg(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader2.onload = function () {
            setFile(reader2.result);
        };
        reader2.onerror = function (error) {
            console.log("Error: ", error);
        };
    }
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        const dname = location.state.workflowId + props.type + props.index;
        const files2 = acceptedFiles.find((file) => file);
        const files = acceptedFiles.map((file) => {
            //  console.log(file.name)
            setPath(dname + file.name.slice(-4));
            return new File([file], dname + file.name.slice(-4), {
                type: file.type,
            });
        });
        //  console.log(files)
        setFile(files);
        setImg(files[0]);
        getBase64(files2);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ accept: "image/*", onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    function changeSubject(e) {
        setsubject(e.target.value);
    }

    function changeButtonText(e) {
        setbuttonText(e.target.value);
    }

    const handlePreEditorChange = (content, delta, source, editor) => {
        setPreEditorState(editor.getHTML());
    };

    const handlePostEditorChange = (content, delta, source, editor) => {
        setPostEditorState(editor.getHTML());
    };

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ color: [] }, { background: [] }],
            ],
        }),
        []
    );

    const formats = useMemo(
        () => [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "background",
        ],
        []
    );

    const sendTestMail = (e) => {
        e.stopPropagation();
        const emailIndex = {
            body: preEditorState,
            body2: postEditorState,
            button: buttonText,
            image: img,
            name,
            render: getRenderTemplate(),
            subject,
        };

        const uri = "/company/workflow/testmail";
        const getData = {
            workflowId: location.state.workflowId,
            emailIndex,
        };

        axios
            .post(uri, getData)
            .then((response) => {
                console.log(response);
                onSnackOpen();
                setSnackSuccess("Test email sent successfully");
            })
            .catch((err) => {
                console.log(err);
                handleErrSnackClick();
                setSnackErr("Could not send test email at the moment");
            });
    };

    useEffect(() => {
        if (modalopen) {
            setName(field.name ? field.name : "");
            setsubject(field.subject ? field.subject : "");
            setImg(field.image ? field.image : "");
            setFile(
                field.image
                    ? process.env.REACT_APP_STORAGE_BUCKET_URL + field.image
                    : ""
            );
            setPath(field.image ? field.image : "");
            setbuttonText(field.button ? field.button : "");
            setPreEditorState(field.body ? field.body : "");
            setPostEditorState(field.body2 ? field.body2 : "");
        }
    }, [modalopen]);

    return (
        <div
            className="add-card"
            style={{
                position: "relative",
                zIndex:
                    currentWalkthroughStep === 3 &&
                    currentWalkthroughType === "invite" &&
                    showWalkthrough
                        ? 1000
                        : "auto",
            }}
            id="inviteStep3"
        >
            <div>
                <IconButton
                    aria-label="add"
                    onClick={onOpenModal}
                    id="testInviteRemindTemplateFlow-selector-1"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#014954"
                        width={24}
                        height={24}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                </IconButton>
                <p className="template-title">Add invitation template</p>
            </div>

            <Modal
                open={modalopen}
                onClose={onCloseModal}
                classNames={{ modal: "customModal" }}
                center
            >
                <div style={{ display: "flex" }}>
                    <div className="email-editing-container">
                        <div className="update-logo-container">
                            <div {...getRootProps({ style })} style={baseStyle}>
                                <img
                                    src={file}
                                    style={{
                                        maxWidth: "40%",
                                        width: "300px",
                                        maxHeight: "180px",
                                    }}
                                />
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p style={{ textAlign: "center" }}>
                                        Change Logo
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                                top: "4px",
                                                position: "relative",
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 4.5v15m7.5-7.5h-15"
                                            />
                                        </svg>
                                        <br />
                                        <span style={{ fontSize: "12px" }}>
                                            150px (h) by 450px (w)
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="modal-input">
                            <div style={{ marginLeft: "2%" }}>
                                <label>Name</label>
                                <br />
                                <input
                                    id="testInviteRemindTemplateFlow-selector-2"
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder="Name"
                                    style={{
                                        width: "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </div>
                            <div style={{ marginLeft: "2%" }}>
                                <label>Subject</label>
                                <br />
                                <input
                                    id="testInviteRemindTemplateFlow-selector-3"
                                    type="text"
                                    onChange={changeSubject}
                                    value={subject}
                                    placeholder="Subject"
                                    style={{
                                        width: "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </div>
                            <div style={{ margin: "10px 0 10px 2%" }}>
                                <label>Button</label>
                                <br />
                                <input
                                    id="testInviteRemindTemplateFlow-selector-4"
                                    type="text"
                                    onChange={changeButtonText}
                                    value={buttonText}
                                    placeholder="Button Name"
                                    style={{
                                        width: "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="quill-container">
                            <p
                                style={{
                                    margin: "0 0 10px 10px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                Body before button
                            </p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={preEditorState}
                                onChange={handlePreEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div className="quill-container">
                            <p
                                style={{
                                    margin: "0 0 10px 10px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                Body after button
                            </p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={postEditorState}
                                onChange={handlePostEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                id="testInviteRemindTemplateFlow-selector-5"
                                style={{
                                    color: "#fff",
                                    background: "orange",
                                    margin: "10px 20px",
                                    borderRadius: 20,
                                    fontSize: 14,
                                    lineHeight: 0,
                                }}
                                onClick={sendTestMail}
                            >
                                Send Test Mail
                            </Button>
                            <Button
                                id="testInviteRemindTemplateFlow-selector-6"
                                style={{
                                    color: "#fff",
                                    background: "orange",
                                    margin: "10px 20px",
                                    borderRadius: 20,
                                    fontSize: 14,
                                    lineHeight: 0,
                                    zIndex:
                                        currentWalkthroughStep === 4
                                            ? "1000"
                                            : "",
                                }}
                                onClick={updateEmail}
                                disabled={saveEmailLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="preview-email-container">
                        <h2
                            style={{
                                marginBottom: "20px",
                                color: "rgb(150,150,150)",
                                textAlign: "left",
                            }}
                        >
                            Preview
                        </h2>
                        <div
                            id="preview"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "50px 0 0 0",
                                background: "#fff",
                                padding: "20px",
                            }}
                        >
                            <img
                                src={file}
                                style={{
                                    maxWidth: "40%",
                                    width: "300px",
                                    maxHeight: "180px",
                                }}
                            />
                            <div
                                style={{
                                    overflow: "wrap",
                                    textAlign: "left",
                                    width: "100%",
                                    overflowWrap: "break-word",
                                    margin: "10px 0",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: preEditorState,
                                }}
                            ></div>
                            {buttonText === "" ? (
                                ""
                            ) : (
                                <Button
                                    style={{
                                        background: "#7823DC",
                                        borderRadius: "10em",
                                        color: "#fff",
                                        lineHeight: 0,
                                    }}
                                >
                                    <span
                                        style={{
                                            textTransform: "none",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {buttonText}
                                    </span>
                                </Button>
                            )}
                            <div
                                style={{
                                    overflow: "wrap",
                                    textAlign: "left",
                                    width: "100%",
                                    overflowWrap: "break-word",
                                    marginTop: "10px",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: postEditorState,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                {currentWalkthroughStep === 4 &&
                currentWalkthroughType === "invite" &&
                showWalkthrough ? (
                    <WalkthroughOverlay
                        showWalkthroughNextBtn={false}
                        onClickBack={() => {
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep - 1
                            );
                            setModalOpen(false);
                            setSaveEmailLoading(false);
                        }}
                    />
                ) : null}
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={2500}
                onClose={onSnackClose}
                msg={snackSuccess}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={snackErr}
                type="error"
            />
        </div>
    );
};

AddInviteEmail.propTypes = {
    field: PropTypes.object,
    type: PropTypes.string,
    index: PropTypes.number,
    setTempChange: PropTypes.func,
    tempChange: PropTypes.number,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
    showWalkthrough: PropTypes.bool,
};

export default AddInviteEmail;

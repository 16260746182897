import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import Modal from "../../components/common/CustomModal";
import Button from "../../components/common/Button";
import { getUserName } from "../../selectors/app";
import { sendSupportMsg as sendSupportMsgRequest } from "../../actions/app";

import UploadIcon from "../../assets/support-upload.png";

const SupportModal = (props) => {
    const {
        open,
        onClose,
        userName,
        sendSupportMsg,
        setErrSnackOpen,
        setSuccessSnackOpen,
        setSnackErrMsg,
        setSnackSuccessMsg,
    } = props;

    const [contactEmail, setContactEmail] = useState("");
    const [contactDesc, setContactDesc] = useState("");
    const [contactImgArr, setContactImgArr] = useState([]);
    const [fileNames, setFileNames] = useState([]);

    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

    const navigate = useNavigate();

    const onChangeContactImg = (e) => {
        imgFileReader(e.target.files, 0, []);
        let tempFileArr = [...e.target.files].map((file) => file.name);
        setFileNames(tempFileArr);
    };

    const imgFileReader = (files, i, filesArr) => {
        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = function () {
            filesArr.push(reader.result);
            // setContactImg(reader.result);
            if (i < files.length - 1) {
                imgFileReader(files, i + 1, filesArr);
            } else {
                setContactImgArr([...filesArr]);
            }
        };
    };

    const onSubmit = () => {
        if (contactEmail === "") {
            setErrSnackOpen(true);
            setSnackErrMsg("Enter valid email");
        } else if (contactDesc === "") {
            setErrSnackOpen(true);
            setSnackErrMsg("Enter valid description");
        } else {
            const formData = new FormData();
            formData.append("email", contactEmail);
            formData.append("desc", contactDesc);

            if (contactImgArr.length > 0) {
                appendContactImg(formData, 0);
            } else {
                sendSupportMsg({ formData, navigate });
                setContactEmail("");
                setContactDesc("");
                setContactImgArr([]);
                onClose();
            }

            setSuccessSnackOpen(true);
            setSnackSuccessMsg("Your query has been sent successfully");
        }
    };

    const appendContactImg = (formData, i) => {
        fetch(contactImgArr[i])
            .then((res) => res.blob())
            .then((blob) => {
                const dname = "contact_us_screenshot_" + new Date().getTime();
                const file = new File([blob], dname + ".png", {
                    type: "image/png",
                });
                formData.append("screenshot", file);
                if (i < contactImgArr.length - 1) {
                    appendContactImg(formData, i + 1);
                } else {
                    sendSupportMsg({ formData, navigate });
                    onClose();
                    setContactEmail("");
                    setContactDesc("");
                    setContactImgArr([]);
                }
            });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            styles={{ closeIcon: { top: "1rem", right: "1rem" } }}
        >
            <div
                style={{
                    padding: mediaQueryMobile.matches ? 0 : "20px 40px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        color: "#5C5C5C",
                        fontSize: 24,
                        fontWeight: "700",
                    }}
                >
                    Contact Us
                </div>
                <div
                    style={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#404040",
                        marginTop: 25,
                        lineHeight: "18px",
                    }}
                >
                    Hi {userName}, I am{" "}
                    <span style={{ fontWeight: "700" }}>
                        Jovan - your dedicated account manager!
                    </span>{" "}
                    I am glad to assist you in any question you may have.Please
                    mention your query here and I will get back to you within 12
                    hours :)
                </div>
                <div style={{ marginTop: 30 }}>
                    <input
                        type="text"
                        placeholder="Enter your email ID"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                        className="support-modal-input"
                    />
                </div>
                <div style={{ marginTop: 30 }}>
                    <textarea
                        rows={10}
                        placeholder="Tell us more about your issue"
                        value={contactDesc}
                        onChange={(e) => setContactDesc(e.target.value)}
                        className="support-modal-input"
                    />
                </div>
                <div
                    style={{
                        marginTop: 30,
                        position: "relative",
                        backgroundColor: "#f2f2f2",
                        width: "100%",
                        borderRadius: 10,
                        padding: "10px 20px",
                        fontSize: 14,
                        color: "#9E9E9E",
                        boxSizing: "border-box",
                    }}
                >
                    {fileNames.length > 0
                        ? fileNames.join(", ")
                        : "Upload relevant screenshots (if any)"}
                    <input
                        type="file"
                        onChange={onChangeContactImg}
                        accept="image/*"
                        style={{
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            opacity: 0,
                            boxSizing: "border-box",
                            zIndex: 1,
                        }}
                        multiple
                    />
                    <img
                        src={UploadIcon}
                        alt="upload-icon"
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            right: 16,
                            width: 20,
                            height: "auto",
                        }}
                    />
                </div>
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        fontSize: 20,
                        fontWeight: "700",
                        width: mediaQueryMobile.matches ? "100%" : 150,
                        borderRadius: 40,
                        marginTop: 30,
                        alignSelf: "flex-end",
                    }}
                    onClick={onSubmit}
                    id="supportFlow-selector-2"
                >
                    Send
                </Button>
            </div>
        </Modal>
    );
};

SupportModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    userName: PropTypes.string,
    sendSupportMsg: PropTypes.func,
    setErrSnackOpen: PropTypes.func,
    setSuccessSnackOpen: PropTypes.func,
    setSnackErrMsg: PropTypes.func,
    setSnackSuccessMsg: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    userName: getUserName(),
});

const mapDispatchToProps = (dispatch) => ({
    sendSupportMsg: (data) => dispatch(sendSupportMsgRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportModal);

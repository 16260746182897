import axios from 'axios';

axios.defaults.headers['Access-Control-Allow-Origin'] = process.env.REACT_APP_BASE_URL;

axios.defaults.headers['Accept'] = '*/*';
axios.defaults.headers['Accept-Language'] = 'en';
axios.defaults.headers['Content-Language'] = 'en-US';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_SERVER_URL_FULL,
	withCredentials: true,
})

export default instance;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import Button from "../common/Button";
import Modal from "../common/CustomModal";
import IconButton from "../common/IconButton";

import { getPostProgressToReviewLoading } from "../../selectors/app";
import { postProgressToReviewCandidates as postProgressToReviewCandidatesRequest } from "../../actions/app";

function ConcludeAssessment(props) {
    const {
        workflowId,
        isPostProgressToReviewLoading,
        postProgressToReviewCandidates,
    } = props;

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);

    const showModal = () => setOpenModal(true);
    const closeModal = () => setOpenModal(false);

    const handleProceed = () => {
        postProgressToReviewCandidates({
            workflowId,
            navigate,
            onSuccess: closeModal,
        });
    };

    return (
        <>
            <div className="tooltip">
                <IconButton>
                    <svg
                        width={24}
                        height={24}
                        strokeWidth={1.5}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={showModal}
                    >
                        <path
                            fill="#c4c4c4"
                            d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z"
                        />
                    </svg>
                </IconButton>
                <span className="tooltiptext">Conclude Assessment</span>
            </div>
            <Modal
                open={openModal}
                onClose={closeModal}
                center
                styles={{
                    modal: {
                        maxWidth: 500,
                    },
                }}
            >
                <p
                    style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: 16,
                    }}
                >
                    Conclude Assessment
                </p>
                <p>
                    This will submit all in-progress candidate assessment
                    submissions that have exceeded time limit.
                </p>
                <div className="modal__btnbox">
                    <Button
                        size={"small"}
                        variant={"contained"}
                        style={{
                            marginLeft: 16,
                        }}
                        isLoading={isPostProgressToReviewLoading}
                        onClick={handleProceed}
                    >
                        Proceed
                    </Button>
                    <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </>
    );
}

ConcludeAssessment.propTypes = {
    workflowId: PropTypes.string,
    isPostProgressToReviewLoading: PropTypes.bool,
    postProgressToReviewCandidates: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isPostProgressToReviewLoading: getPostProgressToReviewLoading(),
});

const mapDispatchToProps = (dispatch) => ({
    postProgressToReviewCandidates: (data) =>
        dispatch(postProgressToReviewCandidatesRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcludeAssessment);

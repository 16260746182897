import React, { useState, useEffect } from "react";
import Button from '../../components/common/Button';
import Modal from '../../components/common/CustomModal';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from "../../utils/axios";

import "../../styles/ccList.scss";

const CCListEditor = (props) => {
	const { setSnackErrMsg, handleErrSnackClick, handleSuccessSnackClick, setSnackSuccessMsg } = props;
	const location = useLocation()
	const [ccChanged, setccChanged] = useState(0)
	const [emails, setEmails] = useState([])
	const [oldEmails, setOldEmails] = useState([])
	const [val, setVal] = useState()
	const [emailsBcc, setEmailsBcc] = useState([])
	const [oldEmailsBcc, setOldEmailsBcc] = useState([])
	const [valBcc, setValBcc] = useState()
	const [error, setError] = useState()
	const [errorBcc, setErrorBcc] = useState()
	const [open, setOpen] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [invite, setInvite] = useState(false)
	const [reject, setReject] = useState(false)
	const [remind, setRemind] = useState(false)
	const [shortlist, setShortlist] = useState(false)
	const [complete, setComplete] = useState(false)
	const mediaQueryMobile = window.matchMedia('(max-width:768px)')

	const handleKeyDown = (evt) => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = val.trim();

			if (value && isValid(value)) {
				setVal("")
				setEmails([...emails, value])
			}
		}
	}
	const handleKeyDownBcc = (evt) => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = valBcc.trim();

			if (value && isValid(value, false)) {
				setValBcc("")
				setEmailsBcc([...emailsBcc, value])
			}
		}
	}
	useEffect(() => {
		console.log(location.state.workflowId)
		axios.get("/workflow/getcclist/" + location.state.workflowId).then((res) => {
			console.log(res.data)
			setInvite(res.data.invite)
			setRemind(res.data.remind)
			setShortlist(res.data.shortlist)
			setReject(res.data.reject)
			setComplete(res.data.complete)
			setEmails(res.data.emails.length ? [] : [localStorage.getItem("email")])
			setEmailsBcc([])
			setOldEmails(res.data.emails.length ? res.data.emails : [])
			setOldEmailsBcc(res.data.emailsBcc.length ? res.data.emailsBcc : [])
			setVal("")
		}).catch((err) => {
			console.log(err)
		})
	}, [ccChanged])

	const handleClose = () => {
		if (emails.length || (val && val.trim().length)) {
			setOpenConfirmModal(true)
			return
		}
		setOpen(false)
	}
	const onSubmit = (e) => {
		e.preventDefault();

		if (invite === false && remind === false && reject === false && shortlist === false && complete === false) {
			setSnackErrMsg('Kindly select at least one of the emails above to proceed');
			handleErrSnackClick();
		}
		else {
			let data = {
				invite,
				reject,
				remind,
				shortlist,
				complete,
				emails: [...oldEmails, ...emails],
				emailsBcc: [...oldEmailsBcc, ...emailsBcc]
			}
			if (val && val.trim().length) {
				let value = val.trim()
				if (!isValid(value, true))
					return
				else
					data['emails'].push(value)
			}
			if (valBcc && valBcc.trim().length) {
				let value = valBcc.trim()
				if (!isValid(value, false))
					return
				else
					data['emailsBcc'].push(value)
			}
			axios.post("/workflow/setcclist/" + location.state.workflowId, data).then((res) => {
				console.log(res)
				setccChanged(ccChanged + 1)
				setOpen(false);
				handleSuccessSnackClick();
				setSnackSuccessMsg('Your settings have been saved');
			}).catch(err => {
				console.log(err)
			})
		}
	}
	const handleChange = (evt) => {
		setVal(evt.target.value)
		setError(null)
	};

	const handleDelete = (item) => {
		setEmails(emails.filter(i => i !== item))
	};

	const handleDeleteOld = (item) => {
		setOldEmails(oldEmails.filter(i => i !== item))
	};

	const handlePaste = (evt) => {
		evt.preventDefault();

		var paste = evt.clipboardData.getData("text");
		var newemails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

		if (newemails) {
			var toBeAdded = newemails.filter(email => !isInList(email));
			setEmails([...emails, ...toBeAdded])

		}
	}

	const handleChangeBcc = (evt) => {
		setValBcc(evt.target.value)
		setErrorBcc(null)
	};

	const handleDeleteBcc = (item) => {
		setEmailsBcc(emailsBcc.filter(i => i !== item))
	};

	const handleDeleteOldBcc = (item) => {
		setOldEmailsBcc(oldEmailsBcc.filter(i => i !== item))
	};

	const handlePasteBcc = (evt) => {
		evt.preventDefault();

		var paste = evt.clipboardData.getData("text");
		var newemails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

		if (newemails) {
			var toBeAdded = newemails.filter(email => !isInList(email));
			setEmailsBcc([...emails, ...toBeAdded])

		}
	}

	const isValid = (email, cc) => {
		let err = null;

		if (isInList(email)) {
			err = `${email} has already been added.`;
		}
		if (!isEmail(email)) {
			err = `${email} is not a valid email address.`;
		}
		if (err) {
			if (cc) setError(err);
			else setErrorBcc(error)
			return false;
		}
		return true;
	}

	const isInList = (email) => {
		return emails.includes(email) || emailsBcc.includes(email);
	}

	const isEmail = (email) => {
		return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	}

	return (
		<>
			<div onClick={() => setOpen(true)}>
				Add Email
			</div>
			<Modal open={open} onClose={handleClose}>
				<div className="modal__heading">
					Add Cc and Bcc Recipients
				</div>
				<div className="mainContainer">
					<div style={{ flexDirection: "column" }}>
						<div className="wrapperCC">
							<p className='wrapperCC-title'>Cc</p>
							<div className="wrapperCC-inputEmails">
								{oldEmails.map(item => (
									<div className="tag-itemCC" key={item}>
										{item}
										<button
											type="button"
											className="button"
											onClick={() => handleDeleteOld(item)}>

											&times;
										</button>
									</div>
								))}
								{emails.map(item => (
									<div className="save-itemCC" key={item}>
										{item}
										<button
											type="button"
											className="button"
											onClick={() => handleDelete(item)}>

											&times;
										</button>
									</div>
								))}

								<input
									className={"inputCC " + (error && " has-error")}
									value={val}
									placeholder="Type or paste email addresses and press `Enter`..."
									onKeyDown={handleKeyDown}
									onChange={handleChange}
									onPaste={handlePaste}
								/>

								{error && <p className="errorCC">{error}</p>}
							</div>
						</div>
						<div className="wrapperBCC">
							<p className='wrapperBCC-title'>Bcc</p>
							<div className="wrapperBCC-inputEmails">
								{oldEmailsBcc.map(item => (
									<div className="tag-itemCC" key={item}>
										{item}
										<button
											type="button"
											className="button"
											onClick={() => handleDeleteOldBcc(item)}>

											&times;
										</button>
									</div>
								))}
								{emailsBcc.map(item => (
									<div className="save-itemCC" key={item}>
										{item}
										<button
											type="button"
											className="button"
											onClick={() => handleDeleteBcc(item)}>

											&times;
										</button>
									</div>
								))}

								<input
									className={"inputCC " + (error && " has-error")}
									value={valBcc}
									placeholder="Type or paste email addresses and press `Enter`"
									onKeyDown={handleKeyDownBcc}
									onChange={handleChangeBcc}
									onPaste={handlePasteBcc}
								/>

								{errorBcc && <p className="errorCC">{errorBcc}</p>}
							</div>
						</div>
					</div>
					<div className="cc-form-group">
						<div className="modal-checkbox">
							<label>
								Invitation emails
								<input type="checkbox" name="invite" checked={invite} onChange={(e) => setInvite(e.target.checked)} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="modal-checkbox">
							<label>
								Reminder emails
								<input type="checkbox" name="remind" checked={remind} onChange={(e) => setRemind(e.target.checked)} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="modal-checkbox">
							<label>
								Shortlist emails
								<input type="checkbox" name="shortlist" checked={shortlist} onChange={(e) => setShortlist(e.target.checked)} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="modal-checkbox">
							<label>
								Rejection emails
								<input type="checkbox" name="reject" checked={reject} onChange={(e) => setReject(e.target.checked)} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="modal-checkbox">
							<label>
								Post-assessment emails
								<input type="checkbox" name="complete" checked={complete} onChange={(e) => setComplete(e.target.checked)} />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				</div>

				<div className="modal__btnbox" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={(e) => onSubmit(e)}
					>
						Save changes
					</Button>
				</div>
			</Modal>
			<Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)} center styles={{ modal: { borderRadius: mediaQueryMobile.matches ? '0.5rem' : "50px", padding: mediaQueryMobile.matches ? '1.2rem' : "40px" } }} closeOnOverlayClick={false}>
				<p className="modal-text">
					You have unsaved changes, which will be lost. Do you wish to proceed?
				</p>
				<div className="button-div">
					<Button
						size="small"
						variant="contained"
						className="modal-button"
						onClick={() => {
							setOpen(false)
							setOpenConfirmModal(false)
							setccChanged(ccChanged + 1)
						}}
						style={{
							background: '#479BD2',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							marginLeft: "10px"
						}}
					>
						<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Yes</span>
					</Button>
					<Button
						size="small"
						variant="contained"
						className="cancel-modal-button"
						onClick={() => {
							setOpenConfirmModal(false)
						}}
						style={{
							background: '#479BD2',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							textTransform: 'none'
						}}
					>
						<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>No</span>
					</Button>
				</div>
			</Modal>
		</>
	)
}

CCListEditor.propTypes = {
	setSnackErrMsg: PropTypes.func,
	handleErrSnackClick: PropTypes.func,
	handleSuccessSnackClick: PropTypes.func,
	setSnackSuccessMsg: PropTypes.func
}

export default CCListEditor;
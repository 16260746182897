import React  from 'react';
import PropTypes from 'prop-types';

import '../../styles/rangeSlider.scss';

const RangeSlider = (props) => {
    const { onChangeSlider, rangeValue, minValue, maxValue, helperText } = props;

    return (
        <div className="range-slider">
            <input className="range-slider-range" type="range" value={rangeValue} min="10" max="50" step="10" onChange={onChangeSlider} />
            <div className="filled-value" style={{ width: `calc(${(rangeValue - 10) * 2.5}% + 6px)` }}></div>
            <div className="empty-value"></div>
            <div className="range-thumb" style={{ left: `calc(${(rangeValue - 10) * 2.5}% - 4px)`}}></div>
            <div className="range-min-value">{minValue}</div>
            <div className="range-max-value">{maxValue}</div>
            <span className="range-slider-value" style={{ left: `${(rangeValue - 10) * 2.5}%` }}>{rangeValue}</span>
            <div className="helper-text">{helperText}</div>
        </div>
    )
}

RangeSlider.propTypes = {
    onChangeSlider: PropTypes.func,
    rangeValue: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    helperText: PropTypes.string
}

export default RangeSlider;

import Button from '../common/Button';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/CustomModal';

const DeletePreScreeningModal = (props) => {
	const { deleteModal, onCloseDeleteModal, handleDelete, selectedRow } = props;

	return (
		<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
			<p className="modal-text">
				Do you want to delete this bot?
			</p>
			<div className="button-div">
				<Button
					id='prescreening-selector-5'
					className="modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseDeleteModal()
						handleDelete(selectedRow)
					}}>
					Yes
				</Button>
				<Button
					className="cancel-modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseDeleteModal()
					}}>
					No
				</Button>
			</div>
		</Modal>
	)
}

DeletePreScreeningModal.propTypes = {
	deleteModal: PropTypes.bool,
	onCloseDeleteModal: PropTypes.func,
	handleDelete: PropTypes.func,
	selectedRow: PropTypes.array
}

export default DeletePreScreeningModal;

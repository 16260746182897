import React from 'react';
import PropTypes from 'prop-types';

const NoVideoCard = (props) => {
	const { mediaType } = props;

	return (
		<div className="videobox">
			<div className="video">
				<p>No {mediaType} submissions</p>
			</div>
		</div>
	)
}

NoVideoCard.propTypes = {
    mediaType: PropTypes.string
}

export default NoVideoCard;

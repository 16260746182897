import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { fetchSaasPlanList as fetchSaasPlanListRequest } from '../actions/app';
import { getSaasPlanList } from '../selectors/app';

const SaasPlans = (props) => {
	const { fetchSaasPlanList, saasPlanList } = props;

	useEffect(() => {
		fetchSaasPlanList();
	}, []);

	return (
		<div style={{ flex: 1, padding: '20px 20px 0', display: 'flex', flexDirection: 'column' }}>
			<div style={{ fontSize: 20, fontWeight: '600' }}>Saas Plans</div>
			<div style={{ background: '#fff', borderRadius: 20, marginTop: 20, padding: 20, flex: 1 }}>
				<div>
					<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }}>
						<div style={{ flex: 1, padding: '10px 20px' }}>S. No.</div>
						<div style={{ flex: 4, padding: '10px 20px' }}>Name</div>
						<div style={{ flex: 2, padding: '10px 20px' }}>Type</div>
						<div style={{ flex: 2, padding: '10px 20px' }}>Action</div>
					</div>
					{
						saasPlanList.map((sp, i) => (
							<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }} key={sp._id}>
								<div style={{ flex: 1, padding: '10px 20px' }}>{i + 1}</div>
								<div style={{ flex: 4, padding: '10px 20px' }}>{sp.name}</div>
								<div style={{ flex: 2, padding: '10px 20px' }}>{sp.planType}</div>
								<div style={{ flex: 2, padding: '10px 20px' }}>
									<Link to={'/user-plans/' + sp._id} style={{ padding: '5px 15px', background: '#479bd2', borderRadius: 5, cursor: 'pointer', display: 'inline-block', textDecoration: 'none', color: '#fff' }}>View</Link>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

SaasPlans.propTypes = {
	fetchSaasPlanList: PropTypes.func,
	saasPlanList: PropTypes.array
}

const mapStateToProps = createStructuredSelector({
	saasPlanList: getSaasPlanList()
});

const mapDispatchToProps = (dispatch) => ({
	fetchSaasPlanList: () => dispatch(fetchSaasPlanListRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(SaasPlans);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Button from '../components/common/Button';
import Snackbar from '../components/common/Snackbar';
import { sendUpdateAll as sendUpdateAllRequest } from '../actions/app';

const ManageNotifications = (props) => {
	const { sendUpdateAll } = props;

	const [notificationText, setNotificationText] = useState('');
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');

	const handleSuccessSnackClick = () => {
		setSuccessSnackOpen(true);
	};

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const sendUpdate = () => {
		const notificationData = {
			text: notificationText
		};

		sendUpdateAll(notificationData);

		handleSuccessSnackClick();
		setSnackSuccessMsg('Update Sent Successfully');
		setNotificationText('');
	}

	return (
		<div style={{ padding: '50px 40px', width: '100%', height: '100vh', boxSizing: 'border-box' }}>
			<div style={{ background: '#fff', borderRadius: 20, boxShadow: '0 0 4px 4px #ccc', padding: 20 }}>
				<div>
					<textarea
						placeholder='Enter notification text'
						style={{
							outline: 'none',
							border: '1px solid #c4c4c4',
							borderRadius: 10,
							width: 600,
							padding: '10px 20px',
							fontSize: 16
						}}
						rows={5}
						value={notificationText}
						onChange={(e) => setNotificationText(e.target.value)}
					></textarea>
				</div>
				<Button
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "inherit",
						letterSpacing: "0.01em",
						textTransform: "capitalize",
						borderRadius: 25,
						padding: '8px 20px',
						color: '#fff',
						marginTop: 25,
						fontSize: 18
					}}
					size="small"
					variant="contained"
					onClick={sendUpdate}
				>Send Update</Button>
			</div>
			<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success"/>
		</div>
	)
}

ManageNotifications.propTypes = {
	sendUpdateAll: PropTypes.func
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	sendUpdateAll: (data) => dispatch(sendUpdateAllRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageNotifications);

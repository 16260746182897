import React, { useState, useEffect, useRef } from "react";
import D3Funnel from "d3-funnel";
import PropTypes from "prop-types";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useCopyToClipboard from "../hooks/useCopyToClipboard";
import AccountMenu from "../components/AccountMenu";
import Avatar from "../components/common/Avatar";
import axios from "../utils/axios";
import BottomBar from "../components/common/BottomBar";
import Button from "../components/common/Button";
import Header from "../components/header/header";
import Modal from "../components/common/CustomModal";
import NotificationBell from "../components/notifications/NotificationBell";
import Select from "../components/common/Select";
import Snackbar from "../components/common/Snackbar";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import {
    addNotification as addNotificationRequest,
    getCandidatesByType as getCandidatesByTypeRequest,
    updateUserSettings as updateUserSettingsRequest,
    createWorkflowJobRole as createWorkflowJobRoleRequest,
    updateJobRoleDetails as updateJobRoleDetailsRequest,
    fetchPreScreeningBots as fetchPreScreeningBotsRequest,
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
    updateWalkthroughFlows as updateWalkthroughFlowsRequest,
} from "../actions/app";
import {
    getCandidatesList,
    getCreateJobLoading,
    getCurrentWalkthroughStep,
    getJobRoleDetails,
    getUserPlan,
    getSaasPlanDetails,
    getCompanyFeatures,
    getPreScreeningBots,
    getCurrentWalkthroughType,
    getCMSCredentials,
} from "../selectors/app";
import { getCandidateStatus } from "../utils/reports";
import { redirectUnauthorisedUser } from "../helper/helperFunction";

import CopyIcon from "../assets/copy-icon.png";
import emptyRowImg from "../assets/emptyrow2.png";
import RightArrow from "../assets/Icons/right.png";

import "../styles/QuickMenu.scss";
import "../styles/createJobOpening.scss";

const QuickMenu = (props) => {
    const {
        addNotification,
        updateUserSettings,
        createWorkflowJobRole,
        updateJobRoleDetails,
        jobRoleDetails,
        createJobLoading,
        preScreeningBots,
        fetchPreScreeningBots,
        currentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughStep,
        updateCurrentWalkthroughType,
        updateWalkthroughFlows,
        // cmsCredentials,
    } = props;
    const fin = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const [workflow, setWorkflow] = useState();
    const [isLoading, setLoading] = useState(true);
    // const [isRedirectLoading, setRedirectLoading] = useState(false);
    const [finalData, setFinalData] = useState();
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    // const [CSVData, setCSVData] = useState([]);
    const [newJobModal, setNewJobModal] = useState(false);
    const [jobRoleName, setJobRoleName] = useState("");
    const [jobRoleDesc, setJobRoleDesc] = useState("");
    const [jobRoleFile, setJobRoleFile] = useState();
    const [jobRoleFilePath, setJobRoleFilePath] = useState("");
    const [jobRoleStatus, setJobRoleStatus] = useState("create");
    const [jobRolePsBot, setJobRolePsBot] = useState({});
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackErrMsg, setSnackErrMsg] = useState("");
    const [snackSuccessMsg, setSnackSuccessMsg] = useState("");
    const [showPreScreeningBots, setShowPreScreeningBots] = useState(false);
    const [nextWalkthroughStep, setNextWalkthroughStep] = useState(4);

    const [value, copy] = useCopyToClipboard();
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");
    const companyId = localStorage.getItem("company");

    const onOpenNewJobModal = () => {
        if (jobRoleDetails.name) {
            setJobRoleStatus("edit");
        } else {
            setJobRoleStatus("create");
        }
        setNewJobModal(true);
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "createjob"
        ) {
            updateCurrentWalkthroughStep(3);
        }
    };

    const onCloseNewJobModal = () => {
        if (!jobRoleDetails.name) {
            setJobRoleName("");
            setJobRoleDesc("");
            setJobRoleFilePath("");
            setJobRoleFile();
            setJobRolePsBot({});
            setShowPreScreeningBots(false);
        }
        setNewJobModal(false);
    };

    useEffect(() => {
        if (!workflow) return;

        let temp = [];
        workflow.candidates.map((val) => {
            let x = {
                ID: val.id,
                name: val.name,
                email: val.email,
                status: val.status,
            };
            temp.push(x);
        });
        // setCSVData(temp);
    }, [workflow]);

    const getScore = function (params) {
        var score = 0.0;
        var index = 0;
        try {
            if (
                params.test &&
                params.test.result &&
                Object.prototype.hasOwnProperty.call(
                    params.test.result,
                    "total"
                )
            )
                score = params.test.result.total;
            else {
                if (params.productId.toLowerCase().includes("sale")) {
                    if (params.test.result.dab)
                        score += 0.2 * params.test.result.dab;
                    if (params.test.result.lpg)
                        score += 0.2 * params.test.result.lpg;
                    if (params.test.result.stk)
                        score += 0.1 * params.test.result.stk;
                    if (params.test.result.ema)
                        score += 0.5 * params.test.result.ema;
                } else {
                    for (let keys in params.row.test.result) {
                        score += params.test.result[keys];
                        index += 1;
                    }
                    if (index > 0) score = score / index;
                }
            }
            if (score > 0) score *= 100;
        } catch {
            // console.log("Error in scores");
        }

        return score.toFixed();
    };

    useEffect(() => {
        if (finalData) setLoading(false);
    }, [finalData]);

    useEffect(() => {
        axios
            .get("/workflow/" + location.state.workflowId)
            .then((res) => {
                // console.log(res.data)
                localStorage.setItem(
                    "driveInvitedCount",
                    res.data.invite.length
                );
                localStorage.setItem(
                    "driveSubmittedCount",
                    res.data.review.length
                );
                addNotification({
                    driveInvitedCount: res.data.invite.length,
                    driveSubmittedCount: res.data.review.length,
                });
                setWorkflow(res.data);
                localStorage.setItem(
                    "settings",
                    JSON.stringify(res.data.settings)
                );
                updateUserSettings(res.data.settings);
                if (res.data.jobRole && res.data.jobRole.name) {
                    updateJobRoleDetails(res.data.jobRole);
                    setJobRoleStatus("edit");
                } else {
                    const jobRole = {
                        name: "",
                        desc: "",
                    };
                    updateJobRoleDetails(jobRole);
                }
                setFinalData([
                    <tr className="quickmenu__card-tr" key={"row1"}>
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-invited"></div>
                        </td>
                        <td className="quickmenu__card-td">Invited</td>
                        <td className="quickmenu__card-td">
                            {res.data.candidates.length - res.data.added.length}
                        </td>
                        <td className="quickmenu__card-td">
                            <Link
                                to="/applicants"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.workflowName,
                                    sidebar: "applicants",
                                }}
                                id="quickViewFlow-selector-2"
                            >
                                View full list
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="#4FCA64"
                                    width={24}
                                    height={24}
                                    style={{ marginLeft: "0.5rem" }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                    />
                                </svg>
                            </Link>
                        </td>
                    </tr>,
                    <tr className="quickmenu__card-tr" key={"row2"}>
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-submitted"></div>
                        </td>
                        <td className="quickmenu__card-td">Submitted</td>
                        <td className="quickmenu__card-td">
                            {res.data.hired.length +
                                res.data.rejected.length +
                                res.data.shortlisted.length +
                                res.data.review.length +
                                res.data.kiv.length}
                        </td>
                        <td className="quickmenu__card-td">
                            <Link
                                to="/review-applicants"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.workflowName,
                                    sidebar: "reviewApplicants",
                                }}
                                id="quickViewFlow-selector-3"
                            >
                                View full list
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="#54C5EB"
                                    width={24}
                                    height={24}
                                    style={{ marginLeft: "0.5rem" }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                    />
                                </svg>
                            </Link>
                        </td>
                    </tr>,
                    <tr className="quickmenu__card-tr" key={"row3"}>
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-shortlisted"></div>
                        </td>
                        <td className="quickmenu__card-td">Shortlisted</td>
                        <td className="quickmenu__card-td">
                            {res.data.shortlisted.length}
                        </td>
                        <td className="quickmenu__card-td">
                            <Link
                                to="/finalized"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.workflowName,
                                    sidebar: "finalised",
                                }}
                                id="quickViewFlow-selector-4"
                            >
                                View full list
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="#FFB74A"
                                    width={24}
                                    height={24}
                                    style={{ marginLeft: "0.5rem" }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                    />
                                </svg>
                            </Link>
                        </td>
                    </tr>,
                    <tr className="quickmenu__card-tr" key={"row4"}>
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-hired"></div>
                        </td>
                        <td className="quickmenu__card-td">Hired</td>
                        <td className="quickmenu__card-td">
                            {res.data.hired.length}
                        </td>
                    </tr>,
                ]);
                const dataf = [
                    {
                        label: "Invited",
                        value: res.data.candidates.length > 0 ? 100 : 0,
                    },
                    {
                        label: "Submitted",
                        value:
                            res.data.candidates.length > 0
                                ? parseInt(
                                      ((res.data.hired.length +
                                          res.data.rejected.length +
                                          res.data.shortlisted.length +
                                          res.data.review.length +
                                          res.data.kiv.length) *
                                          100) /
                                          res.data.candidates.length,
                                      10
                                  )
                                : 0,
                    },
                    {
                        label: "Shortlisted",
                        value:
                            res.data.candidates.length > 0
                                ? parseInt(
                                      (res.data.shortlisted.length * 100) /
                                          res.data.candidates.length,
                                      10
                                  )
                                : 0,
                    },
                    {
                        label: "Hired",
                        value:
                            res.data.candidates.length > 0
                                ? parseInt(
                                      (res.data.hired.length * 100) /
                                          res.data.candidates.length,
                                      10
                                  )
                                : 0,
                    },
                ];
                const optionf = {
                    block: {
                        dynamicHeight: true,
                        dynamicSlope: false,
                        minHeight: res.data.candidates.length > 0 ? 10 : 30,
                        fill: {
                            type: "gradient",
                            scale: ["#4FCA64", "#54C5EB", "#FFB74A", "#22242C"],
                        },
                    },
                    tooltip: {
                        enabled: true,
                        format: "{l} : {f}%",
                    },
                    label: { enabled: false },
                    chart: {
                        curve: {
                            width: mediaQueryMobile.matches ? "100%" : null,
                            height: 10,
                            enabled: true,
                        },
                        totalCount: 200,
                        inverted: false,
                    },
                };
                const chart = new D3Funnel(fin.current);
                chart.draw(dataf, optionf);
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
            });

        let datax = {
            status: "shortlisted",
            id: location.state.workflowId,
        };
        let datay = {
            status: "candidates",
            id: location.state.workflowId,
        };
        axios
            .post("/gettype", datax)
            .then((res) => {
                setRows2(res.data);
                // setProgress(res.)
            })
            .catch((err) => {
                redirectUnauthorisedUser(err, navigate);
            });
        axios
            .post("/gettype", datay)
            .then((res) => {
                if (res.data.length > 3) setRows1(res.data);
                else setRows1(res.data);
                // setProgress(res.)
            })
            .catch((err) => {
                redirectUnauthorisedUser(err, navigate);
            });
        fetchPreScreeningBots({ navigate });
    }, []);

    const [open, setOpen] = useState(false);
    const onOpenModal = () => {
        if (
            currentWalkthroughStep == 9 &&
            currentWalkthroughType == "workflow"
        ) {
            updateCurrentWalkthroughStep(0);
            updateCurrentWalkthroughType("");
            let wArr = [];
            if (localStorage.getItem("walkthroughFlows")) {
                wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
            }
            wArr.push("workflow");
            localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
            updateWalkthroughFlows(wArr);
        }
        setOpen(true);
    };

    const onCloseModal = () => setOpen(false);

    function renderData() {
        return finalData;
    }

    const handleJobRoleFileChange = (e) => {
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "createjob"
        ) {
            updateCurrentWalkthroughStep(5);
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            setJobRoleFile(reader.result);
            setJobRoleFilePath(e.target.files[0].name);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const onClickCopyChatbotLink = () => {
        navigator.clipboard.writeText(
            isLoading
                ? ""
                : process.env.REACT_APP_CHATBOT_URL + "demorush/" + workflow._id
        );
        onCloseModal();
        setSnackSuccessMsg("Chatbot link copied to clipboard");
        handleSuccessSnackClick();
    };

    const onCreateJobRole = () => {
        if (jobRoleName === "") {
            setSnackErrMsg("Enter valid Job Role Name");
            handleErrSnackClick();
        } else if (!jobRoleFilePath) {
            setSnackErrMsg("Please Upload Job Description");
            handleErrSnackClick();
        } else if (showPreScreeningBots && !jobRolePsBot.name) {
            setSnackErrMsg("Please select Pre screening bot");
            handleErrSnackClick();
        } else {
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "createjob"
            ) {
                updateCurrentWalkthroughStep(7);
            }
            const formData = new FormData();
            if (jobRoleFile) {
                fetch(jobRoleFile)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const dname = location.state.workflowId + "jobRole";
                        const file = new File([blob], dname + ".pdf", {
                            type: "application/pdf",
                        });
                        formData.append("pdf", file);
                        formData.append(
                            "workflowId",
                            location.state.workflowId
                        );
                        formData.append("name", jobRoleName);
                        formData.append("desc", jobRoleDesc);
                        if (showPreScreeningBots) {
                            formData.append("botId", jobRolePsBot._id);
                        }

                        createWorkflowJobRole({ formData, navigate });
                    });
            } else {
                formData.append("workflowId", location.state.workflowId);
                formData.append("name", jobRoleName);
                formData.append("desc", jobRoleDesc);
                if (showPreScreeningBots) {
                    formData.append("botId", jobRolePsBot._id);
                }

                createWorkflowJobRole({ formData, navigate });
            }
        }
    };

    useEffect(() => {
        if (jobRoleDetails.name && !createJobLoading) {
            setJobRoleDesc(jobRoleDetails.desc);
            setJobRoleName(jobRoleDetails.name);
            setJobRoleFilePath(jobRoleDetails.jobRolePdf);
            if (jobRoleDetails.botId) {
                setShowPreScreeningBots(true);
                let selectedBot;
                preScreeningBots.forEach((psb) => {
                    if (psb._id === jobRoleDetails.botId) {
                        selectedBot = { ...psb };
                    }
                });
                setJobRolePsBot({ ...selectedBot });
            } else {
                setShowPreScreeningBots(false);
                setJobRolePsBot({});
            }
        } else {
            setJobRoleName("");
            setJobRoleDesc("");
            setJobRoleFilePath("");
            setJobRoleFile();
            setJobRolePsBot({});
            setShowPreScreeningBots(false);
        }
    }, [jobRoleDetails.name]);

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleSuccessSnackClick = () => {
        setSuccessSnackOpen(true);
    };

    const handleSuccessSnackClose = () => {
        setSuccessSnackOpen(false);
    };

    const handleErrSnackClose = () => {
        setErrSnackOpen(false);
    };

    const onClickCopyLink = () => {
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "createjob"
        ) {
            updateCurrentWalkthroughStep(0);
            updateCurrentWalkthroughType("");
            let wArr = [];
            if (localStorage.getItem("walkthroughFlows")) {
                wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
            }
            wArr.push("createjob");
            localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
            updateWalkthroughFlows(wArr);
        }
        navigator.clipboard.writeText(
            `https://hirequotient.com/applyforjobs?workflow=${location.state.workflowId}`
        );
        handleSuccessSnackClick();
        setSnackSuccessMsg("The link has been copied");
    };

    const handlePreScreeningCheck = (e) => {
        if (e.target.checked) {
            setShowPreScreeningBots(true);
        } else {
            setShowPreScreeningBots(false);
        }
    };

    const onChangePreScreeningBot = (val) => {
        let selectedBot;
        preScreeningBots.forEach((psb) => {
            if (psb._id === val.value) {
                selectedBot = { ...psb };
            }
        });
        setJobRolePsBot({ ...selectedBot });
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "createjob"
        ) {
            updateCurrentWalkthroughStep(6);
        }
    };

    // const handleCMSClick = async () => {
    // 	setRedirectLoading(true);
    // 	try {
    // 		console.log({ cmsCredentials });
    // 		const response = await axios.post('https://cmsdev.hirequotient.co/api/api/login/', { username: cmsCredentials.name, password: cmsCredentials.password });

    // 		if (response?.data?.token) {
    // 			window.open(`${process.env.REACT_APP_CMS_URL}assessment?token=${response.data.token}`, '_blank')
    // 		}
    // 	} catch (error) {
    // 		console.log({ error })
    // 	} finally {
    // 		setRedirectLoading(false);
    // 	}
    // }

    return (
        <div className="mainbar">
            {mediaQueryMobile.matches && <Header />}
            <div className="notificationbar">
                <div className="notificationbar__icon-box">
                    <WalkthroughWelcome />
                    <NotificationBell />
                    <AccountMenu />
                </div>
            </div>
            <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{ modal: "chatbot-link-modal modal__container" }}
            >
                <div className="modal__heading">Chatbot Link</div>
                <p className="modal__heading-secondary">
                    <span>
                        {isLoading
                            ? ""
                            : process.env.REACT_APP_CHATBOT_URL +
                              "demorush/" +
                              workflow._id}
                    </span>
                    <img
                        src={CopyIcon}
                        onClick={onClickCopyChatbotLink}
                        alt="copy-icon"
                    />
                </p>
                <div className="modal__btnbox">
                    {/* <Button
                            id="quickViewFlow-selector-9"
                            className="btn-primary"
                            onClick={() => {
                                onCloseModal()
                            }}
                            size="small"
                            variant="contained"
                        >
                            Okay
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                            navigator.clipboard.writeText(isLoading ? '' : process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id)
                            onCloseModal()
                        }}>
                        Copy
                    </Button> */}
                    <Button
                        id="quickViewFlow-selector-11"
                        className="btn-primary"
                        size="small"
                        disabled={isLoading}
                        variant="contained"
                        onClick={() => {
                            window.open(
                                process.env.REACT_APP_CHATBOT_URL +
                                    "demorush/" +
                                    workflow._id,
                                "_blank"
                            );
                            onCloseModal();
                        }}
                    >
                        Open in new tab
                    </Button>
                </div>
            </Modal>
            <div className="actionbar">
                <div className="actionbar__details">
                    <h2>Quick Overview</h2>
                    {!isLoading ? (
                        <div className="quickmenu__copyId">
                            <div
                                className="quickmenu__copyId-wrapper"
                                onClick={() =>
                                    copy(`HireQuotient Test - ${workflow._id}`)
                                }
                            >
                                <p style={{ wordBreak: "break-all" }}>
                                    {workflow.name}
                                </p>
                                <div className="svg-wrapper">
                                    {value ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="#4CBB17"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4.5 12.75l6 6 9-13.5"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="#90a0b7"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                                            />
                                        </svg>
                                    )}
                                    <p className="quickmenu__copyId-text">
                                        {value ? "Copied" : "Copy"} Assessment
                                        ID
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {!mediaQueryMobile.matches && (
                    <div
                        className="actionbar__btn-box"
                        style={{ whiteSpace: "nowrap" }}
                    >
                        {companyId === "6381099636eb54641ed5c988" ||
                        companyId === "64075efbe58189914932e7b1" ? null : (
                            <Link
                                to="/analytics"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.workflowName,
                                    sidebar: "analytics",
                                }}
                            >
                                <div className="tooltip">
                                    <Button
                                        className="btn-primary"
                                        size="small"
                                        variant="contained"
                                        id="quickViewFlow-selector-7"
                                        // onClick={onOpenModal}
                                    >
                                        Analytics
                                    </Button>
                                    <span className="tooltiptext">
                                        View analytics and drive performance
                                    </span>
                                </div>
                            </Link>
                        )}
                        <div className="tooltip">
                            <Button
                                className="btn-primary"
                                size="small"
                                variant="contained"
                                onClick={onOpenModal}
                                id="quickViewFlow-selector-8"
                                style={{
                                    position: "relative",
                                    zIndex:
                                        currentWalkthroughStep === 9 &&
                                        currentWalkthroughType === "workflow"
                                            ? 1000
                                            : 0,
                                }}
                            >
                                {companyId === "6381099636eb54641ed5c988" ||
                                companyId === "64075efbe58189914932e7b1"
                                    ? "Assessment Trial"
                                    : "Chatbot Link"}
                            </Button>
                            <span className="tooltiptext">
                                Copy link to the assessment for review
                            </span>
                        </div>
                        {/* {cmsCredentials?.name && cmsCredentials?.password ? <div className="tooltip">
							<Button
								className="btn-primary"
								size="small"
								variant="contained"
								onClick={() => handleCMSClick()}
								style={{ maxWidth: '300px' }}
							>
								{isRedirectLoading ? 'Loading...' : 'Edit assessment questions'}
							</Button>
							<span className="tooltiptext">redirect to cms</span>
						</div> : null} */}
                        {companyId === "6381099636eb54641ed5c988" ||
                        companyId === "64075efbe58189914932e7b1" ? null : (
                            <div
                                className="tooltip"
                                style={{ position: "relative" }}
                            >
                                <Button
                                    className="btn-primary"
                                    size="small"
                                    variant="contained"
                                    onClick={onOpenNewJobModal}
                                    id="quickViewFlow-selector-9"
                                    style={{
                                        position: "relative",
                                        zIndex:
                                            currentWalkthroughStep === 2 &&
                                            currentWalkthroughType ===
                                                "createjob"
                                                ? 1000
                                                : 1,
                                    }}
                                >
                                    {jobRoleStatus === "create"
                                        ? "Create"
                                        : "Edit"}{" "}
                                    Job Opening
                                </Button>
                                <span className="tooltiptext">
                                    Prepare your own job board
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {mediaQueryMobile.matches && (
                <>
                    <div className="quickmenu__actions-box">
                        <Button
                            className="quickmenu__actions-box-btn"
                            size="small"
                            variant="contained"
                            onClick={onOpenModal}
                        >
                            Chatbot Link
                        </Button>
                        <Button
                            className="quickmenu__actions-box-btn"
                            size="small"
                            variant="contained"
                            onClick={onOpenNewJobModal}
                        >
                            {jobRoleStatus === "create" ? "Create" : "Edit"} Job
                            Opening
                        </Button>
                    </div>
                </>
            )}
            <div className="quickmenu__container">
                <div className="quickmenu__card-container quickmenu__card-container-flex">
                    <div className="quickmenu__card-leftbox">
                        <div className="quickmenu__card-headingbox">
                            <h3 className="quickmenu__card-heading">
                                Overview
                            </h3>
                        </div>
                        {/* {
                            workflow && (
                                <div className="quickmenu__card-chartbox">
                                    <Funnel
                                        width={400}
                                        invited={workflow.candidates.length}
                                        submitted={workflow.review.length}
                                        shortlisted={workflow.shortlisted.length}
                                        hired={workflow.hired.length}
                                    />
                                </div>
                            )
                        } */}
                        <div
                            className="chart"
                            ref={fin}
                            id="chart1"
                            style={{ height: "150px" }}
                        />
                    </div>
                    <div className="quickmenu__card-rightbox">
                        <div>
                            <table>
                                <tbody>{renderData()}</tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">
                                All Applicants
                            </h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">{`${
                                mediaQueryMobile.matches
                                    ? "Total :"
                                    : "Total applicants :"
                            } ${rows1.length}`}</p>
                        </div>
                        <div className="quickmenu__card-link-box">
                            <Link
                                className="quickmenu__card-link"
                                to="/applicants"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.caseName,
                                    sidebar: "applicants",
                                }}
                                id="quickViewFlow-selector-5"
                            >
                                <h3 className="quickmenu__card-fulllist">
                                    View full list{" "}
                                    <img src={RightArrow} alt="" />
                                </h3>
                            </Link>
                        </div>
                    </div>
                    <div>
                        {mediaQueryMobile.matches && (
                            <div className="quickmenu-box__content">
                                {rows1.length === 0 ? (
                                    <>
                                        <img
                                            src={emptyRowImg}
                                            alt="no rows"
                                            style={{
                                                height: "auto",
                                                width: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                color: "#334D6E",
                                                textAlign: "center",
                                            }}
                                        >
                                            No data to show
                                        </p>
                                    </>
                                ) : null}
                                {rows1.slice(0, 3).map((row, index) => (
                                    <div
                                        key={index}
                                        className="quickmenu-box__row"
                                    >
                                        <div className="quickmenu-box__row-left">
                                            <div className="quickmenu-box__row-left-image-box">
                                                <Avatar
                                                    name={row.id}
                                                    styles={{
                                                        text: {
                                                            backgroundColor:
                                                                "#EBEBEB",
                                                            color: "#404040",
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="quickmenu-box-row-left-text-box">
                                                <p className="quickmenu-box__row-left-text-name">
                                                    {row.name}
                                                </p>
                                                <p className="quickmenu-box__row-left-text-role">
                                                    {row.email}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="quickmenu-box__row-right">
                                            <p
                                                className="quickmenu-box__row-right-score"
                                                dangerouslySetInnerHTML={{
                                                    __html: `${getScore(row)}%`,
                                                }}
                                            />
                                            <p
                                                className="quickmenu-box__row-left-text-role"
                                                dangerouslySetInnerHTML={{
                                                    __html: getCandidateStatus(
                                                        row.status
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!mediaQueryMobile.matches && (
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>ID</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Name</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Email</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Status</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Score</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows1.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto",
                                                            }}
                                                        />
                                                        <p
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "700",
                                                                color: "#334D6E",
                                                            }}
                                                        >
                                                            No data to show
                                                        </p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1.slice(0, 3).map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                    >
                                                        <span>{row.id}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span>{row.name}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span>{row.email}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: getCandidateStatus(
                                                                    row.status
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${getScore(
                                                                    row
                                                                )}%`,
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                    {mediaQueryMobile.matches && rows1.length !== 0 && (
                        <div className="quickmenu__card-showmore">
                            <Link
                                className="quickmenu__card-link"
                                to="/applicants"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.caseName,
                                    sidebar: "applicants",
                                }}
                                id="quickViewFlow-selector-5"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="#707070"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1.64598 4.64604C1.69242 4.59948 1.7476 4.56253 1.80834 4.53733C1.86909 4.51212 1.93421 4.49915 1.99998 4.49915C2.06575 4.49915 2.13087 4.51212 2.19161 4.53733C2.25236 4.56253 2.30753 4.59948 2.35398 4.64604L7.99998 10.293L13.646 4.64604C13.6925 4.59955 13.7477 4.56267 13.8084 4.53752C13.8691 4.51236 13.9342 4.49941 14 4.49941C14.0657 4.49941 14.1308 4.51236 14.1916 4.53752C14.2523 4.56267 14.3075 4.59955 14.354 4.64604C14.4005 4.69253 14.4373 4.74772 14.4625 4.80846C14.4877 4.86919 14.5006 4.9343 14.5006 5.00004C14.5006 5.06578 14.4877 5.13088 14.4625 5.19162C14.4373 5.25236 14.4005 5.30755 14.354 5.35404L8.35398 11.354C8.30753 11.4006 8.25236 11.4375 8.19161 11.4628C8.13087 11.488 8.06575 11.5009 7.99998 11.5009C7.93421 11.5009 7.86909 11.488 7.80834 11.4628C7.7476 11.4375 7.69242 11.4006 7.64598 11.354L1.64598 5.35404C1.59942 5.30759 1.56247 5.25242 1.53727 5.19167C1.51206 5.13093 1.49908 5.06581 1.49908 5.00004C1.49908 4.93427 1.51206 4.86915 1.53727 4.80841C1.56247 4.74766 1.59942 4.69248 1.64598 4.64604V4.64604Z"
                                        fill="black"
                                    />
                                </svg>
                                <span>Show More</span>
                            </Link>
                        </div>
                    )}
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">
                                Shortlisted
                            </h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">{`${
                                mediaQueryMobile.matches
                                    ? "Total :"
                                    : "Shortlisted candidates :"
                            } ${rows2.length}`}</p>
                        </div>
                        <div className="quickmenu__card-link-box">
                            <Link
                                className="quickmenu__card-link"
                                to="/finalized"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.caseName,
                                    sidebar: "finalised",
                                }}
                                id="quickViewFlow-selector-6"
                            >
                                <h3 className="quickmenu__card-fulllist">
                                    View full list{" "}
                                    <img src={RightArrow} alt="" />
                                </h3>
                            </Link>
                        </div>
                    </div>
                    <div>
                        {mediaQueryMobile.matches && (
                            <div className="quickmenu-box__content">
                                {rows2.length === 0 ? (
                                    <>
                                        <img
                                            src={emptyRowImg}
                                            alt="no rows"
                                            style={{
                                                height: "auto",
                                                width: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                color: "#334D6E",
                                                textAlign: "center",
                                            }}
                                        >
                                            No data to show
                                        </p>
                                    </>
                                ) : null}
                                {rows2.slice(0, 3).map((row, index) => (
                                    <div
                                        key={index}
                                        className="quickmenu-box__row"
                                    >
                                        <div className="quickmenu-box__row-left">
                                            <div className="quickmenu-box__row-left-image-box">
                                                <Avatar
                                                    name={row.id}
                                                    styles={{
                                                        text: {
                                                            backgroundColor:
                                                                "#EBEBEB",
                                                            color: "#404040",
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="quickmenu-box-row-left-text-box">
                                                <p className="quickmenu-box__row-left-text-name">
                                                    {row.name}
                                                </p>
                                                <p className="quickmenu-box__row-left-text-role">
                                                    {row.email}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="quickmenu-box__row-right">
                                            <p className="quickmenu-box__row-right-score">
                                                {getScore(row)}%
                                            </p>
                                            <p
                                                className="quickmenu-box__row-left-text-role"
                                                dangerouslySetInnerHTML={{
                                                    __html: getCandidateStatus(
                                                        row.status
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!mediaQueryMobile.matches && (
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>ID</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Name</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Email</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Status</span>
                                            </TableCell>
                                            <TableCell
                                                className="quickmenu__table-head"
                                                align="left"
                                            >
                                                <span>Score</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows2.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto",
                                                            }}
                                                        />
                                                        <p
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "700",
                                                                color: "#334D6E",
                                                            }}
                                                        >
                                                            No data to show
                                                        </p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows2.slice(0, 3).map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                    >
                                                        <span>{row.id}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span>{row.name}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span>{row.email}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: getCandidateStatus(
                                                                    row.status
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        className="quickmenu__table-cell"
                                                        align="left"
                                                    >
                                                        <span>
                                                            {getScore(row)}%
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                    {mediaQueryMobile.matches && rows2.length !== 0 && (
                        <div className="quickmenu__card-showmore">
                            <Link
                                className="quickmenu__card-link"
                                to="/finalized"
                                state={{
                                    workflowId: location.state.workflowId,
                                    workflowName: location.state.caseName,
                                    sidebar: "finalised",
                                }}
                                id="quickViewFlow-selector-6"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="#707070"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1.64598 4.64604C1.69242 4.59948 1.7476 4.56253 1.80834 4.53733C1.86909 4.51212 1.93421 4.49915 1.99998 4.49915C2.06575 4.49915 2.13087 4.51212 2.19161 4.53733C2.25236 4.56253 2.30753 4.59948 2.35398 4.64604L7.99998 10.293L13.646 4.64604C13.6925 4.59955 13.7477 4.56267 13.8084 4.53752C13.8691 4.51236 13.9342 4.49941 14 4.49941C14.0657 4.49941 14.1308 4.51236 14.1916 4.53752C14.2523 4.56267 14.3075 4.59955 14.354 4.64604C14.4005 4.69253 14.4373 4.74772 14.4625 4.80846C14.4877 4.86919 14.5006 4.9343 14.5006 5.00004C14.5006 5.06578 14.4877 5.13088 14.4625 5.19162C14.4373 5.25236 14.4005 5.30755 14.354 5.35404L8.35398 11.354C8.30753 11.4006 8.25236 11.4375 8.19161 11.4628C8.13087 11.488 8.06575 11.5009 7.99998 11.5009C7.93421 11.5009 7.86909 11.488 7.80834 11.4628C7.7476 11.4375 7.69242 11.4006 7.64598 11.354L1.64598 5.35404C1.59942 5.30759 1.56247 5.25242 1.53727 5.19167C1.51206 5.13093 1.49908 5.06581 1.49908 5.00004C1.49908 4.93427 1.51206 4.86915 1.53727 4.80841C1.56247 4.74766 1.59942 4.69248 1.64598 4.64604V4.64604Z"
                                        fill="black"
                                    />
                                </svg>
                                <span>Show More</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                open={newJobModal}
                onClose={onCloseNewJobModal}
                classNames={{
                    modal: "modal-job-opening",
                }}
                styles={{
                    container: {
                        padding: "1.2rem",
                    },
                    modal: {
                        margin: 0,
                    },
                }}
                center
            >
                <div
                    className="create-job-opening"
                    style={{ position: "relative" }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 16,
                        }}
                    >
                        <div
                            style={{
                                fontSize: 24,
                                fontWeight: "700",
                                marginTop: mediaQueryMobile.matches ? 0 : 35,
                            }}
                        >
                            {jobRoleStatus === "create" ? "Create new" : "Edit"}{" "}
                            job
                        </div>
                    </div>
                    <div
                        id="createjobStep3"
                        style={{
                            position: "relative",
                            zIndex:
                                currentWalkthroughStep === 3 &&
                                currentWalkthroughType === "createjob"
                                    ? 1000
                                    : 1,
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                fontSize: 16,
                                color: "#6b6b6b",
                                fontWeight: "600",
                            }}
                        >
                            Name or Role*
                        </div>
                        <div className="name-role-input">
                            <input
                                value={jobRoleName}
                                onChange={(e) => setJobRoleName(e.target.value)}
                                style={{
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                    padding: "8px 16px",
                                    outline: "none",
                                    border: "none",
                                    background: "#f2f2f2",
                                    borderRadius: 8,
                                    marginTop: 10,
                                    width: "100%",
                                    boxSizing: "border-box",
                                }}
                                type="text"
                                id="quickViewFlow-selector-12"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: 16,
                                color: "#6b6b6b",
                                fontWeight: "600",
                                marginTop: 20,
                            }}
                        >
                            Brief description
                        </div>
                        <div className="brief-desc-input">
                            <textarea
                                rows="5"
                                value={jobRoleDesc}
                                onChange={(e) => setJobRoleDesc(e.target.value)}
                                style={{
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                    padding: "8px 16px",
                                    outline: "none",
                                    border: "none",
                                    background: "#f2f2f2",
                                    borderRadius: 8,
                                    width: "100%",
                                    boxSizing: "border-box",
                                    resize: "none",
                                }}
                                id="quickViewFlow-selector-13"
                            />
                        </div>
                    </div>
                    {jobRoleStatus === "edit" ? null : (
                        <div
                            id="createjobStep4"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: 15,
                                position: "relative",
                                zIndex:
                                    currentWalkthroughStep === 4 &&
                                    currentWalkthroughType === "createjob"
                                        ? 1000
                                        : 1,
                                background: "#fff",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 16,
                                    color: "#6b6b6b",
                                    fontWeight: "600",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>Upload JD*</div>
                            </div>
                            <a
                                href={`https://hirequotient.com/jdgenerator?email=${localStorage.getItem(
                                    "email"
                                )}&utm_source=company-dashboard`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    marginRight: 0,
                                    color: "#A8A8A8",
                                    display: "block",
                                }}
                                onClick={() => {
                                    if (
                                        currentWalkthroughStep > 0 &&
                                        currentWalkthroughType === "createjob"
                                    ) {
                                        updateCurrentWalkthroughStep(5);
                                    }
                                }}
                            >
                                Create you own Job Description now
                            </a>
                        </div>
                    )}
                    {jobRoleStatus === "edit" ? (
                        <>
                            <div
                                id="createjobStep4"
                                style={{
                                    marginTop: 15,
                                    display: "flex",
                                    flexDirection: mediaQueryMobile.matches
                                        ? "column"
                                        : "row",
                                    alignItems: mediaQueryMobile.matches
                                        ? "flex-start"
                                        : "center",
                                    justifyContent: "space-between",
                                    position: "relative",
                                    zIndex:
                                        currentWalkthroughStep === 4 &&
                                        currentWalkthroughType === "createjob"
                                            ? 1000
                                            : 1,
                                }}
                            >
                                <p
                                    style={{
                                        marginBottom: mediaQueryMobile.matches
                                            ? 8
                                            : 0,
                                    }}
                                >
                                    Uploaded JD
                                </p>
                                <div
                                    style={{
                                        position: "relative",
                                        background: "#f2f2f2",
                                        borderRadius: 8,
                                        height: 40,
                                        border: "none",
                                        marginLeft: mediaQueryMobile.matches
                                            ? 0
                                            : 20,
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : 210,
                                        justifyContent: "space-between",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: mediaQueryMobile.matches
                                            ? "0 10px"
                                            : "0 20px",
                                        overflow: "hidden",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <div style={{ color: "#a8a8a8" }}>
                                        {jobRoleFilePath}
                                    </div>
                                    <div
                                        style={{
                                            height: 40,
                                            background: "#cdcdcd",
                                            borderRadius: 10,
                                            padding: "0 20px",
                                            display: "flex",
                                            alignItems: "center",
                                            position: "absolute",
                                            right: 0,
                                        }}
                                    >
                                        Change
                                    </div>
                                    <input
                                        style={{
                                            outline: "none",
                                            position: "absolute",
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            opacity: 0,
                                            boxSizing: "border-box",
                                        }}
                                        type="file"
                                        onChange={handleJobRoleFileChange}
                                        accept="application/pdf"
                                        id="quickViewFlow-selector-17"
                                    />
                                </div>
                            </div>
                            <a
                                href={`https://hirequotient.com/jdgenerator?email=${localStorage.getItem(
                                    "email"
                                )}&utm_source=company-dashboard`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    margin: "0 0 2rem 0",
                                    display: mediaQueryMobile.matches
                                        ? "none"
                                        : "block",
                                    color: "#A8A8A8",
                                }}
                            >
                                Create you own Job Description now
                            </a>
                        </>
                    ) : (
                        <div
                            style={{
                                position: "relative",
                                zIndex:
                                    currentWalkthroughStep === 4 &&
                                    currentWalkthroughType === "createjob"
                                        ? 1000
                                        : 1,
                                background: "#fff",
                            }}
                        >
                            <div
                                style={{
                                    marginTop: 15,
                                    background: "#f2f2f2",
                                    borderRadius: 8,
                                    position: "relative",
                                    height: 40,
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0 15px",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ color: "#a8a8a8" }}>
                                    {jobRoleFilePath}
                                </div>
                                <input
                                    style={{
                                        outline: "none",
                                        border: "none",
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        opacity: 0,
                                    }}
                                    type="file"
                                    onChange={handleJobRoleFileChange}
                                    accept="application/pdf"
                                    id="quickViewFlow-selector-16"
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    width={24}
                                    height={24}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                        </div>
                    )}
                    <div
                        id="createjobStep5"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 15,
                            position: "relative",
                            zIndex:
                                currentWalkthroughStep === 5 &&
                                currentWalkthroughType === "createjob"
                                    ? 1000
                                    : 1,
                            background: "#fff",
                        }}
                    >
                        <div className="modal-checkbox">
                            <label>
                                Add Pre-screening stage
                                <input
                                    type="checkbox"
                                    name="preScreening"
                                    value="preScreening"
                                    onClick={handlePreScreeningCheck}
                                    checked={showPreScreeningBots}
                                />
                                <span
                                    className="checkmark"
                                    id="quickViewFlow-selector-14"
                                ></span>
                            </label>
                        </div>
                    </div>
                    {showPreScreeningBots ? (
                        <div
                            style={{
                                position: "relative",
                                zIndex:
                                    currentWalkthroughStep === 5 &&
                                    currentWalkthroughType === "createjob"
                                        ? 1000
                                        : 2,
                                background: "#fff",
                            }}
                        >
                            <Select
                                selectedValue={
                                    jobRolePsBot.name
                                        ? jobRolePsBot.name
                                        : "Select pre-screening bots"
                                }
                                onChangeSelect={onChangePreScreeningBot}
                                dropdownAddItem={{
                                    text: "Add Pre-screening bot",
                                    link: "/prescreening/dashboard",
                                }}
                                dropdownData={preScreeningBots.map((psb) => {
                                    return { value: psb._id, label: psb.name };
                                })}
                                styles={{
                                    root: {
                                        borderRadius: 10,
                                        border: "1px solid #c4c4c4",
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : 300,
                                        flex: 10,
                                        marginTop: "30px",
                                    },
                                    wrapper: {
                                        borderBottom: "none",
                                        padding: "0 15px",
                                        textAlign: "left",
                                    },
                                    dropdown: {
                                        zIndex: 1,
                                    },
                                }}
                            />
                        </div>
                    ) : null}
                    <div
                        style={{
                            textAlign: "center",
                            position: "relative",
                            marginTop: 50,
                        }}
                        id="createjobStep6"
                    >
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            onClick={onCreateJobRole}
                            disabled={createJobLoading}
                            id="quickViewFlow-selector-15"
                            style={{
                                position: "relative",
                                zIndex:
                                    currentWalkthroughStep === 6 &&
                                    currentWalkthroughType === "createjob"
                                        ? 1000
                                        : 1,
                            }}
                        >
                            {jobRoleStatus === "create"
                                ? "Create link"
                                : "Save"}
                        </Button>
                        {createJobLoading ? (
                            <div className="create-job-loader" />
                        ) : null}
                    </div>
                    {jobRoleDetails.name ? (
                        <div
                            id="createjobStep7"
                            style={{
                                marginTop: 25,
                                display: "flex",
                                justifyContent: "space-between",
                                background: "#f2f2f2",
                                borderRadius: 8,
                                padding: "8px 24px",
                                alignItems: "center",
                                position: "relative",
                                zIndex:
                                    currentWalkthroughStep === 7 &&
                                    currentWalkthroughType === "createjob"
                                        ? 1000
                                        : 1,
                            }}
                        >
                            <div
                                style={{
                                    marginRight: 20,
                                    color: "#a8a8a8",
                                    wordBreak: "break-all",
                                    overflowWrap: "break-word",
                                }}
                            >{`https://hirequotient.com/applyforjobs?workflow=${location.state.workflowId}`}</div>
                            <div className="tooltip">
                                <div
                                    className="copy-jd-link"
                                    style={{ cursor: "pointer" }}
                                    onClick={onClickCopyLink}
                                >
                                    <img src={CopyIcon} alt="" />
                                </div>
                                <span className="tooltiptext">Copy link</span>
                            </div>
                        </div>
                    ) : null}
                </div>
                {currentWalkthroughStep > 2 &&
                currentWalkthroughStep < 8 &&
                currentWalkthroughType === "createjob" ? (
                    <WalkthroughOverlay
                        showWalkthroughBackBtn={currentWalkthroughStep !== 7}
                        showWalkthroughNextBtn={
                            currentWalkthroughStep > 2 &&
                            currentWalkthroughStep < 6
                        }
                        onClickBack={() => {
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep - 1
                            );
                            if (currentWalkthroughStep > 3)
                                setNextWalkthroughStep(nextWalkthroughStep - 1);
                            if (currentWalkthroughStep === 3)
                                onCloseNewJobModal();
                        }}
                        onClickDone={() => {
                            updateCurrentWalkthroughStep(nextWalkthroughStep);
                            setNextWalkthroughStep(nextWalkthroughStep + 1);
                        }}
                    />
                ) : null}
            </Modal>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={2500}
                onClose={handleSuccessSnackClose}
                msg={snackSuccessMsg}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={snackErrMsg}
                type="error"
            />
            {mediaQueryMobile.matches && <BottomBar />}
            {currentWalkthroughStep === 9 &&
            currentWalkthroughType === "workflow" ? (
                <WalkthroughOverlay
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        navigate("/workflows");
                    }}
                />
            ) : null}
            {currentWalkthroughStep === 2 &&
            currentWalkthroughType === "createjob" ? (
                <WalkthroughOverlay
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        navigate("/workflows");
                    }}
                />
            ) : null}
        </div>
    );
};

QuickMenu.propTypes = {
    addNotification: PropTypes.func,
    updateUserSettings: PropTypes.func,
    createWorkflowJobRole: PropTypes.func,
    updateJobRoleDetails: PropTypes.func,
    jobRoleDetails: PropTypes.object,
    createJobLoading: PropTypes.bool,
    preScreeningBots: PropTypes.array,
    fetchPreScreeningBots: PropTypes.func,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughType: PropTypes.func,
    updateWalkthroughFlows: PropTypes.func,
    cmsCredentials: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    candidates: getCandidatesList(),
    jobRoleDetails: getJobRoleDetails(),
    createJobLoading: getCreateJobLoading(),
    preScreeningBots: getPreScreeningBots(),
    saasPlanDetails: getSaasPlanDetails(),
    companyFeatures: getCompanyFeatures(),
    userPlan: getUserPlan(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
    cmsCredentials: getCMSCredentials(),
});

const mapDispatchToProps = (dispatch) => ({
    addNotification: (data) => dispatch(addNotificationRequest(data)),
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
    updateUserSettings: (data) => dispatch(updateUserSettingsRequest(data)),
    createWorkflowJobRole: (data) =>
        dispatch(createWorkflowJobRoleRequest(data)),
    updateJobRoleDetails: (data) => dispatch(updateJobRoleDetailsRequest(data)),
    fetchPreScreeningBots: (data) =>
        dispatch(fetchPreScreeningBotsRequest(data)),
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) =>
        dispatch(updateCurrentWalkthroughTypeRequest(data)),
    updateWalkthroughFlows: (data) =>
        dispatch(updateWalkthroughFlowsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickMenu);

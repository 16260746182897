import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Button from "../components/common/Button";
import {
    getWorkflowList,
    getChatEventsList,
    getCompaniesList,
} from "../selectors/app";
import {
    fetchWorkflows as fetchWorkflowsRequest,
    fetchChatEvents as fetchChatEventsRequest,
    restartCandidateTest as restartCandidateTestRequest,
    fetchCompanies as fetchCompaniesRequest,
    setSuccessMsg as setSuccessMsgRequest,
    setErrorMsg as setErrorMsgRequest,
} from "../actions/app";

const RestartTest = (props) => {
    const {
        fetchCompanies,
        allCompanies,
        workflowList,
        fetchWorkflows,
        fetchChatEvents,
        chatEventsList,
        restartCandidateTest,
        setErrorMsg,
        setSuccessMsg,
    } = props;

    const [remainingTime, setRemainingTime] = useState(0);
    const [selectedCandidate, setSelectedCandidate] = useState();

    useEffect(() => {
        fetchCompanies();
    }, []);

    const onChangeCompany = (e) => {
        fetchWorkflows({ companyId: e.target.value });
    };

    const onChangeWorkflow = (e) => {
        fetchChatEvents({ workflowId: e.target.value });
    };

    const onRestartTest = () => {
        if (selectedCandidate) {
            restartCandidateTest({
                candidateId: selectedCandidate,
                remainingTime,
            });
            setSuccessMsg("Candidate Test restarted successfully");
        } else {
            setErrorMsg("Please select atleast one candidate");
        }
    };

    return (
        <div
            className="mainbar"
            style={{
                display: "flex",
                flexDirection: "column",
                width: "80vw",
                minHeight: "100vh",
            }}
        >
            <div
                style={{
                    padding: 40,
                    fontSize: 24,
                    fontWeight: "bold",
                    background: "#fff",
                }}
            >
                Restart Test
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "space-between",
                }}
            >
                <div style={{ marginRight: 20, flex: 1 }}>
                    <select onChange={onChangeCompany}>
                        <option>Select Company</option>
                        {allCompanies.map((comp) => (
                            <option key={comp._id} value={comp._id}>
                                {comp.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ marginRight: 20, flex: 1 }}>
                    <select onChange={onChangeWorkflow}>
                        <option>Select Workflow</option>
                        {workflowList.map((wf) => (
                            <option key={wf._id} value={wf._id}>
                                {wf.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ flex: 1 }}>
                    <select
                        onChange={(e) => setSelectedCandidate(e.target.value)}
                    >
                        <option>Select Candidate</option>
                        {chatEventsList.map((cel) => (
                            <option key={cel._id} value={cel._id}>
                                {cel.email}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div onClick={onClickShowEvents}>Reset</div> */}
            </div>
            <div style={{ marginTop: 25 }}>
                <div>
                    <input
                        type="text"
                        placeholder="Add Remaining Time"
                        value={remainingTime}
                        onChange={(e) => {
                            setRemainingTime(e.target.value);
                        }}
                    />
                    <br />
                </div>
                <div>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onRestartTest}
                    >
                        Create
                    </Button>
                </div>
            </div>
        </div>
    );
};

RestartTest.propTypes = {
    workflowList: PropTypes.array,
    fetchWorkflows: PropTypes.func,
    fetchChatEvents: PropTypes.func,
    chatEventsList: PropTypes.array,
    restartCandidateTest: PropTypes.func,
    allCompanies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    setSuccessMsg: PropTypes.func,
    setErrorMsg: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    workflowList: getWorkflowList(),
    chatEventsList: getChatEventsList(),
    allCompanies: getCompaniesList(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchWorkflows: (data) => dispatch(fetchWorkflowsRequest(data)),
    fetchChatEvents: (data) => dispatch(fetchChatEventsRequest(data)),
    restartCandidateTest: (data) => dispatch(restartCandidateTestRequest(data)),
    fetchCompanies: () => dispatch(fetchCompaniesRequest()),
    setSuccessMsg: (data) => dispatch(setSuccessMsgRequest(data)),
    setErrorMsg: (data) => dispatch(setErrorMsgRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestartTest);

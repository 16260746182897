export function redirectUnauthorisedUser(err, navigate, urlParams = "") {
    if (err.response && err.response.status == "403") {
        navigate(`/signin${urlParams}`);
    }
}

export const companyCheck = (convo, setConvo2) => {
    let companyName = localStorage.getItem("company");
    if (companyName == "62ac3bb53089fbfa5008efd6") {
        setConvo2(
            convo.filter((val) =>
                [
                    "Project Manager 1 - TVTech (Prescreening)",
                    "Project Manager 1 - TVTech",
                    "Integration Director - Lion Corp",
                ].includes(val.name)
            )
        );
    } else if (companyName == "62b5477c1af472aad92437eb") {
        setConvo2(
            convo.filter((val) => ["DBS - Project Manager"].includes(val.name))
        );
    } else if (companyName == "62bc7dca1af472aad9245870") {
        setConvo2(
            convo.filter((val) =>
                [
                    "Preliminary Screening - CapConnect",
                    "Screening - CapConnect",
                ].includes(val.name)
            )
        );
    } else if (companyName == "62c3ce5a9ceb9dfcb5d92506") {
        setConvo2(
            convo.filter((val) =>
                ["Software Engineer - Razer"].includes(val.name)
            )
        );
    } else if (companyName == "62c2b080138b1701fd343c8f") {
        setConvo2(
            convo.filter((val) =>
                [
                    "Partnership manager : TravelOtter",
                    "Senior product developer : Relic",
                    "Consulting: Telecom",
                    "Igloo - Consulting",
                    "Senior Product Evangelist : Relic",
                    "Change Manager - Warehousing",
                ].includes(val.name)
            )
        );
    } else if (companyName == "62c55be8836e73f0f74fe513") {
        setConvo2(
            convo.filter((val) =>
                ["BCG Rise - TFIP AI assessment"].includes(val.name)
            )
        );
    } else {
        setConvo2(convo);
    }
};

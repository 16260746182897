import React, { useEffect, useState, useRef } from "react";
import D3Funnel from "d3-funnel";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTour } from "@reactour/tour";

import AccountMenu from "../components/AccountMenu";
import AddNewDrive from "../components/workflows/AddNewDrive";
import axios from "../utils/axios";
import Button from "../components/common/Button";
import BottomBar from "../components/common/BottomBar";
import DataTable from "../components/common/DataTable";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Loader from "../components/common/Loader";
import Modal from "../components/common/CustomModal";
import NotificationBell from "../components/notifications/NotificationBell";
import SingleDrive from "../components/workflows/singleDrive";
import Snackbar from "../components/common/Snackbar";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";

import {
    companyCheck,
    redirectUnauthorisedUser,
} from "../helper/helperFunction";
import {
    fetchCompanyBots as fetchCompanyBotsRequest,
    editDriveName as editDriveNameRequest,
    resetRerender as resetRerenderRequest,
    setWorkFlowFunnelData as setWorkFlowFunnelDataRequest,
    setReportModel as setReportModelRequest,
    setDashboardLogo as setDashboardLogoRequest,
    setCompanyName as setCompanyNameRequest,
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
} from "../actions/app";
import {
    getCompanyBots,
    getCompanyFeatures,
    getCurrentWalkthroughType,
    getCurrentWalkthroughStep,
    getRerender,
    getSaasPlanDetails,
    getUserName,
    getUserPlan,
    getUserRole,
    getEditDriveNameLoading,
} from "../selectors/app";

import Edit from "../assets/Icons/Edit.png";
import tourendimg from "../assets/Done-rafiki.gif";
import tourstartimg from "../assets/tourstartimg.gif";
import Trash from "../assets/Icons/Trash.png";

import "../styles/workflow.scss";
import "../styles/table.scss";
import NoRowsOverlay from "../components/common/NoRowsOverlay";

const btnStyle = {
    background: "#fff",
    fontFamily: "Poppins",
    letterSpacing: "0.01em",
    borderRadius: "25px",
    padding: "2px 25px",
};

const Workflow = (props) => {
    const {
        userName,
        setShouldShowTour,
        shouldShowTour,
        setWorkflowFunnelData,
        companyBots,
        fetchCompanyBots,
        editDriveName,
        rerender,
        resetRerender,
        setReportModel,
        notification,
        refObj,
        // userPlan,
        userRole,
        // saasPlanDetails,
        setDashboardLogo,
        // companyFeatures,
        currentWalkthroughStep,
        updateCurrentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughType,
        editDriveNameLoading,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const { setIsOpen, setSteps, isOpen } = useTour();

    const [allWorkflows, setAllWorkflows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [companyName, setCompanyName] = useState();
    const [snackOpen, setSnackOpen] = useState(false);
    const [greenSnackOpen, setGreenSnackOpen] = useState(false);
    const [snackSuccess, setSnackSuccess] = useState(null);
    const [snackErr, setSnackErr] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [change, setChange] = useState(0);
    const [loading, setLoading] = useState(true);
    const [convo2, setConvo2] = useState([]);
    // const [searchText, setSearchText] = useState('');
    const [tourModel, setTourModel] = useState(false);
    const [tourEndModel, setTourEndModel] = useState(false);
    const [tourDrive, setTourDrive] = useState();
    const [addNewDrive, setAddNewDrive] = useState(false);
    const [deleteDriveLoading, setDeleteDriveLoading] = useState(false);
    const [showCopyDriveModal, setShowCopyDriveModal] = useState(false);
    const [copyDrive, setCopyDrive] = useState({
        id: "",
        name: "",
        desc: "",
        loading: false,
    });
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

    const editNameRef = useRef(null);

    const onOpenDeleteModal = () => {
        if (selectionModel.length > 0) setDeleteModal(true);
        else {
            setSnackErr("Please select atleast one recruitment drive");
            handleSnackClick();
        }
    };

    const onCloseDeleteModal = () => setDeleteModal(false);

    const handleSnackClick = () => {
        setSnackOpen(true);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };

    const handleGreenSnackClick = () => {
        setGreenSnackOpen(true);
    };

    const handleGreenSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setGreenSnackOpen(false);
    };

    useEffect(() => {
        if (selectionModel.length == 0) setSelectedRow([]);
        if (selectionModel.length == 1)
            setSelectedRow(
                workflows.filter((row) => selectionModel[0] == row.id)
            );
    }, [selectionModel]);

    const onOpenEditModal = () => {
        if (selectionModel.length == 1) setEditModal(true);
        else {
            setSnackErr("Please select a recruitment drive");
            handleSnackClick();
        }
    };

    const onCloseEditModal = () => setEditModal(false);

    const onOpenCopyDriveModal = (workflowId) => {
        setCopyDrive({
            ...copyDrive,
            id: workflowId,
        });
        setShowCopyDriveModal(true);
    };

    const onCloseCopyDriveModal = () => {
        setShowCopyDriveModal(false);
        setCopyDrive({
            id: "",
            name: "",
            desc: "",
        });
    };

    const workflowEdit = function (row) {
        editDriveName({ row, newName: editNameRef.current.value, navigate });
    };

    useEffect(() => {
        if (!rerender) return;

        setChange(change + 1);
        resetRerender();

        if (editModal) onCloseEditModal();
        if (deleteModal) onCloseDeleteModal();
    }, [rerender]);

    useEffect(() => {
        if (companyBots.length == 0) return;

        companyCheck(companyBots, setConvo2);
    }, [companyBots]);

    useEffect(() => {
        fetchCompanyBots({ navigate });
    }, [change]);

    const onOpenAddNewDrive = () => {
        if (userRole === "Member") {
            setSnackErr("You are not authorised to do this action");
            handleSnackClick();
        } else {
            // if (checkCompanyFeatureAccess(companyFeatures, 'driveCount', workflows.length) && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'driveCount', workflows.length)) {
            setAddNewDrive(true);
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "workflow"
            ) {
                updateCurrentWalkthroughStep(2);
                updateCurrentWalkthroughType("workflow");
            }
            // } else {
            // 	setSnackErr('Your quota is full')
            // 	handleSnackClick();
            // }
        }
    };

    const onCloseAddNewDrive = () => {
        setAddNewDrive(false);
    };

    const filterResponse = (response) => {
        let funnelScores = { total: 0, submitted: 0, hired: 0, shortlisted: 0 };
        let tempWorkflows = [];

        setCompanyName(response.data.name);

        if (response.data.logo) {
            localStorage.setItem(
                "dashboardLogo",
                process.env.REACT_APP_STORAGE_BUCKET_URL + response.data.logo
            );
            setDashboardLogo(
                process.env.REACT_APP_STORAGE_BUCKET_URL + response.data.logo
            );
        }

        let idxxx = tempWorkflows.length;

        response.data.workflows.reverse().forEach((wf, i) => {
            const workflow = {
                id: i + idxxx,
                caseName: wf.name,
                workflowId: wf._id,
                invitedCount: wf.invite.length,
                totalCount: wf.candidates.length - wf.added.length,
                startedCount:
                    wf.progress.length +
                    wf.review.length +
                    (wf.shortlisted.length +
                        wf.hired.length +
                        wf.rejected.length +
                        wf.kiv.length),
                awaitApprovalCount: 0,
                underReviewCount:
                    wf.review.length +
                    wf.shortlisted.length +
                    wf.hired.length +
                    wf.rejected.length +
                    wf.kiv.length,
                reviewedCount: wf.shortlisted.length + wf.hired.length,
                candidates: wf.candidates,
            };
            funnelScores.total = funnelScores.total + workflow.totalCount;
            funnelScores.hired = funnelScores.hired + wf.hired.length;
            funnelScores.shortlisted =
                funnelScores.shortlisted + wf.shortlisted.length;
            funnelScores.submitted =
                funnelScores.submitted + workflow.underReviewCount;
            tempWorkflows.push(workflow);
        });

        const optionf = {
            block: {
                dynamicHeight: true,
                dynamicSlope: false,
                minHeight: 10,
                fill: {
                    type: "gradient",
                    scale: ["#4FCA64", "#54C5EB", "#FFB74A", "#22242C"],
                },
            },
            tooltip: {
                enabled: true,
                format: "{l} : {f}",
            },
            label: { enabled: false },
            chart: {
                curve: {
                    height: 15,
                    enabled: true,
                },
                totalCount: funnelScores.total * 2,
                inverted: false,
                height: 175,
            },
        };
        const dataf = [
            { label: "Invited", value: funnelScores.total },
            { label: "Submitted", value: funnelScores.submitted },
            { label: "Shortlisted", value: funnelScores.shortlisted },
            { label: "Hired", value: funnelScores.hired },
        ];

        if (refObj.current) {
            const chart = new D3Funnel(refObj.current);
            chart.draw(dataf, optionf);
            refObj.current.style.width = "inherit";
            refObj.current.style.height = "200px";
        }

        setWorkflowFunnelData(funnelScores);
        setWorkflows(tempWorkflows);
        setAllWorkflows(tempWorkflows);
        setLoading(false);
    };

    useEffect(() => {
        if (location.state?.company) {
            //undefined
            axios
                .get("/admin/getCompany/" + location.state.company._id)
                .then((response) => {
                    filterResponse(response);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);

                    redirectUnauthorisedUser(err, navigate);
                });
        } else {
            axios
                .get("/company")
                .then((response) => {
                    filterResponse(response);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);

                    redirectUnauthorisedUser(err, navigate);
                });
        }
    }, [change]);

    const handleCopyDrive = () => {
        if (copyDrive.name === "") {
            setSnackErr("Enter a valid drive name");
            handleSnackClick();
        } else {
            setCopyDrive({ ...copyDrive, loading: true });
            axios
                .post("/workflow/copy", {
                    workflowName: copyDrive.name,
                    workflowDescription: copyDrive.desc,
                    copyWorkflowId: copyDrive.id,
                })
                .then((res) => {
                    setSnackSuccess(res.data.message);
                    handleGreenSnackClick();
                    setChange(change + 1);
                    onCloseCopyDriveModal();
                    setCopyDrive({
                        id: "",
                        name: "",
                        desc: "",
                        loading: false,
                    });
                })
                .catch((err) => {
                    setSnackErr(err);
                    handleSnackClick();
                    setCopyDrive({ ...copyDrive, loading: false });
                });
        }
    };

    const onClickViewDrive = () => {
        if (
            currentWalkthroughType === "workflow" &&
            currentWalkthroughStep > 0
        ) {
            updateCurrentWalkthroughStep(9);
        } else if (
            [
                "invite",
                "sendemails",
                "whitelabelling",
                "videoproctoring",
                "automaticemails",
                "createjob",
                "evaluate",
            ].includes(currentWalkthroughType) &&
            currentWalkthroughStep > 0
        ) {
            updateCurrentWalkthroughStep(2);
        }
    };

    const columns = [
        {
            field: "caseName",
            headerName: "Drive Name",
            width: 200,
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textAlign: "left",
                                textDecoration: "none",
                                width: "100%",
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                            }}
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                        >
                            <div className="tooltip" style={{ width: "100%" }}>
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {params.row.caseName}
                                </div>
                                <span className="tooltiptext datagrid">
                                    {params.row.caseName}
                                </span>
                            </div>
                        </Link>
                    </>
                );
            },
        },
        {
            field: "totalCount",
            headerAlign: "center",
            headerName: "Invited",
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textDecoration: "none",
                                width: "100%",
                                color: "black",
                                marginRight: 25,
                            }}
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                        >
                            {params.row.totalCount}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "startedCount",
            headerName: "Started",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textDecoration: "none",
                                width: "100%",
                                color: "black",
                                marginRight: 45,
                            }}
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                        >
                            {params.row.startedCount}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "underReviewCount",
            headerName: "Submitted",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textDecoration: "none",
                                width: "100%",
                                color: "black",
                                marginRight: 25,
                            }}
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                        >
                            {params.row.underReviewCount}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "reviewedCount",
            headerName: "Shortlisted",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            style={{
                                textDecoration: "none",
                                width: "100%",
                                color: "black",
                                marginRight: 25,
                            }}
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                        >
                            {params.row.reviewedCount}
                        </Link>
                    </>
                );
            },
        },
        {
            headerName: "Analysis",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{ width: "100%" }}>
                        <Link
                            to={`/workflows/${params.row.caseName}`}
                            state={{
                                workflowId: params.row.workflowId,
                                workflowName: params.row.caseName,
                                sidebar: "quickLook",
                            }}
                            style={{ textDecoration: "none", marginRight: 15 }}
                            onClick={onClickViewDrive}
                        >
                            <Button
                                id="quickViewFlow-selector-1"
                                style={{
                                    background: "#479BD2",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                    letterSpacing: "0.01em",
                                    borderRadius: "25px",
                                    fontSize: "14px",
                                }}
                                size="small"
                                variant="contained"
                            >
                                <span>View</span>
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
    ];

    const workflowDelete = () => {
        selectionModel.map((cand) => {
            let id = allWorkflows[cand].workflowId;
            setDeleteDriveLoading(true);
            axios
                .delete("/workflow/" + id)
                .then(() => {
                    setWorkflows(
                        workflows.filter((item) => item.workflowId !== id)
                    );
                    setChange(change + 1);
                    setSnackSuccess("Deleted drive successfully");
                    handleGreenSnackClick();
                    setDeleteDriveLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setDeleteDriveLoading(false);
                    redirectUnauthorisedUser(err, navigate);
                });
        });

        setSelectionModel([]);
    };

    const workflowDeleteMobile = (wId) => {
        axios
            .delete("/workflow/" + wId)
            .then(() => {
                setWorkflows(
                    workflows.filter((item) => item.workflowId !== wId)
                );
                setChange(change + 1);
                setSnackSuccess("Deleted drive successfully");
                handleGreenSnackClick();
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
            });
    };

    const escapeRegExp = (value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };

    const requestSearch = (searchValue) => {
        // setSearchText(searchValue);

        if (searchValue == "") {
            setWorkflows(allWorkflows);
        } else {
            const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
            const filteredRows = allWorkflows.filter((row) => {
                if (
                    searchRegex.test(row.caseName) ||
                    row.candidates.some((candidate) =>
                        searchRegex.test(candidate.name)
                    )
                ) {
                    return true;
                }
            });
            setWorkflows(filteredRows);
        }
    };

    const onOpenTourModel = () => setTourModel(true);

    const onCloseTourModel = () => setTourModel(false);

    const onOpenTourEndModel = () => setTourEndModel(true);

    const onCloseTourEndModel = () => setTourEndModel(false);

    useEffect(() => {
        if (shouldShowTour) {
            onOpenTourModel();
            setShouldShowTour(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (workflows.length === 0) return;

        setTourDrive({
            id: 0,
            caseName: "Finance Associate",
            workflowId: "1",
            invitedCount: 500,
            totalCount: 3000,
            startedCount: 2595,
            awaitApprovalCount: 0,
            underReviewCount: 2500,
            reviewedCount: 0,
        });
    }, [workflows]);

    useEffect(() => {
        if (tourDrive) {
            setSteps(steps);
        }
    }, [tourDrive]);

    const steps = !tourDrive
        ? []
        : [
              {
                  selector: ".dashboard-first-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Create new drives
                                  </h4>
                                  <p>Step 1 of 9</p>
                              </div>
                              <p>
                                  Start by creating a new recruitment drive for
                                  your new hiring campaign. Eg: Associate hiring
                                  (Spring’22)
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      id="testDashboardTourFlow-selector-15"
                                      style={{
                                          color: "#eee",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div>
                                      <Button
                                          id="testDashboardTourFlow-selector-2"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/new-applicants", {
                                                  state: {
                                                      sidebar: "invitations",
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                  },
                                              });
                                              setCurrentStep(1);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-second-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: 10,
                                  }}
                              >
                                  <h4 style={{ fontSize: 20 }}>
                                      Invite candidates to attempt the
                                      assessment
                                  </h4>
                                  <p>Step 2 of 9</p>
                              </div>
                              <p>It’s an easy 3 step process:</p>
                              <ul style={{ margin: "10px 0" }}>
                                  <li style={{ margin: "5px 0" }}>
                                      Customise mails, add your brand identity
                                      and set your own templates
                                  </li>
                                  <li style={{ margin: "5px 0" }}>
                                      Add applicants (individually or by
                                      uploading a csv)
                                  </li>
                                  <li style={{ margin: "5px 0" }}>
                                      Send out invitation mails and schedule
                                      automatic reminders
                                  </li>
                              </ul>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          id="testDashboardTourFlow-selector-14"
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              fontWeight: "600",
                                              cursor: "pointer",
                                          }}
                                          onClick={() => {
                                              navigate("/workflows");
                                              setCurrentStep(0);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-3"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/review-applicants", {
                                                  state: {
                                                      sidebar:
                                                          "reviewApplicants",
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                  },
                                              });
                                              setCurrentStep(2);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: 16,
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-third-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: 10,
                                  }}
                              >
                                  <h4 style={{ fontSize: 20 }}>
                                      Review Applicants
                                  </h4>
                                  <p>Step 3 of 9</p>
                              </div>
                              <p>
                                  Evaluate candidates&apos; performance and
                                  decide whether to Shortlist, Reject or Keep in
                                  View (allows you to hold that application for
                                  evaluation later)
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          id="testDashboardTourFlow-selector-13"
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              fontWeight: "600",
                                              cursor: "pointer",
                                          }}
                                          onClick={() => {
                                              navigate("/new-applicants", {
                                                  state: {
                                                      sidebar: "invitations",
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                  },
                                              });
                                              setCurrentStep(1);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-4"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              setReportModel(true);
                                              setTimeout(
                                                  () => setCurrentStep(3),
                                                  200
                                              );
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-forth-step",
                  position: "top",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Performance reports
                                  </h4>
                                  <p>Step 4 of 9</p>
                              </div>
                              <p>
                                  View or download candidate’s report, video
                                  answers and chat transcripts to help you make
                                  your decision. You can also access the
                                  Interviewer’s Guide to understand the
                                  role-specific skills and key tasks judged in
                                  the assessment, and to help your interviewers
                                  focus on the key issues.
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          id="testDashboardTourFlow-selector-12"
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              cursor: "pointer",
                                              fontWeight: "600",
                                          }}
                                          onClick={() => {
                                              setReportModel(false);
                                              setCurrentStep(2);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-5"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate(
                                                  `/workflows/${tourDrive.caseName}`,
                                                  {
                                                      state: {
                                                          workflowId:
                                                              tourDrive.workflowId,
                                                          workflowName:
                                                              tourDrive.caseName,
                                                          sidebar: "quickLook",
                                                      },
                                                  }
                                              );
                                              setReportModel(false);
                                              setCurrentStep(4);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-fifth-step",
                  highlightedSelectors: [
                      ".dashboard-fifth-step",
                      ".dashboard-fifth-step-2",
                  ],
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Quick Overview
                                  </h4>
                                  <p>Step 5 of 9</p>
                              </div>
                              <p>
                                  Check out the performance of your recruitment
                                  drive with our quick overview. You can also
                                  copy the assessment link from the Chatbot Link
                                  button to use it for internal team testing.
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              cursor: "pointer",
                                              fontWeight: "600",
                                          }}
                                          onClick={() => {
                                              navigate("/review-applicants", {
                                                  state: {
                                                      sidebar:
                                                          "reviewApplicants",
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                  },
                                              });
                                              setReportModel(true);
                                              setTimeout(
                                                  () => setCurrentStep(3),
                                                  200
                                              );
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-6"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/finalized", {
                                                  state: {
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                      sidebar: "finalised",
                                                  },
                                              });
                                              setCurrentStep(5);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-sixth-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Reviewed Candidates
                                  </h4>
                                  <p>Step 6 of 9</p>
                              </div>
                              <p>
                                  See a list of shortlisted and rejected
                                  candidates, and send out customised mails to
                                  inform them of the outcome.
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              cursor: "pointer",
                                              fontWeight: "600",
                                          }}
                                          onClick={() => {
                                              navigate(
                                                  `/workflows/${tourDrive.caseName}`,
                                                  {
                                                      state: {
                                                          workflowId:
                                                              tourDrive.workflowId,
                                                          workflowName:
                                                              tourDrive.caseName,
                                                          sidebar: "quickLook",
                                                      },
                                                  }
                                              );
                                              setCurrentStep(4);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-7"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/analytics", {
                                                  state: {
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                      sidebar: "analytics",
                                                  },
                                              });
                                              setCurrentStep(6);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-seventh-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Analytics
                                  </h4>
                                  <p>Step 7 of 9</p>
                              </div>
                              <p>
                                  Make data-driven decisions by analysing the
                                  performance of your recruitment drive.
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              cursor: "pointer",
                                              fontWeight: "600",
                                          }}
                                          onClick={() => {
                                              navigate("/finalized", {
                                                  state: {
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                      sidebar: "finalized",
                                                  },
                                              });
                                              setCurrentStep(5);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-8"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/settings", {
                                                  state: {
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                      sidebar: "settings",
                                                  },
                                              });
                                              setCurrentStep(7);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-eighth-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>Settings</h4>
                                  <p>Step 8 of 9</p>
                              </div>
                              <p>
                                  Change your settings - send post-assessment
                                  mails to candidates to acknowledge their
                                  submissions and switch on/ off video
                                  proctoring features. You can also add your
                                  branding by making your company logo as the
                                  primary logo of dashboard and assessment
                                  interface - just go to whitelabelling!
                              </p>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                      }}
                                      onClick={() => setIsOpen(false)}
                                  >
                                      Skip tour
                                  </div>
                                  <div
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}
                                  >
                                      <div
                                          style={{
                                              color: "#eee",
                                              marginRight: 10,
                                              cursor: "pointer",
                                              fontWeight: "600",
                                          }}
                                          onClick={() => {
                                              navigate("/analytics", {
                                                  state: {
                                                      workflowId:
                                                          tourDrive.workflowId,
                                                      workflowName:
                                                          tourDrive.caseName,
                                                      sidebar: "analytics",
                                                  },
                                              });
                                              setCurrentStep(6);
                                          }}
                                      >
                                          Back
                                      </div>
                                      <Button
                                          id="testDashboardTourFlow-selector-9"
                                          size="small"
                                          variant="contained"
                                          style={btnStyle}
                                          onClick={() => {
                                              navigate("/team", {
                                                  state: {
                                                      sidebar: "team",
                                                  },
                                              });
                                              setCurrentStep(8);
                                          }}
                                      >
                                          <span
                                              style={{
                                                  color: "#479BD2",
                                                  fontSize: "16px",
                                                  fontWeight: "bold",
                                              }}
                                          >
                                              Next
                                          </span>
                                      </Button>
                                  </div>
                              </div>
                          </div>
                      );
                  },
              },
              {
                  selector: ".dashboard-ninth-step",
                  content: ({ setCurrentStep, setIsOpen }) => {
                      return (
                          <div style={{ color: "#fff" }}>
                              <div
                                  style={{
                                      color: "#eee",
                                      fontSize: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                  }}
                              >
                                  <h4 style={{ fontSize: "20px" }}>
                                      Manage team access
                                  </h4>
                                  <p>Step 9 of 9</p>
                              </div>
                              <p>
                                  Invite your coworkers and set their access
                                  levels
                              </p>
                              <ul style={{ margin: "10px 0" }}>
                                  <li style={{ margin: "5px 0" }}>
                                      Admin: Has authority to perform actions
                                      like inviting, shortlisting etc
                                  </li>
                                  <li style={{ margin: "5px 0" }}>
                                      Member: Only has authority to view lists,
                                      but not perform any function
                                  </li>
                              </ul>
                              <br />
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                  }}
                              >
                                  <div
                                      style={{
                                          color: "#eee",
                                          marginRight: 10,
                                          cursor: "pointer",
                                          fontWeight: "600",
                                      }}
                                      onClick={() => {
                                          navigate("/settings", {
                                              state: {
                                                  workflowId:
                                                      tourDrive.workflowId,
                                                  workflowName:
                                                      tourDrive.caseName,
                                                  sidebar: "settings",
                                              },
                                          });
                                          setCurrentStep(7);
                                      }}
                                  >
                                      Back
                                  </div>
                                  <Button
                                      id="testDashboardTourFlow-selector-10"
                                      size="small"
                                      variant="contained"
                                      style={btnStyle}
                                      onClick={() => {
                                          setIsOpen(false);
                                          navigate("/workflows");
                                          onOpenTourEndModel();
                                      }}
                                  >
                                      <span
                                          style={{
                                              color: "#479BD2",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                          }}
                                      >
                                          Finish
                                      </span>
                                  </Button>
                              </div>
                          </div>
                      );
                  },
              },
          ];

    return (
        <div className="productList">
            {mediaQueryMobile.matches && <Header />}
            <Modal
                open={tourModel}
                onClose={onCloseTourModel}
                center
                styles={{
                    modal: {
                        borderRadius: "0",
                        padding: "40px",
                        maxWidth: "500px",
                    },
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <div style={{ color: "#3c3c3c" }}>
                        <h1 style={{ color: "inherit", fontWeight: "500" }}>
                            Hello {userName},
                        </h1>
                        <h1 style={{ color: "inherit", fontWeight: "500" }}>
                            Welcome to HQ dashboard
                        </h1>
                    </div>
                    <div style={{ margin: "10px 0 0 0" }}>
                        <img
                            style={{
                                width: "300px",
                            }}
                            src={tourstartimg}
                            alt="user-logo"
                        />
                    </div>
                    <h4
                        style={{
                            color: "inherit",
                            margin: "10px 0",
                            fontWeight: "500",
                        }}
                    >
                        Want to view our quick user guide ?
                    </h4>
                    <div style={{ margin: "10px 0" }}>
                        <Button
                            size="small"
                            variant="contained"
                            className="cancel-modal-button"
                            style={{
                                backgroundImage:
                                    "linear-gradient(to left, #23597C, #008095)",
                                fontFamily: "Poppins",
                                letterSpacing: "0.01em",
                                borderRadius: "25px",
                                padding: "2px 30px",
                            }}
                            onClick={() => {
                                onCloseTourModel();
                                setIsOpen(true);
                            }}
                            id="testDashboardTourFlow-selector-1"
                        >
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                }}
                            >
                                Take tour
                            </span>
                        </Button>
                    </div>
                    <div>
                        <div
                            className="cancel-modal-button"
                            style={{
                                background: "transparent",
                                fontFamily: "Poppins",
                                letterSpacing: "0.01em",
                            }}
                            onClick={() => {
                                onCloseTourModel();
                            }}
                            id="testLogoutFlow-selector-1"
                        >
                            <span
                                style={{
                                    color: "#3c3c3c",
                                    fontSize: "16px",
                                    textDecoration: "underline",
                                }}
                            >
                                No thanks, I&apos;ll take it from here
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={tourEndModel}
                onClose={onCloseTourEndModel}
                center
                styles={{
                    modal: {
                        borderRadius: "0",
                        padding: "40px",
                        maxWidth: "500px",
                    },
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <div style={{ margin: "10px 0 0 0" }}>
                        <img
                            style={{
                                width: "300px",
                            }}
                            src={tourendimg}
                            alt="user-logo"
                        />
                    </div>
                    <h1
                        style={{
                            margin: "10px 0",
                            fontWeight: "600",
                            color: "#686970",
                        }}
                    >
                        That concludes the tour
                    </h1>
                    <p
                        style={{
                            color: "#686970",
                            marginBottom: "20px",
                            fontWeight: "600",
                        }}
                    >
                        If you have any problems or issues, we are here to help.
                        Feel free to browse our Help Center or open a ticket
                        with our support team if you&apos;d like to learn more.
                    </p>
                    <div style={{ margin: "10px 0" }}>
                        <Button
                            size="small"
                            variant="contained"
                            className="cancel-modal-button"
                            style={{
                                backgroundImage:
                                    "linear-gradient(to left, #23597C, #008095)",
                                fontFamily: "Poppins",
                                letterSpacing: "0.01em",
                                borderRadius: "25px",
                                padding: "2px 30px",
                            }}
                            onClick={() => {
                                onCloseTourEndModel();
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                }}
                            >
                                Finish
                            </span>
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={deleteModal} onClose={onCloseDeleteModal} center>
                <p className="modal-text">
                    Do you want to delete this recruitment drive?
                </p>
                <div className="button-div">
                    <Button
                        id="testDeleteDriveFlow-selector-2"
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        disabled={deleteDriveLoading}
                        onClick={() => {
                            onCloseDeleteModal();
                            workflowDelete();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={onCloseDeleteModal}
                    >
                        No
                    </Button>
                </div>
            </Modal>
            <Modal open={editModal} onClose={onCloseEditModal} center>
                <p className="modal-text">Edit drive name</p>
                {selectedRow.length == 1 ? (
                    <>
                        <div className="input-div-workflow">
                            <label
                                style={{
                                    margin: "0 15px",
                                    fontWeight: "bold",
                                    fontSize: 18,
                                }}
                            >
                                New name
                            </label>
                            <br />
                            <input
                                id="testEditDriveNameFlow-selector-2"
                                type="text"
                                ref={editNameRef}
                                defaultValue={selectedRow[0].caseName}
                                placeholder="Name"
                                style={{
                                    background: "#F2F2F2",
                                    borderRadius: 30,
                                }}
                                required
                            />
                        </div>
                    </>
                ) : (
                    "Loading"
                )}
                <div className="button-div">
                    <Button
                        id="testEditDriveNameFlow-selector-3"
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: 25,
                            padding: "5px 12px",
                            fontSize: 16,
                        }}
                        size="small"
                        variant="contained"
                        disabled={editDriveNameLoading}
                        onClick={() => workflowEdit(selectedRow[0])}
                    >
                        Ok
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={onCloseEditModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <div className="inner-div">
                <div className="top-icon-box">
                    <WalkthroughWelcome />
                    {userRole.includes("Admin") ? (
                        <div className="tooltip">
                            <IconButton
                                aria-label="edit"
                                onClick={onOpenEditModal}
                                id="testEditDriveNameFlow-selector-1"
                            >
                                <img src={Edit} alt="" width={30} height={30} />
                            </IconButton>
                            <span className="tooltiptext">Edit</span>
                        </div>
                    ) : (
                        ""
                    )}
                    {userRole.includes("Admin") ? (
                        <div className="tooltip">
                            <IconButton
                                onClick={onOpenDeleteModal}
                                aria-label="delete"
                                id="testDeleteDriveFlow-selector-1"
                            >
                                <img
                                    src={Trash}
                                    alt=""
                                    width={30}
                                    height={30}
                                />
                            </IconButton>
                            <span className="tooltiptext">Delete</span>
                        </div>
                    ) : (
                        ""
                    )}
                    <NotificationBell notification={notification} />
                    <AccountMenu />
                </div>
            </div>
            <div
                className="userListTitleContainer"
                style={{ paddingTop: 20, position: "relative" }}
            >
                {!mediaQueryMobile.matches && (
                    <div className="inner-div-details">&nbsp;</div>
                )}
                <div className="search-box" style={{ width: "55vw" }}>
                    <input
                        type="search"
                        placeholder="Search"
                        onChange={(event) => requestSearch(event.target.value)}
                        id="testSearchDrive-selector"
                    />
                </div>
                <div
                    className="tooltip"
                    style={{
                        zIndex:
                            currentWalkthroughStep === 1 &&
                            currentWalkthroughType === "workflow"
                                ? 1000
                                : "auto",
                    }}
                >
                    <Button
                        className="dashboard-first-step"
                        id="testNewDriveFlow-selector-1"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            textTransform: "capitalize",
                            borderRadius: 25,
                            padding: "12px 16px",
                            fontSize: "0.8125rem",
                        }}
                        size="small"
                        variant="contained"
                        onClick={onOpenAddNewDrive}
                    >
                        <span style={{ marginRight: "0.5rem" }}>ADD NEW</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            width={16}
                            height={16}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                            />
                        </svg>
                    </Button>
                    <span className="tooltiptext">Add a recruitment drive</span>
                </div>
            </div>
            <div className="workflowsListContainer">
                {mediaQueryMobile.matches ? (
                    <>
                        {workflows.length == 0 ? (
                            <Loader />
                        ) : (
                            <p className="recruitment-drive-txt">
                                Recruitment Drives
                            </p>
                        )}
                        {workflows.map((workflow, index) => (
                            <SingleDrive
                                key={index}
                                data={workflow}
                                workflowDeleteMobile={workflowDeleteMobile}
                            />
                        ))}
                    </>
                ) : (
                    <DataTable
                        loading={loading}
                        noRowsComponent={<NoRowsOverlay />}
                        rows={
                            !isOpen
                                ? workflows
                                : [
                                      {
                                          id: 0,
                                          caseName: "Finance Associate",
                                          workflowId: "1",
                                          invitedCount: 500,
                                          totalCount: 3000,
                                          startedCount: 2595,
                                          awaitApprovalCount: 0,
                                          underReviewCount: 2500,
                                          reviewedCount: 0,
                                      },
                                  ]
                        }
                        columns={columns}
                        checkboxSelection={userRole.includes("Admin")}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        userRole={userRole}
                        openCopyDriveModal={onOpenCopyDriveModal}
                        selectionModel={selectionModel}
                        pageSize={15}
                        highlightedBox={
                            (currentWalkthroughStep === 8 &&
                                currentWalkthroughType === "workflow") ||
                            (currentWalkthroughStep === 1 &&
                                [
                                    "invite",
                                    "sendemails",
                                    "whitelabelling",
                                    "videoproctoring",
                                    "automaticemails",
                                    "createjob",
                                    "evaluate",
                                ].includes(currentWalkthroughType))
                        }
                    />
                )}
            </div>
            <Modal
                open={addNewDrive}
                onClose={onCloseAddNewDrive}
                center
                closeOnOverlayClick={false}
            >
                <AddNewDrive
                    setSnackErr={setSnackErr}
                    change={change}
                    handleSnackClick={handleSnackClick}
                    setChange={setChange}
                    companyName={companyName}
                    selectBotMenu={convo2}
                    onClose={onCloseAddNewDrive}
                    currentWalkthroughStep={currentWalkthroughStep}
                    updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
                    currentWalkthroughType={currentWalkthroughType}
                    open={addNewDrive}
                />
            </Modal>
            <Modal
                open={showCopyDriveModal}
                onClose={onCloseCopyDriveModal}
                center
                closeOnOverlayClick={false}
                classNames={{ modal: "copyDriveModal" }}
            >
                <div className="default-form">
                    <div className="title">Copy drive</div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) =>
                                setCopyDrive({
                                    ...copyDrive,
                                    name: e.target.value,
                                })
                            }
                            value={copyDrive.name}
                            placeholder="Name of drive"
                        />
                    </div>
                    <div>
                        <textarea
                            onChange={(e) =>
                                setCopyDrive({
                                    ...copyDrive,
                                    desc: e.target.value,
                                })
                            }
                            value={copyDrive.desc}
                            placeholder="Add description"
                        />
                    </div>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            className="action"
                            onClick={handleCopyDrive}
                            style={{
                                padding: "10px 40px",
                                borderRadius: 40,
                                zIndex: currentWalkthroughStep === 7 ? 1000 : 1,
                                position: "relative",
                            }}
                            disabled={copyDrive.loading}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={2500}
                onClose={handleSnackClose}
                msg={snackErr ? snackErr : ""}
                type="error"
            />
            <Snackbar
                open={greenSnackOpen}
                autoHideDuration={2500}
                onClose={handleGreenSnackClose}
                msg={snackSuccess ? snackSuccess : ""}
                type="success"
            />
            {mediaQueryMobile.matches && (
                <BottomBar companyName={companyName} />
            )}
            {(currentWalkthroughStep === 1 || currentWalkthroughStep === 8) &&
            currentWalkthroughType === "workflow" ? (
                <WalkthroughOverlay
                    showWalkthroughBackBtn={
                        currentWalkthroughStep === 8 ? false : true
                    }
                />
            ) : (
                ""
            )}
            {currentWalkthroughStep === 1 &&
            [
                "invite",
                "sendemails",
                "team",
                "whitelabelling",
                "videoproctoring",
                "automaticemails",
                "createjob",
                "evaluate",
            ].includes(currentWalkthroughType) ? (
                <WalkthroughOverlay />
            ) : (
                ""
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    companyBots: getCompanyBots(),
    rerender: getRerender(),
    userPlan: getUserPlan(),
    userName: getUserName(),
    userRole: getUserRole(),
    saasPlanDetails: getSaasPlanDetails(),
    companyFeatures: getCompanyFeatures(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
    editDriveNameLoading: getEditDriveNameLoading(),
});

const mapDispatchToProps = (dispatch) => ({
    setWorkflowFunnelData: (data) =>
        dispatch(setWorkFlowFunnelDataRequest(data)),
    setReportModel: (data) => dispatch(setReportModelRequest(data)),
    fetchCompanyBots: (data) => dispatch(fetchCompanyBotsRequest(data)),
    resetRerender: () => dispatch(resetRerenderRequest()),
    editDriveName: (data) => dispatch(editDriveNameRequest(data)),
    setDashboardLogo: (data) => dispatch(setDashboardLogoRequest(data)),
    setCompanyName: (data) => dispatch(setCompanyNameRequest(data)),
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) =>
        dispatch(updateCurrentWalkthroughTypeRequest(data)),
});

Workflow.propTypes = {
    userName: PropTypes.string,
    setShouldShowTour: PropTypes.func,
    setWorkflowFunnelData: PropTypes.func,
    shouldShowTour: PropTypes.bool,
    companyBots: PropTypes.array,
    fetchCompanyBots: PropTypes.func,
    editDriveName: PropTypes.func,
    rerender: PropTypes.number,
    resetRerender: PropTypes.func,
    setReportModel: PropTypes.func,
    notification: PropTypes.object,
    refObj: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    userPlan: PropTypes.object,
    userRole: PropTypes.string,
    saasPlanDetails: PropTypes.object,
    setDashboardLogo: PropTypes.func,
    companyFeatures: PropTypes.object,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughType: PropTypes.func,
    editDriveNameLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);

import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/Checkbox.scss';

const Checkbox = ({ checked, onChange, label, classNames = {}, styles = {}, value }) => {
    return (
        <div
            className={`${Object.keys(classNames).length > 0 && Object.prototype.hasOwnProperty.call(classNames, 'container') ? classNames.container : ""} custom-checkbox`}
            style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'container') ? styles.container : {}}
        >
            <label>
                {label}
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    className={`${Object.keys(classNames).length > 0 && Object.prototype.hasOwnProperty.call(classNames, 'input') ? classNames.input : ""}`}
                    style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'input') ? styles.input : {}}
                />
                <span
                    className={`${Object.keys(classNames).length > 0 && Object.prototype.hasOwnProperty.call(classNames, 'checkmark') ? classNames.checkmark : ""} checkmark`}
                    style={Object.keys(styles).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'checkmark') ? styles.checkmark : {}}
                />
            </label>
        </div>
    )
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    classNames: PropTypes.object,
    styles: PropTypes.object,
    value: PropTypes.any
}

export default Checkbox;
import React from "react";
import emptyRowImg from "../../assets/emptyrow2.png";

export default function NoRowsOverlay() {
    return (
        <div className="norowsoverlay__container">
            <img
                src={emptyRowImg}
                alt="no rows"
                className="norowsoverlay__img"
            />
            <p className="norowsoverlay__text">No data to show</p>
        </div>
    );
}

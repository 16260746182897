import { createSelector } from "reselect";

const selectApp = (state) => state.globalState;

const selectLoader = (state) => state.loaders;

export const getAuthErrorMsg = () =>
    createSelector(selectApp, (appState) => appState.get("authErrorMsg"));

export const getAssessmentHistory = () =>
    createSelector(selectApp, (appState) => appState.get("assessmentHistory"));

export const getAuthLoading = () =>
    createSelector(selectApp, (appState) => appState.get("authLoading"));

export const getAuthSuccessMsg = () =>
    createSelector(selectApp, (appState) => appState.get("authSuccessMsg"));

export const getCandidatesList = () =>
    createSelector(selectApp, (appState) => appState.get("candidates").toJS());

export const getChatEventsList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("chatEventsList").toJS()
    );

export const getCompanyBots = () =>
    createSelector(selectApp, (appState) => appState.get("companyBots").toJS());

export const getConvoList = () =>
    createSelector(selectApp, (appState) => appState.get("convo").toJS());

export const getDriveDetails = () =>
    createSelector(selectApp, (appState) =>
        appState.get("driveDetails").toJS()
    );

export const getDriveInvitedList = () =>
    createSelector(selectApp, (appState) => appState.get("driveInvitedCount"));

export const getDriveSubmittedList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("driveSubmittedCount")
    );

export const getFunnelData = () =>
    createSelector(selectApp, (appState) => appState.get("funnelData").toJS());

export const getIsAuthenticated = () =>
    createSelector(selectApp, (appState) => appState.get("isAuthenticated"));

export const getIsReportModelOpen = () =>
    createSelector(selectApp, (appState) => appState.get("isReportModelOpen"));

export const getNotificationList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("notifications").toJS()
    );

export const getRerender = () =>
    createSelector(selectApp, (appState) => appState.get("rerender"));

export const getSettingsLoading = () =>
    createSelector(selectApp, (appState) => appState.get("settingsLoading"));

export const getShowVerificationSuccess = () =>
    createSelector(selectApp, (appState) =>
        appState.get("showVerificationSuccess")
    );

export const getSuccessMsg = () =>
    createSelector(selectApp, (appState) => appState.get("successMsg"));

export const getErrorMsg = () =>
    createSelector(selectApp, (appState) => appState.get("errorMsg"));

export const getTotalAssessments = () =>
    createSelector(selectApp, (appState) => appState.get("totalAssessments"));

export const getUserName = () =>
    createSelector(selectApp, (appState) => appState.get("userName"));

export const getUserPlan = () =>
    createSelector(selectApp, (appState) => appState.get("userPlan").toJS());

export const getUserRole = () =>
    createSelector(selectApp, (appState) => appState.get("userRole"));

export const getUserVerificationLoading = () =>
    createSelector(selectApp, (appState) =>
        appState.get("userVerificationLoading")
    );

export const getWalkthroughWelcome = () =>
    createSelector(selectApp, (appState) => appState.get("walkthroughWelcome"));

export const getSuperUserList = () =>
    createSelector(selectApp, (appState) => appState.get("superUser").toJS());

export const getCompaniesList = () =>
    createSelector(selectApp, (appState) => appState.get("companies").toJS());

export const getFeedbackList = () =>
    createSelector(selectApp, (appState) => appState.get("feedback").toJS());

export const getMyDetails = () =>
    createSelector(selectApp, (appState) => appState.get("myDetails").toJS());

export const getPreScreeningBots = () =>
    createSelector(selectApp, (appState) =>
        appState.get("preScreeningBots").toJS()
    );

export const getSaasPlanList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("saasPlanList").toJS()
    );

export const getSaasPlanDetails = () =>
    createSelector(selectApp, (appState) =>
        appState.get("saasPlanDetails").toJS()
    );

export const getDashboardLogo = () =>
    createSelector(selectApp, (appState) => appState.get("dashboardLogo"));

export const getJobRoleDetails = () =>
    createSelector(selectApp, (appState) =>
        appState.get("jobRoleDetails").toJS()
    );

export const getCreateJobLoading = () =>
    createSelector(selectApp, (appState) => appState.get("createJobLoading"));

export const getTrialsUsersList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("trialsUsersList").toJS()
    );

export const getCompanyDetails = () =>
    createSelector(selectApp, (appState) =>
        appState.get("companyDetails").toJS()
    );

export const getCompanyFeatures = () =>
    createSelector(selectApp, (appState) =>
        appState.get("companyFeatures").toJS()
    );

export const getCompanyName = () =>
    createSelector(selectApp, (appState) => appState.get("companyName"));

export const getApplicantsLoading = () =>
    createSelector(selectApp, (appState) => appState.get("applicantsLoading"));

export const getCurrentWalkthroughStep = () =>
    createSelector(selectApp, (appState) =>
        appState.get("currentWalkthroughStep")
    );

export const getCurrentWalkthroughType = () =>
    createSelector(selectApp, (appState) =>
        appState.get("currentWalkthroughType")
    );

export const getSupportMsgList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("supportMsgList").toJS()
    );

export const getWalkthroughFlows = () =>
    createSelector(selectApp, (appState) =>
        appState.get("walkthroughFlows").toJS()
    );

export const getWorkflowList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("workflowList").toJS()
    );

export const getEditDriveNameLoading = () =>
    createSelector(selectApp, (appState) =>
        appState.get("editDriveNameLoading")
    );

export const getPwdCodeVerificationStatus = () =>
    createSelector(selectApp, (appState) =>
        appState.get("resetPwdOtpVerificationStatus")
    );

export const getVerifyCodeSuccess = () =>
    createSelector(selectApp, (appState) => appState.get("verifyCodeSuccess"));

export const getSkillCodeList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("skillCodeList").toJS()
    );

export const getCompanyUserList = () =>
    createSelector(selectApp, (appState) =>
        appState.get("companyUserList").toJS()
    );

export const getCMSCredentials = () =>
    createSelector(selectApp, (appState) =>
        appState.get("cmsCredentials").toJS()
    );

export const getPostProgressToReviewLoading = () =>
    createSelector(selectApp, (appState) =>
        appState.get("postProgressToReviewCandidatesLoading")
    );

export const getCandidateTestReport = () =>
    createSelector(selectApp, (appState) =>
        appState.get("candidateTestReport").toJS()
    );

export const checkIfLoading = (...actionsToCheck) =>
    createSelector(selectLoader, (appState) =>
        appState.loader.actions.some((action) =>
            actionsToCheck.includes(action)
        )
    );

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import HQLogo from "../assets/Logo.png";
import { redirectUnauthorisedUser } from "../helper/helperFunction";
import axios from "../utils/axios";

const VideoDetails = ({ data, isVideoAnswer }) => (
    <Grid
        item
        xs={5}
        height="100%"
        bgcolor="white"
        display="flex"
        flexDirection="column"
    >
        <Box
            sx={{
                display: "flex",
                my: 2,
                mx: 8,
            }}
        >
            <img
                src={HQLogo}
                alt="company-logo"
                style={{
                    maxWidth: "12vw",
                    maxHeight: "70px",
                    padding: "10px",
                }}
            />
        </Box>
        <Box
            sx={{
                display: "flex",
                my: 2,
                mx: 9,
                fontSize: 24,
                fontWeight: 700,
            }}
        >
            {isVideoAnswer
                ? "Candidate Report and Video Answer"
                : "Candidate Proctoring Report and Video"}
        </Box>
        <DataGrid data={data} />
    </Grid>
);

VideoDetails.propTypes = {
    data: PropTypes.object,
    isVideoAnswer: PropTypes.bool,
};

const DataGrid = ({ data }) => {
    const reportUrl = data.reportUrl;

    const fields = [
        {
            label: "Report",
            value: reportUrl
                ? `<a href='${reportUrl}' style='color: blue;' target='_blank'>Click here</a>`
                : "-",
        },
        { label: "Name", value: data.name },
        { label: "Email", value: data.email },
        { label: "Start Time", value: data.startTime },
        { label: "End Time", value: data.endTime },
    ];

    return (
        <Box
            sx={{
                mx: 9,
                flexGrow: 1,
            }}
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
        >
            <Grid
                container
                display="flex"
                rowGap={3}
                sx={{
                    flexGrow: 1,
                    my: "30%",
                    "& *": {
                        fontSize: "20px",
                    },
                }}
            >
                {fields.map((field) => (
                    <React.Fragment key={field.label}>
                        <Grid item xs={4} fontWeight={700}>
                            {field.label}:
                        </Grid>
                        <Grid item xs={8} spacing={1}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: field.value ?? "-",
                                }}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

DataGrid.propTypes = {
    data: PropTypes.object,
};

const VideoPlayer = ({ videoUrl }) => (
    <Grid item xs={7} height={"100%"} bgcolor={"rgb(0, 128, 149)"}>
        <Box
            component="div"
            sx={{ my: 2, mx: 8 }}
            height="98%"
            display="flex"
            alignItems="center"
        >
            <video
                controls
                width="100%"
                height="500px"
                style={{ background: "white" }}
            >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Box>
    </Grid>
);

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string,
};

const VideoPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [data, setData] = useState(null);
    const isVideoAnswer = window.location.pathname === "/video/answer";

    const getCandVideo = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        try {
            setIsLoading(true);
            const response = await axios.get(
                isVideoAnswer
                    ? `/candidate/answer/video/${code}`
                    : `/candidate/proc/video/${code}`
            );
            if (response.data === null) {
                setNoDataFound(true);
                return;
            }
            if (response?.data) {
                setData(response.data);
            }
        } catch (error) {
            redirectUnauthorisedUser(
                error,
                navigate,
                `?redirectTo=${window.location.pathname}${window.location.search}`
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCandVideo();
        return () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <Grid
            container
            height="100vh"
            width="100vw"
            sx={{
                "& *": {
                    color: "black",
                },
            }}
        >
            {isLoading && <CircularProgress />}
            {noDataFound && (
                <Grid
                    item
                    xs={12}
                    height="100%"
                    bgcolor="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <h1>No Data Found</h1>
                </Grid>
            )}
            {!noDataFound && data && (
                <>
                    <VideoDetails data={data} isVideoAnswer={isVideoAnswer} />
                    <VideoPlayer videoUrl={data.videoUrl} />
                </>
            )}
        </Grid>
    );
};

export default VideoPage;

import React, { useEffect } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "../../utils/axios";
import { connect } from "react-redux";
import { addUser } from "../../actions/app";

function SSOComplete(props) {
    const { userSignIn } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const code = searchParams.get("code");
                const state = searchParams.get("state");
                const redirectTo = localStorage.getItem("ssoRedirect");
                localStorage.removeItem("ssoRedirect");

                if (code && state) {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BASE_SERVER_URL_FULL}/auth/sso/callback?code=${code}&state=${state}`
                    );

                    if (response?.data?.message?._id) {
                        userSignIn({
                            userName: response.data.message.name,
                            userRole: response.data.message.role,
                            userPlan: response.data.message.plan
                                ? response.data.message.plan
                                : {},
                            totalAssessments: parseInt(
                                response.data.message.totalAssessments,
                                10
                            ),
                            companyFeatures:
                                response.data.message.companyFeatures,
                        });
                        localStorage.setItem(
                            "company",
                            response.data.message.company
                        );
                        localStorage.setItem(
                            "userName",
                            response.data.message.name
                        );
                        localStorage.setItem(
                            "email",
                            response.data.message.email
                        );
                        localStorage.setItem(
                            "userRole",
                            response.data.message.role
                        );
                        localStorage.setItem(
                            "companyFeatures",
                            JSON.stringify(
                                response.data.message.companyFeatures
                            )
                        );
                        localStorage.setItem(
                            "accessToken",
                            JSON.stringify(response.data.message.accessToken)
                        );
                        localStorage.setItem(
                            "totalAssessments",
                            response.data.message.totalAssessments
                        );
                        if (response.data.message.plan) {
                            localStorage.setItem(
                                "userPlan",
                                JSON.stringify(response.data.message.plan)
                            );
                        }
                        navigate(redirectTo ?? "/workflows");
                    } else {
                        throw new Error();
                    }
                } else {
                    throw new Error();
                }
            } catch (error) {
                navigate("/signin");
                console.log({ error });
            }
        })();
    }, [searchParams, navigate]);

    return <h1>Loading...</h1>;
}

const mapDispatchToProps = (dispatch) => ({
    userSignIn: (data) => dispatch(addUser(data)),
});

SSOComplete.propTypes = {
    userSignIn: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(SSOComplete);

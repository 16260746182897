import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { DataGrid } from "@mui/x-data-grid";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

import axios from "../utils/axios";
import Button from "../components/common/Button";
import Modal from "../components/common/CustomModal";
import Select from "../components/common/Select";
import NoRowsOverlay from "../components/common/NoRowsDataGridOverlay";

import { getCompaniesList, getRerender } from "../selectors/app";
import {
    resetSuccessMsg as resetSuccessMsgRequest,
    resetRerender as resetRerenderRequest,
    addCompany as addCompanyRequest,
    fetchCompanies as fetchCompaniesRequest,
    deactivateCompany as deactivateCompanyRequest,
    updateCompanyGuidelines as updateCompanyGuidelinesRequest,
    activateCompany as activateCompanyRequest,
    updateCompanyDetails as updateCompanyDetailsRequest,
} from "../actions/app";

import "../styles/workflow.scss";
import "../styles/table.scss";

const Company = (props) => {
    const {
        addCompany,
        fetchCompanies,
        allCompanies,
        deactivateCompany,
        updateCompanyGuidelines,
        activateCompany,
        updateCompanyDetails,
    } = props;

    const isNotEmpty = (object) => {
        for (const property in object) {
            return true;
        }
        return false;
    };

    const [allUserChart, setAllUserChart] = useState({});
    const [allWfChart, setAllWfChart] = useState({});
    const [dayWf, setDayWf] = useState(0);
    const [dayUser] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [compId, setCompId] = useState();
    const [compDetails, setCompDetails] = useState();
    const [firstUserName, setFirstUserName] = useState("");
    const [firstUserEmail, setFirstUserEmail] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [open, setOpen] = useState(false);
    const [openComp, setOpenComp] = useState(false);
    const [deactivateModal, setDeactivateModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);
    const [logDetails, setLogDetails] = useState();
    const [selectedCompanyGuidelines, setSelectedCompanyGuidelines] =
        useState();
    // const [selectedCompanyInfo, setSelectedCompanyInfo] = useState();
    const [selectedCompanyDeactivate, setSelectedCompanyDeactivate] =
        useState();
    const [selectedCompanyActivate, setSelectedCompanyActivate] = useState();
    const [selectedCompanyEdit, setSelectedCompanyEdit] = useState();
    const [guidelinesModal, setGuidelinesModal] = useState(false);
    const [companyGuidelines, setCompanyGuidelines] = useState([]);
    const [assessmentWelcomeText, setAssessmentWelcomeText] = useState("");
    const [editModal, setEditModal] = useState(false);

    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => {
        setCompanyName("");
        setFirstUserName("");
        setFirstUserEmail("");
        setOpen(false);
    };

    const onOpenEditModal = (e, val) => {
        e.stopPropagation();
        setCompanyName(val.name);
        setSelectedCompanyEdit(val);
        setEditModal(true);
    };

    const onCloseEditModal = () => {
        setCompanyName("");
        setSelectedCompanyEdit();
        setEditModal(false);
    };

    const onOpenCompModal = () => setOpenComp(true);

    const onCloseCompModal = () => setOpenComp(false);

    const onOpenDeactivateModal = (val) => {
        setSelectedCompanyDeactivate(val);
        setDeactivateModal(true);
    };

    const onCloseDeactivateModal = () => {
        setSelectedCompanyDeactivate();
        setDeactivateModal(false);
    };

    const onOpenActivateModal = (val) => {
        setSelectedCompanyActivate(val);
        setActivateModal(true);
    };

    const onCloseActivateModal = () => {
        setSelectedCompanyActivate();
        setActivateModal(false);
    };

    const [userChart, setUserChart] = useState({});
    const [wfChart, setWfChart] = useState({});

    useEffect(() => {
        console.log(compId);
        if (isNotEmpty(compId)) {
            axios
                .get("/admin/getDetail/" + compId._id)
                .then((res) => {
                    setCompDetails(res.data.company);
                    setLogDetails(res.data.logs);
                    onOpenCompModal();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [compId]);

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (isNotEmpty(allWfChart)) {
            if (dayWf == 0 || allWfChart.labels.length < dayWf)
                setWfChart({ ...allWfChart });
            else {
                let tmp = JSON.parse(JSON.stringify(allWfChart));
                tmp.labels = tmp.labels.slice(tmp.labels.length - dayWf);
                for (let i = 0; i < tmp.datasets.length; i++)
                    tmp.datasets[i].data = tmp.datasets[i].data.slice(
                        tmp.datasets[i].data.length - dayWf
                    );
                setWfChart({ ...tmp });
            }
        }
    }, [dayWf]);

    useEffect(() => {
        if (isNotEmpty(allUserChart)) {
            if (dayUser == 0 || allUserChart.labels.length < dayUser)
                setUserChart({ ...allUserChart });
            else {
                let tmp = JSON.parse(JSON.stringify(allUserChart));
                tmp.labels = tmp.labels.slice(tmp.labels.length - dayUser);
                for (let i = 0; i < tmp.datasets.length; i++)
                    tmp.datasets[i].data = tmp.datasets[i].data.slice(
                        tmp.datasets[i].data.length - dayUser
                    );
                setUserChart({ ...tmp });
            }
        }
    }, [dayUser]);

    useEffect(() => {
        if (isNotEmpty(compDetails)) {
            let dat = workflowChart();
            setWfChart(dat);
            setAllWfChart(dat);
        }
    }, [compDetails]);

    useEffect(() => {
        if (isNotEmpty(logDetails)) {
            let dat = logChart;
            setUserChart(dat);
            setAllUserChart(dat);
        }
    }, [logDetails]);

    const onOpenGuidelinesModal = (e, comp) => {
        e.stopPropagation();
        setSelectedCompanyGuidelines({ ...comp });
        setCompanyGuidelines([...comp.guidelines]);
        setAssessmentWelcomeText(comp.assessmentWelcomeText);
        setGuidelinesModal(true);
    };

    const onCloseGuidelinesModal = () => {
        setSelectedCompanyGuidelines();
        setCompanyGuidelines([]);
        setAssessmentWelcomeText("");
        setGuidelinesModal(false);
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link
                            className="datagrid__row datagrid__row-center"
                            to="/workflows"
                            state={{ company: params.row }}
                        >
                            {params.row.name}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "workflowsCount",
            headerName: "No. of Workflows",
            flex: 0.8,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Link to="/workflows" state={{ company: params.row }}>
                            {getWorkflowsLength(params)}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "createdDate",
            headerName: "Date Created",
            flex: 0.8,
            renderCell: (params) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                };
                let date = new Date(params.row.createdDate).toLocaleDateString(
                    "en-US",
                    options
                );
                return (
                    <>
                        <Link
                            className="datagrid__row"
                            to="/workflows"
                            state={{ company: params.row }}
                        >
                            {date}
                        </Link>
                    </>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            flex: 2,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            className="btn-datagrid"
                            size="small"
                            variant="contained"
                            onClick={(e) => onOpenEditModal(e, params.row)}
                        >
                            Edit
                        </Button>
                        <Link
                            className="btn-datagrid"
                            style={{ textDecoration: "none" }}
                            to="/manage-bots"
                            state={{ company: params.row }}
                        >
                            <Button
                                className="btn-datagrid"
                                size="small"
                                variant="contained"
                            >
                                Bots
                            </Button>
                        </Link>
                        <Link
                            className="btn-datagrid"
                            style={{ textDecoration: "none" }}
                            to="/manage-features"
                            state={{ company: params.row }}
                        >
                            <Button
                                className="btn-datagrid"
                                size="small"
                                variant="contained"
                            >
                                Features
                            </Button>
                        </Link>
                        <Button
                            className="btn-datagrid"
                            size="small"
                            variant="contained"
                            onClick={(e) =>
                                onOpenGuidelinesModal(e, params.row)
                            }
                        >
                            Guidelines
                        </Button>
                        <Button
                            className="btn-datagrid btn-danger"
                            size="small"
                            variant="contained"
                            onClick={() => setCompId(params.row)}
                        >
                            Info
                        </Button>
                        {params.row.active ? (
                            <Button
                                className="btn-datagrid btn-danger"
                                size="small"
                                variant="contained"
                                onClick={() =>
                                    onOpenDeactivateModal(params.row)
                                }
                            >
                                Deactivate
                            </Button>
                        ) : (
                            <Button
                                className="btn-datagrid btn-danger"
                                size="small"
                                variant="contained"
                                onClick={() => onOpenActivateModal(params.row)}
                            >
                                Activate
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    const getCompanyData = (id) => {
        axios({
            url: "/admin/alldetails/" + id, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", id + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    };

    const getDate = (ms) => {
        return new Date(ms).toLocaleDateString();
    };

    const getNextDay = (ms) => {
        let tmpDate = new Date(ms);
        tmpDate.setDate(tmpDate.getDate() + 1);
        return new Date(tmpDate).getTime();
    };

    const workflowChart = () => {
        if (!isNotEmpty(compDetails)) return null;
        let tmpInv = {};
        let tmpStart = {};
        let tmpEnd = {};
        let minDate = new Date().getTime();
        for (var i = 0; i < compDetails.workflows.length; i++) {
            for (
                var j = 0;
                j < compDetails.workflows[i].candidates.length;
                j++
            ) {
                let d1 = getDate(
                    compDetails.workflows[i].candidates[j].createdDate
                );
                tmpInv[d1] = tmpInv[d1] ? tmpInv[d1] + 1 : 1;
                if (compDetails.workflows[i].candidates[j].test) {
                    let d2 = getDate(
                        compDetails.workflows[i].candidates[j].test.createdDate
                    );
                    tmpStart[d2] = tmpStart[d2] ? tmpStart[d2] + 1 : 1;
                    if (
                        compDetails.workflows[i].candidates[j].test.status ==
                        "Complete"
                    ) {
                        let d3 = getDate(
                            compDetails.workflows[i].candidates[j].test.endDate
                        );
                        tmpEnd[d3] = tmpEnd[d3] ? tmpEnd[d3] + 1 : 1;
                    }
                }
                if (
                    minDate >
                    new Date(
                        compDetails.workflows[i].candidates[j].createdDate
                    ).getTime()
                )
                    minDate = new Date(
                        compDetails.workflows[i].candidates[j].createdDate
                    ).getTime();
            }
        }

        let labels = [];
        let dataInv = [];
        let dataStart = [];
        let dataEnd = [];
        while (minDate <= new Date().getTime()) {
            let currdate = getDate(minDate);
            labels.push(currdate);
            dataInv.push(tmpInv[currdate] ? tmpInv[currdate] : 0);
            dataStart.push(tmpStart[currdate] ? tmpStart[currdate] : 0);
            dataEnd.push(tmpEnd[currdate] ? tmpEnd[currdate] : 0);
            minDate = getNextDay(minDate);
            if (labels.length <= 1) continue;
            dataInv[dataInv.length - 1] += dataInv[dataInv.length - 2];
            dataStart[dataStart.length - 1] += dataStart[dataStart.length - 2];
            dataEnd[dataEnd.length - 1] += dataEnd[dataEnd.length - 2];
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: "Invited",
                    data: dataInv,
                    fill: false,
                    borderColor: "rgba(0,0,255,1)",
                },
                {
                    label: "Started",
                    data: dataStart,
                    fill: false,
                    borderColor: "rgba(0,255,0,0.5)",
                },
                {
                    label: "End",
                    data: dataEnd,
                    fill: false,
                    borderColor: "rgba(255,0,0,1)",
                },
            ],
        };
        return data;
    };

    const logChart = () => {
        if (!isNotEmpty(logDetails)) return null;
        let tmpLogin = {};
        let tmpInteraction = {};
        let minDate = new Date().getTime();
        for (var i = 0; i < logDetails.length; i++) {
            let d1 = getDate(logDetails[i].createdDate);
            if (logDetails[i].activity == "Login")
                tmpLogin[d1] = tmpLogin[d1] ? tmpLogin[d1] + 1 : 1;
            else
                tmpInteraction[d1] = tmpInteraction[d1]
                    ? tmpInteraction[d1] + 1
                    : 1;
            if (minDate > new Date(logDetails[i].createdDate).getTime())
                minDate = new Date(logDetails[i].createdDate).getTime();
        }
        let labels = [];
        let dataLogin = [];
        let dataInteraction = [];
        while (minDate <= new Date().getTime()) {
            let currdate = getDate(minDate);
            labels.push(currdate);
            dataLogin.push(tmpLogin[currdate] ? tmpLogin[currdate] : 0);
            dataInteraction.push(
                tmpInteraction[currdate] ? tmpInteraction[currdate] : 0
            );
            minDate = getNextDay(minDate);
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: "Login",
                    data: dataLogin,
                    fill: false,
                    borderColor: "rgba(0,0,255,1)",
                },
                {
                    label: "Interaction",
                    data: dataInteraction,
                    fill: false,
                    borderColor: "rgba(0,255,0,1)",
                },
            ],
        };
        return data;
    };

    const getWorkflowsLength = (params) => {
        if (params.row.workflows) return params.row.workflows.length;
        else return 0;
    };

    const handleDeactivate = () => {
        const data = {
            companyId: selectedCompanyDeactivate._id,
        };

        deactivateCompany(data);
        onCloseDeactivateModal();
    };

    const handleActivate = () => {
        const data = {
            companyId: selectedCompanyActivate._id,
        };

        activateCompany(data);
        onCloseActivateModal();
    };

    const sendCompany = () => {
        const getData = {
            name: companyName,
            adminName: firstUserName,
            adminEmail: firstUserEmail,
        };
        addCompany(getData);
        onCloseModal();
    };

    const onSubmitCompanyGuidelines = () => {
        updateCompanyGuidelines({
            companyId: selectedCompanyGuidelines._id,
            guidelines: companyGuidelines,
            assessmentWelcomeText,
        });
        onCloseGuidelinesModal();
    };

    const changeCompanyGuidelines = (val, i) => {
        const newGuidelines = [...companyGuidelines];
        newGuidelines[i] = val;

        setCompanyGuidelines([...newGuidelines]);
    };

    const deleteCompanyGuidelines = (i) => {
        const newGuidelines = [...companyGuidelines];
        newGuidelines.splice(i, 1);

        setCompanyGuidelines([...newGuidelines]);
    };

    const addCompanyGuidelines = () => {
        const newGuidelines = [...companyGuidelines];
        newGuidelines.push("");

        setCompanyGuidelines([...newGuidelines]);
    };

    const onUpdateCompany = () => {
        const data = {
            companyId: selectedCompanyEdit._id,
            name: companyName,
        };

        updateCompanyDetails(data);
        onCloseEditModal();
    };

    const renderDate = (date) => {
        const createdDate = new Date(date);

        return <>{createdDate.toLocaleString()}</>;
    };

    return (
        <div
            className="mainbar"
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: "100vh",
            }}
        >
            <Modal
                open={deactivateModal}
                onClose={onCloseDeactivateModal}
                center
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">
                    Do you want to deactivate this company?
                </div>
                {selectedCompanyDeactivate
                    ? Object.keys(selectedCompanyDeactivate).map((key) => (
                          <>
                              <div>
                                  <span>
                                      <b>{key} : </b>
                                  </span>
                                  {key !== "features" &&
                                  key !== "guidelines" ? (
                                      <span>
                                          {key == "createdDate"
                                              ? renderDate(
                                                    selectedCompanyDeactivate[
                                                        key
                                                    ]
                                                )
                                              : selectedCompanyDeactivate[key]}
                                      </span>
                                  ) : (
                                      ""
                                  )}
                              </div>
                              <br />
                          </>
                      ))
                    : ""}
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary btn-danger"
                        size="small"
                        variant="contained"
                        disabled={!selectedCompanyDeactivate}
                        onClick={handleDeactivate}
                    >
                        Ok
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseDeactivateModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal
                open={activateModal}
                onClose={onCloseActivateModal}
                center
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">
                    Do you want to activate this company?
                </div>
                {selectedCompanyActivate
                    ? Object.keys(selectedCompanyActivate).map((key) => (
                          <>
                              <div>
                                  <span>
                                      <b>{key} : </b>
                                  </span>
                                  {key !== "features" &&
                                  key !== "guidelines" ? (
                                      <span>
                                          {key == "createdDate"
                                              ? renderDate(
                                                    selectedCompanyActivate[key]
                                                )
                                              : selectedCompanyActivate[key]}
                                      </span>
                                  ) : (
                                      ""
                                  )}
                              </div>
                              <br />
                          </>
                      ))
                    : ""}
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary btn-danger"
                        size="small"
                        variant="contained"
                        disabled={!selectedCompanyActivate}
                        onClick={handleActivate}
                    >
                        Ok
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseActivateModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal
                open={open}
                onClose={onCloseModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">Create new company</div>
                <div className="modal__inputbox">
                    <input
                        type="text"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => {
                            setCompanyName(e.target.value);
                        }}
                    />
                    <br />
                    <input
                        type="text"
                        placeholder="First User Name"
                        value={firstUserName}
                        onChange={(e) => {
                            setFirstUserName(e.target.value);
                        }}
                    />
                    <br />
                    <input
                        type="email"
                        placeholder="First User Email"
                        value={firstUserEmail}
                        onChange={(e) => {
                            setFirstUserEmail(e.target.value);
                        }}
                    />
                    <br />
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => sendCompany()}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal
                open={editModal}
                onClose={onCloseEditModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">Edit Company</div>
                <div className="modal__inputbox">
                    <input
                        type="text"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => {
                            setCompanyName(e.target.value);
                        }}
                    />
                    <br />
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onUpdateCompany}
                    >
                        Submit
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>

            <Modal
                open={openComp}
                onClose={onCloseCompModal}
                classNames={{ modal: "modal__container" }}
            >
                {isNotEmpty(wfChart) ? (
                    <div className="modal__heading">
                        {compId.name}
                        <Select
                            selectedValue={0}
                            onChangeSelect={(val) => setDayWf(val.value)}
                            styles={{
                                root: {
                                    width: 380,
                                    height: 45,
                                    margin: 10,
                                    backgroundColor: "#F2F2F2",
                                    borderRadius: 30,
                                    padding: "0.3rem 0",
                                },
                                wrapper: {
                                    borderBottom: "unset",
                                },
                            }}
                            dropdownData={[
                                { label: "All Days", value: 0 },
                                { label: "Last 7 Days", value: 7 },
                                { label: "Last 30 Days", value: 30 },
                            ]}
                        />
                        <Line data={wfChart} />
                    </div>
                ) : null}
                {isNotEmpty(userChart) ? (
                    <div className="modal__heading">
                        <Select
                            selectedValue={0}
                            onChangeSelect={(val) => setDayWf(val.value)}
                            styles={{
                                root: {
                                    width: 380,
                                    height: 45,
                                    margin: 10,
                                    borderRadius: 30,
                                    padding: "0.3rem 0",
                                },
                                wrapper: {
                                    borderBottom: "unset",
                                },
                            }}
                            dropdownData={[
                                { label: "All Days", value: 0 },
                                { label: "Last 7 Days", value: 7 },
                                { label: "Last 30 Days", value: 30 },
                            ]}
                        />
                        <Line data={userChart} />
                    </div>
                ) : null}
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseCompModal}
                    >
                        Close
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => getCompanyData(compId._id)}
                    >
                        Get Data
                    </Button>
                </div>
            </Modal>

            <Modal
                open={guidelinesModal}
                onClose={onCloseGuidelinesModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">
                    {selectedCompanyGuidelines &&
                        selectedCompanyGuidelines.name}
                </div>
                <div>Change Welcome Text</div>
                <div className="modal__inputbox">
                    <textarea
                        rows={2}
                        type="text"
                        placeholder="Welcome Text"
                        value={assessmentWelcomeText}
                        onChange={(e) =>
                            setAssessmentWelcomeText(e.target.value)
                        }
                        style={{ width: "90%" }}
                    />
                </div>
                <div>Change Guidelines</div>
                <div className="modal__inputbox">
                    {companyGuidelines.map((guideline, i) => (
                        <div
                            key={i}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <textarea
                                rows={4}
                                type="text"
                                placeholder={`Guidelines ${i}`}
                                value={guideline}
                                onChange={(e) => {
                                    changeCompanyGuidelines(e.target.value, i);
                                }}
                                style={{ width: "90%" }}
                            />
                            <Button
                                className="btn-primary"
                                size="small"
                                variant="contained"
                                onClick={() => deleteCompanyGuidelines(i)}
                            >
                                Delete
                            </Button>
                        </div>
                    ))}
                </div>
                <div>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={addCompanyGuidelines}
                    >
                        Add Guideline
                    </Button>
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onSubmitCompanyGuidelines}
                    >
                        Submit
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseGuidelinesModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <div className="notificationbar">
                <button className="btn-primary" onClick={onOpenModal}>
                    Create new Company
                </button>
            </div>
            <div className="actionbar" />
            <div className="datagrid__container">
                <DataGrid
                    rows={allCompanies}
                    getRowId={(row) => row._id}
                    disableRowSelectionOnClick
                    columns={columns}
                    slots={{
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{ "--DataGrid-overlayHeight": "300px" }}
                    autoHeight
                    pageSizeOptions={[5, 10, 15]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    className="datagrid__scroll scroll-blue"
                />
            </div>
        </div>
    );
};

Company.propTypes = {
    addCompany: PropTypes.func,
    rerender: PropTypes.number,
    resetRerender: PropTypes.func,
    fetchCompanies: PropTypes.func,
    allCompanies: PropTypes.array,
    deactivateCompany: PropTypes.func,
    updateCompanyGuidelines: PropTypes.func,
    activateCompany: PropTypes.func,
    updateCompanyDetails: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    rerender: getRerender(),
    allCompanies: getCompaniesList(),
});

const mapDispatchToProps = (dispatch) => ({
    resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
    resetRerender: () => dispatch(resetRerenderRequest()),
    addCompany: (data) => dispatch(addCompanyRequest(data)),
    deactivateCompany: (data) => dispatch(deactivateCompanyRequest(data)),
    fetchCompanies: () => dispatch(fetchCompaniesRequest()),
    updateCompanyGuidelines: (data) =>
        dispatch(updateCompanyGuidelinesRequest(data)),
    activateCompany: (data) => dispatch(activateCompanyRequest(data)),
    updateCompanyDetails: (data) => dispatch(updateCompanyDetailsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);

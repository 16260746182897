import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AccountMenu from '../components/AccountMenu';
import axios from "../utils/axios";
import BillingAndPurchases from '../components/myAccount/BillingAndPurchases';
import BottomBar from "../components/common/BottomBar";
import ChangePassword from '../components/myAccount/ChangePassword';
import Header from "../components/header/header";
import MyDetails from '../components/myAccount/MyDetails';
import NotificationBell from '../components/notifications/NotificationBell';
import Snackbar from '../components/common/Snackbar';
import { checkCompanyFeatureAccess } from '../utils/featureAccess';
import { getCompanyFeatures, getUserPlan } from '../selectors/app';

import '../styles/myAccount.scss';

const MyAccount = (props) => {
	const { notification, userPlan, companyFeatures } = props;
	const mediaQueryMobile = window.matchMedia('(max-width: 768px)');

	const [currentTab, setCurrentTab] = useState(0);
	const [errorAlert, setErrorAlert] = useState(false);
	const [errorAlertMsg, setErrorAlertMsg] = useState('');
	const [successAlert, setSuccessAlert] = useState(false);
	const [successAlertMsg, setSuccessAlertMsg] = useState('');

	const onErrorAlertOpen = () => {
		setErrorAlert(true);
	}

	const onErrorAlertClose = () => {
		setErrorAlertMsg('');
		setErrorAlert(false);
	}

	const onSuccessAlertOpen = () => {
		setSuccessAlert(true);
	}

	const onSuccessAlertClose = () => {
		setSuccessAlertMsg('');
		setSuccessAlert(false);
	}
	const submitSuccessfulPayemnt = (clientSecret) => {
		let data = {
			clientSecret,
			companyId: localStorage.getItem('company')
		}
		axios.post("/user-plan/update-company-cycle", data).then((res) => {
			console.log(res.data)
		})
	}
	useEffect(() => {

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		submitSuccessfulPayemnt(clientSecret)

	}, []);

	return (
		<div className='accountContainer'>
			{mediaQueryMobile.matches && <Header />}
			<div id="myAccount">
				<div className="inner-div" style={{ marginBottom: 15 }}>
					<div className='top-icon-box'>
						<NotificationBell notification={notification} />
						<AccountMenu />
					</div>
				</div>
				<div className='myAccHeading'>My Account</div>
				<div className='wrapper'>
					<div className='myaccount-tabs'>
						<div className={`${currentTab === 0 ? 'active' : ''} detailsTab`} onClick={() => setCurrentTab(0)}>My details</div>
						<div className={`${currentTab === 1 ? 'active' : ''} changePassTab`} onClick={() => setCurrentTab(1)}>Change password</div>
						{
							userPlan.name && checkCompanyFeatureAccess(companyFeatures, 'myBilling') ? (
								<div className={`${currentTab === 2 ? 'active' : ''}`} onClick={() => setCurrentTab(2)}>Billing &amp; purchase</div>
							) : null
						}
					</div>
					{
						currentTab === 0 ? (
							<MyDetails
								onErrorAlertOpen={onErrorAlertOpen}
								setErrorAlertMsg={setErrorAlertMsg}
								onSuccessAlertOpen={onSuccessAlertOpen}
								setSuccessAlertMsg={setSuccessAlertMsg}
							/>
						) : null
					}
					{
						currentTab === 1 ? (
							<ChangePassword
								onErrorAlertOpen={onErrorAlertOpen}
								setErrorAlertMsg={setErrorAlertMsg}
								onSuccessAlertOpen={onSuccessAlertOpen}
								setSuccessAlertMsg={setSuccessAlertMsg}
							/>
						) : null
					}
					{
						currentTab === 2 ? (
							<BillingAndPurchases
								onErrorAlertOpen={onErrorAlertOpen}
								setErrorAlertMsg={setErrorAlertMsg}
								onSuccessAlertOpen={onSuccessAlertOpen}
								setSuccessAlertMsg={setSuccessAlertMsg}
								userPlan={userPlan}
							/>
						) : null
					}
				</div>
				<Snackbar open={successAlert} autoHideDuration={2500} onClose={onSuccessAlertClose} msg={successAlertMsg} type="success" />
				<Snackbar open={errorAlert} autoHideDuration={2500} onClose={onErrorAlertClose} msg={errorAlertMsg} type="error" />
			</div>
			{mediaQueryMobile.matches && <BottomBar />}
		</div>
	)
}

MyAccount.propTypes = {
	notification: PropTypes.object,
	userPlan: PropTypes.object,
	companyFeatures: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
	companyFeatures: getCompanyFeatures(),
	userPlan: getUserPlan()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

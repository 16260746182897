export const getQuestionText = (row) => {
    let txt = "";

    const question = row.ques;
    if (Array.isArray(question)) {
        const ele = question[question.length - 1].split("***::");
        txt = ele[ele.length - 1];
    } else if (typeof question == "string") {
        txt = question.split("***::")[question.split("***::").length - 1];
    }

    return txt.replaceAll(";;", " ").replaceAll("::", " ");
};

export const getTimeTaken = (startTime, endTime, defaultTime = 30) => {
    const millisecondsPerMinute = 60000;

    const startTimeInMillis = new Date(startTime).getTime();
    const endTimeInMillis = new Date(endTime).getTime();
    const timeDifferenceInMinutes = parseInt(
        Math.abs(endTimeInMillis - startTimeInMillis) / millisecondsPerMinute
    );

    const timeTaken = Math.min(timeDifferenceInMinutes, defaultTime);
    return timeTaken;
};

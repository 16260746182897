import React from "react";
import PropTypes from "prop-types";

import "../../styles/Button.scss";

const Button = (props) => {
    const {
        children,
        onClick,
        className = "",
        size = "medium",
        variant = "text",
        color = "primary",
        disabled = false,
        style = {},
        id = "",
        type = "button",
        isLoading = false,
        ...other
    } = props;

    return (
        <button
            id={id}
            style={style}
            className={`${className} ${
                size === "small"
                    ? "btn-small"
                    : size === "large"
                    ? "btn-large"
                    : "btn-medium"
            } ${
                variant === "text"
                    ? "btn-text"
                    : variant === "contained"
                    ? "btn-contained"
                    : "btn-outlined"
            }
			${
                color === "primary"
                    ? "button-primary"
                    : color === "secondary"
                    ? "btn-secondary"
                    : color === "success"
                    ? "btn-success"
                    : color === "error"
                    ? "btn-error"
                    : color === "info"
                    ? "btn-info"
                    : "btn-warning"
            } custom-btn`}
            onClick={onClick}
            disabled={disabled || isLoading}
            type={type}
            {...other}
        >
            {children}
            {isLoading && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 38 38"
                    style={{
                        width: 20,
                        height: 20,
                        marginLeft: 8,
                        stroke: "#fff",
                    }}
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="3">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            )}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    id: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default Button;

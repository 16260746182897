import React from 'react';
import IconButton from "../../components/common/IconButton";
import PropTypes from 'prop-types';

import { dateToTimeAgo } from '../../utils/timeSince';

import notificationcircle from '../../assets/notificationcircle.svg';
import notificationcircleGrey from '../../assets/notification-circle-grey.svg';
import notificationsRead from '../../assets/notifications-read.png';

const UpdatesNotificationTab = (props) => {
	const { notifications, updateNotificationStatus } = props;

	const updateNotification = (notificationId) => {
		updateNotificationStatus(notificationId, notifications);
	}

	return (
		<>
			{
				notifications.length > 0 ? (notifications.map((item) => (
					item.type == 'update' ? (
						<div className='notification-card' key={item.notificationId}>
							<div className='notification-card-topbar'>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img src={item.status == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: "20px", height: "20px" }} />
									<p style={{ marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>New Message</p>
								</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<p style={{ marginRight: "50px" }}>{dateToTimeAgo(item.createdDate)}</p>
									<IconButton aria-label="edit" onClick={() => updateNotification(item.notificationId)}>
										{
											item.status == 'Read' ? (
												<img src={notificationsRead} style={{ width: "20", height: "20" }} alt="" />
											) : (
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
												</svg>
											)
										}
									</IconButton>
								</div>
							</div>
							<div className='notification-card-body'>
								<p>{item.message || item.text}</p>
							</div>
						</div>
					) : ''
				))) : (
					<div>No notifications</div>
				)
			}
		</>
	)
}

UpdatesNotificationTab.propTypes = {
	notifications: PropTypes.array,
	updateNotificationStatus: PropTypes.func
}

export default UpdatesNotificationTab;

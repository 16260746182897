import React, { useEffect, useState } from "react";
import Snackbar from "../components/common/Snackbar";

import axios from "../utils/axios";

import Edit from '../assets/Icons/Edit.png';
import Save from '../assets/save-icon.svg';

import '../styles/LeverIntegration.scss';

const LeverIntegration = () => {
    const [lever, setLever] = useState({
        edit: false,
        stages: [],
        selectedStage: 'Select Stage'
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        type: 'error',
        msg: ''
    });
    const queryParams = new URLSearchParams(window.location.search);
    const company = queryParams.get('company');
    
    useEffect(() => {
        if(company) {
            axios.get('/lever-stages')
            .then(resp => {
                console.log(resp.data);
                setLever({ ...lever, stages: resp.data.stages });
            })
            .catch(err => console.log(err));
        }
    }, [])

    const handleSnackClose = () => {
        setSnackbar({
            open: false,
            type: 'error',
            msg: ''
        });
    }

    const handleStageChange = (e) => {
        setLever({ ...lever, selectedStage: e.target.value });
    };

    const handleSaveStage = () => {
        if(!lever.edit) {
            setLever(({ ...lever, edit: true }))
        } else {
            if(lever.selectedStage === 'Select Stage') {
                setSnackbar({
                    open: true,
                    type: 'error',
                    msg: 'Please select a stage'
                });
                return;
            }

            axios.post('/lever', {
                companyId: company,
                stage: lever.selectedStage,
            })
            .then((resp) => {
                setSnackbar({
                    open: true,
                    type: 'success',
                    msg: resp.data.message
                });
                setLever(({ ...lever, edit: false }));
            })
            .catch(err => {
                console.log(err);
                setSnackbar({
                    open: true,
                    type: 'error',
                    msg: 'Error in updating stage'
                });
            });
        }
    }

    return (
        <>
            <div className="leverSuccessPage">
                <div className="header">
                    <img src={`${process.env.REACT_APP_STORAGE_BUCKET_URL}website_videos/consulting/logo.png`} alt="hirequotient-logo" />
                    <h1>Best Skill Assessments Platform</h1>
                </div>
                <div className="wrapper">
                    <div className={`wrapper-main ${company ? 'success' : null}`}>
                        <div className='wrapper-content'>
                            <p className={`wrapper-heading ${company ? 'success' : null}`}>Credentials {company ? 'Authorised' : 'Unauthorised' }</p>
                            <p className="wrapper-subheading">
                                {company
                                    ? 'Please select a Lever Trigger from options below to proceed'
                                    : 'Please contact HireQuotient Admin to resolve the error'
                                }
                            </p>
                        </div>
                        {company ? (
                            <div className="wrapper-content1">
                                <div className="wrapper-content2">
                                    <p className="wrapper-subheading">Lever Trigger Stage</p>
                                    <button className={lever.edit ? "save-btn" : "edit-btn"} onClick={handleSaveStage}>
                                        <img  src={lever.edit ? Save : Edit} alt="edit-icon" />
                                        {lever.edit ? 'Save' : 'Edit'}
                                    </button>
                                </div>
                                <select value={lever.selectedStage} onChange={handleStageChange} disabled={!lever.edit}>
                                    {[{ id: 'ss', text: 'Select Stage' }, ...lever.stages]
                                        .map((stage) => 
                                        <option key={stage.id} value={stage.id} hidden={stage.id==='ss'}>{stage.text}</option>
                                    )}
                                </select>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Snackbar open={snackbar.open} autoHideDuration={2500} onClose={handleSnackClose} msg={snackbar.msg} type={snackbar.type} />
        </>
    )
};

export default LeverIntegration;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Switch from "../components/common/SwitchBtn";
import {
	fetchSaasPlanDetails as fetchSaasPlanDetailsRequest,
	updateSaasPlanDetails as updateSaasPlanDetailsRequest
} from '../actions/app';
import { getSaasPlanDetails } from '../selectors/app';

const SaasPlanDetails = (props) => {
	const { fetchSaasPlanDetails, saasPlanDetails, updateSaasPlanDetails } = props;

	const { userPlanId } = useParams();

	const [planFeatures, setPlanFeatures] = useState({});

	useEffect(() => {
		const data = {
			planId: userPlanId
		};

		fetchSaasPlanDetails(data);
	}, []);

	useEffect(() => {
		if (Object.prototype.hasOwnProperty.call(saasPlanDetails, 'features')) {
			setPlanFeatures(saasPlanDetails.features);
		}
	}, [saasPlanDetails]);

	const handleFeatureChange = (e, feature) => {
		const newPlanFeatures = { ...planFeatures };
		newPlanFeatures[feature] = e.target.checked;
		setPlanFeatures({ ...newPlanFeatures });
	}

	const handleFeatureNumberChange = (e, feature) => {
		const newPlanFeatures = { ...planFeatures };
		if (e.target.value) {
			newPlanFeatures[feature] = e.target.value;
		} else {
			newPlanFeatures[feature] = 0;
		}
		setPlanFeatures({ ...newPlanFeatures });
	}

	const saveChanges = () => {
		const data = {
			planId: userPlanId,
			features: planFeatures
		};

		updateSaasPlanDetails(data);
	}

	return (
		<div style={{ flex: 1, padding: '20px 20px 0', display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div>
					<div style={{ fontSize: 20, fontWeight: '600' }}>Saas Plan</div>
					<div style={{ fontSize: 16, fontWeight: '600', color: '#8b8b8b' }}>{saasPlanDetails.name}</div>
				</div>
				<div style={{ padding: '5px 15px', background: '#479bd2', borderRadius: 5, cursor: 'pointer', display: 'inline-block', textDecoration: 'none', color: '#fff' }} onClick={saveChanges}>Save Changes</div>
			</div>
			<div style={{ background: '#fff', borderRadius: 20, marginTop: 20, padding: 20, flex: 1 }}>
				<div>
					<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }}>
						<div style={{ flex: 1, padding: '10px 20px' }}>S. No.</div>
						<div style={{ flex: 4, padding: '10px 20px' }}>Name</div>
						<div style={{ flex: 2, padding: '10px 20px' }}>Action</div>
					</div>
					{
						Object.prototype.hasOwnProperty.call(saasPlanDetails, 'features') ? Object.keys(planFeatures).map((feature, i) => (
							<div style={{ display: 'flex', borderBottom: '1px solid #c4c4c4' }} key={i}>
								<div style={{ flex: 1, padding: '10px 20px' }}>{i + 1}</div>
								<div style={{ flex: 4, padding: '10px 20px' }}>{feature}</div>
								<div style={{ flex: 2, padding: '10px 20px' }}>
									{
										typeof planFeatures[feature] != "boolean" ? (
											<input type='number' value={planFeatures[feature]} onChange={(e) => handleFeatureNumberChange(e, feature)} />
										) : (
											<Switch checked={planFeatures[feature]} onChange={(e) => handleFeatureChange(e, feature)} />
										)
									}
								</div>
							</div>
						)) : ''
					}
				</div>
			</div>
		</div>
	)
}

SaasPlanDetails.propTypes = {
	fetchSaasPlanDetails: PropTypes.func,
	saasPlanDetails: PropTypes.object,
	updateSaasPlanDetails: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	saasPlanDetails: getSaasPlanDetails()
});

const mapDispatchToProps = (dispatch) => ({
	fetchSaasPlanDetails: (data) => dispatch(fetchSaasPlanDetailsRequest(data)),
	updateSaasPlanDetails: (data) => dispatch(updateSaasPlanDetailsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaasPlanDetails);

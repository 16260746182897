export const checkUserPlanAccess = (planName, planDetails, featureName, featureCount) => {
	try{
		if (planName) {
			if (featureCount) {
				if (parseInt(planDetails.features[featureName], 10) < 0) {
					return true;
				} else {
					return parseInt(planDetails.features[featureName], 10) < featureCount;
				}
			} else {
				return planDetails.features && planDetails.features[featureName];
			}
		} else {
			return true;
		}
	} catch (e){
		console.log(e)
		return true
	}
}
export const checkCompanyFeatureAccess = (companyFeatures, featureName, featureCount) => {
	try{
		if (Object.prototype.hasOwnProperty.call(companyFeatures, featureName)) {
			if (featureCount) {
				if (parseInt(companyFeatures[featureName], 10) < 0) {
					return true;
				} else {
					return parseInt(companyFeatures[featureName], 10) < featureCount;
				}
			} else {
				return companyFeatures[featureName] > 0;
			}
		} else {
			return true;
		}
	} catch (e){
		console.log(e)
		return true
	}
}
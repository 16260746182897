import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Menu } from "@mui/material";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import AccountMenu from "../AccountMenu";
import AddNewDrive from "../workflows/AddNewDrive";
import NotificationBell from "../notifications/NotificationBell";
import Snackbar from "./Snackbar";
import SupportModal from "./SupportModal";
import { getCompanyBots } from "../../selectors/app";

import addIcon from "../../assets/Icons/add.svg";
import activeAddIcon from "../../assets/Icons/activeAddIcon.png";
import KnowledgeIcon from '../../assets/Icons/knowledgeIcon.png';
import RightArrow from '../../assets/arrow.png';
import Logo from '../../assets/Logo.png';

import "../../styles/bottomBar.scss";

const BottomBar = (props) => {
	const {
		convo,
		companyName,
		emailTemplate,
		setEmailTemplate,
		emailTemplateInvite,
		setEmailTemplateInvite,
	} = props;
	const location = useLocation();
	const [homeActive, setHomeActive] = useState(false);
	const [bottomDrawer, setBottomDrawer] = useState(false);
	const [change, setChange] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [contactModal, setContactModal] = useState(false);
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackErrMsg, setSnackErrMsg] = useState('');
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');
	const openEl = Boolean(anchorEl);

	useEffect(() => {
		if (window.location.pathname === "/workflows") {
			setHomeActive(true);
		}
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleAddIcon = () => {
		if (location.state && location.state.sidebar === "finalised") {
			if (emailTemplate) return activeAddIcon;
			else return addIcon;
		} else if (location.state && location.state.sidebar === "invitations") {
			if (emailTemplateInvite) return activeAddIcon;
			else return addIcon;
		} else return addIcon;
	};

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const handleErrSnackClose = () => {
		setErrSnackOpen(false);
	};

	return (
		<>
			<div className="bottomBarContainer">
				<div
					className={`homeIconContainer ${homeActive ? "bottomBarActive" : ""}`}
				>
					<a href="/workflows">
						<svg
							width="20"
							height="22"
							viewBox="0 0 20 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.376238 7.094C0 7.91203 0 8.84265 0 10.7039V16.5167C0 19.1016 0 20.394 0.803009 21.197C1.57276 21.9667 2.79222 21.9986 5.1687 21.9999V15.146C5.1687 13.8366 6.23015 12.7752 7.53952 12.7752H11.652C12.9614 12.7752 14.0228 13.8366 14.0228 15.146V21.9999C16.3993 21.9986 17.6187 21.9667 18.3885 21.197C19.1915 20.394 19.1915 19.1016 19.1915 16.5167V10.7039C19.1915 8.84265 19.1915 7.91203 18.8153 7.094C18.439 6.27598 17.7325 5.67034 16.3193 4.45906L14.9485 3.28407C12.3942 1.09469 11.1171 0 9.59575 0C8.07445 0 6.79731 1.09469 4.24304 3.28407L2.87222 4.45906C1.45906 5.67034 0.752475 6.27598 0.376238 7.094ZM12.0228 22V15.146C12.0228 14.9412 11.8568 14.7752 11.652 14.7752H7.53952C7.33472 14.7752 7.1687 14.9412 7.1687 15.146V22H12.0228Z"
								fill="#A9A9A9"
							/>
						</svg>
					</a>
				</div>
				<div
					className="addIconContainer"
					onClick={() => {
						if (
							location.state &&
							location.state.sidebar === "finalised"
						) {
							if (emailTemplate) {
								setEmailTemplate(false);
							} else {
								setEmailTemplate(true);
							}
						} else if (
							location.state &&
							location.state.sidebar === "invitations"
						) {
							if (emailTemplateInvite) {
								setEmailTemplateInvite(false);
							} else {
								setEmailTemplateInvite(true);
							}
						} else {
							setBottomDrawer(true);
						}
					}}
				>
					<img src={handleAddIcon()} alt="add icon" className="imgContain" />
				</div>
				<div className="notificationIconContainer">
					<NotificationBell />
				</div>
				<div
					className={`${openEl ? "bottomBarActive" : ""} helpIconContainer`}
					id="help-btn"
					aria-controls={openEl ? "help-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={openEl ? "true" : undefined}
					onClick={handleClick}
				>
					<svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM12.2222 18.3333C12.2222 19.0083 11.675 19.5556 11 19.5556C10.325 19.5556 9.77778 19.0083 9.77778 18.3333C9.77778 17.6583 10.325 17.1111 11 17.1111C11.675 17.1111 12.2222 17.6583 12.2222 18.3333ZM11 2.66667C8.42267 2.66667 6.33333 4.756 6.33333 7.33333H8.33333C8.33333 5.86058 9.52724 4.66667 11 4.66667H11.1153C12.5244 4.66667 13.6667 5.80897 13.6667 7.21807V7.84527C13.6667 8.56892 13.2036 9.21137 12.5171 9.44021C11.0139 9.94127 10 11.348 10 12.9325V15.8889H12V12.9325C12 12.2089 12.4631 11.5664 13.1496 11.3376C14.6528 10.8365 15.6667 9.42978 15.6667 7.84527V7.21807C15.6667 4.7044 13.6289 2.66667 11.1153 2.66667H11Z" fill="#A9A9A9" />
					</svg>
				</div>
				<AccountMenu />
			</div>
			<Menu
				id="help-menu"
				anchorEl={anchorEl}
				open={openEl}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'help-btn',
				}}
				PaperProps={{
					className: 'helpListContainer',
				}}
			>
				<a className="helpSectionOne" href="https://hirequotient.tawk.help" target='_blank' rel="noreferrer">
					<div className="leftBox">
						<img src={KnowledgeIcon} alt="logo" className="knowledgeIcon" />
						<p className="text">Knowledge base</p>
					</div>
					<img src={RightArrow} alt="right arrow" className="rightArrow" />
				</a>
				<div className="helpSectionTwo">
					<div style={{ width: 'fit-content', padding: '0.5rem', backgroundColor: '#479BD2', color: '#fff', borderRadius: 10, fontSize: 14, textAlign: 'center', fontWeight: '700', cursor: 'pointer' }} onClick={() => setContactModal(true)}>Need support ?</div>
					<p style={{ fontWeight: 'bold', margin: '0.5rem 0' }}>OR</p>
					<p className="helpSupportText">Write to us at:<br /><a href="mailto:recruiter-support@hirequotient.com">recruiter-support@hirequotient.com</a></p>
					<div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
						<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
						<div style={{ display: 'flex' }}>
							<img src={Logo} alt='' style={{ height: 14 }} />
						</div>
					</div>
				</div>
			</Menu>
			<SupportModal
				open={contactModal}
				onClose={() => setContactModal(false)}
				setErrSnackOpen={setErrSnackOpen}
				setSuccessSnackOpen={setSuccessSnackOpen}
				setSnackErrMsg={setSnackErrMsg}
				setSnackSuccessMsg={setSnackSuccessMsg}
			/>
			<Drawer
				anchor="bottom"
				open={bottomDrawer}
				onClose={() => setBottomDrawer(false)}
				PaperProps={{
					style: {
						borderTopLeftRadius: "20px",
						borderTopRightRadius: "20px",
						padding: "1rem 2rem",
						maxHeight: "80%",
					},
				}}
			>
				<AddNewDrive
					setSnackErr={setSnackErrMsg}
					change={change}
					handleSnackClick={() => setErrSnackOpen(true)}
					setChange={setChange}
					companyName={companyName || ''}
					selectBotMenu={convo}
					onClose={() => setBottomDrawer(false)}
				/>
			</Drawer>
			<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose} msg={snackSuccessMsg ? snackSuccessMsg : ''} type="success"/>
            <Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose} msg={snackErrMsg ? snackErrMsg : ''} type="error"/>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	convo: getCompanyBots(),
});

BottomBar.propTypes = {
	convo: PropTypes.array,
	companyName: PropTypes.string,
	onOpenModal: PropTypes.func,
	onOpenNewJobModal: PropTypes.func,
	jobRoleStatus: PropTypes.string,
	emailTemplate: PropTypes.bool,
	setEmailTemplate: PropTypes.func,
	emailTemplateInvite: PropTypes.bool,
	setEmailTemplateInvite: PropTypes.func,
};

export default connect(mapStateToProps)(BottomBar);

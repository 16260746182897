import React, { useMemo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "../../utils/axios";
import Button from "../../components/common/Button";
import IconButton from "../../components/common/IconButton";
import Modal from "../../components/common/CustomModal";
import Snackbar from "../../components/common/Snackbar";
import { redirectUnauthorisedUser } from "../../helper/helperFunction";

import "../../styles/react-draft-wysiwyg.css";
import "../../styles/manageInvitations/addInviteEmail.scss";
import "react-quill/dist/quill.snow.css";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const UploadInvite = (props) => {
    const { field, setTempChange, tempChange, type, index, isDefault } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState(field.name ? field.name : "");
    const [img, setImg] = useState(field.image ? field.image : "");
    const [file, setFile] = useState(
        field.image
            ? process.env.REACT_APP_STORAGE_BUCKET_URL + field.image
            : ""
    );
    const [subject, setsubject] = useState(field.subject ? field.subject : "");
    const [path, setPath] = useState(field.image ? field.image : "");
    const [buttonText, setbuttonText] = useState(
        field.button ? field.button : ""
    );
    const [open, setOpen] = useState(false);
    const [tempDeleteModal, setTempDeleteModal] = useState(false);
    const [preEditorState, setPreEditorState] = useState(
        field.body ? field.body : ""
    );
    const [postEditorState, setPostEditorState] = useState(
        field.body2 ? field.body2 : ""
    );
    const [snackOpen, setSnackOpen] = useState(false);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [snackErr, setSnackErr] = useState("");
    const [snackSuccess, setSnackSuccess] = useState("");
    const [saveEmailLoading, setSaveEmailLoading] = useState(false);

    const onSnackClose = () => setSnackOpen(false);

    const onSnackOpen = () => setSnackOpen(true);

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleErrSnackClose = () => {
        setErrSnackOpen(false);
    };

    useEffect(() => {
        if (open) {
            setPreEditorState(field.body ? field.body : "");
            setPostEditorState(field.body2 ? field.body2 : "");
            setName(field.name ? field.name : "");
            setsubject(field.subject ? field.subject : "");
            setImg(field.image ? field.image : "");
            setFile(
                field.image
                    ? process.env.REACT_APP_STORAGE_BUCKET_URL + field.image
                    : ""
            );
            setPath(field.image ? field.image : "");
            setbuttonText(field.button ? field.button : "");
        }
    }, [open]);

    const onCloseModal = () => {
        setOpen(false);
        setTempDeleteModal(false);
        setSaveEmailLoading(false);
    };

    const onOpenModal = () => {
        setOpen(true);
    };

    const handleDelete = () => {
        let data = {
            workflowId: location.state.workflowId,
            key: type,
            index: index,
        };

        axios
            .post("/workflow/deleteemail", data)
            .then(() => {
                onSnackOpen();
                setSnackSuccess("Email deleted successfully");
                setTempChange(tempChange + 1);
            })
            .catch((err) => {
                console.log(err);
                handleErrSnackClick();
                setSnackErr("Could not delete email at the moment");
            });
    };

    const getRenderTemplate = () => {
        return `<div style="width:100%;background:#F5F5F5">
       				<div style="width:500px;margin:auto;background:#fff;padding:20px; font-size:16px;">
          				<div style="width: 500px;text-align: center; ">
            				<img src="${process.env.REACT_APP_STORAGE_BUCKET_URL}${path}" style=" max-width: 40%; height: auto;"/>
          				</div>
          				<p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
           			 	<br/>
          				<div style="text-align: center;margin-top:15px;;margin-bottom:15px;">
             				<a href="{{link}}" target="_blank" style="padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;">${buttonText}</a>
          				</div>
          				<br/>
						{{deadline}}
          				<p><span style="font-size:16px; text-align: left;">${postEditorState}</span></p>
          				<p><span style="font-size:12px;overflow-wrap: break-word;"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at support@hirequotient.com.</span></p>
       	 			</div>
				</div>`;
    };

    const updateEmail = () => {
        let data = {
            render: getRenderTemplate(),
            body: preEditorState,
            body2: postEditorState,
            image: file,
            subject: subject,
            button: buttonText,
        };

        var formData = new FormData();
        formData.append("workflowId", location.state.workflowId);
        formData.append("key", type);
        formData.append("index", index);
        formData.append("name", name);
        formData.append("image", img);
        formData.append("path", path);
        formData.append("body2", data["body2"]);
        formData.append("body", data["body"]);
        formData.append("subject", subject);
        formData.append("button", buttonText);
        formData.append("render", data["render"]);
        setSaveEmailLoading(true);
        axios
            .post("/workflow/setemail", formData, {
                "Content-Type": "multipart/form-data",
            })
            .then(() => {
                onSnackOpen();
                setSnackSuccess("Email Template saved successfully");
                setTempChange(tempChange + 1);
                onCloseModal();
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
                handleErrSnackClick();
                setSnackErr("Could not update email at the moment");
                onCloseModal();
            });
    };

    const getBase64 = (file1) => {
        var reader = new FileReader();
        var reader2 = new FileReader();
        reader.readAsArrayBuffer(file1);
        reader2.readAsDataURL(file1);
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader2.onload = function () {
            setFile(reader2.result);
        };
        reader2.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const onDrop = useCallback((acceptedFiles) => {
        const dname = location.state.workflowId + type + new Date().getTime();
        const files2 = acceptedFiles.find((file) => file);
        const files = acceptedFiles.map((file) => {
            setPath(dname + file.name.slice(-4));

            return new File([file], dname + file.name.slice(-4), {
                type: file.type,
            });
        });

        setFile(files);
        setImg(files[0]);
        getBase64(files2);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ accept: "image/*", onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const changeSubject = (e) => {
        setsubject(e.target.value);
    };

    const changeButtonText = (e) => {
        setbuttonText(e.target.value);
    };

    const onOpenTempDeleteModal = () => setTempDeleteModal(true);

    const onCloseTempDeleteModal = () => setTempDeleteModal(false);

    const handlePreEditorChange = (content, delta, source, editor) => {
        setPreEditorState(editor.getHTML());
    };

    const handlePostEditorChange = (content, delta, source, editor) => {
        setPostEditorState(editor.getHTML());
    };

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ color: [] }, { background: [] }],
            ],
        }),
        []
    );

    const formats = useMemo(
        () => [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "background",
        ],
        []
    );

    const sendTestMail = (e) => {
        e.stopPropagation();

        const emailIndex = {
            body: preEditorState,
            body2: postEditorState,
            button: buttonText,
            image: img,
            name,
            render: getRenderTemplate(),
            subject,
        };

        const uri = "/company/workflow/testmail";
        const getData = {
            workflowId: location.state.workflowId,
            emailIndex,
        };

        axios
            .post(uri, getData)
            .then((response) => {
                console.log(response);
                onSnackOpen();
                setSnackSuccess("Test email sent successfully");
            })
            .catch((err) => {
                console.log(err);
                handleErrSnackClick();
                setSnackErr("Could not send test email at the moment");
            });
    };

    return (
        <div>
            <Modal
                open={tempDeleteModal}
                onClose={onCloseTempDeleteModal}
                center
            >
                <p className="modal-text">
                    Are you sure you want to delete this template?
                </p>
                <div className="button-div">
                    <Button
                        id="testInviteRemindTemplateFlow-selector-19"
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "6px 20px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            handleDelete();
                            onCloseTempDeleteModal();
                        }}
                    >
                        <span style={{ fontSize: "16px" }}>Yes</span>
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "6px 20px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            onCloseTempDeleteModal();
                        }}
                    >
                        <span style={{ fontSize: "16px" }}>No</span>
                    </Button>
                </div>
            </Modal>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    height: "100%",
                    width: "300px",
                }}
            >
                <div
                    className="upload-card"
                    style={{ cursor: "pointer" }}
                    onClick={onOpenModal}
                >
                    <div className="upload-editbox">
                        <div className="tooltip">
                            <IconButton aria-label="mail">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    width={24}
                                    height={24}
                                    onClick={sendTestMail}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">
                                Send test email to yourself
                            </span>
                        </div>
                        {!isDefault ? (
                            <div className="tooltip">
                                <IconButton
                                    aria-label="delete"
                                    className="testInviteRemindTemplateFlow-selector-18"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#014954"
                                        width={24}
                                        height={24}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onOpenTempDeleteModal();
                                        }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </IconButton>
                                <span className="tooltiptext">
                                    Delete template
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="tooltip">
                            <IconButton
                                aria-label="edit"
                                id="testInviteRemindTemplateFlow-selector-13"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#014954"
                                    width={22}
                                    height={24}
                                >
                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                </svg>
                            </IconButton>
                            <span className="tooltiptext">Edit template</span>
                        </div>
                    </div>
                    <div className="upload-card-imgbox">
                        <img src={file} alt="logo" />
                    </div>
                    <hr
                        style={{
                            width: "100%",
                            border: 0,
                            height: 0,
                            borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                        }}
                    />
                    {field.subject.length < 58 ? (
                        <div
                            className="upload-card-subject"
                            dangerouslySetInnerHTML={{ __html: field.subject }}
                        ></div>
                    ) : (
                        <div
                            className="upload-card-subject"
                            dangerouslySetInnerHTML={{
                                __html: `${field.subject.slice(0, 58)}...`,
                            }}
                        ></div>
                    )}
                    <hr
                        style={{
                            width: "100%",
                            border: 0,
                            height: 0,
                            borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                        }}
                    />
                    <div className="upload-card-body-container">
                        <div
                            className="upload-card-body"
                            dangerouslySetInnerHTML={{ __html: field.body }}
                        ></div>
                        {buttonText === "" ? (
                            ""
                        ) : (
                            <Button
                                style={{
                                    background: "#ffb74a",
                                    color: "#fff",
                                    borderRadius: "10em",
                                    lineHeight: 0,
                                }}
                                className="templateButton"
                            >
                                <span
                                    style={{
                                        textTransform: "none",
                                        fontSize: "8px",
                                    }}
                                >
                                    {isDefault ? "Add new" : buttonText}
                                </span>
                            </Button>
                        )}
                        <div
                            className="upload-card-body"
                            dangerouslySetInnerHTML={{ __html: field.body2 }}
                        ></div>
                    </div>
                </div>
                <div className="upload-title">
                    <h3>{field.name}</h3>
                </div>
                <Modal
                    open={open}
                    onClose={onCloseModal}
                    classNames={{ modal: "customModal" }}
                    center
                >
                    <div style={{ display: "flex" }}>
                        <div className="email-editing-container">
                            <div className="update-logo-container">
                                <div
                                    {...getRootProps({ style })}
                                    style={baseStyle}
                                >
                                    <img
                                        src={file}
                                        style={{
                                            maxWidth: "40%",
                                            width: "300px",
                                            maxHeight: "180px",
                                        }}
                                        alt=""
                                    />
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    ) : (
                                        <p style={{ textAlign: "center" }}>
                                            Change Logo{" "}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    top: "4px",
                                                    position: "relative",
                                                }}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 4.5v15m7.5-7.5h-15"
                                                />
                                            </svg>
                                            <br />
                                            <span style={{ fontSize: "12px" }}>
                                                150px (h) by 450px (w)
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="modal-input">
                                <div style={{ marginLeft: "2%" }}>
                                    <label>Name</label>
                                    <br />
                                    <input
                                        id="testInviteRemindTemplateFlow-selector-14"
                                        type="text"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        value={name}
                                        placeholder="Name"
                                        style={{
                                            width: "90%",
                                            padding: "8px",
                                            marginBottom: "10px",
                                            boxShadow: "0px 1px 1px #EDEFF1",
                                            border: "none",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: "2%" }}>
                                    <label>Subject</label>
                                    <br />
                                    <input
                                        id="testInviteRemindTemplateFlow-selector-15"
                                        type="text"
                                        onChange={changeSubject}
                                        value={subject}
                                        placeholder="Subject"
                                        style={{
                                            width: "90%",
                                            padding: "8px",
                                            marginBottom: "10px",
                                            boxShadow: "0px 1px 1px #EDEFF1",
                                            border: "none",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                </div>
                                <div style={{ margin: "10px 0 10px 2%" }}>
                                    <label>Button</label>
                                    <br />
                                    <input
                                        id="testInviteRemindTemplateFlow-selector-16"
                                        type="text"
                                        onChange={changeButtonText}
                                        value={buttonText}
                                        placeholder="Button Name"
                                        style={{
                                            width: "90%",
                                            padding: "8px",
                                            marginBottom: "10px",
                                            boxShadow: "0px 1px 1px #EDEFF1",
                                            border: "none",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="quill-container">
                                <p
                                    style={{
                                        margin: "0 0 10px 10px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Body before button
                                </p>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={preEditorState}
                                    onChange={handlePreEditorChange}
                                    style={{ height: "100%" }}
                                />
                            </div>
                            <div className="quill-container">
                                <p
                                    style={{
                                        margin: "0 0 10px 10px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Body after button
                                </p>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={postEditorState}
                                    onChange={handlePostEditorChange}
                                    style={{ height: "100%" }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "orange",
                                        margin: "10px 20px",
                                        borderRadius: 20,
                                        fontSize: 14,
                                        lineHeight: 0,
                                    }}
                                    onClick={sendTestMail}
                                >
                                    Send Test Mail
                                </Button>
                                <Button
                                    id="testInviteRemindTemplateFlow-selector-17"
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "orange",
                                        margin: "10px 20px",
                                        borderRadius: 20,
                                        fontSize: 14,
                                        lineHeight: 0,
                                    }}
                                    onClick={() => {
                                        updateEmail();
                                    }}
                                    disabled={saveEmailLoading}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        <div className="preview-email-container">
                            <h2
                                style={{
                                    marginBottom: "20px",
                                    color: "rgb(150,150,150)",
                                    textAlign: "left",
                                }}
                            >
                                Preview
                            </h2>
                            <div
                                id="preview"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    margin: "50px 0 0 0",
                                    background: "#fff",
                                    padding: "20px",
                                }}
                            >
                                <img
                                    src={file}
                                    style={{
                                        maxWidth: "40%",
                                        width: "300px",
                                        maxHeight: "180px",
                                    }}
                                    alt=""
                                />
                                <div
                                    style={{
                                        overflow: "wrap",
                                        textAlign: "left",
                                        width: "100%",
                                        overflowWrap: "break-word",
                                        margin: "10px 0",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: preEditorState,
                                    }}
                                ></div>
                                {buttonText === "" ? (
                                    ""
                                ) : (
                                    <Button
                                        style={{
                                            background: "#ffb74a",
                                            borderRadius: "10em",
                                            color: "#fff",
                                            lineHeight: 0,
                                        }}
                                    >
                                        <span
                                            style={{
                                                textTransform: "none",
                                                fontSize: "16px",
                                            }}
                                        >
                                            {buttonText}
                                        </span>
                                    </Button>
                                )}
                                <div
                                    style={{
                                        overflow: "wrap",
                                        textAlign: "left",
                                        width: "100%",
                                        overflowWrap: "break-word",
                                        marginTop: "10px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: postEditorState,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <Snackbar
                open={snackOpen}
                autoHideDuration={2500}
                onClose={onSnackClose}
                msg={snackSuccess}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={snackErr}
                type="error"
            />
        </div>
    );
};

UploadInvite.propTypes = {
    field: PropTypes.object,
    type: PropTypes.string,
    index: PropTypes.number,
    setTempChange: PropTypes.func,
    tempChange: PropTypes.number,
    isDefault: PropTypes.bool,
};

export default UploadInvite;

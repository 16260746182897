import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@mui/material";
import alarmIcon from "../../assets/Icons/Alarm.png";
import axios from "../../utils/axios";

import "../../styles/BulkEmailModal.scss";

function BulkDeadlineModal({
    selectedRows,
    onCancel,
    setChange,
    closeModal,
    setSelectedRows,
}) {
    const [updatedDeadline, setUpdatedDeadline] = useState(0);
    const [enableInput, setEnableInput] = useState({
        enableDaysInput: false,
        enableDateInput: true,
    });
    const todaysDate = new Date();
    const [scheduleInviteParams, setScheduleInviteParams] = useState(
        todaysDate.toISOString().slice(0, 10)
    );
    const [loading, setLoading] = useState(false);

    const makeRequest = (days) => {
        setLoading(true);
        Promise.all(
            selectedRows.map((i) => {
                const data = {
                    ...i,
                    deadline: new Date(
                        new Date().getTime() + days * 24 * 60 * 60 * 1000
                    ),
                };
                return axios.post("/update-candidate", data);
            })
        )
            .then((res) => {
                console.log({ res });
                setChange((prev) => prev + 1);
                setSelectedRows([]);
                closeModal();
            })
            .catch((err) => console.error({ err }))
            .finally(() => setLoading(false));
    };

    const onModifyDeadlineClick = async () => {
        if (enableInput.enableDateInput) {
            const timeDiff = Math.abs(
                new Date(scheduleInviteParams).getTime() - todaysDate.getTime()
            );
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            makeRequest(diffDays);
        } else {
            makeRequest(updatedDeadline);
        }
    };

    return (
        <>
            <div className="bulk-modal-title">Bulk Modify Deadline</div>
            <p className="bulk-modal-subtitle">
                Modify time after which the invitation link expires
            </p>
            <div className="bulk-modal-container">
                <span className="bulk-modal-input-container">
                    <label className="bulk-modal-checkbox">
                        <input
                            type="checkbox"
                            checked={enableInput.enableDateInput}
                            onChange={() =>
                                setEnableInput((prev) => ({
                                    ...prev,
                                    enableDateInput: !prev.enableDateInput,
                                    enableDaysInput: false,
                                }))
                            }
                        />
                    </label>
                    <label
                        htmlFor="bulk-deadline-label"
                        className="bulk-modal-deadline-label"
                    >
                        Set Deadline:{" "}
                    </label>
                    <TextField
                        id="bulk-deadline-label"
                        label="Date"
                        type="date"
                        value={scheduleInviteParams}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            style: { padding: "0.5rem 0.8rem" },
                        }}
                        onChange={(e) =>
                            setScheduleInviteParams(e.target.value)
                        }
                        disabled={enableInput.enableDaysInput}
                    />
                </span>
                <span className="bulk-modal-input-container">
                    <label className="bulk-modal-checkbox">
                        <input
                            type="checkbox"
                            checked={enableInput.enableDaysInput}
                            onChange={() =>
                                setEnableInput((prev) => ({
                                    ...prev,
                                    enableDaysInput: !prev.enableDaysInput,
                                    enableDateInput: false,
                                }))
                            }
                        />
                    </label>
                    <label
                        htmlFor="bulk-deadline-label"
                        className="bulk-modal-deadline-label"
                    >
                        Increase Deadline by Days:{" "}
                    </label>
                    <TextField
                        type="number"
                        style={{ width: "80px" }}
                        label="Days"
                        variant="standard"
                        value={updatedDeadline}
                        onChange={(e) => {
                            setUpdatedDeadline(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        src={alarmIcon}
                                        style={{
                                            height: "2rem",
                                            color: "#555",
                                        }}
                                        alt=""
                                    ></img>
                                </InputAdornment>
                            ),
                        }}
                        disabled={enableInput.enableDateInput}
                    />
                </span>
                <div style={{ alignSelf: "flex-end", paddingTop: "16px" }}>
                    <button
                        className="bulk-modal-cancel"
                        style={{
                            backgroundColor: "#f0f0f0",
                            padding: "4px 16px",
                            fontWeight: "700",
                            marginRight: "1rem",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            border: 0,
                            fontSize: "16px",
                        }}
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="bulk-modal-modify"
                        style={{
                            backgroundColor: "#479bd2",
                            padding: "4px 16px",
                            fontWeight: "700",
                            color: "#fff",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            border: 0,
                            fontSize: "16px",
                        }}
                        onClick={onModifyDeadlineClick}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Modify"}
                    </button>
                </div>
            </div>
        </>
    );
}

BulkDeadlineModal.propTypes = {
    selectedRows: PropTypes.array,
    onCancel: PropTypes.func,
    closeModal: PropTypes.func,
    setChange: PropTypes.func,
    setSelectedRows: PropTypes.func,
};

export default BulkDeadlineModal;

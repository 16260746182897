import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../utils/axios';
import Button from '../../../components/common/Button';
import { validateCreateNewVideoDrive } from '../../../utils/validations';

import DeleteIcon from '../../../assets/Icons/Trash-prescreen.png';

const AddVideoDrive = (props) => {
    const { companyName, change, setChange, onClose, setSnackErr, handleSnackClick } = props;

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [role, setRole] = useState('');
    const [addDriveLoading, setAddDriveLoading] = useState(false);
    const [showVideoQuestions, setShowVideoQuestions] = useState(false);
    const [videoQuestionsData, setVideoQuestionsData] = useState([{ question: '', time: 1 }])

    const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

    const onChangeRole = (e) => {
        setRole(e.target.value);
    }

    const onChangeQuestionTime = (e, index) => {
        const newAddBotQuestionData = [...videoQuestionsData];
        newAddBotQuestionData[index].time = parseInt(e.target.value, 10);
        setVideoQuestionsData([...newAddBotQuestionData]);
    }

    const onChangeQuestionText = (e, index) => {
        const newAddBotQuestionData = [...videoQuestionsData];
        newAddBotQuestionData[index].question = e.target.value;
        setVideoQuestionsData([...newAddBotQuestionData]);
    }

    const addNewQuestion = () => {
        const addBotQuestion = {
            question: '',
            time: 1
        };
        const newAddBotQuestionData = [...videoQuestionsData];
        newAddBotQuestionData.push(addBotQuestion);
        setVideoQuestionsData([...newAddBotQuestionData]);
    }

    const removeQuestion = (i) => {
        const newAddBotQuestionData = [...videoQuestionsData];
        newAddBotQuestionData.splice(i, 1);
        setVideoQuestionsData([...newAddBotQuestionData]);
    }

    const sendWorkflowDefault = () => {
        const errorResult = validateCreateNewVideoDrive(name, desc, role);

        const videoQsArr = [];
        videoQuestionsData.forEach((vq) => {
            if (vq.question) {
                videoQsArr.push(vq);
            }
        });

        let totalAssessmentTime = 0;
        for (let i in videoQuestionsData) {
            totalAssessmentTime += parseInt(videoQuestionsData[i].time, 10);
        }
        totalAssessmentTime = totalAssessmentTime + (2 * videoQuestionsData.length);

        if (errorResult.isValid && videoQsArr.length === videoQuestionsData.length) {
            const uri = "/company/workflow/";
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    isMegacase: false,
                    isCustom: true,
                    customId: 'Video',
                    role
                },
                problem: 'na',
                title: 'na',
                companyName: companyName,
                assessmentTime: totalAssessmentTime,
                assessment: videoQsArr,
                videoQuestion: true
            }
            setAddDriveLoading(true);
            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    setAddDriveLoading(false);
                    setName('');
                    setDesc('');
                    setRole('');
                    setChange(change + 1);
                })
                .catch((err) => {
                    console.log(err);
                    setAddDriveLoading(false);
                });

            onClose();
        } else {
            if (!errorResult.isValid) {
                Object.keys(errorResult.errors).forEach((item) => {
                    setSnackErr(errorResult.errors[item]);
                });
            } else {
                setSnackErr('Please enter all the questions');
            }
            handleSnackClick();
        }
    }

    return (
        <div className='default-form'>
            {
                showVideoQuestions ? (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                            <div className='back-btn' onClick={() => setShowVideoQuestions(false)}>
                                <div></div>
                            </div>
                            <div className='title'>Customise</div>
                        </div>
                        {videoQuestionsData.map((vidq, i) => (
                            <div style={{ marginTop: 15 }} key={i}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ fontSize: 16, fontWeight: '700', color: '#878787', marginRight: 50 }}>Video Question {i + 1}</div>
                                    <div style={{ position: 'relative' }}>
                                        <input style={{ lineHeight: '21px', width: 100, color: '#858585', outline: 'none', border: 'none', padding: '5px 10px', borderRadius: 8, backgroundColor: '#F5F5F5' }} type='number' placeholder='Choose Time' value={vidq.time} onChange={(e) => onChangeQuestionTime(e, i)} />
                                        {
                                            vidq.time !== '' ? (
                                                <div style={{ position: 'absolute', top: 6, right: 50, color: '#858585', fontWeight: 600, fontSize: 14 }}>min</div>
                                            ) : ''
                                        }
                                    </div>
                                    <div style={{ marginLeft: 20, fontSize: 12, fontWeight: '600', color: '#9D9D9D' }}>Time Limit</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: 20 }}>
                                    <div style={{ flex: 1 }}>
                                        <textarea style={{ lineHeight: '21px', width: '100%', color: '#858585', outline: 'none', border: 'none', padding: '10px 20px', marginTop: 0, boxSizing: 'border-box', fontSize: 14 }} type='text' placeholder='Add your question here...' value={vidq.question} onChange={(e) => onChangeQuestionText(e, i)} rows={4} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        {
                                            i === videoQuestionsData.length - 1 ? (
                                                <div className='add-question-btn' onClick={addNewQuestion}></div>
                                            ) : ''
                                        }
                                        {
                                            i > 0 ? (
                                                <div style={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 20, position: 'relative', marginTop: 20, cursor: 'pointer' }} onClick={(e) => removeQuestion(e, i)}>
                                                    <img src={DeleteIcon} alt="Delete icon" width={24} height={'auto'} />
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                </div>
                            </div>

                        ))}

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button size="small" variant="contained" className='action' onClick={sendWorkflowDefault} style={{ padding: '10px 40px', borderRadius: 40 }} disabled={addDriveLoading}>Create</Button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                            <div className='title'>Create new drive</div>
                        </div>
                        <div>
                            <input
                                type="text"
                                onChange={onChangeName}
                                value={name}
                                placeholder='Name of drive'
                                id="testNewDriveFlow-selector-3"
                            />
                        </div>
                        <div>
                            <textarea
                                onChange={onChangeDesc}
                                value={desc}
                                placeholder='Add description'
                                id="testNewDriveFlow-selector-4"
                            />
                        </div>
                        <div>
                            <input type="text" onChange={onChangeRole} value={role} placeholder='Add role' />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                size="small"
                                variant="contained"
                                className='action'
                                onClick={() => setShowVideoQuestions(true)}
                                style={{ padding: '10px 40px', borderRadius: 40 }}
                            >
                                Create
                            </Button>
                        </div>
                    </>
                )
            }
        </div >
    )
}

AddVideoDrive.propTypes = {
    goBack: PropTypes.func,
    bot: PropTypes.object,
    companyName: PropTypes.string,
    change: PropTypes.number,
    setChange: PropTypes.func,
    onClose: PropTypes.func,
    setSnackErr: PropTypes.func,
    handleSnackClick: PropTypes.func
}

export default AddVideoDrive;

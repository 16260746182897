import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../../utils/axios';

import './emailTemplate.scss';
import ReactQuill from 'react-quill';
import { useDropzone } from 'react-dropzone';
import Snackbar from '../common/Snackbar';
import Button from '../../components/common/Button';
import Checkbox from  '../../components/common/Checkbox'


const EmailTemplate = (props) => {
    const { show, close, shortlistMails, rejectMails, tempChange, setTempChange } = props;

    const location = useLocation();

    const [addMail, setAddMail] = useState('');
    const [btnURL, setBtnURL] = useState('');
    const [btnCheckBox, setBtnCheckBox] = useState(true);
    const [initialScreen, setInitialScreen] = useState(true);
    const [addMailScreen, setAddMailScreen] = useState(false);
    const [editMailScreen, setEditMailScreen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [snackErr, setSnackErr] = useState('');
    const [snackSuccess, setSnackSuccess] = useState('');
    const [selectMails, setSelectMails] = useState({});

    const [name, setName] = useState('');
    const [img, setImg] = useState('');
    const [file, setFile] = useState('')
    const [subject, setsubject] = useState('')
    const [path, setPath] = useState('')
    const [buttonText, setbuttonText] = useState('')
    const [preEditorState, setPreEditorState] = useState('');
    const [postEditorState, setPostEditorState] = useState('');
    
    useEffect(() => {
        if (!show) {
            setSelectMails("");
        }
        setName(selectMails?.field?.name);
        setImg(selectMails?.field?.image);
        setFile(process.env.REACT_APP_STORAGE_BUCKET_URL + selectMails?.field?.image);
        setsubject(selectMails?.field?.subject);
        setPath(selectMails?.field?.image);
        setbuttonText(selectMails?.field?.button);
        setPreEditorState(selectMails?.field?.body);
        setPostEditorState(selectMails?.field?.body2);
    }, [show, selectMails]);    

    const getRenderTemplate = () => {
        return btnCheckBox ?
            `<div style="width:100%;background:#bbb">
				<div style="width:500px;margin:auto;background:#fff;padding:20px;">
  					<div style="width: 500px;text-align: center; ">
						<img src="${process.env.REACT_APP_STORAGE_BUCKET_URL}${path}" style=" max-width: 40%; height: auto;"/>
  					</div>
  					<p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
					<br/><br/>
  					<p><span style="font-size:16px; text-align: left;">${postEditorState}</span></p>
				</div>
			</div>`
            :
            `<div style="width:100%;background:#bbb">
				<div style="width:500px;margin:auto;background:#fff;padding:20px;">
					<div style="width: 500px;text-align: center; ">
 						<img src="${process.env.REACT_APP_STORAGE_BUCKET_URL}${path}" style=" max-width: 40%; height: auto;"/>
					</div>
					<p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
 					<br/>
					<div style="text-align: center;margin-top:15px;margin-bottom:15px;">
 						<a href="${btnURL}" target="_blank" style="padding:6px 20px;border-radius:10em;background:#7823DC;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;">${buttonText}</a>
					</div>
					<br/>
					<p><span style="font-size:16px; text-align: left;">${postEditorState}</span></p>
				</div>
			</div>`
    }

    const sendTestMail = () => {
        const emailIndex = {
            body: preEditorState,
            body2: postEditorState,
            button: buttonText,
            image: img,
            name,
            render: getRenderTemplate(),
            subject
        }

        const uri = "/company/workflow/testmail";
        const getData = {
            workflowId: location.state.workflowId,
            emailIndex
        };

        axios.post(uri, getData)
            .then(() => {
                setSnackOpen(true);
                setSnackSuccess('Test email sent successfully');
            })
            .catch((err) => {
                console.log(err);
                setErrSnackOpen(true);
                setSnackErr('Could not send test email at the moment');
            });
    }

    const handleTemplateDelete = () => {
        let data = {
            workflowId: location.state.workflowId,
            key: selectMails.type,
            index: selectMails.idx
        }
        axios.post("/workflow/deleteemail", data)
            .then(() => {
                setSnackOpen(true)
                setSnackSuccess('Email deleted successfully');
                setTempChange(tempChange + 1);
                setSelectMails({});
            })
            .catch((err) => {
                console.log(err);
                setErrSnackOpen(true);
                setSnackErr('Could not delete email at the moment');
            })
    }

    const updateEmail = () => {
        let data = {
            render: getRenderTemplate(),
            body: preEditorState,
            body2: postEditorState,
            image: file,
            subject: subject,
            button: btnCheckBox ?
                { isButtonVisible: true, buttonURL: btnURL, buttonText: buttonText }
                :
                { isButtonVisible: false, buttonURL: null, buttonText: null }
        };

        var formData = new FormData();
        formData.append('workflowId', location.state.workflowId);
        formData.append('key', selectMails.type);
        formData.append('index', selectMails.idx);
        formData.append('image', img);
        formData.append('path', path);
        formData.append('name', name);
        formData.append('body', data["body"]);
        formData.append('subject', subject);
        formData.append('button', buttonText);
        formData.append('render', data["render"]);
        axios.post("/workflow/setemail", formData, { 'Content-Type': 'multipart/form-data' })
            .then(() => {
                setSnackOpen(true);
                setSnackSuccess('Email Template saved successfully');
                setTempChange(tempChange + 1);
            })
            .catch((err) => {
                console.log(err);
                setErrSnackOpen(true);
                setSnackErr('Could not update email at the moment');
            });
    }

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const getBase64 = (file1) => {
        var reader = new FileReader();
        var reader2 = new FileReader();

        reader.readAsArrayBuffer(file1);
        reader2.readAsDataURL(file1);
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader2.onload = function () {
            setFile(reader2.result);
        };
        reader2.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const onDrop = useCallback(acceptedFiles => {
        const dname = location.state.workflowId + selectMails.type + selectMails.idx;
        const files2 = acceptedFiles.find((file) => file);
        const files = acceptedFiles.map((file) => {
            setPath(dname + file.name.slice(-4))
            return new File([file], dname + file.name.slice(-4), { type: file.type });
        });

        setFile(files);
        setImg(files[0]);
        getBase64(files2);
    }, []);

    const { getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({ accept: 'image/*', onDrop })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const changeSubject = (e) => {
        setsubject(e.target.value);
    }

    const changeButtonText = (e) => {
        setbuttonText(e.target.value);
    }

    const handleBtnCheckBox = (event) => {
        setBtnCheckBox(event.target.checked);
    };

    const handleBtnURLChange = (e) => {
        setBtnURL(e.target.value);
    }

    const handlePreEditorChange = (content, delta, source, editor) => {
        setPreEditorState(editor.getHTML());
    }

    const handlePostEditorChange = (content, delta, source, editor) => {
        setPostEditorState(editor.getHTML());
    }

    const modules = useMemo(() => (
        {
            toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ 'color': [] }, { 'background': [] }]
            ]
        }
    ), []);

    const formats = useMemo(() => (
        ["header", "bold", "italic", "underline", "strike", "list", "bullet", "indent", "link", "image", "color", "background"]
    ), []);

    return (
        <div className="emailTemplateBox" style={{ display: show ? 'block' : 'none' }}>
            {initialScreen &&
                <>
                    {Object.keys(selectMails).length > 0 &&
                        <div className='optionsEmailNavbar'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" onClick={sendTestMail} width={24} height={24}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24" fill="currentColor"
                                style={{ width: 22, height: 24, color: 'white', margin: '0 20px' }}
                                onClick={() => {
                                    setInitialScreen(false);
                                    setEditMailScreen(true);
                                    setAddMailScreen(true);
                                }}
                            >
                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" width={24} height={24} onClick={handleTemplateDelete}>
                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                            </svg>
                        </div>
                    }
                    <div className='emailTemplateBox__header'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" viewBox="0 0 24 24" stroke="#ADADAD" strokeWidth={2} onClick={close}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        <p className="addMailHeading">
                            Add New Email
                        </p>
                    </div>
                    <div className="shortlistMailBox">
                        <p>Shortlist Mail</p>
                        <div className='addNewMailIcon' onClick={() => {
                            setAddMail('shortlist');
                            setInitialScreen(false);
                            setAddMailScreen(true);
                        }}></div>
                    </div>
                    {shortlistMails?.length > 0 && shortlistMails?.map((item, index) => (
                        <p key={index} className={`${selectMails.name === item.name && 'singleMailSelected'} singleMailTemplate`} onClick={() => {
                            if (selectMails.name === item.name) {
                                setSelectMails({});
                            }
                            else {
                                setSelectMails({
                                    name: item.name,
                                    type: "shortlistEmail",
                                    idx: index,
                                    field: item
                                });
                            }
                        }}>{item.name}</p>
                    ))}
                    <div className="rejectionMailBox">
                        <p>Rejection Mail</p>
                        <div className='addNewMailIcon' onClick={() => {
                            setAddMail('reject');
                            setInitialScreen(false);
                            setAddMailScreen(true);
                        }}></div>
                    </div>
                    {rejectMails?.length > 0 && rejectMails.map((item, index) => (
                        <p key={index} className={`${selectMails.name === item.name && 'singleMailSelected'} singleMailTemplate`} onClick={() => {
                            if (selectMails.name === item.name) {
                                setSelectMails({});
                            }
                            else {
                                setSelectMails({
                                    name: item.name,
                                    type: 'rejectEmail',
                                    idx: index,
                                    field: item
                                });
                            }
                        }}>{item.name}</p>
                    ))}
                </>
            }
            {addMailScreen &&
                <div style={{ display: "flex" }}>
                    <div className='email-editing-container'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" viewBox="0 0 24 24" stroke="#ADADAD" strokeWidth={2} onClick={() => {
                                if (editMailScreen) {
                                    setEditMailScreen(false);
                                }
                                setAddMailScreen(false);
                                setInitialScreen(true);
                            }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                            </svg>
                            <p style={{ marginLeft: 10, fontWeight: 'bold' }}>{editMailScreen ? `Editing ${selectMails.name}` : addMail === 'shortlist' ? 'Add Shortlist Mail' : 'Add Rejection Mail'}</p>
                        </div>
                        <div className='update-logo-container'>
                            <div {...getRootProps({ style })} style={baseStyle}>
                                {file && <img src={file} style={{ maxWidth: "40%", width: '300px', maxHeight: '180px' }} alt='' />}
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p style={{ textAlign: "center" }}>
                                            Change Logo
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "18px", height: "18px", top: "4px", position: "relative" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            <br />
                                            <span style={{ fontSize: "12px" }}>150px (h) by 450px (w)</span>
                                        </p>
                                }
                            </div>
                        </div>
                        <div className='modal-input'>
                            <div>
                                <label>Name</label>
                                <br />
                                <input type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Name" style={{ width: "100%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} />
                            </div>
                            <div>
                                <label>Subject</label>
                                <br />
                                <input type="text" onChange={changeSubject} value={subject} placeholder="Subject" style={{ width: "100%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} />
                            </div>
                            <div>
                                <Checkbox
                                    checked={btnCheckBox}
                                    onChange={handleBtnCheckBox}
                                    label="Add button"
                                />
                            </div>
                            <div>
                                <label>Button URL</label>
                                <br />
                                <input type="text" disabled={!btnCheckBox} onChange={handleBtnURLChange} value={btnURL} placeholder="URL for button" style={{ width: "100%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} />
                            </div>
                            <div>
                                <label>Button</label>
                                <br />
                                <input type="text" disabled={!btnCheckBox} onChange={changeButtonText} value={buttonText} placeholder="Button Name" style={{ width: "100%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} />
                            </div>
                        </div>

                        <div className='quill-container'>
                            <p style={{ margin: '0 0 10px 10px', fontSize: '12px', fontWeight: '600' }}>Body before button</p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={preEditorState || ''}
                                onChange={handlePreEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div className='quill-container'>
                            <p style={{ margin: '0 0 10px 10px', fontSize: '12px', fontWeight: '600' }}>Body after button</p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={postEditorState || ''}
                                onChange={handlePostEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{ color: "#fff", background: "orange", margin: "10px 20px", borderRadius: 20, fontFamily: 'inherit', lineHeight: "0", fontSize: "14px" }} onClick={sendTestMail}>Send Test Mail</Button>
                            <Button style={{ color: "#fff", background: "orange", margin: "10px 20px", borderRadius: 20, fontFamily: 'inherit', lineHeight: "0", fontSize: "14px" }} onClick={() => updateEmail()}>Save</Button>
                        </div>
                    </div>
                    <div className='preview-email-container'>
                        <h2 style={{ marginBottom: "20px", color: "rgb(150,150,150)", textAlign: "left" }}>Preview</h2>
                        <div id='preview' style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "50px 0 0 0", background: "#fff", padding: "20px" }}>
                            <img src={file} style={{ maxWidth: "40%", width: '300px', maxHeight: '180px' }} />
                            <div style={{ overflow: "wrap", textAlign: 'left', width: '100%', overflowWrap: 'break-word', margin: '10px 0' }} dangerouslySetInnerHTML={{ __html: preEditorState }}></div>
                            {buttonText === "" ? "" :
                                btnCheckBox ?
                                    <a href={btnURL} style={{ background: "#7823DC", borderRadius: "10em", color: "#fff", padding: "8px 10px", textDecoration: "none" }}>
                                        {buttonText}
                                    </a> : ""}
                            <div style={{ overflow: "wrap", textAlign: 'left', width: '100%', overflowWrap: 'break-word', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: postEditorState }}></div>
                        </div>
                    </div>
                </div>
            }
            <Snackbar open={snackOpen} autoHideDuration={2500} onClose={() => setSnackOpen(false)} msg={snackSuccess ? snackSuccess : ''} type="success"/>
            <Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={() => setErrSnackOpen(false)} msg={snackErr ? snackErr : ''} type="error"/>
        </div>
    )
}

EmailTemplate.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    shortlistMails: PropTypes.array,
    rejectMails: PropTypes.array,
    tempChange: PropTypes.number,
    setTempChange: PropTypes.func
}

export default EmailTemplate;

import React, { useEffect, useState } from "react";
import { Fade, Menu } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import AllNotificationTab from "./AllNotificationTab";
import IconButton from "../common/IconButton";
import ReadNotificationTab from "./ReadNotificationTab";
import UpdatesNotificationTab from "./UpdatesNotificationTab";
import {
    fetchNotifications as fetchNotificationsRequest,
    updateNotification as updateNotificationRequest,
} from "../../actions/app";
import {
    getDriveInvitedList,
    getDriveSubmittedList,
    getNotificationList,
} from "../../selectors/app";

import notificationBell from "../../assets/Icons/notificationBell.png";
import BackButton from "../../assets/Icons/back-button.png";

import "../../styles/notificationBell.scss";

const NotificationBell = (props) => {
    const {
        notification,
        fetchNotifications,
        notificationList,
        updateNotification,
    } = props;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const mediaQueryMobile = window.matchMedia("(max-width:768px)");

    const openEl = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchNotifications({ navigate });
    }, []);

    useEffect(() => {
        const newNotificationList = [];
        notificationList.forEach((item) => {
            const newItem = { ...item };
            if (!newItem.notificationId) {
                newItem.notificationId = item._id;
            }
            newNotificationList.push(newItem);
        });

        setNotifications(newNotificationList);
    }, [notificationList]);

    useEffect(() => {
        if (notification && notification.type) {
            if (
                notification.type == "inviteCount" ||
                notification.type == "submitCount"
            ) {
                const newNotificationList = notificationList.filter(
                    (item) => item.type != notification.type
                );
                newNotificationList.push(notification);
                setNotifications(newNotificationList);
            } else {
                const newNotificationList = [...notificationList];
                newNotificationList.push(notification);
                setNotifications(newNotificationList);
            }
        }
    }, [notification]);

    const updateNotificationStatus = (notificationId) => {
        const notificationData = {
            notificationId,
            status: "Read",
        };
        updateNotification({ notificationData, notifications, navigate });
    };

    return (
        <>
            <div className="tooltip">
                <IconButton
                    onClick={(event) => handleClick(event)}
                    aria-label="notification"
                    className="notificationIconBtn"
                    style={{ display: "flex", marginRight: 5, marginLeft: 3 }}
                >
                    {mediaQueryMobile.matches ? (
                        <svg
                            width="19"
                            height="22"
                            viewBox="0 0 19 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={openEl ? "notificationIconSvg" : ""}
                        >
                            <path
                                d="M14.931 15.2308V15.6475L15.227 15.9409L16.9159 17.6154H1.29101L2.97993 15.9409L3.27586 15.6475V15.2308V9.58974C3.27586 6.46986 4.90958 4.07092 7.62524 3.43299L8.39655 3.2518V2.45949V1.69231C8.39655 1.31626 8.70314 1 9.10345 1C9.50376 1 9.81034 1.31626 9.81034 1.69231V2.45949V3.25117L10.5809 3.43281C13.2866 4.07061 14.931 6.48203 14.931 9.58974V15.2308ZM9.87197 20.7436C9.65859 20.9038 9.39265 21 9.10345 21C8.81035 21 8.54332 20.904 8.33017 20.7436H9.87197Z"
                                fill="#F8F8F8"
                                stroke="#A9A9A9"
                                strokeWidth="2"
                            />
                        </svg>
                    ) : (
                        <img
                            src={notificationBell}
                            alt=""
                            width={22}
                            height={24}
                        />
                    )}
                </IconButton>
                <span className="tooltiptext">Notifications</span>
            </div>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={openEl}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        minWidth: 500,
                        borderRadius: 30,
                    },
                }}
                classes={{
                    paper: "notificationMenuPaper",
                }}
            >
                <div style={{ padding: 20 }}>
                    <div>
                        <div className="notification-header">
                            {mediaQueryMobile.matches && (
                                <img
                                    src={BackButton}
                                    alt="back-button"
                                    onClick={handleClose}
                                />
                            )}
                            <h2>Notification</h2>
                        </div>
                        <div className="notification-tabs">
                            <div
                                className={
                                    currentTab === 0
                                        ? "active-notification-tab"
                                        : ""
                                }
                                onClick={() => setCurrentTab(0)}
                            >
                                All
                            </div>
                            <div
                                className={
                                    currentTab === 1
                                        ? "active-notification-tab"
                                        : ""
                                }
                                onClick={() => setCurrentTab(1)}
                            >
                                Read
                            </div>
                            <div
                                className={
                                    currentTab === 2
                                        ? "active-notification-tab"
                                        : ""
                                }
                                onClick={() => setCurrentTab(2)}
                            >
                                Updates
                            </div>
                        </div>
                    </div>
                    <div className="notification-body scroll-blue">
                        {currentTab === 0 ? (
                            <AllNotificationTab
                                {...props}
                                notifications={notifications}
                                updateNotificationStatus={
                                    updateNotificationStatus
                                }
                            />
                        ) : (
                            ""
                        )}
                        {currentTab === 1 ? (
                            <ReadNotificationTab
                                notifications={notifications}
                            />
                        ) : (
                            ""
                        )}
                        {currentTab === 2 ? (
                            <UpdatesNotificationTab
                                notifications={notifications}
                                updateNotificationStatus={
                                    updateNotificationStatus
                                }
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </Menu>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    driveInvitedCount: getDriveInvitedList(),
    driveSubmittedCount: getDriveSubmittedList(),
    notificationList: getNotificationList(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchNotifications: (data) => dispatch(fetchNotificationsRequest(data)),
    updateNotification: (data) => dispatch(updateNotificationRequest(data)),
});

NotificationBell.propTypes = {
    notification: PropTypes.object,
    fetchNotifications: PropTypes.func,
    updateNotification: PropTypes.func,
    notificationList: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);

import React from "react";
import { useParams } from "react-router-dom";

import '../styles/ZohoAuthorizationPage.scss';

const ZohoAuthorization = () => {
    const { status } = useParams();
    const query = new URLSearchParams(window.location.search);
    const message = query.get('message');

    return (
        <div className="zohoAuthorizationPage">
            <div className="header">
                <img src={`${process.env.REACT_APP_STORAGE_BUCKET_URL}website_videos/consulting/logo.png`} alt="hirequotient-logo" />
                <h1>Best Skill Assessments Platform</h1>
            </div>
            <div className="wrapper">
                <div className={`wrapper-main ${status === 'success' ? 'success' : 'error'}`}>
                    <div className='wrapper-content'>
                        <p className={`wrapper-heading ${status === 'success' ? 'success' : 'error'}`}>Credentials {status === 'success' ? 'Authorised' : 'Unauthorised' }</p>
                        <p className="wrapper-subheading">
                            {status === 'success' ? (
                                'You can close this window, and try clicking on ʻInvite to HireQuotient Assessmentʼ button on your Zoho Recruit Dashboard'
                            ): null}
                            {status === 'error' && message === 'company-not-found' ? (
                                'Please register your company with HireQuotient first.'
                            ) : null}
                            {status === 'error' && message && message !== 'company-not-found' ? (
                                `${message}. Please try to authorize yourself again.`
                            ) : null}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZohoAuthorization;
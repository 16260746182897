import { START_LOADING, STOP_LOADING } from "../constants/app";

const initialState = {
    loader: {
        actions: [],
    },
};

const loadingReduer = (state = initialState, { type, payload }) => {
    const { actions } = state.loader;
    switch (type) {
        case START_LOADING:
            return {
                ...state,
                loader: { actions: [...actions, payload.action] },
            };
        case STOP_LOADING:
            return {
                ...state,
                loader: {
                    actions: actions.filter((act) => act !== payload.action),
                },
            };
        default:
            return state;
    }
};

export default loadingReduer;

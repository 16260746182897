import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Select from "../common/Select";
import Button from "../../components/common/Button";
import WalkthroughOverlay from "../common/WalkthroughOverlay";

import DeleteIcon from "../../assets/Icons/Trash-prescreen.png";

const AddPreScreeningModal = (props) => {
    const {
        addBotModal,
        onCloseAddBotModal,
        addPreScreeningBot,
        setSnackSuccessMsg,
        handleSuccessSnackClick,
        setSnackErrMsg,
        handleErrSnackClick,
        currentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughStep,
    } = props;

    const navigate = useNavigate();

    const [addBotName, setAddBotName] = useState("");
    const [addAboutBot, setAddAboutBot] = useState("");
    const mediaQueryMobile = window.matchMedia("(max-width:768px)");
    const [addBotQuestionData, setAddBotQuestionData] = useState([]);
    const [nextWalkthroughStep, setNextWalkthroughStep] = useState(3);

    const onChangeQuestionText = (e, index) => {
        const newAddBotQuestionData = [...addBotQuestionData];
        newAddBotQuestionData[index].question = e.target.value;
        setAddBotQuestionData([...newAddBotQuestionData]);
    };

    const onChangeQuestionType = (val, index) => {
        const newAddBotQuestionData = [...addBotQuestionData];
        newAddBotQuestionData[index].questionType = val;
        setAddBotQuestionData([...newAddBotQuestionData]);
    };

    const handleOptionCheck = (e, j, i) => {
        const newAddBotQuestionData = [...addBotQuestionData];
        newAddBotQuestionData[i].options[j].correct = e.target.checked;
        setAddBotQuestionData([...newAddBotQuestionData]);
    };

    const handleOptionKeyDown = (e, index) => {
        if (e.key === "Enter") {
            const newAddBotQuestionData = [...addBotQuestionData];
            const newOptionObj = {
                text: e.target.value,
                correct: false,
            };
            newAddBotQuestionData[index].options.push(newOptionObj);
            setAddBotQuestionData([...newAddBotQuestionData]);
            e.target.value = "";
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "prescreening"
            ) {
                updateCurrentWalkthroughStep(7);
            }
        }
    };

    const handleOptionKeyDownAdded = (e, index, j) => {
        if (e.key === "Enter") {
            const newAddBotQuestionData = [...addBotQuestionData];
            newAddBotQuestionData[index].options[j].text = e.target.value;
            setAddBotQuestionData([...newAddBotQuestionData]);
        }
    };

    const onChangeOption = (e, index, j) => {
        const newAddBotQuestionData = [...addBotQuestionData];
        newAddBotQuestionData[index].options[j].text = e.target.value;
        setAddBotQuestionData([...newAddBotQuestionData]);
    };

    const addNewQuestion = () => {
        const addBotQuestion = {
            question: "",
            questionType: "",
            options: [],
        };
        const newAddBotQuestionData = [...addBotQuestionData];
        newAddBotQuestionData.push(addBotQuestion);
        setAddBotQuestionData([...newAddBotQuestionData]);
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "prescreening"
        ) {
            updateCurrentWalkthroughStep(9);
        }
    };

    const deleteNewQuestion = (index) => {
        const newAddBotQuestionData = [...addBotQuestionData];
        if (index === addBotQuestionData.length - 1) {
            const addBotQuestion = {
                question: "",
                questionType: "",
                options: [],
            };
            newAddBotQuestionData.pop();
            newAddBotQuestionData.push(addBotQuestion);
        } else {
            newAddBotQuestionData.splice(index, 1);
        }
        setAddBotQuestionData([...newAddBotQuestionData]);
    };

    const onCreatePreScreeningBot = () => {
        if (addBotName === "") {
            setSnackErrMsg("Enter valid Name");
            handleErrSnackClick();
        } else if (checkQuestionsEmpty()) {
            setSnackErrMsg("Enter valid questions");
            handleErrSnackClick();
        } else {
            const finalAddBotQuestionData = [];
            addBotQuestionData.forEach((abqd) => {
                if (
                    abqd.questionType === "" &&
                    abqd.question === "" &&
                    abqd.options.length === 0
                ) {
                    //continue;
                } else {
                    finalAddBotQuestionData.push(abqd);
                }
            });
            const botData = {
                name: addBotName,
                about: addAboutBot,
                questionData: finalAddBotQuestionData,
            };

            addPreScreeningBot({ botData, navigate });
            onCloseAddBotModal();
            setSnackSuccessMsg("Pre-Screening Bot Added Successfully");
            handleSuccessSnackClick();
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "prescreening"
            ) {
                updateCurrentWalkthroughStep(10);
            }
        }
    };

    const checkQuestionsEmpty = () => {
        let emptyQuestionField = false;
        if (addBotQuestionData.length > 1) {
            addBotQuestionData.forEach((abqd, i) => {
                if (i < addBotQuestionData.length - 1) {
                    if (abqd.questionType === "" || abqd.question === "")
                        emptyQuestionField = true;
                    if (
                        abqd.questionType.value === "singleAnswerMcq" ||
                        abqd.questionType.value === "multiAnswerMcq"
                    ) {
                        if (abqd.options.length === 0)
                            emptyQuestionField = true;
                        else {
                            abqd.options.forEach((option) => {
                                if (option.text === "")
                                    emptyQuestionField = true;
                            });
                        }
                    }
                }
            });
            if (!emptyQuestionField) {
                const lastQ = addBotQuestionData[addBotQuestionData.length - 1];
                let lastQEmpty = true;
                if (lastQ.questionType === "" && lastQ.question === "") {
                    // lastQ.options.forEach((option) => {
                    // 	if(option.text !== '') {
                    // 		lastQEmpty = false;
                    // 	}
                    // });
                    lastQEmpty = true;
                } else if (
                    lastQ.questionType.value === "singleAnswerMcq" ||
                    lastQ.questionType.value === "multiAnswerMcq"
                ) {
                    if (lastQ.options.length === 0) emptyQuestionField = true;
                    else {
                        lastQ.options.forEach((option) => {
                            if (option.text === "") emptyQuestionField = true;
                        });
                    }
                } else {
                    lastQEmpty = false;
                }
                if (!lastQEmpty) {
                    if (lastQ.questionType === "" || lastQ.question === "")
                        emptyQuestionField = true;
                    if (
                        lastQ.questionType.value === "singleAnswerMcq" ||
                        lastQ.questionType.value === "multiAnswerMcq"
                    ) {
                        if (lastQ.options.length === 0)
                            emptyQuestionField = true;
                        else {
                            lastQ.options.forEach((option) => {
                                if (option.text === "")
                                    emptyQuestionField = true;
                            });
                        }
                    }
                }
            }
        } else {
            addBotQuestionData.forEach((abqd) => {
                if (abqd.questionType === "" || abqd.question === "")
                    emptyQuestionField = true;
                if (
                    abqd.questionType.value === "singleAnswerMcq" ||
                    abqd.questionType.value === "multiAnswerMcq"
                ) {
                    if (abqd.options.length === 0) emptyQuestionField = true;
                    else {
                        abqd.options.forEach((option) => {
                            if (option.text === "") emptyQuestionField = true;
                        });
                    }
                }
            });
        }
        return emptyQuestionField;
    };

    useEffect(() => {
        if (addBotModal) {
            const addBotFirstQuestion = {
                question: "",
                questionType: "",
                options: [],
            };
            const newAddBotQuestionData = [];
            newAddBotQuestionData.push(addBotFirstQuestion);
            setAddBotQuestionData([...newAddBotQuestionData]);
            setAddBotName("");
            setAddAboutBot("");
        }
    }, [addBotModal]);

    return (
        <div className="add-prescreening-bot-wrapper">
            <div className="add-questions-box">
                <div className="add-questions">
                    <div
                        style={{
                            fontSize: mediaQueryMobile.matches ? 18 : 22,
                            fontWeight: "600",
                            whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center",
                            color: mediaQueryMobile.matches
                                ? "#ADADAD"
                                : "unset",
                        }}
                    >
                        {mediaQueryMobile.matches && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={25}
                                height={25}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#ADADAD"
                                strokeWidth="2"
                                onClick={onCloseAddBotModal}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                        )}
                        {mediaQueryMobile.matches
                            ? "Make pre-screening tests"
                            : "Add Questions"}
                    </div>
                    <div
                        id="prescreeningAddBot2"
                        style={{
                            marginTop: 15,
                            position: "relative",
                            zIndex:
                                currentWalkthroughStep === 2 &&
                                currentWalkthroughType === "prescreening"
                                    ? 1000
                                    : 1,
                        }}
                    >
                        <input
                            style={{
                                color: "#8B8B8B",
                                outline: "none",
                                border: "none",
                                padding: "5px 0",
                                borderBottom: "1px solid #8b8b8b",
                                boxSizing: mediaQueryMobile.matches
                                    ? "border-box"
                                    : "unset",
                                width: mediaQueryMobile.matches
                                    ? "100%"
                                    : "unset",
                            }}
                            type="text"
                            placeholder="PS Name"
                            value={addBotName}
                            onChange={(e) => setAddBotName(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    id="prescreeningAddBot3"
                    style={{
                        marginTop: mediaQueryMobile.matches ? 15 : "unset",
                        zIndex:
                            currentWalkthroughStep === 3 &&
                            currentWalkthroughType === "prescreening"
                                ? 1000
                                : 1,
                        backgroundColor: "#fff",
                    }}
                >
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "600",
                            color: "#595959",
                        }}
                    >
                        About us:
                    </div>
                    <div
                        style={{ marginTop: mediaQueryMobile.matches ? 0 : 15 }}
                    >
                        <div className="tooltip" style={{ width: "100%" }}>
                            <input
                                style={{
                                    color: "#8B8B8B",
                                    outline: "none",
                                    border: "none",
                                    padding: "5px 0",
                                    borderBottom: "1px solid #8b8b8b",
                                    boxSizing: mediaQueryMobile.matches
                                        ? "border-box"
                                        : "unset",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : 400,
                                }}
                                type="text"
                                placeholder="Add description here"
                                value={addAboutBot}
                                onChange={(e) => setAddAboutBot(e.target.value)}
                            />
                            <span className="tooltiptext">{addAboutBot}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    borderRadius: mediaQueryMobile.matches ? 0 : 20,
                    boxShadow: mediaQueryMobile.matches
                        ? "none"
                        : "0px 4px 5px 2px #B7B7B761",
                    padding: mediaQueryMobile.matches ? 0 : "15px 30px",
                    marginTop: 30,
                    marginBottom: 35,
                }}
            >
                <div
                    style={{
                        fontSize: 15,
                        color: "#878787",
                        fontWeight: "700",
                    }}
                >
                    List of questions
                </div>
                {addBotQuestionData.map((abqd, i) => (
                    <div
                        style={{
                            display: "flex",
                            marginTop: 15,
                            flexDirection: mediaQueryMobile.matches
                                ? "column"
                                : "row",
                        }}
                        key={i}
                    >
                        <div
                            style={{
                                fontSize: 16,
                                fontWeight: "700",
                                color: "#878787",
                                marginRight: 15,
                                marginBottom: mediaQueryMobile.matches ? 8 : 0,
                            }}
                        >
                            Q. {i + 1}
                        </div>
                        <div
                            style={{
                                flex: 1,
                                padding: mediaQueryMobile.matches
                                    ? "15px"
                                    : "15px 30px",
                                borderRadius: 25,
                                boxShadow: "0px 2px 3px 1px #B0B0B061",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: mediaQueryMobile.matches
                                        ? "wrap"
                                        : "unset",
                                }}
                            >
                                <div
                                    id="prescreeningAddBot4"
                                    style={{
                                        flex: mediaQueryMobile.matches
                                            ? "unset"
                                            : 1,
                                        marginRight: mediaQueryMobile.matches
                                            ? 0
                                            : 25,
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : "unset",
                                        marginBottom: mediaQueryMobile.matches
                                            ? 8
                                            : 0,
                                        zIndex:
                                            currentWalkthroughStep === 4 &&
                                            currentWalkthroughType ===
                                                "prescreening" &&
                                            i === 0
                                                ? 1000
                                                : 1,
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <input
                                        style={{
                                            lineHeight: "21px",
                                            width: "100%",
                                            color: "#858585",
                                            outline: "none",
                                            border: "none",
                                            padding: "5px 0",
                                            borderBottom: "1px solid #c4c4c4",
                                        }}
                                        type="text"
                                        placeholder="Type new question ..."
                                        value={abqd.question}
                                        onChange={(e) =>
                                            onChangeQuestionText(e, i)
                                        }
                                    />
                                </div>
                                <Select
                                    id="prescreeningAddBot5"
                                    selectedValue={
                                        abqd.questionType.value
                                            ? abqd.questionType.label
                                            : "Select question type"
                                    }
                                    onChangeSelect={(val) =>
                                        onChangeQuestionType(val, i)
                                    }
                                    dropdownData={[
                                        {
                                            label: "Single Answer MCQ",
                                            value: "singleAnswerMcq",
                                        },
                                        {
                                            label: "Multi Answer MCQ",
                                            value: "mutiAnswerMcq",
                                        },
                                        {
                                            label: "Free Text Answer",
                                            value: "freeText",
                                        },
                                    ]}
                                    styles={{
                                        root: {
                                            position: "relative",
                                            zIndex:
                                                currentWalkthroughStep === 5 &&
                                                currentWalkthroughType ===
                                                    "prescreening" &&
                                                i === 0
                                                    ? 1000
                                                    : mediaQueryMobile.matches
                                                    ? "2"
                                                    : addBotQuestionData.length -
                                                      i,
                                            backgroundColor: "#fff",
                                        },
                                        wrapper: {
                                            color: "#8B8B8B",
                                            fontSize: 13,
                                            textAlign: mediaQueryMobile.matches
                                                ? "left"
                                                : "center",
                                            padding: mediaQueryMobile.matches
                                                ? "0px"
                                                : "unset",
                                        },
                                        dropdown: {
                                            fontSize: 13,
                                        },
                                    }}
                                />
                            </div>
                            <div
                                className="options"
                                style={{ position: "relative" }}
                            >
                                {abqd.options.map((option, j) => (
                                    <div
                                        key={j}
                                        style={{
                                            display: "flex",
                                            marginTop: 10,
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                marginRight:
                                                    mediaQueryMobile.matches
                                                        ? 16
                                                        : 50,
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: mediaQueryMobile.matches
                                                        ? 18
                                                        : 16,
                                                    height: 16,
                                                    border: "2px solid #b4b4b4",
                                                    borderRadius: "50%",
                                                    marginRight: 10,
                                                }}
                                            ></div>
                                            {/* <div style={{ fontSize: 13, color: '#6b6b6b' }}>{option.text}</div> */}
                                            <input
                                                style={{
                                                    width: mediaQueryMobile.matches
                                                        ? "100%"
                                                        : 200,
                                                    color: "#8B8B8B",
                                                    outline: "none",
                                                    border: "none",
                                                    padding: "5px 0",
                                                    borderBottom:
                                                        "1px solid #8b8b8b",
                                                }}
                                                type="text"
                                                placeholder="Add Option"
                                                value={option.text}
                                                onKeyDown={(e) =>
                                                    handleOptionKeyDownAdded(
                                                        e,
                                                        i,
                                                        j
                                                    )
                                                }
                                                onChange={(e) =>
                                                    onChangeOption(e, i, j)
                                                }
                                            />
                                        </div>
                                        <div
                                            key={j}
                                            id="prescreeningAddBot7"
                                            className="option-item"
                                            style={{
                                                position: "relative",
                                                zIndex:
                                                    currentWalkthroughStep ===
                                                        7 &&
                                                    currentWalkthroughType ===
                                                        "prescreening" &&
                                                    i === 0 &&
                                                    j === 0
                                                        ? 1000
                                                        : 0,
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <label>
                                                Acceptable
                                                <input
                                                    type="checkbox"
                                                    name={option.text}
                                                    checked={option.correct}
                                                    onClick={(e) =>
                                                        handleOptionCheck(
                                                            e,
                                                            j,
                                                            i
                                                        )
                                                    }
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {abqd.questionType.value !== "freeText" ? (
                                <div
                                    style={{
                                        marginTop: mediaQueryMobile.matches
                                            ? 8
                                            : 25,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        position: "relative",
                                    }}
                                >
                                    <input
                                        id="prescreeningAddBot6"
                                        style={{
                                            width: mediaQueryMobile.matches
                                                ? "100%"
                                                : 200,
                                            color: "#8B8B8B",
                                            outline: "none",
                                            border: "none",
                                            padding: "5px 0",
                                            borderBottom: "1px solid #8b8b8b",
                                            zIndex:
                                                currentWalkthroughStep === 6 &&
                                                currentWalkthroughType ===
                                                    "prescreening" &&
                                                i === 0
                                                    ? 1000
                                                    : 1,
                                            backgroundColor: "#fff",
                                        }}
                                        type="text"
                                        placeholder="Add Option"
                                        onKeyDown={(e) =>
                                            handleOptionKeyDown(e, i)
                                        }
                                    />
                                    <div
                                        className="prescreening-selector-26 delete-question-btn"
                                        onClick={() => deleteNewQuestion(i)}
                                    >
                                        <img
                                            src={DeleteIcon}
                                            alt="Delete icon"
                                            width={24}
                                            height={"auto"}
                                        />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {abqd.questionType.value === "freeText" ? (
                                <div
                                    className="prescreening-selector-26 delete-question-btn"
                                    onClick={() => deleteNewQuestion(i)}
                                >
                                    <img
                                        src={DeleteIcon}
                                        alt="Delete icon"
                                        width={24}
                                        height={"auto"}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        {i === addBotQuestionData.length - 1 ? (
                            <div
                                className="add-question-btn"
                                id="prescreeningAddBot8"
                                onClick={addNewQuestion}
                                style={{
                                    position: "relative",
                                    zIndex:
                                        currentWalkthroughStep === 8 &&
                                        currentWalkthroughType ===
                                            "prescreening" &&
                                        i === 0
                                            ? 1000
                                            : 1,
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                ))}
            </div>
            <div
                className="button-div"
                style={{
                    marginTop: mediaQueryMobile.matches ? "auto" : "unset",
                }}
            >
                <Button
                    id="prescreeningAddBot9"
                    style={{
                        backgroundColor: mediaQueryMobile.matches
                            ? "#197BBD"
                            : "#479BD2",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        letterSpacing: "0.01em",
                        borderRadius: mediaQueryMobile.matches ? "8px" : "25px",
                        padding: "5px 12px",
                        fontSize: "16px",
                        width: mediaQueryMobile.matches ? "100%" : "unset",
                        color: "#fff",
                        position: "relative",
                        zIndex:
                            currentWalkthroughStep === 9 &&
                            currentWalkthroughType === "prescreening"
                                ? 1000
                                : 1,
                    }}
                    size="small"
                    variant="contained"
                    onClick={onCreatePreScreeningBot}
                >
                    <span style={{ fontSize: "16px" }}>Create</span>
                </Button>
            </div>
            {currentWalkthroughStep > 1 &&
            currentWalkthroughStep < 10 &&
            currentWalkthroughType === "prescreening" ? (
                <WalkthroughOverlay
                    showWalkthroughNextBtn={
                        currentWalkthroughStep !== 6 &&
                        currentWalkthroughStep !== 9
                    }
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        if (currentWalkthroughStep > 2)
                            setNextWalkthroughStep(nextWalkthroughStep - 1);
                        if (currentWalkthroughStep === 2) {
                            onCloseAddBotModal();
                        }
                    }}
                    onClickDone={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep + 1
                        );
                        setNextWalkthroughStep(
                            currentWalkthroughStep !== 7
                                ? nextWalkthroughStep + 1
                                : 9
                        );
                    }}
                />
            ) : (
                ""
            )}
        </div>
    );
};

AddPreScreeningModal.propTypes = {
    addBotModal: PropTypes.bool,
    onCloseAddBotModal: PropTypes.func,
    addPreScreeningBot: PropTypes.func,
    setSnackSuccessMsg: PropTypes.func,
    handleSuccessSnackClick: PropTypes.func,
    setSnackErrMsg: PropTypes.func,
    handleErrSnackClick: PropTypes.func,
    currentWalkthroughStep: PropTypes.number,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughStep: PropTypes.func,
};

export default AddPreScreeningModal;

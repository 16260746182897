import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import IconButton from "../components/common/IconButton";
import Button from "../components/common/Button";
import NotificationBell from "../components/notifications/NotificationBell";
import AccountMenu from "../components/AccountMenu";
import Modal from "../components/common/CustomModal";
import Snackbar from "../components/common/Snackbar";
import Avatar from "../components/common/Avatar";
import Loader from "../components/common/Loader";

import axios from "../utils/axios";
import { getCandidatesByType as getCandidatesByTypeRequest } from "../actions/app";
import {
    getApplicantsLoading,
    getCandidatesList,
    getUserRole,
} from "../selectors/app";
import { redirectUnauthorisedUser } from "../helper/helperFunction";

import Edit from "../assets/Icons/Edit.png";
import LeftArrow from "../assets/backarrow.png";
import NoData from "../assets/emptyrow2.png";
import RightArrow from "../assets/forwardarrow.png";

import "../styles/VideoApplicant.scss";

const Nodata = () => {
    return (
        <div className="no-data">
            <img src={NoData} alt="no-data-img" />
            <p>No data to show</p>
        </div>
    );
};

const VideoApplicant = (props) => {
    const {
        loading,
        candidates,
        notification,
        userRole,
        editCandidateName,
        getCandidatesByType,
    } = props;

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [initialIndex, setInitialIndex] = useState("");
    const [candidate, setCandidate] = useState({});
    const [activeVideoQuestion, setActiveVideoQuestion] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [editCandName, setEditCandName] = useState("");
    const [videoCardData, setVideoCardData] = useState([]);
    const [videoCardLoading, setVideoCardLoading] = useState(false);
    const [vidNotes, setVidNotes] = useState();
    const [inputScore, setInputScore] = useState(0);

    const handleGreenSnackOpen = () => setGreenSnackOpen(true);
    const handleErrSnackOpen = () => setErrSnackOpen(true);
    const handleGreenSnackClose = () => setGreenSnackOpen(false);
    const handleErrSnackClose = () => setErrSnackOpen(false);
    const [greenSnackOpen, setGreenSnackOpen] = useState(false);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const refreshRows = () => {
        const reqData = {
            status: "review",
            id: location.state.workflowId,
        };

        getCandidatesByType({ reqData, navigate });
    };

    useEffect(() => {
        refreshRows();
    }, []);

    useEffect(() => {
        if (candidates.length > 0 && !candidate.name) {
            setCandidateData(id);
        }
    }, [candidates]);

    const setCandidateData = (candidateId) => {
        const newInitialIndex = candidates.findIndex(
            (item) => item._id === candidateId
        );
        setInitialIndex(newInitialIndex);
        const newCandidate = candidates[newInitialIndex];
        setCandidate({ ...newCandidate });
        (async () => {
            setVideoCardLoading(true);
            let tempArr = [];
            const cids = [];
            for (let i = 0; i < newCandidate?.test.chat?.length; i++) {
                let val = newCandidate?.test.chat[i];
                if (
                    val.ans &&
                    !val.ans.Workbook &&
                    !val.ans.sheetid &&
                    (val.ans.includes(".mp4") ||
                        val.ans.includes(".webm") ||
                        val.ans.includes(".mp3"))
                ) {
                    let viddata = { case: val.ans };
                    if (
                        newCandidate?.productId &&
                        newCandidate?.productId.customId &&
                        newCandidate?.productId.customId
                            .toLowerCase()
                            .includes("chatbotbcgriseai")
                    ) {
                        viddata["case"] = newCandidate?.productId.customId;
                    }
                    let res = await axios.post("/cand-video", viddata);
                    let tempData = {
                        ques: val.ques,
                        score: val.score,
                        src: res.data.signedUrl,
                        cid: val.cid,
                        notes: val.notes,
                    };
                    if (!cids.includes(val.cid)) {
                        cids.push(val.cid);
                        tempArr.push(tempData);
                    }
                }
            }

            setVideoCardData(tempArr);
            setActiveVideoQuestion(tempArr[0]);
            setVideoCardLoading(false);
        })();
    };

    const onOpenEditModal = () => {
        setEditModal(true);
        setEditCandName(candidate?.name);
    };
    const onCloseEditModal = () => {
        setEditModal(false);
        setEditCandName("");
    };

    const nameEdit = () => {
        if (!editCandName) {
            setSuccessMsg("");
            setErrMsg("Candidate Name cannot be empty");
            handleErrSnackOpen();
        } else {
            editCandidateName({
                row: candidate,
                newName: editCandName,
                navigate,
            });
            onCloseEditModal();
            refreshRows();
        }
    };

    const handleVideoQuestionClick = (quest) => {
        setActiveVideoQuestion(quest);
    };

    const navigateApplicant = (candidateId) => {
        setCandidateData(candidateId);
        navigate(`/video-applicant/${candidateId}`, {
            state: {
                sidebar: location.state.sidebar,
                workflowId: location.state.workflowId,
                workflowName: location.state.workflowName,
            },
        });
    };

    const handleSaveNotes = function () {
        if (inputScore > 100) {
            setErrMsg("Please enter a valid score");
            handleErrSnackOpen();
            return;
        }

        axios
            .post("/savenotes", {
                notes: vidNotes,
                score: inputScore,
                cid: activeVideoQuestion.cid,
                testid: candidate?.test?._id,
            })
            .then(() => {
                if (activeVideoQuestion.score != inputScore) {
                    setSuccessMsg("Score saved successfully");
                } else {
                    setSuccessMsg("Notes saved successfully");
                }
                handleGreenSnackOpen();
                refreshRows();
            })
            .catch((err) => {
                setErrMsg("Couldn't save changes at the moment");
                handleErrSnackOpen();
                redirectUnauthorisedUser(err, history);
            });
    };

    if (loading)
        return (
            <div className="loading-wrapper">
                <Loader />
            </div>
        );
    return (
        <>
            <div className="video-applicant">
                <div className="inner-div">
                    <div className="top-icon-box">
                        {userRole.includes("Admin") ? (
                            <div className="tooltip">
                                <IconButton
                                    aria-label="edit"
                                    onClick={onOpenEditModal}
                                >
                                    <img
                                        src={Edit}
                                        alt="edit-icon"
                                        width={30}
                                        height={30}
                                    />
                                </IconButton>
                                <span className="tooltiptext">Edit</span>
                            </div>
                        ) : null}
                        <NotificationBell notification={notification} />
                        <AccountMenu />
                    </div>
                </div>
                <div className="video-applicant-header">
                    <p className="text">
                        All Candidates&nbsp;&gt;&nbsp;{candidate?.name}
                    </p>
                    <Button
                        style={{
                            backgroundColor: "#479BD2",
                            color: "#fff",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "8px 40px",
                        }}
                        onClick={handleSaveNotes}
                    >
                        Save
                    </Button>
                </div>
                <div className="video-applicant-wrapper">
                    <div className="video-applicant-wrapper-header">
                        <button
                            className="left-arrow"
                            onClick={() =>
                                navigateApplicant(
                                    candidates[initialIndex - 1]._id
                                )
                            }
                            disabled={initialIndex === 0}
                        >
                            <img src={LeftArrow} alt="back-button" />
                        </button>
                        <div className="candidate">
                            <div className="candidate-info">
                                <Avatar
                                    name={candidate?.name}
                                    styles={{
                                        text: {
                                            backgroundColor: "#F3F3F3",
                                            border: "2px solid #CECECE",
                                            color: "#858585",
                                            fontSize: 20,
                                        },
                                    }}
                                />
                                <div className="candidate-info-basic">
                                    <p className="name">{candidate?.name}</p>
                                    <p className="email">{candidate?.email}</p>
                                </div>
                            </div>
                        </div>
                        <button
                            className="right-arrow"
                            onClick={() =>
                                navigateApplicant(
                                    candidates[initialIndex + 1]._id
                                )
                            }
                            disabled={initialIndex === candidates.length - 1}
                        >
                            <img src={RightArrow} alt="forward-button" />
                        </button>
                    </div>
                    <div className="video-applicant-wrapper-body">
                        <div className="video-applicant-wrapper-body-left">
                            {activeVideoQuestion?.src ? (
                                <video
                                    className="video"
                                    src={activeVideoQuestion.src}
                                    controls="true"
                                    playsInline
                                    type="video/mp4"
                                />
                            ) : (
                                <div className="no-video-found">
                                    <p>No Video Found</p>
                                </div>
                            )}
                            <textarea
                                className="note"
                                placeholder={
                                    "Add your remarks/ reviews here..."
                                }
                                rows={5}
                                value={vidNotes}
                                onChange={(e) => setVidNotes(e.target.value)}
                            />
                            <div className="score-wrapper">
                                <div className="score-wrapper-input">
                                    <p className="text">Enter Score</p>
                                    <input
                                        value={inputScore}
                                        onChange={(e) =>
                                            setInputScore(e.target.value)
                                        }
                                    />
                                </div>
                                <p className="score-wrapper-total">
                                    Total Score:&ensp;
                                    <span>{activeVideoQuestion?.score}%</span>
                                </p>
                            </div>
                        </div>
                        <div className="video-applicant-wrapper-body-right">
                            <p className="heading">Review Video Questions:</p>
                            {candidate?.test?.chat?.length > 0 ? (
                                videoCardLoading ? (
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="videobox-loader" />
                                    </div>
                                ) : videoCardData.length == 0 ? (
                                    "No Video Question"
                                ) : (
                                    videoCardData.map((val, index) => (
                                        <div
                                            key={val.cid}
                                            className="video-question-wrapper"
                                            onClick={() =>
                                                handleVideoQuestionClick(val)
                                            }
                                        >
                                            <p className="question">
                                                Q.{index + 1}
                                            </p>
                                            <div
                                                className={`play-icon ${
                                                    activeVideoQuestion?.cid ===
                                                    val.cid
                                                        ? "active"
                                                        : null
                                                }`}
                                            />
                                            <div className="tooltip">
                                                <p className="question">
                                                    {val.ques}
                                                </p>
                                                <span className="tooltiptext">
                                                    {val.ques}
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                )
                            ) : (
                                <Nodata />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={editModal}
                onClose={onCloseEditModal}
                center
                classNames={{ modal: "editModalApplicants" }}
            >
                <p className="modal-text">Edit candidate&apos;s name</p>
                <div className="input-div-workflow">
                    <label
                        style={{
                            margin: "0 15px",
                            fontWeight: "bold",
                            fontSize: "18px",
                        }}
                    >
                        New name
                    </label>
                    <br />
                    <input
                        type="text"
                        value={editCandName}
                        onChange={(e) => setEditCandName(e.target.value)}
                        placeholder="Name"
                        style={{ backgroundColor: "#F2F2F2", borderRadius: 30 }}
                    />
                </div>
                <div className="button-div">
                    <Button
                        className="modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={nameEdit}
                    >
                        Ok
                    </Button>
                    <Button
                        className="cancel-modal-button"
                        style={{
                            background: "#479BD2",
                            fontWeight: "bold",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 12px",
                            fontSize: "16px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={onCloseEditModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <Snackbar
                open={greenSnackOpen}
                autoHideDuration={2500}
                onClose={handleGreenSnackClose}
                msg={successMsg}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={errMsg}
                type="error"
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userRole: getUserRole(),
    candidates: getCandidatesList(),
    loading: getApplicantsLoading(),
});

const mapDispatchToProps = (dispatch) => ({
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
});

VideoApplicant.propTypes = {
    notification: PropTypes.object,
    userRole: PropTypes.string,
    editCandidateName: PropTypes.func,
    candidates: PropTypes.array,
    getCandidatesByType: PropTypes.func,
    loading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoApplicant);

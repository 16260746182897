import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "../../utils/axios";
import Button from "./Button";

import "../../styles/proctoringModal.scss";

const buttonStyle = {
    backgroundColor: "#479BD2",
    fontWeight: "bold",
    letterSpacing: "0.01em",
    borderRadius: "25px",
    padding: "6px 20px",
    fontSize: "16px",
};

const EventDetails = ({
    eventType,
    proctoringEvents,
    resolveProctoringStatus,
    selectedRow,
    renderTime,
}) => {
    return (
        <div className="proctoring-box-expanded">
            {proctoringEvents?.length ? (
                <div className="proctoring-timestamps">
                    <div className="proctoring-timestamp-title">Time stamp</div>
                    <div className="proctoring-timestamp-details">
                        {proctoringEvents
                            .filter((pe) => eventType.includes(pe.eventType))
                            .map((pe, index) => (
                                <div key={`${pe}${index}`}>
                                    - {renderTime(pe)}
                                </div>
                            ))}
                    </div>
                </div>
            ) : null}
            <div className="proctoring-resolve-btn">
                <Button
                    style={buttonStyle}
                    size="small"
                    variant="contained"
                    onClick={() =>
                        resolveProctoringStatus(eventType, selectedRow)
                    }
                >
                    Resolve
                </Button>
            </div>
        </div>
    );
};

EventDetails.propTypes = {
    eventType: PropTypes.array,
    proctoringEvents: PropTypes.array,
    resolveProctoringStatus: PropTypes.func,
    selectedRow: PropTypes.object,
    renderTime: PropTypes.func,
};

const EventAccordian = ({ title, text, active, handleExpand, children }) => {
    return (
        <div className="proctoring-accordian">
            <div className="proctoring-box">
                <div className="proctoring-count">
                    <div className="proctoring-type">{title}</div>
                    <div
                        className="proctoring-details"
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </div>
                <div
                    className={`proctoring-expand-btn ${
                        active ? "active" : ""
                    }`}
                    onClick={handleExpand}
                />
            </div>
            {children}
        </div>
    );
};

EventAccordian.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    active: PropTypes.bool,
    handleExpand: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

const ProctoringModal = ({
    proctoringData,
    onCloseProctoringModal,
    selectedRow,
    resolveProctoringStatus,
    changeStatus,
}) => {
    const {
        tabChangeCount,
        copyPaste,
        proctoringEvents,
        userId,
        candidateData,
        phoneDetected,
        proctoringConcern,
        faceDetected,
        // dfaceDetected,
        ipChange,
        repeatedTest,
        windowResizeCount,
    } = proctoringData;
    const [expanded, setExpanded] = useState({
        tabChange: false,
        copyPaste: false,
        multipleFace: false,
        differentFace: false,
        mobilePhone: false,
        ipChange: false,
        repeatedTest: false,
    });
    const [proctoringUrl, setProctoringUrl] = useState(
        `https://myproctvideos.s3.ap-southeast-1.amazonaws.com/${userId}.avi`
    );

    const renderTime = (pe) => {
        const eventCreatedDate = pe.createdDate;
        if (eventCreatedDate) {
            const createdDate = new Date(candidateData.test.createdDate);
            const eventDate = new Date(eventCreatedDate);
            const timeDiff = parseInt(
                (eventDate.getTime() - createdDate.getTime()) / 1000,
                10
            );

            const timeDiffMins = Math.floor(timeDiff / 60);
            const timeDiffSecs = parseInt(timeDiff % 60);

            return `${timeDiffMins < 10 ? 0 : ""}${timeDiffMins}:${
                timeDiffSecs < 10 ? 0 : ""
            }${timeDiffSecs}`;
        } else {
            return "00:00";
        }
    };

    useEffect(() => {
        axios
            .post("/cand-proct-video", { key: `${userId}.avi` })
            .then((res) => {
                setProctoringUrl(res.data.signedUrl);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [proctoringData]);

    const handleExpand = (eventType) => {
        setExpanded((prev) => ({ ...prev, [eventType]: !prev[eventType] }));
    };

    return (
        <div className="proctoringModal">
            <div className="modal-text">Red flags</div>
            <div style={{ marginTop: 15 }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: 25,
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 20,
                                background: "#E92525",
                                marginRight: 15,
                            }}
                        >
                            <div
                                style={{
                                    width: 15,
                                    height: 2,
                                    background: "#fff",
                                }}
                            />
                        </div>
                        <div>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color:
                                        proctoringConcern === "High"
                                            ? "#EA5858"
                                            : proctoringConcern === "Medium"
                                            ? "#FD8064"
                                            : "#FDC064",
                                }}
                            >
                                {proctoringConcern}
                            </div>
                            <div style={{ color: "#014954", fontSize: 14 }}>
                                Concern Level
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <a
                            rel="noreferrer"
                            href={proctoringUrl}
                            target="_blank"
                            style={{
                                color: "#014954",
                                fontSize: 14,
                                textDecoration: "underline",
                            }}
                        >
                            Watch proctoring video
                        </a>
                    </div> */}
                </div>
                {tabChangeCount || windowResizeCount ? (
                    <EventAccordian
                        title={"Switching tabs"}
                        text={`Number of times tabs were changed - ${tabChangeCount}<br>Number of times window was resized - ${windowResizeCount}`}
                        active={expanded.tabChange}
                        handleExpand={() => handleExpand("tabChange")}
                    >
                        {expanded.tabChange ? (
                            <EventDetails
                                eventType={["tabChange", "windowResize"]}
                                proctoringEvents={proctoringEvents}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                                renderTime={renderTime}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
                {copyPaste > 0 ? (
                    <EventAccordian
                        title={"Copy Paste Detected"}
                        text={`Number of times copy paste detected - ${copyPaste}`}
                        active={expanded.copyPaste}
                        handleExpand={() => handleExpand("copyPaste")}
                    >
                        {expanded.copyPaste ? (
                            <EventDetails
                                eventType={["copypaste"]}
                                proctoringEvents={proctoringEvents}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                                renderTime={renderTime}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
                {/* {dfaceDetected > 0 ? (
                    <EventAccordian
                        title={"Same Candidate Detection"}
                        text={"Face detected - Yes"}
                        active={expanded.differentFace}
                        handleExpand={() => handleExpand("differentFace")}
                    >
                        {expanded.differentFace ? (
                            <EventDetails
                                eventType={["differentFace"]}
                                proctoringEvents={
                                    proctoringEvents
                                }
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null} */}
                {faceDetected > 0 ? (
                    <EventAccordian
                        title={"Multiple Face Detection"}
                        text={"Face detected - Yes"}
                        active={expanded.multipleFace}
                        handleExpand={() => handleExpand("multipleFace")}
                    >
                        {expanded.multipleFace ? (
                            <EventDetails
                                eventType={["multipleFace"]}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
                {phoneDetected > 0 ? (
                    <EventAccordian
                        title={"Mobile Phone Detection"}
                        text={"Mobile Phone - Yes"}
                        active={expanded.mobilePhone}
                        handleExpand={() => handleExpand("mobilePhone")}
                    >
                        {expanded.mobilePhone ? (
                            <EventDetails
                                eventType={["mobilePhone"]}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
                {ipChange > 0 ? (
                    <EventAccordian
                        title={"IP Change Detection"}
                        text={"IP Change - Yes"}
                        active={expanded.ipChange}
                        handleExpand={() => handleExpand("ipChange")}
                    >
                        {expanded.ipChange ? (
                            <EventDetails
                                eventType={["ipChange"]}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
                {repeatedTest > 0 ? (
                    <EventAccordian
                        title={"Duplicate Candidate Detection"}
                        text={"Duplicate Candidate - Yes"}
                        active={expanded.repeatedTest}
                        handleExpand={() => handleExpand("repeatedTest")}
                    >
                        {expanded.repeatedTest ? (
                            <EventDetails
                                eventType={["repeatedTest"]}
                                resolveProctoringStatus={
                                    resolveProctoringStatus
                                }
                                selectedRow={selectedRow}
                            />
                        ) : null}
                    </EventAccordian>
                ) : null}
            </div>
            <div className="button-div" style={{ marginTop: 15 }}>
                <Button
                    style={buttonStyle}
                    size="small"
                    variant="contained"
                    onClick={() => {
                        onCloseProctoringModal();
                        resolveProctoringStatus(["all"], selectedRow);
                    }}
                >
                    Resolve All
                </Button>
                <Button
                    className="cancel-modal-button"
                    style={buttonStyle}
                    size="small"
                    variant="contained"
                    onClick={() => {
                        onCloseProctoringModal();
                        changeStatus("Rejected", selectedRow);
                    }}
                >
                    Reject candidate
                </Button>
            </div>
        </div>
    );
};

ProctoringModal.propTypes = {
    proctoringData: PropTypes.object,
    onCloseProctoringModal: PropTypes.func,
    selectedRow: PropTypes.array,
    resolveProctoringStatus: PropTypes.func,
    changeStatus: PropTypes.func,
};

export default ProctoringModal;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import Button from "../components/common/Button";
import Modal from "../components/common/CustomModal";
import Switch from "../components/common/SwitchBtn";
import NoRowsOverlay from "../components/common/NoRowsDataGridOverlay";

import {
    addBots as addBotsRequest,
    deleteBots as deleteBotsRequest,
    fetchBots as fetchBotsRequest,
    updateBotDetails as updateBotDetailsRequest,
} from "../actions/app";
import { getConvoList } from "../selectors/app";

const Bots = (props) => {
    const { addBots, deleteBots, convo, fetchBots, updateBotDetails } = props;

    const navigate = useNavigate();

    const [newModal, setNewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [name, setName] = useState("");
    const [productId, setProductId] = useState("");
    const [problem, setProblem] = useState("");
    const [botTime, setBotTime] = useState(30);
    const [botSectionCount, setBotSectionCount] = useState(1);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [botToBeDeleted, setBotToBeDeleted] = useState({});
    const [selectedBotId, setSelectedBotId] = useState();
    const [botHasVideoQ, setBotHasVideoQ] = useState(false);

    const onOpenNewModal = () => setNewModal(true);

    const onCloseNewModal = () => {
        setName("");
        setProductId("");
        setProblem("");
        setBotTime(30);
        setNewModal(false);
    };

    const onOpenDeleteModal = (val) => {
        setBotToBeDeleted({ ...val });
        setDeleteModal(true);
    };

    const onCloseDeleteModal = () => {
        setDeleteModal(false);
    };

    const onOpenEditModal = (val) => {
        setEditModal(true);
        setSelectedBotId(val._id);
        setName(val.name);
        setProductId(val.productId);
        setProblem(val.problem);
        setBotTime(val.assessmentTime || 30);
        setBotHasVideoQ(val.hasVideoQ);
        setBotSectionCount(val.sectionCount || 1);
    };

    const onCloseEditModal = () => {
        setEditModal(false);
    };

    useEffect(() => {
        fetchBots({ navigate });
    }, []);

    const onSubmitEdit = () => {
        const botData = {
            botId: selectedBotId,
            name,
            productId,
            problem,
            assessmentTime: botTime,
            sectionCount: botSectionCount,
            hasVideoQ: botHasVideoQ,
        };

        updateBotDetails(botData);
        onCloseEditModal();
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            headerAlign: "left",
            align: "left",
            flex: 3,
        },
        {
            field: "productId",
            headerName: "Product ID",
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Actions",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="datagrid__row datagrid__row-center">
                        <Button
                            className="btn-datagrid btn-danger"
                            size="small"
                            variant="contained"
                            onClick={() => onOpenEditModal(params.row)}
                        >
                            Edit
                        </Button>
                        <Button
                            className="btn-datagrid btn-danger"
                            size="small"
                            variant="contained"
                            onClick={() => onOpenDeleteModal(params.row)}
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];

    const sendBots = () => {
        onCloseNewModal();
        addBots({
            name: name,
            productId: productId,
            problem: problem,
            assessmentTime: botTime,
            sectionCount: botSectionCount,
        });
    };

    const handleDelete = (id) => {
        onCloseDeleteModal();
        deleteBots({ botId: id });
    };

    const renderDate = (date) => {
        const createdDate = new Date(date);

        return <>{createdDate.toLocaleString()}</>;
    };

    return (
        <div
            className="mainbar"
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: "100vh",
            }}
        >
            <div className="notificationbar">
                <button className="btn-primary" onClick={onOpenNewModal}>
                    Create new Bot
                </button>
            </div>
            <div className="actionbar" />
            <div className="datagrid__container">
                <DataGrid
                    sortingOrder={["desc", "asc"]}
                    slots={{
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{ "--DataGrid-overlayHeight": "300px" }}
                    getRowId={(row) => row._id}
                    rows={convo.length == 0 ? [] : convo}
                    disableRowSelectionOnClick
                    columns={columns}
                    autoHeight
                    pageSizeOptions={[5, 10, 15]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    className="datagrid__scroll scroll-blue"
                />
            </div>
            <Modal
                open={deleteModal}
                onClose={onCloseDeleteModal}
                center
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">
                    Do you want to delete this bot?
                </div>
                {Object.keys(botToBeDeleted)
                    .filter((item) => !item.includes("features"))
                    .map((key) => (
                        <>
                            <div>
                                <span>
                                    <b>{key} : </b>
                                </span>
                                <span>
                                    {key == "createdDate"
                                        ? renderDate(botToBeDeleted[key])
                                        : typeof botToBeDeleted[key] ===
                                          "boolean"
                                        ? botToBeDeleted[key]
                                            ? "true"
                                            : "false"
                                        : botToBeDeleted[key]}
                                </span>
                            </div>
                            <br />
                        </>
                    ))}
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary btn-danger"
                        size="small"
                        variant="contained"
                        disabled={botToBeDeleted.length == 0}
                        onClick={() => handleDelete(botToBeDeleted._id)}
                    >
                        Ok
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseDeleteModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <Modal
                open={newModal}
                onClose={onCloseNewModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">Create new bot</div>
                <div className="modal__inputbox">
                    <div style={{ marginLeft: 10 }}>Bot Name</div>
                    <input
                        type="text"
                        placeholder="Bot Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <br />
                    <div style={{ marginLeft: 10, marginTop: 10 }}>
                        Product ID
                    </div>
                    <input
                        type="text"
                        placeholder="Product ID"
                        value={productId}
                        onChange={(e) => {
                            setProductId(e.target.value);
                        }}
                    />
                    <br />
                    <div style={{ marginLeft: 10, marginTop: 10 }}>Problem</div>
                    <textarea
                        type="text"
                        placeholder="Problem"
                        value={problem}
                        onChange={(e) => {
                            setProblem(e.target.value);
                        }}
                        required
                    />
                    <br />
                    <div style={{ marginLeft: 10, marginTop: 10 }}>
                        Assessment Time
                    </div>
                    <input
                        type="number"
                        placeholder="Assessment Time"
                        value={botTime}
                        onChange={(e) => {
                            setBotTime(e.target.value);
                        }}
                    />
                    <br />
                    <div style={{ marginLeft: 10, marginTop: 10 }}>
                        Section Count
                    </div>
                    <input
                        type="number"
                        placeholder="Section Count"
                        value={botSectionCount}
                        onChange={(e) => {
                            setBotSectionCount(e.target.value);
                        }}
                    />
                    <br />
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={sendBots}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseNewModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <Modal
                open={editModal}
                onClose={onCloseEditModal}
                classNames={{ modal: "modal__container" }}
            >
                <div className="modal__heading">Edit bot</div>
                <div className="modal__inputbox">
                    <input
                        type="text"
                        placeholder="Bot Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <br />
                    <input
                        type="text"
                        placeholder="Product ID"
                        value={productId}
                        onChange={(e) => {
                            setProductId(e.target.value);
                        }}
                    />
                    <br />
                    <textarea
                        type="text"
                        placeholder="Problem"
                        value={problem}
                        onChange={(e) => {
                            setProblem(e.target.value);
                        }}
                        required
                    />
                    <br />
                    <label style={{ display: "block", padding: "0 15px" }}>
                        Assessment Time
                    </label>
                    <input
                        type="number"
                        placeholder="Assessment Time"
                        value={botTime}
                        onChange={(e) => {
                            setBotTime(e.target.value);
                        }}
                    />
                    <br />
                    <label style={{ display: "block", padding: "10px 15px 0" }}>
                        Section Count
                    </label>
                    <input
                        type="number"
                        placeholder="Section Count"
                        value={botSectionCount}
                        onChange={(e) => {
                            setBotSectionCount(e.target.value);
                        }}
                    />
                    <br />
                    <div
                        style={{
                            width: 380,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0 10px 10px",
                        }}
                    >
                        <label>Has Video Question :</label>
                        <Switch
                            style={{ width: 52 }}
                            checked={botHasVideoQ}
                            onChange={(e) => setBotHasVideoQ(e.target.checked)}
                        />
                    </div>
                </div>
                <div className="modal__btnbox">
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onSubmitEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onCloseEditModal}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

Bots.propTypes = {
    addBots: PropTypes.func,
    deleteBots: PropTypes.func,
    convo: PropTypes.array,
    fetchBots: PropTypes.func,
    updateBotDetails: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    convo: getConvoList(),
});

const mapDispatchToProps = (dispatch) => ({
    addBots: (data) => dispatch(addBotsRequest(data)),
    deleteBots: (data) => dispatch(deleteBotsRequest(data)),
    fetchBots: (data) => dispatch(fetchBotsRequest(data)),
    updateBotDetails: (data) => dispatch(updateBotDetailsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bots);

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import Snackbar from "../components/common/Snackbar";
import Modal from "../components/common/CustomModal";

import axios from "../utils/axios";

import "../styles/ZohoIntegration.scss";

const ZohoIntegration = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const candidateId = queryParams.get("candidateId");
    const accessToken = localStorage.getItem("accessToken");

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        candidate: {},
        assessments: [],
    });
    const [openModal, setOpenModal] = useState(false);
    const [assessment, setAssesssment] = useState("select");
    const [snackbar, setSnackbar] = useState({
        open: false,
        type: "error",
        msg: "",
    });

    useEffect(() => {
        if (accessToken) {
            axios
                .post("/auth/verify-access-token", {
                    token: JSON.parse(accessToken),
                })
                .then((res) => {
                    if (candidateId) {
                        const zohoCandidateApi = axios.post("/zoho", {
                            candidateId,
                            companyId: res.data.companyId,
                        });
                        const companyAssessmentsApi =
                            axios.get("/company-workflows");
                        Promise.all([zohoCandidateApi, companyAssessmentsApi])
                            .then((resp) => {
                                setData({
                                    candidate: resp[0].data.details[0],
                                    assessments: resp[1].data.workflows,
                                });
                                setLoading(false);
                            })
                            .catch((error) => {
                                if (!error?.response?.data?.token) {
                                    navigate(
                                        "/zoho-authorization/error?message=Access denied"
                                    );
                                }
                                console.log(error);
                                setLoading(false);
                            });
                    } else {
                        setSnackbar({
                            open: true,
                            type: "error",
                            msg: "Candidate Id is missing in url",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    navigate(
                        `/signin?redirectTo=${window.location.pathname}${window.location.search}`
                    );
                });
        } else {
            navigate(
                `/signin?redirectTo=${window.location.pathname}${window.location.search}`
            );
        }
    }, []);

    const handleSelectAssessment = (e) => {
        setAssesssment(e.target.value);
    };

    const handleSnackClose = () => {
        setSnackbar({
            open: false,
            type: "error",
            msg: "",
        });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        navigate("/workflows");
    };

    const handleAssessmentInvite = () => {
        const postData = {
            name: data.candidate.Full_Name,
            email: data.candidate.Email,
            workflowId: assessment,
            zohoCandidateId: candidateId,
        };

        axios
            .post("/zoho-send-test", postData)
            .then((resp) => {
                console.log(resp);
                setOpenModal(true);
            })
            .catch((err) => {
                console.log(err);
                setSnackbar({
                    open: true,
                    type: "error",
                    msg: err.message,
                });
            });
    };

    if (loading)
        return (
            <div className="applicant-loading-wrapper">
                <div className="applicant-loader" />
            </div>
        );
    return (
        <>
            <div className="zohoIntegrationPage">
                <h1 className="zohoIntegrationPage-heading">
                    HireQuotient Assessment
                </h1>
                <div className="zohoIntegrationPage-container">
                    <div className="zohoIntegrationPage-disclaimer">
                        <p>Important Notes</p>
                        <ul>
                            <li>
                                On initiating the assessment, an email will be
                                sent to the selected candidate from ZohoRecruit.
                            </li>
                            <li>
                                Once the candidate completes the assessment, the
                                score report will be updated inside ZohoRecruit.
                            </li>
                            <li>
                                Kindly note that the candidate&apos;s PII(First
                                name, last name and email address) will be
                                shared with HireQuotient
                            </li>
                        </ul>
                    </div>
                    <div className="zohoIntegrationPage-wrapper">
                        <div className="zohoIntegrationPage-wrapper-row">
                            <p className="zohoIntegrationPage-wrapper-row-title">
                                Candidate
                            </p>
                            <div className="zohoIntegrationPage-wrapper-row-name">
                                {data.candidate.First_Name}{" "}
                                {data.candidate.Last_Name}
                            </div>
                        </div>
                        {/* <div className='zohoIntegrationPage-wrapper-row'>
                            <p className='zohoIntegrationPage-wrapper-row-title'>Job Opening</p>
                            <div className='zohoIntegrationPage-wrapper-row-name'>Candidate</div>
                        </div> */}
                        <div className="zohoIntegrationPage-wrapper-row">
                            <p className="zohoIntegrationPage-wrapper-row-title">
                                HireQuotient Assessment
                            </p>
                            <div className="zohoIntegrationPage-wrapper-row-select">
                                <select
                                    className="zohoIntegrationPage-wrapper-row-select-name"
                                    value={assessment}
                                    onChange={handleSelectAssessment}
                                >
                                    <option value={"select"} hidden>
                                        Select an assessment
                                    </option>
                                    {data.assessments.map((assessment) => (
                                        <option
                                            key={assessment._id}
                                            value={assessment._id}
                                        >
                                            {assessment.name}
                                        </option>
                                    ))}
                                </select>
                                <Link
                                    to={"/workflows"}
                                    target={"_blank"}
                                    className="zohoIntegrationPage-wrapper-row-select-btn"
                                    title="Create an assessment"
                                >
                                    +
                                </Link>
                            </div>
                        </div>
                        <div className="zohoIntegrationPage-wrapper-btns">
                            <Button
                                size={"small"}
                                variant={"contained"}
                                onClick={handleAssessmentInvite}
                                disabled={assessment === "select"}
                            >
                                Send Invite
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2500}
                onClose={handleSnackClose}
                msg={snackbar.msg}
                type={snackbar.type}
            />
            <Modal open={openModal} onClose={handleCloseModal} center>
                <div
                    style={{
                        backgroundColor: "rgb(187, 247, 208)",
                        color: "rgb(34, 197, 94)",
                        borderRadius: "50%",
                        margin: "0 auto",
                        width: 24,
                        height: 24,
                        padding: "0.5rem",
                    }}
                >
                    <svg
                        width={"100%"}
                        height={"100%"}
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                        ></path>
                    </svg>
                </div>
                <p
                    style={{
                        margin: "1rem 0",
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: 18,
                    }}
                >
                    Invitation Link Sent Successfully. You can close this window
                    now.
                </p>
                <div className="zohoIntegrationPage-wrapper-btns">
                    <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={handleCloseModal}
                    >
                        OK
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ZohoIntegration;

import { useState } from "react";

const menuSX = {
    "& .MuiPaper-root": {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0.5rem",
    },
    "&.MuiMenuItem-root": {
        fontSize: "14px",
        borderRadius: "0.5rem",
        padding: "6px 10px",
    },
    "& .MuiList-root": {
        display: "flex",
        flexDirection: "column",
        gap: "0.3rem",

        "& .MuiButtonBase-root": {
            minHeight: "initial",
        },
    },
};

export default function useMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return { anchorEl, setAnchorEl, open, handleClick, handleClose, menuSX };
}

import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/common/Button';

import AssessmentTypeImg from '../../../assets/assessment-type.png';
import WalkthroughOverlay from '../../common/WalkthroughOverlay';

const SelectAssessmentType = (props) => {
	const { selectBotMenu, openDefaultForm, openCustomForm, updateCurrentWalkthroughStep, currentWalkthroughStep, onClose } = props;
	const companyId = localStorage.getItem('company');

	return (
		<>
			<div
				style={{
					position: 'relative',
					zIndex: currentWalkthroughStep === 2 ? 1000 : 1,
				}}
				id="workflowStep2"
			>
				<div className='title'>Virtual Interviewer - Select type</div>
				<div className='subtitle'>Select assessment to create</div>
				<div className='default-list' id="testNewDriveFlow-selector-2">
					{
						selectBotMenu.map((item) => (
							<div className='list-item' key={item._id}>
								<div className='list-item-box' onClick={() => openDefaultForm(item)}>
									<img src={AssessmentTypeImg} alt='' />
									<div>{item.name}</div>
								</div>
								<div>Default</div>
							</div>
						))
					}
				</div>
				{(companyId === '6381099636eb54641ed5c988' || companyId === '64075efbe58189914932e7b1') ? null : (
					<>
						<div className='divider'>OR</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								className='action tooltip'
								size="small"
								variant="contained"
								onClick={openCustomForm}
							>
								Create custom Virtual Interviewer
								<span className='tooltiptext' style={{ marginLeft: '-300px', width: 'max-content' }}>Self service mode customising our expert content to your requirements and getting an assessment in two minutes!</span>
							</Button>
						</div>
					</>
				)}
			</div>
			{
				currentWalkthroughStep === 2 ? (
					<WalkthroughOverlay
						// onClickSkip={() => updateCurrentWalkthroughStep(2)}
						onClickExit={() => updateCurrentWalkthroughStep(0)}
						onClickBack={() => {
							onClose();
							updateCurrentWalkthroughStep(currentWalkthroughStep - 1);
						}}
					/>
				) : ''
			}
		</>
	)
}

SelectAssessmentType.propTypes = {
	selectBotMenu: PropTypes.array,
	openDefaultForm: PropTypes.func,
	openCustomForm: PropTypes.func,
	currentWalkthroughStep: PropTypes.number,
	updateCurrentWalkthroughStep: PropTypes.func,
	onClose: PropTypes.func
}

export default SelectAssessmentType;

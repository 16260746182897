import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import Button from "../common/Button";

function SidebarItem(props) {
    const {
        label,
        img,
        path,
        state = {},
        tooltipText,
        className = "",
        tooltipStyle = {},
        ...rest
    } = props;

    const renderButton = () => {
        return (
            <Button>
                <img src={img} alt={`${label} icon`} />
                <span>&nbsp;{label}</span>
            </Button>
        );
    };

    return (
        <NavLink
            end
            to={path}
            state={state}
            className={({ isActive }) =>
                `sidebarMenu ${
                    isActive ? "activesidebarMenu" : ""
                } ${className}`
            }
            {...rest}
        >
            {tooltipText ? (
                <div className="tooltip">
                    {renderButton()}
                    <span className="tooltiptext" style={tooltipStyle}>
                        {tooltipText}
                    </span>
                </div>
            ) : (
                renderButton()
            )}
        </NavLink>
    );
}

SidebarItem.propTypes = {
    label: PropTypes.string,
    img: PropTypes.string,
    path: PropTypes.string,
    state: PropTypes.object,
    tooltipText: PropTypes.string,
    className: PropTypes.string,
    tooltipStyle: PropTypes.object,
};

export default SidebarItem;

import React from 'react';
import PropTypes from 'prop-types';
import { Document, Image, Page, Text, View, Font, StyleSheet, Link } from '@react-pdf/renderer';

import { getBucket } from '../utils/reports';
import { percentageColor, reportGrade, reportWorkFunctions } from '../constants/reports';

import ReportBg from '../assets/report-bg.jpg';
import WorkFunction1 from '../assets/work-function-1.png';
import WorkFunction2 from '../assets/work-function-2.png';


Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
    ]
});

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: '80pt 0 50pt',
        fontFamily: 'Open Sans',
    },
    header: {
        height: '60pt',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    },
    headerWrapper: {
        marginRight: '30pt',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    headerTextOne: {
        color: '#fff',
        fontSize: '12pt'
    },
    headerTextTwo: {
        color: '#fff',
        fontSize: '12pt'
    },
    pageWrapper: {
        position: 'relative',
        zIndex: 2,
        lineHeight: 1.5
    }
});

const PdfReport = (props) => {
    const { doughnutRef, candidateData, videoCardData } = props;

    const driveName = candidateData.title;
    const totalScore = parseInt((candidateData.test.result.total || 0) * 100);
    const workFunctions = [];
    Object.keys(candidateData.test.result).forEach((item) => {
        if (item !== 'total') {
            workFunctions.push(item);
        }
    });

    const getScoreChart = () => {
        return doughnutRef.current.toBase64Image("image/png");
    };

    const getCandVidLink = () =>{
        if (videoCardData.length)
            return videoCardData[0].src?.length>0
        else
            return 0
    }
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <View style={styles.headerWrapper}>
                        <Text style={styles.headerTextOne}>{driveName}</Text>
                        <Text style={styles.headerTextTwo}>IBF TFIP</Text>
                    </View>
                </View>
                <View style={{ position: 'absolute', left: 0, right: '60pt', bottom: '30pt', zIndex: 1 }} fixed>
                    <Text style={{ textAlign: 'right', fontSize: '12pt' }} render={({ pageNumber }) => (
                        `${pageNumber}`
                    )} />
                </View>
                <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 3 }} fixed>
                    <Image src={ReportBg} style={{ width: '100%', height: '100%' }} />
                </View>
                <View style={styles.pageWrapper}>
                    <View style={{ padding: '0 80pt', fontSize: '12pt' }}>
                        <Text style={{ marginTop: '25pt', color: '#134f5c', fontSize: '14pt', fontWeight: 700, textAlign: 'center' }}>REPORT</Text>
                        <View style={{ marginTop: '20pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderWidth: '1pt' }}>
                            <View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
                                <View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>Name</Text>
                                </View>
                                <View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
                                    <Text>{candidateData.name}</Text>
                                </View>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
                                <View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>Candidate ID</Text>
                                </View>
                                <View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
                                    <Text>{candidateData.id}</Text>
                                </View>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: '#f1f1f1', borderBottomWidth: '1pt' }}>
                                <View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>Start time</Text>
                                </View>
                                <View style={{ width: '25%', color: '#7d7d7d', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>{new Date(candidateData.test.createdDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toUpperCase()}</Text>
                                </View>
                                <View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>Date</Text>
                                </View>
                                <View style={{ width: '25%', color: '#7d7d7d', padding: '5pt' }}>
                                    <Text>{new Date(candidateData.test.endDate).toDateString()}</Text>
                                </View>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <View style={{ width: '25%', color: '#000', padding: '5pt', borderStyle: 'solid', borderColor: '#f1f1f1', borderRightWidth: '1pt' }}>
                                    <Text>Attempt duration</Text>
                                </View>
                                <View style={{ width: '75%', color: '#7d7d7d', padding: '5pt' }}>
                                    <Text>{parseInt(((new Date(candidateData.test.endDate).getTime() - new Date(candidateData.test.createdDate).getTime()) / 60000)) + ' minutes'}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={{ marginTop: '30pt', color: '#134f5c', fontSize: '14pt', fontWeight: 700, textAlign: 'center', textTransform: 'uppercase' }}>Performance Overview</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <View style={{ position: 'relative', padding: '10pt', display: 'flex', flexDirection: 'column', alignItems: 'center', borderStyle: 'solid', borderColor: '#f1f1f1', borderWidth: '1pt' }}>
                                <Text style={{ position: 'absolute', left: '45pt', top: '45pt', fontSize: '20pt' }}>{totalScore}%</Text>
                                <Image style={{ width: '100pt', height: '100pt' }} src={getScoreChart()} />
                                <Text style={{ marginTop: '5pt', fontSize: '10pt', textAlign: 'center' }}>{reportGrade[getBucket(totalScore)]}</Text>
                            </View>
                        </View>
                        <Text style={{ marginTop: '30pt', color: '#134f5c', fontSize: '12pt', fontWeight: 700, textAlign: 'center' }}>Total Score</Text>
                        {
                            workFunctions.map((item, i) => {
                                if (Object.prototype.hasOwnProperty.call(reportWorkFunctions, item.toLowerCase())) {
                                    const title = reportWorkFunctions[item.toLowerCase()].label;
                                    const skillScore = parseInt(candidateData.test.result[item] * 100);

                                    return (
                                        <View style={{ display: 'flex', flexDirection: 'row', padding: '0 20pt', alignItems: 'center', marginTop: '10pt' }} key={title}>
                                            <View>
                                                <Image src={i % 2 === 0 ? WorkFunction1 : WorkFunction2} style={{ width: '24pt', height: '24pt' }} />
                                            </View>
                                            <View style={{ width: '350pt', marginLeft: '15pt' }}>
                                                <Text style={{ fontSize: '12pt', width: '350pt' }}>{title}</Text>
                                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2pt' }}>
                                                    <View style={{ position: 'relative', display: 'flex', flex: 1 }}>
                                                        <View style={{ backgroundColor: '#F1F1F1', height: '10pt' }} />
                                                        <View style={{ backgroundColor: percentageColor[getBucket(skillScore)], position: 'absolute', top: 0, bottom: 0, left: 0, right: `${(100 - skillScore)}%` }} />
                                                    </View>
                                                    <Text style={{ fontSize: '14pt', color: '#696969', marginLeft: '10pt' }}>{skillScore}%</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )
                                } else {
                                    return <View key={i} />
                                }
                            })
                        }
                        {getCandVidLink()?(
                            <Text style={{ marginTop: '15pt', color: '#134f5c', fontSize: '8pt', fontWeight: 700, textAlign: 'center' }}>
                            <Link src={videoCardData[0].src}>Click here for video answer</Link>
                            </Text>) : ""}
                    </View>
                </View>
            </Page>
        </Document >
    )
};

PdfReport.propTypes = {
    doughnutRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    candidateData: PropTypes.object,
    videoCardData: PropTypes.array
}

export default PdfReport;

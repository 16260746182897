import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Button from "../components/common/Button";
import Error from "../components/common/Error";
import Loader from "../components/common/Loader";
import {
    getAuthErrorMsg,
    getAuthLoading,
    getPwdCodeVerificationStatus,
    getVerifyCodeSuccess,
} from "../selectors/app";
import {
    userSignIn as userSignInRequest,
    setAuthErrorMsg as setAuthErrorMsgRequest,
    sendForgotPasswordLink as sendForgotPasswordLinkRequest,
    changeUserPassword as changeUserPasswordRequest,
    verificationCode as verificationCodeRequest,
} from "../actions/app";
import { validateEmail, validateSignInInput } from "../utils/validations";

import logoDesktop from "../assets/Logo.png";
import logoMobile from "../assets/logo-white.png";

import "../styles/btn.scss";
import "../styles/signIn.scss";

const SignIn = (props) => {
    const {
        sendForgotPasswordLink,
        userSignIn,
        isLoading,
        errorMsg,
        setAuthErrorMsg,
        changeUserPassword,
        verificationCode,
        pwdVerifyStatus,
        verifyCodeSuccess,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(window.location.search);
    const redirectUrl = query.get("redirectTo");
    const isBpad = query.get("company") === "BPAD";
    const isSafari = window.safari !== undefined;
    const SSO_ROUTE_URL = `/signin/sso${
        redirectUrl ? `?redirectTo=${redirectUrl}` : ""
    }`;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotEmail, setForgotEmail] = useState("");
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const [handleScreen, setHandleScreen] = useState("");
    const mediaQueryMobile = window.matchMedia("(max-width: 600px)");
    const [forgotPassword, setForgotPassword] = useState({
        new: "",
        confirm: "",
    });
    const [showForgotPassword, setShowForgotPassword] = useState({
        new: false,
        confirm: false,
    });

    useEffect(() => {
        if (location.pathname.includes("forgot-password")) {
            setHandleScreen(0);
        } else {
            setHandleScreen("");
        }
    }, [location]);

    const handleBackScreen = () => {
        setHandleScreen("");
        navigate("/signin");
    };

    const handleBackScreen1 = () => {
        setVerifyCode("");
        setHandleScreen(0);
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        setAuthErrorMsg("");

        const userData = {
            email,
            password,
            company: isBpad ? "BPAD" : "",
        };

        if (process.env.NODE_ENV !== "development" && !isBpad) {
            userData.captchaValue = captchaValue;
        }

        const errorResult = validateSignInInput(userData);

        if (!errorResult.isValid) {
            Object.keys(errorResult.errors).forEach((item) => {
                if (item) {
                    setAuthErrorMsg(errorResult.errors[item]);
                    return;
                }
            });
            return;
        }

        if (!isBpad) delete userData.company;
        userSignIn({
            userData,
            navigate,
            redirectUrl,
        });
    };

    const onChange = (value) => {
        setCaptchaValue(value);
    };

    const onRequestLink = () => {
        const data = {
            email: forgotEmail,
        };

        const errorResult = validateEmail(forgotEmail);

        if (!errorResult.isValid) {
            Object.keys(errorResult.errors).forEach((item) => {
                if (item) {
                    setAuthErrorMsg(errorResult.errors[item]);
                    return;
                }
            });
        } else {
            sendForgotPasswordLink(data);
            setHandleScreen(1);
        }
    };

    const onVerifyCode = () => {
        if (!verifyCode) {
            setAuthErrorMsg("Verification code can't be empty");
        } else {
            const data = {
                verifyCode,
                forgotEmail,
            };

            verificationCode(data);
        }
    };

    useEffect(() => {
        if (verifyCodeSuccess) {
            if (pwdVerifyStatus) {
                setHandleScreen(2);
            } else {
                setAuthErrorMsg("Code verification failed");
            }
        }
    }, [pwdVerifyStatus, verifyCodeSuccess]);

    const onVerifyPassword = () => {
        if (forgotPassword.new === forgotPassword.confirm) {
            const userData = {
                forgotEmail: forgotEmail,
                newPassword: forgotPassword.new,
            };

            changeUserPassword({ userData, navigate });
        }
    };

    return (
        <div className="signinWrapper">
            <form className="signinForm" onSubmit={handleSignIn}>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "400px",
                            margin: "auto",
                            maxWidth: "80%",
                        }}
                    >
                        <div className="logoContainer">
                            <img
                                src={
                                    mediaQueryMobile.matches
                                        ? logoMobile
                                        : logoDesktop
                                }
                                alt="logo"
                                className="logoImage"
                            />
                            <h1
                                style={{
                                    fontSize: "30px",
                                    color: "#008095",
                                    marginBottom: "15px",
                                    fontWeight: "800",
                                }}
                                className="clientDashboard"
                            >
                                Client Dashboard
                            </h1>
                        </div>
                        {location.pathname.includes("signin") && (
                            <>
                                <div style={{ marginBottom: "20px" }}>
                                    <p className="inputLabel">Email</p>
                                    <input
                                        required
                                        className="signinInput"
                                        placeholder={
                                            mediaQueryMobile.matches
                                                ? "Email ID"
                                                : "Please enter your email"
                                        }
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type="email"
                                        id="testLoginFlow-selector-1"
                                    />
                                </div>
                                <div style={{ marginBottom: "20px" }}>
                                    <p className="inputLabel">Password</p>
                                    <div
                                        style={{
                                            position: "relative",
                                            marginBottom: 10,
                                        }}
                                    >
                                        <input
                                            required
                                            placeholder={
                                                mediaQueryMobile.matches
                                                    ? "Password"
                                                    : "Please enter your password"
                                            }
                                            className="signinInput"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            id="testLoginFlow-selector-2"
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                right: isSafari ? 40 : 15,
                                                top: 15,
                                                fontSize: 12,
                                                color: mediaQueryMobile.matches
                                                    ? "#fff"
                                                    : "#479bd2",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            {showPassword ? "Hide" : "Show"}
                                        </div>
                                    </div>
                                    <Link
                                        className="inputLabelForgot"
                                        to="/forgot-password"
                                    >
                                        Forgot password?
                                    </Link>
                                </div>
                                {process.env.NODE_ENV !== "development" &&
                                !isBpad ? (
                                    <ReCAPTCHA
                                        sitekey="6LdR4pgfAAAAAC5TKy_JSYyA9u-6BcRalLBW_eIQ"
                                        onChange={onChange}
                                    />
                                ) : null}
                                <Button
                                    size="small"
                                    variant="contained"
                                    className="signinBtn"
                                    type="submit"
                                    disabled={isLoading}
                                    id="testLoginFlow-selector-3"
                                >
                                    Login
                                </Button>
                                <Link
                                    className="inputLabelForgot"
                                    to={SSO_ROUTE_URL}
                                    style={{
                                        textAlign: "center",
                                        marginTop: "2rem",
                                    }}
                                >
                                    Use your team&#39;s Single sign-on (SSO)
                                    authentication
                                </Link>
                            </>
                        )}
                        {handleScreen === 0 && (
                            <>
                                <p className="inputLabel3">Forgot password?</p>
                                <p className="inputLabel2">
                                    Reset password in 2 quick steps
                                </p>
                                <div style={{ margin: "3rem 0 0.75rem" }}>
                                    <input
                                        required
                                        className="signinInput"
                                        placeholder={"Enter your email ID"}
                                        value={forgotEmail}
                                        onChange={(e) =>
                                            setForgotEmail(e.target.value)
                                        }
                                        type="email"
                                        id="testLoginFlow-selector-4"
                                    />
                                </div>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className="resetPasswordBtn"
                                    id="testLoginFlow-selector-5"
                                    onClick={onRequestLink}
                                >
                                    Reset password
                                </Button>
                                <p
                                    className="backBtn"
                                    onClick={handleBackScreen}
                                >
                                    Back
                                </p>
                            </>
                        )}
                        {handleScreen === 1 ? (
                            <>
                                <p className="inputLabel3">
                                    We have sent a code to your email
                                </p>
                                <p className="inputLabel2">
                                    Enter the verification code sent to{" "}
                                    {forgotEmail[0] +
                                        forgotEmail
                                            .substring(
                                                1,
                                                forgotEmail.indexOf("@")
                                            )
                                            .replace(/./g, "*") +
                                        forgotEmail.substring(
                                            forgotEmail.indexOf("@"),
                                            forgotEmail.length
                                        )}
                                </p>
                                <div style={{ margin: "3rem 0 0.75rem" }}>
                                    <input
                                        required
                                        className="signinInput"
                                        placeholder={"Verification code"}
                                        value={verifyCode}
                                        onChange={(e) =>
                                            setVerifyCode(e.target.value)
                                        }
                                        type="text"
                                        id="testLoginFlow-selector-6"
                                    />
                                </div>
                                <p
                                    className="inputLabel2"
                                    style={{ cursor: "pointer" }}
                                    onClick={onRequestLink}
                                >
                                    Resend code
                                </p>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className="resetPasswordBtn"
                                    id="testLoginFlow-selector-7"
                                    onClick={onVerifyCode}
                                >
                                    Submit
                                </Button>
                                <p
                                    className="backBtn"
                                    onClick={handleBackScreen1}
                                >
                                    Back
                                </p>
                            </>
                        ) : null}
                        {handleScreen === 2 ? (
                            <>
                                <div style={{ marginBottom: "20px" }}>
                                    <p className="inputLabel3">New Password</p>
                                    <div
                                        style={{
                                            position: "relative",
                                            marginBottom: 10,
                                        }}
                                    >
                                        <input
                                            required
                                            className="signinInput"
                                            type={
                                                showForgotPassword.new
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder={
                                                "Enter your new password"
                                            }
                                            value={forgotPassword.new}
                                            onChange={(e) =>
                                                setForgotPassword({
                                                    ...forgotPassword,
                                                    new: e.target.value,
                                                })
                                            }
                                            id="testLoginFlow-selector-8"
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                right: isSafari ? 40 : 15,
                                                top: 15,
                                                fontSize: 12,
                                                color: mediaQueryMobile.matches
                                                    ? "#fff"
                                                    : "#479bd2",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setShowForgotPassword({
                                                    ...showForgotPassword,
                                                    new: !showForgotPassword.new,
                                                })
                                            }
                                        >
                                            {showForgotPassword.new
                                                ? "Hide"
                                                : "Show"}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "20px" }}>
                                    <p className="inputLabel3">
                                        Confirm Password
                                    </p>
                                    <div
                                        style={{
                                            position: "relative",
                                            marginBottom: 10,
                                        }}
                                    >
                                        <input
                                            required
                                            placeholder={
                                                "Please confirm your new password"
                                            }
                                            className="signinInput"
                                            type={
                                                showForgotPassword.confirm
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={forgotPassword.confirm}
                                            onChange={(e) =>
                                                setForgotPassword({
                                                    ...forgotPassword,
                                                    confirm: e.target.value,
                                                })
                                            }
                                            id="testLoginFlow-selector-9"
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                right: isSafari ? 40 : 15,
                                                top: 15,
                                                fontSize: 12,
                                                color: mediaQueryMobile.matches
                                                    ? "#fff"
                                                    : "#479bd2",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setShowForgotPassword({
                                                    ...showForgotPassword,
                                                    confirm:
                                                        !showForgotPassword.confirm,
                                                })
                                            }
                                        >
                                            {showForgotPassword.confirm
                                                ? "Hide"
                                                : "Show"}
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className="resetPasswordBtn"
                                    id="testLoginFlow-selector-10"
                                    onClick={onVerifyPassword}
                                >
                                    Submit
                                </Button>
                            </>
                        ) : null}
                        <div
                            style={{
                                minHeight: 55,
                                margin: "1rem",
                            }}
                        >
                            {isLoading ? <Loader /> : null}
                            {errorMsg ? <Error errorMsg={errorMsg} /> : null}
                        </div>
                    </div>
                </div>
            </form>
            <div className="singinBg">
                <div className="signinContainer">
                    <div className="signinRightText">
                        <h1>Assess more</h1>
                        <h1>Interview less</h1>
                        <h1>Hire better</h1>
                        <h2 className="efficientHiring">
                            Your efficient hiring toolkit
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: getAuthLoading(),
    errorMsg: getAuthErrorMsg(),
    pwdVerifyStatus: getPwdCodeVerificationStatus(),
    verifyCodeSuccess: getVerifyCodeSuccess(),
});

const mapDispatchToProps = (dispatch) => ({
    sendForgotPasswordLink: (data) =>
        dispatch(sendForgotPasswordLinkRequest(data)),
    userSignIn: (data) => dispatch(userSignInRequest(data)),
    setAuthErrorMsg: (data) => dispatch(setAuthErrorMsgRequest(data)),
    changeUserPassword: (data) => dispatch(changeUserPasswordRequest(data)),
    verificationCode: (data) => dispatch(verificationCodeRequest(data)),
});

SignIn.propTypes = {
    userSignIn: PropTypes.func,
    setAuthErrorMsg: PropTypes.func,
    isLoading: PropTypes.bool,
    errorMsg: PropTypes.string,
    sendForgotPasswordLink: PropTypes.func,
    changeUserPassword: PropTypes.func,
    verificationCode: PropTypes.func,
    pwdVerifyStatus: PropTypes.bool,
    verifyCodeSuccess: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

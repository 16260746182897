import React, { useEffect, useState } from "react";
import Button from "../components/common/Button";
import Loader from "../components/common/Loader";
import axios from "../utils/axios";

import "../styles/Integrations.scss";

import LeverLogo from "../assets/lever-logo.png";
import ZohoRecruitLogo from "../assets/zoho-recruit-logo.png";

const availableIntegrations = [
    {
        id: "lever",
        name: "Lever",
        img: LeverLogo,
        desc: "Lever is a top-rated recruiting software that helps your talent teams scale recruitment efforts and hire people faster.",
        authorizationLink: `${
            process.env.REACT_APP_LEVER_AUTH_LINK
        }?client_id=${process.env.REACT_APP_LEVER_CLIENT_ID}&redirect_uri=${
            process.env.REACT_APP_BASE_URL
        }/api/lever?company=${localStorage.getItem(
            "company"
        )}&state=wnGQ2w&response_type=code&scope=opportunities:write:admin%20stages:read:admin%20notes:write:admin%20webhooks:write:admin%20offline_access&prompt=consent&audience=${
            process.env.REACT_APP_LEVER_AUDIENCE
        }`,
    },
    {
        id: "zoho",
        name: "Zoho Recruit",
        img: ZohoRecruitLogo,
        desc: `Zoho Recruit is a cloud based applicant tracking system that’s built to provide diverse, end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce.`,
        authorizationLink: `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoRecruit.modules.candidate.READ,ZohoRecruit.modules.candidate.UPDATE,ZohoRecruit.modules.custom.ALL&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${process.env.REACT_APP_BASE_URL}/api/zoho`,
    },
];

const Integrations = () => {
    const [atsTokens, setAtsTokens] = useState({
        lever: false,
        zoho: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .get("/get-ats-tokens")
            .then((resp) => {
                setAtsTokens({
                    zoho: resp.data.zoho,
                    lever: resp.data.lever,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleAuthorization = (id, link) => {
        if (id === "zoho" && atsTokens[id]) {
            axios
                .get("/revoke-ats-tokens/zoho")
                .then((res) =>
                    setAtsTokens({
                        ...atsTokens,
                        zoho: res.data.zoho,
                    })
                )
                .catch((err) => console.log(err));
            return;
        }
        window.location.href = link;
    };

    return (
        <div className="integration-page">
            {availableIntegrations.map((integration, index) => (
                <div key={index} className="integration-wrapper">
                    <div className="integration-wrapper-row">
                        <div className="integration-wrapper-row-left">
                            <img
                                src={integration.img}
                                alt={integration.name}
                                className="integration-wrapper-row-left-img"
                            />
                            <p className="integration-wrapper-row-left-name">
                                {integration.name}
                            </p>
                        </div>
                        <Button
                            size={"small"}
                            variant={
                                integration.id === "zoho" &&
                                atsTokens[integration.id]
                                    ? "outlined"
                                    : "contained"
                            }
                            color={
                                loading
                                    ? "primary"
                                    : atsTokens[integration.id]
                                    ? integration.id === "zoho"
                                        ? "error"
                                        : "success"
                                    : "primary"
                            }
                            disabled={
                                loading ||
                                (integration.id !== "zoho" &&
                                    atsTokens[integration.id])
                            }
                            onClick={() =>
                                handleAuthorization(
                                    integration.id,
                                    integration.authorizationLink
                                )
                            }
                        >
                            {loading ? (
                                <Loader size={20} style={{ margin: 0 }} />
                            ) : atsTokens[integration.id] ? (
                                integration.id === "zoho" ? (
                                    "Revoke"
                                ) : (
                                    "Connected"
                                )
                            ) : (
                                "Connect"
                            )}
                        </Button>
                    </div>
                    <p className="integration-wrapper-desc">
                        {integration.desc}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default Integrations;

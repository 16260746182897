import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../components/common/Button";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import { formatDDMMYYYY } from "../utils/timeSince";
import AccountMenu from "../components/AccountMenu";
import AddPreScreeningModal from "../components/preScreeningBot/AddPreScreeningModal";
import BottomBar from "../components/common/BottomBar";
import DeletePreScreeningModal from "../components/preScreeningBot/DeletePreScreeningModal";
import EditPreScreeningBotNameModal from "../components/preScreeningBot/EditPreScreeningBotNameModal";
import EditPreScreeningModal from "../components/preScreeningBot/EditPreScreeningModal";
import Header from "../components/header/header";
import IconButton from "../components/common/IconButton";
import Loader from "../components/common/Loader";
import Modal from "../components/common/CustomModal";
import NotificationBell from "../components/notifications/NotificationBell";
import Snackbar from "../components/common/Snackbar";
import NoRowsOverlay from "../components/common/NoRowsOverlay";
import WelcomePageModal from "../components/preScreeningBot/WelcomePageModal";
import {
    addPreScreeningBot as addPreScreeningBotRequest,
    editPreScreeningBot as editPreScreeningBotRequest,
    deletePreScreeningBot as deletePreScreeningBotRequest,
    fetchPreScreeningBots as fetchPreScreeningBotsRequest,
    sendPreScreeningTest as sendPreScreeningTestRequest,
    fetchCompanyDetails as fetchCompanyDetailsRequest,
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
    updateWalkthroughFlows as updateWalkthroughFlowsRequest,
} from "../actions/app";
import {
    getPreScreeningBots,
    getCompanyDetails,
    getCurrentWalkthroughStep,
    getCurrentWalkthroughType,
} from "../selectors/app";

import Edit from "../assets/Icons/Edit.png";
import Trash from "../assets/Icons/Trash.png";
import TickBlueIcon from "../assets/right-blue-icon.svg";

import "../styles/preScreeningDashboard.scss";
import DataTable from "../components/common/DataTable";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";

const PreScreeningDashboard = (props) => {
    const {
        notification,
        preScreeningBots,
        deletePreScreeningBot,
        addPreScreeningBot,
        fetchPreScreeningBots,
        editPreScreeningBot,
        sendPreScreeningTest,
        companyDetails,
        fetchCompanyDetails,
        currentWalkthroughStep,
        updateCurrentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughType,
        updateWalkthroughFlows,
    } = props;

    const navigate = useNavigate();

    //const [searchText, setSearchText] = useState('');
    const [tableRows, setTableRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [snackErrMsg, setSnackErrMsg] = useState(null);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackSuccessMsg, setSnackSuccessMsg] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editNameModal, setEditNameModal] = useState(false);
    //const [pageSize, setPageSize] = useState(15);
    const [selectedRow, setSelectedRow] = useState([]);
    const [editName, setEditName] = useState("");
    const [addBotModal, setAddBotModal] = useState(false);
    const [editBotModal, setEditBotModal] = useState(false);
    const [editRow, setEditRow] = useState({});
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");
    const [decideScreen, setDecideScreen] = useState(0);
    const [openWelcomeModal, setOpenWelcomeModal] = useState(false);

    useEffect(() => {
        fetchPreScreeningBots({ navigate });
        fetchCompanyDetails({ companyId: localStorage.getItem("company") });
    }, []);

    useEffect(() => {
        const newTableRows = [];
        preScreeningBots.forEach((psb, i) => {
            const preScreeningBotObj = { ...psb };
            preScreeningBotObj["id"] = i + 1;
            preScreeningBotObj["createdByName"] = psb.createdBy
                ? psb.createdBy.name
                : "";
            newTableRows.push(preScreeningBotObj);
        });

        setTableRows([...newTableRows.reverse()]);
    }, [preScreeningBots]);

    useEffect(() => {
        if (selectionModel.length === 0) setSelectedRow([]);
        if (selectionModel.length === 1) {
            const editableRow = tableRows.filter(
                (row) => selectionModel[0] === row.id
            );
            setSelectedRow([...editableRow]);
            setEditName(editableRow[0].name);
        } else {
            let temp = [];

            for (let i = 0; i < selectionModel.length; i++) {
                temp.push(
                    ...tableRows.filter((row) => selectionModel[i] === row.id)
                );
            }

            setSelectedRow(temp);
        }
    }, [selectionModel]);

    const checkPrescreeningDetails = () => {
        if (
            companyDetails.prescreeningLogo !== "" &&
            companyDetails.prescreeningTitle !== "" &&
            companyDetails.prescreeningAbout !== "" &&
            companyDetails.prescreeningImage !== "" &&
            companyDetails.prescreeningVideo !== "" &&
            Object.values(companyDetails.socialLinks).some((val) => val !== "")
        ) {
            return true;
        } else return false;
    };

    const nameEdit = (row) => {
        const editNameData = {
            botId: row._id,
            name: editName,
            about: row.about,
            questionData: [...row.questionData],
        };

        editPreScreeningBot({ botData: editNameData, navigate });
        onCloseEditNameModal();
        setSnackSuccessMsg("Pre-Screening Bot Name edited Successfully");
        handleSuccessSnackClick();
    };

    const onOpenDeleteModal = () => {
        if (mediaQueryMobile.matches && selectedRow.length > 0) {
            setDeleteModal(true);
        } else {
            if (selectionModel.length === 0) {
                setSnackErrMsg("Please select atleast one prescreening bot");
                handleErrSnackClick();
            } else setDeleteModal(true);
        }
    };

    const onCloseDeleteModal = () => setDeleteModal(false);

    const onOpenEditNameModal = () => {
        if (mediaQueryMobile.matches) {
            if (selectedRow.length > 1 || selectedRow.length === 0) {
                setSnackErrMsg("Please select a prescreening bot");
                handleErrSnackClick();
            } else {
                setEditNameModal(true);
                setEditName(selectedRow[0].name);
            }
        } else {
            if (selectionModel.length === 1) setEditNameModal(true);
            else {
                setSnackErrMsg("Please select a prescreening bot");
                handleErrSnackClick();
            }
        }
    };

    const onCloseEditNameModal = () => setEditNameModal(false);

    const onOpenAddBotModal = () => {
        if (mediaQueryMobile.matches) {
            setDecideScreen(1);
        } else {
            setAddBotModal(true);
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "prescreening"
            ) {
                updateCurrentWalkthroughStep(2);
            }
        }
    };

    const onCloseAddBotModal = () => {
        if (mediaQueryMobile.matches) {
            setDecideScreen(0);
        } else {
            setAddBotModal(false);
        }
    };

    const onOpenEditBotModal = (e, val) => {
        if (e) {
            e.stopPropagation();
        }
        if (mediaQueryMobile.matches) {
            if (selectedRow.length === 0) {
                setSnackErrMsg("Please select a prescreening bot");
                handleErrSnackClick();
            } else if (selectedRow.length > 1) {
                setSnackErrMsg("Please select only one prescreening bot");
                handleErrSnackClick();
            } else {
                setEditRow(val[0]);
                setDecideScreen(2);
            }
        } else {
            setSelectedRow(val);
            setEditBotModal(true);
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "prescreening"
            ) {
                updateCurrentWalkthroughStep(0);
                updateCurrentWalkthroughType("");
                let wArr = [];
                if (localStorage.getItem("walkthroughFlows")) {
                    wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
                }
                wArr.push("prescreening");
                localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
                updateWalkthroughFlows(wArr);
            }
        }
    };

    const onCloseEditBotModal = () => {
        if (mediaQueryMobile.matches) {
            setDecideScreen(0);
        } else {
            setEditBotModal(false);
        }
    };

    const onOpenWelcomePageModal = () => {
        setOpenWelcomeModal(true);
    };

    const onCloseWelcomePageModal = () => {
        setOpenWelcomeModal(false);
    };

    const handleDelete = (rowSelected) => {
        const IDs = [];
        rowSelected.forEach((val) => {
            IDs.push(val._id);
        });
        deletePreScreeningBot({ id: IDs, navigate });
        setSnackSuccessMsg("Pre-Screening Bot deleted Successfully");
        handleSuccessSnackClick();
    };

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleErrSnackClose = () => {
        setErrSnackOpen(false);
    };

    const handleSuccessSnackClick = () => {
        setSuccessSnackOpen(true);
    };

    const handleSuccessSnackClose = () => {
        setSuccessSnackOpen(false);
    };

    // const requestSearch = (searchValue) => {
    // 	setSearchText(searchValue);

    // 	if (searchValue === '') {
    // 		setTableRows(preScreeningBots);
    // 	} else {
    // 		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    // 		const filteredRows = preScreeningBots.filter((row) => {
    // 			return Object.keys(row).some((field) => {
    // 				return searchRegex.test(row[field].toString());
    // 			});
    // 		});

    // 		setTableRows(filteredRows);
    // 	}
    // };

    const onClickTest = (e, val) => {
        e.stopPropagation();
        const botData = {
            botId: val._id,
        };

        sendPreScreeningTest({ botData, navigate });
        setSnackSuccessMsg("Mail with test link sent successfully");
        handleSuccessSnackClick();
    };

    const columns = [
        // {
        // 	field: "id",
        // 	headerName: "Sr. No",
        // 	flex: 1,
        // 	renderCell: (params) => {
        // 		return (
        // 			<div style={{ marginRight: 25 }}>
        // 				{params.row.id}
        // 			</div>
        // 		)
        // 	}
        // },
        {
            field: "name",
            headerName: "Name of Pre-screening",
            width: window.innerWidth / 5,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: "400",
                            width: "100%",
                        }}
                    >
                        <div className="tooltip" style={{ width: "100%" }}>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {params.row.name}
                            </div>
                            <span className="tooltiptext datagrid">
                                {params.row.name}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "createdByName",
            headerName: "Created by",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: "400",
                            width: "100%",
                        }}
                    >
                        <div className="tooltip" style={{ width: "100%" }}>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {params.row.createdByName}
                            </div>
                            <span className="tooltiptext datagrid">
                                {params.row.createdByName}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "createdDate",
            headerName: "Date created",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: "400",
                            marginRight: "2ch",
                        }}
                    >
                        {formatDDMMYYYY(params.row.createdDate)}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{ justifyContent: "center", marginRight: "2ch" }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                backgroundColor: "#479BD2",
                                padding: "4px 25px",
                                color: "#fff",
                                boxShadow: "0px 4px 10px 0px #109CF13D",
                                fontSize: 14,
                                fontWeight: "600",
                                borderRadius: 15,
                                cursor: "pointer",
                                lineHeight: "20px",
                                fontFamily: "inherit",
                            }}
                            className="prescreening-selector-6 tooltip"
                            onClick={(e) => onOpenEditBotModal(e, params.row)}
                        >
                            Edit
                            <span
                                className="tooltiptext"
                                style={{
                                    width: 80,
                                    whiteSpace: "break-spaces",
                                }}
                            >
                                Edit the pre-screening questions
                            </span>
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                backgroundColor: "#479BD2",
                                padding: "4px 25px",
                                color: "#fff",
                                boxShadow: "0px 4px 10px 0px #109CF13D",
                                fontSize: 14,
                                fontWeight: "600",
                                borderRadius: 15,
                                cursor: "pointer",
                                lineHeight: "20px",
                                fontFamily: "inherit",
                                marginLeft: 15,
                            }}
                            className="prescreening-selector-7 tooltip"
                            onClick={(e) => onClickTest(e, params.row)}
                        >
                            Test
                            <span
                                className="tooltiptext"
                                style={{
                                    width: 100,
                                    whiteSpace: "break-spaces",
                                }}
                            >
                                Send a test email to yourself with this
                                pre-screening bot
                            </span>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleSelectApplicant = (row) => {
        if (
            selectedRow.length !== 0 &&
            selectedRow.some((el) => el._id === row._id)
        ) {
            setSelectedRow(selectedRow.filter((el) => el._id !== row._id));
        } else {
            setSelectedRow([...selectedRow, row]);
        }
    };

    const MenuItems = [
        {
            name: "Rename",
            action: () => onOpenEditNameModal(),
        },
        {
            name: "Edit",
            action: () => onOpenEditBotModal(null, selectedRow),
        },
        {
            name: "Delete",
            action: () => onOpenDeleteModal(),
        },
    ];

    return (
        <div className="pre-screening-dashboard">
            {mediaQueryMobile.matches && <Header MenuItems={MenuItems} />}
            <div className="inner-div" style={{ marginBottom: 15 }}>
                <div className="top-icon-box">
                    <WalkthroughWelcome />
                    <div className="tooltip">
                        <IconButton
                            aria-label="edit"
                            onClick={onOpenEditNameModal}
                            id="prescreening-selector-1"
                        >
                            <img src={Edit} alt="" width={30} height={30} />
                        </IconButton>
                        <span className="tooltiptext">Edit</span>
                    </div>
                    <div className="tooltip">
                        <IconButton
                            onClick={onOpenDeleteModal}
                            aria-label="delete"
                            id="prescreening-selector-4"
                        >
                            <img src={Trash} alt="" width={30} height={30} />
                        </IconButton>
                        <span className="tooltiptext">Delete</span>
                    </div>
                    <NotificationBell notification={notification} />
                    <AccountMenu />
                </div>
            </div>
            {!mediaQueryMobile.matches && (
                <div className="pre-screeening-wrapper">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    color: "#334d6e",
                                    fontWeight: "700",
                                    fontSize: 18,
                                }}
                            >
                                Pre-screening Stage
                            </div>
                            <div
                                style={{
                                    color: "#90A0B7",
                                    fontSize: 15,
                                    fontWeight: "600",
                                }}
                            >
                                Make your own pre-screening tests
                            </div>
                        </div>
                        <div
                            className="add-prescreen-btn"
                            style={{ position: "relative" }}
                        >
                            {/* <div style={{ marginRight: 20, backgroundColor: '#479BD2', padding: '8px 32px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15 }}>Chatbot Bot</div> */}
                            <Button
                                className="tooltip"
                                size="small"
                                variant="contained"
                                style={{
                                    marginRight: "1rem",
                                    backgroundColor: "#479BD2",
                                    padding: "8px 32px",
                                    color: "#fff",
                                    boxShadow: "0px 4px 10px 0px #109CF13D",
                                    fontSize: 14,
                                    fontWeight: "600",
                                    borderRadius: 15,
                                    cursor: "pointer",
                                    fontFamily: "inherit",
                                }}
                                onClick={onOpenWelcomePageModal}
                            >
                                {checkPrescreeningDetails ? "Edit" : "Create"}{" "}
                                Welcome Page
                                <span
                                    className="tooltiptext"
                                    style={{
                                        width: 300,
                                        top: "110%",
                                        marginLeft: "-150px",
                                    }}
                                >
                                    Create a welcome page in your company’s
                                    branding to share more about the company
                                    with candidates
                                </span>
                            </Button>
                            <Button
                                id="prescreeningAddBot1"
                                className="tooltip"
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: mediaQueryMobile.matches
                                        ? "#197BBD"
                                        : "#479BD2",
                                    padding: "8px 32px",
                                    color: "#fff",
                                    boxShadow: "0px 4px 10px 0px #109CF13D",
                                    fontSize: 14,
                                    fontWeight: "600",
                                    borderRadius: mediaQueryMobile.matches
                                        ? 8
                                        : 15,
                                    cursor: "pointer",
                                    fontFamily: "inherit",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "auto",
                                    position: "relative",
                                    zIndex:
                                        currentWalkthroughStep === 1 &&
                                        currentWalkthroughType ===
                                            "prescreening"
                                            ? 1000
                                            : 1,
                                }}
                                onClick={onOpenAddBotModal}
                            >
                                Add New
                                <span
                                    className="tooltiptext"
                                    style={{
                                        marginLeft: "-100px",
                                        top: "110%",
                                        width: "max-content",
                                    }}
                                >
                                    Create a new pre-screening bot
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            height: "100%",
                            padding: 10,
                            background: "#fff",
                            borderRadius: 25,
                            overflow: "hidden",
                            flex: 1,
                            marginTop: 10,
                        }}
                    >
                        <DataTable
                            loading={false}
                            noRowsComponent={<NoRowsOverlay />}
                            rows={tableRows}
                            columns={columns}
                            checkboxSelection={true}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            pageSize={15}
                            highlightedBox={
                                currentWalkthroughStep === 10 &&
                                currentWalkthroughType === "prescreening"
                            }
                        />
                    </div>
                </div>
            )}
            {mediaQueryMobile.matches && decideScreen === 0 && (
                <div className="pre-screeening-wrapper">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    color: "#334d6e",
                                    fontWeight: "700",
                                    fontSize: 18,
                                }}
                            >
                                Pre-screening Stage
                            </div>
                            <div
                                style={{
                                    color: "#90A0B7",
                                    fontSize: 15,
                                    fontWeight: "600",
                                }}
                            >
                                Make your own pre-screening tests
                            </div>
                        </div>
                        <div className="add-prescreen-btn">
                            {/* <div style={{ marginRight: 20, backgroundColor: '#479BD2', padding: '8px 32px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15 }}>Chatbot Bot</div> */}
                            <Button
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: mediaQueryMobile.matches
                                        ? "#197BBD"
                                        : "#479BD2",
                                    padding: "8px 32px",
                                    color: "#fff",
                                    boxShadow: "0px 4px 10px 0px #109CF13D",
                                    fontSize: 14,
                                    fontWeight: "600",
                                    borderRadius: mediaQueryMobile.matches
                                        ? 8
                                        : 15,
                                    cursor: "pointer",
                                    fontFamily: "inherit",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "auto",
                                }}
                                onClick={onOpenAddBotModal}
                            >
                                Add New
                            </Button>
                        </div>
                    </div>
                    <div className="applicant-box__content">
                        {tableRows.length === 0 ? <Loader /> : null}
                        {tableRows.map((row, index) => (
                            <div
                                key={index}
                                className={`${
                                    selectedRow.length !== 0 &&
                                    selectedRow.some((el) => el._id === row._id)
                                        ? "prescreening-box__row-active"
                                        : ""
                                } prescreening-box__row`}
                                onClick={() => handleSelectApplicant(row)}
                            >
                                <div className="prescreening-box__row-left">
                                    {selectedRow.length !== 0 &&
                                        selectedRow.some(
                                            (el) => el._id === row._id
                                        ) && (
                                            <img
                                                src={TickBlueIcon}
                                                alt="right-blue-icon"
                                            />
                                        )}
                                    <div className="prescreening-box-row-left-text-box">
                                        <p className="prescreening-box__row-left-text-name">
                                            {row.name}
                                        </p>
                                        <p className="prescreening-box__row-left-text-role">
                                            {row.createdBy
                                                ? row.createdBy.name
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                                <div className="prescreening-box__row-right">
                                    <p className="prescreening-box__row-left-text-role">
                                        {formatDDMMYYYY(row.createdDate)}
                                    </p>
                                </div>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="prescreening-box__row-cta"
                                    onClick={(e) => onClickTest(e, row)}
                                >
                                    <path
                                        d="M26.25 3.75V2.25H27.75V3.75H26.25ZM14.8107 17.3107C14.2249 17.8964 13.2751 17.8964 12.6893 17.3107C12.1036 16.7249 12.1036 15.7751 12.6893 15.1893L14.8107 17.3107ZM24.75 13.75V3.75H27.75V13.75H24.75ZM26.25 5.25H16.25V2.25H26.25V5.25ZM27.3107 4.81066L14.8107 17.3107L12.6893 15.1893L25.1893 2.68934L27.3107 4.81066Z"
                                        fill="#197BBD"
                                    />
                                    <path
                                        d="M25 18.75V19C25 21.8284 25 23.2426 24.1213 24.1213C23.2426 25 21.8284 25 19 25H11C8.17157 25 6.75736 25 5.87868 24.1213C5 23.2426 5 21.8284 5 19V11C5 8.17157 5 6.75736 5.87868 5.87868C6.75736 5 8.17157 5 11 5H11.25"
                                        stroke="#197BBD"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <DeletePreScreeningModal
                deleteModal={deleteModal}
                onCloseDeleteModal={onCloseDeleteModal}
                handleDelete={handleDelete}
                selectedRow={selectedRow}
            />
            <EditPreScreeningBotNameModal
                editNameModal={editNameModal}
                onCloseEditNameModal={onCloseEditNameModal}
                selectedRow={selectedRow}
                setEditName={setEditName}
                editName={editName}
                nameEdit={nameEdit}
            />
            <WelcomePageModal
                welcomePageModal={openWelcomeModal}
                onCloseWelcomePageModal={onCloseWelcomePageModal}
                companyDetails={companyDetails}
                checkPrescreeningDetails={checkPrescreeningDetails}
            />
            {!mediaQueryMobile.matches && (
                <>
                    <Modal
                        open={addBotModal}
                        onClose={onCloseAddBotModal}
                        center
                        closeOnOverlayClick={false}
                        classNames={{ modal: "addPrescreeeningBotModal" }}
                    >
                        <AddPreScreeningModal
                            addBotModal={addBotModal}
                            onCloseAddBotModal={onCloseAddBotModal}
                            addPreScreeningBot={addPreScreeningBot}
                            setSnackSuccessMsg={setSnackSuccessMsg}
                            handleSuccessSnackClick={handleSuccessSnackClick}
                            setSnackErrMsg={setSnackErrMsg}
                            handleErrSnackClick={handleErrSnackClick}
                            currentWalkthroughStep={currentWalkthroughStep}
                            currentWalkthroughType={currentWalkthroughType}
                            updateCurrentWalkthroughStep={
                                updateCurrentWalkthroughStep
                            }
                        />
                    </Modal>
                    <Modal
                        open={editBotModal}
                        onClose={onCloseEditBotModal}
                        center
                        closeOnOverlayClick={false}
                        classNames={{ modal: "addPrescreeeningBotModal" }}
                    >
                        <EditPreScreeningModal
                            editBotModal={editBotModal}
                            onCloseEditBotModal={onCloseEditBotModal}
                            editPreScreeningBot={editPreScreeningBot}
                            setSnackSuccessMsg={setSnackSuccessMsg}
                            handleSuccessSnackClick={handleSuccessSnackClick}
                            selectedRow={selectedRow}
                            setSnackErrMsg={setSnackErrMsg}
                            handleErrSnackClick={handleErrSnackClick}
                        />
                    </Modal>
                </>
            )}
            {mediaQueryMobile.matches && decideScreen === 1 && (
                <AddPreScreeningModal
                    addBotModal={true}
                    onCloseAddBotModal={onCloseAddBotModal}
                    addPreScreeningBot={addPreScreeningBot}
                    setSnackSuccessMsg={setSnackSuccessMsg}
                    handleSuccessSnackClick={handleSuccessSnackClick}
                    setSnackErrMsg={setSnackErrMsg}
                    handleErrSnackClick={handleErrSnackClick}
                />
            )}
            {mediaQueryMobile.matches && decideScreen === 2 && (
                <EditPreScreeningModal
                    editBotModal={true}
                    onCloseEditBotModal={onCloseEditBotModal}
                    editPreScreeningBot={editPreScreeningBot}
                    setSnackSuccessMsg={setSnackSuccessMsg}
                    handleSuccessSnackClick={handleSuccessSnackClick}
                    selectedRow={editRow}
                    setSnackErrMsg={setSnackErrMsg}
                    handleErrSnackClick={handleErrSnackClick}
                />
            )}
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={2500}
                onClose={handleSuccessSnackClose}
                msg={snackSuccessMsg ? snackSuccessMsg : ""}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={snackErrMsg ? snackErrMsg : ""}
                type="error"
            />
            {mediaQueryMobile.matches && <BottomBar />}
            {(currentWalkthroughStep === 1 || currentWalkthroughStep === 10) &&
            currentWalkthroughType === "prescreening" ? (
                <WalkthroughOverlay
                    showWalkthroughBackBtn={currentWalkthroughStep !== 10}
                    showWalkthroughNextBtn={currentWalkthroughStep === 10}
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                    }}
                    onClickDone={() => {
                        updateCurrentWalkthroughStep(0);
                        updateCurrentWalkthroughType("");
                        let wArr = [];
                        if (localStorage.getItem("walkthroughFlows")) {
                            wArr = JSON.parse(
                                localStorage.getItem("walkthroughFlows")
                            );
                        }
                        wArr.push("prescreening");
                        localStorage.setItem(
                            "walkthroughFlows",
                            JSON.stringify(wArr)
                        );
                        updateWalkthroughFlows(wArr);
                    }}
                />
            ) : (
                ""
            )}
        </div>
    );
};

PreScreeningDashboard.propTypes = {
    notification: PropTypes.object,
    preScreeningBots: PropTypes.array,
    deletePreScreeningBot: PropTypes.func,
    addPreScreeningBot: PropTypes.func,
    fetchPreScreeningBots: PropTypes.func,
    editPreScreeningBot: PropTypes.func,
    sendPreScreeningTest: PropTypes.func,
    companyDetails: PropTypes.object,
    fetchCompanyDetails: PropTypes.func,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughType: PropTypes.func,
    updateWalkthroughFlows: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    preScreeningBots: getPreScreeningBots(),
    companyDetails: getCompanyDetails(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
});

const mapDispatchToProps = (dispatch) => ({
    deletePreScreeningBot: (data) =>
        dispatch(deletePreScreeningBotRequest(data)),
    addPreScreeningBot: (data) => dispatch(addPreScreeningBotRequest(data)),
    editPreScreeningBot: (data) => dispatch(editPreScreeningBotRequest(data)),
    fetchPreScreeningBots: (data) =>
        dispatch(fetchPreScreeningBotsRequest(data)),
    sendPreScreeningTest: (data) => dispatch(sendPreScreeningTestRequest(data)),
    fetchCompanyDetails: (companyDetails) =>
        dispatch(fetchCompanyDetailsRequest(companyDetails)),
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) =>
        dispatch(updateCurrentWalkthroughTypeRequest(data)),
    updateWalkthroughFlows: (data) =>
        dispatch(updateWalkthroughFlowsRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreScreeningDashboard);

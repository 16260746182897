import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import "../../styles/Snackbar.scss";

const Snackbar = ({ type, open, msg, onClose, autoHideDuration }) => {
	useEffect(() => {
		if (open) {
			setTimeout(() => {
				onClose();
			}, autoHideDuration);
		}
	}, [open]);

	return (
		<div
			className={`${type === "success" ? "snackbar-success" : "snackbar-error"} ${open ? "snackbar-show" : ""
				} snackbar`}
		>
			<p className="snackbar-text">{msg}</p>
			<p className="snackbar-close-icon" onClick={onClose}>
				&times;
			</p>
		</div>
	);
};

Snackbar.propTypes = {
	type: PropTypes.string,
	open: PropTypes.bool,
	msg: PropTypes.string,
	onClose: PropTypes.func,
	autoHideDuration: PropTypes.number
}

export default Snackbar;

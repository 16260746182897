export function dateToTimeAgo(date) {
	const now = new Date(Date.now());
	const difftime = now.getTime() - new Date(date).getTime();
	const diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
	const [sec, min, hr, day, month] = [
		diffDate.getSeconds(),
		diffDate.getMinutes(),
		diffDate.getHours(),
		diffDate.getDate() - 1,
		diffDate.getMonth(),
	];
	const f = (property, end) =>{
		return`${property} ${end}${property > 1 ? "s" : ""} ago`;
	}
	return month >= 1
		? f(month, "month")
		: day >= 1
		? f(day, "day")
		: hr >= 1
		? f(hr, "hour")
		: min >= 1
		? f(min, "minute")
		: day >= 1
		? f(sec, "second")
		: "";
}

export function formatDDMMYYYY(idate) {
	const date = new Date(idate);
	const year = date.getFullYear();
	let month = date.getMonth()+1;
	let dt = date.getDate();

	if (dt < 10) {
		dt = '0' + dt;
	}
	if (month < 10) {
		month = '0' + month;
	}

	return(`${dt}/${month}/${year}`);
}
import React, { useEffect, useState } from "react";
import Button from '../../components/common/Button';
import Modal from '../../components/common/CustomModal';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from "../../utils/axios";

import "../../styles/ccList.scss";

const EmailNotificationsEditor = (props) => {
	const { handleSuccessSnackClick, setSnackSuccessMsg, emailList } = props;
	const location = useLocation()
	const [ccChanged, setccChanged] = useState(0);
	const [emails, setEmails] = useState([])
	const [val, setVal] = useState()
	const [error, setError] = useState();
	const [open, setOpen] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const mediaQueryMobile = window.matchMedia('(max-width:768px)');

	useEffect(() => {
		const newEmails = [];
		if(emailList) {
			emailList.forEach((email) => {
				newEmails.push(email);
			});
		}
		setEmails(newEmails);
	}, [emailList]);

	const handleKeyDown = (evt) => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = val.trim();

			if (value && isValid(value)) {
				setVal("")
				setEmails([...emails, value])
			}
		}
	}

	const handleClose = () => {
		if (emails.length || (val && val.trim().length)) {
			setOpenConfirmModal(true);
			return
		}
		setOpen(false)
	}
	const onSubmit = (e) => {
		e.preventDefault();

		let data = {
			emails: [...emails],
		}
		if (val && val.trim().length) {
			let value = val.trim()
			if (!isValid(value, true))
				return
			else
				data['emails'].push(value);
		}
		axios.post("/workflow/setemailnotification/" + location.state.workflowId, data).then((res) => {
			console.log(res)
			setccChanged(ccChanged + 1)
			setOpen(false);
			handleSuccessSnackClick();
			setSnackSuccessMsg('Your settings have been saved');
		}).catch(err => {
			console.log(err)
		})
	}

	const handleChange = (evt) => {
		setVal(evt.target.value)
		setError(null)
	};

	const handleDelete = (item) => {
		setEmails(emails.filter(i => i !== item))
	};

	const handlePaste = (evt) => {
		evt.preventDefault();

		var paste = evt.clipboardData.getData("text");
		var newemails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

		if (newemails) {
			var toBeAdded = newemails.filter(email => !isInList(email));
			setEmails([...emails, ...toBeAdded])

		}
	}

	const isValid = (email, cc) => {
		let err = null;

		if (isInList(email)) {
			err = `${email} has already been added.`;
		}
		if (!isEmail(email)) {
			err = `${email} is not a valid email address.`;
		}
		if (err) {
			if (cc) setError(err);
			return false;
		}
		return true;
	}

	const isInList = (email) => {
		return emails.includes(email);
	}

	const isEmail = (email) => {
		return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	}

	return (
		<>
			<div onClick={() => setOpen(true)}>
				Add Email
			</div>
			<Modal open={open} onClose={handleClose}>
				<div className="modal__heading">
					Add Recipients
				</div>
				<div className="mainContainer">
					<div style={{ flexDirection: "column" }}>
						<div className="wrapperCC">
							<div className="wrapperCC-inputEmails">
								{emails.map(item => (
									<div className="save-itemCC" key={item}>
										{item}
										<button
											type="button"
											className="button"
											onClick={() => handleDelete(item)}>

											&times;
										</button>
									</div>
								))}

								<input
									className={"inputCC " + (error && " has-error")}
									value={val}
									placeholder="Type or paste email addresses and press `Enter`..."
									onKeyDown={handleKeyDown}
									onChange={handleChange}
									onPaste={handlePaste}
									style={{ marginTop: 0 }}
								/>

								{error && <p className="errorCC">{error}</p>}
							</div>
						</div>
					</div>
				</div>

				<div className="modal__btnbox" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onSubmit}
					>
						Save changes
					</Button>
				</div>
			</Modal>
			<Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)} center styles={{ modal: { borderRadius: mediaQueryMobile.matches ? '0.5rem' : "50px", padding: mediaQueryMobile.matches ? '1.2rem' : "40px" } }} closeOnOverlayClick={false}>
				<p className="modal-text">
					You have unsaved changes, which will be lost. Do you wish to proceed?
				</p>
				<div className="button-div">
					<Button
						size="small"
						variant="contained"
						className="modal-button"
						onClick={() => {
							setOpen(false)
							setOpenConfirmModal(false)
							setccChanged(ccChanged + 1)
						}}
						style={{
							background: '#479BD2',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							marginLeft: "10px"
						}}
					>
						<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Yes</span>
					</Button>
					<Button
						size="small"
						variant="contained"
						className="cancel-modal-button"
						onClick={() => {
							setOpenConfirmModal(false)
						}}
						style={{
							background: '#479BD2',
							fontFamily: "inherit",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							textTransform: 'none'
						}}
					>
						<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>No</span>
					</Button>
				</div>
			</Modal>
		</>
	)
}

EmailNotificationsEditor.propTypes = {
	setSnackErrMsg: PropTypes.func,
	handleErrSnackClick: PropTypes.func,
	handleSuccessSnackClick: PropTypes.func,
	setSnackSuccessMsg: PropTypes.func,
	emailList: PropTypes.array
}

export default EmailNotificationsEditor;

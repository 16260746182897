import React from "react";
import PropTypes from 'prop-types';

import "../../styles/Avatar.scss";

const Avatar = ({ name, imageUrl, classNames, styles }) => {
	return (
		<div
			className={`${Object.keys(classNames || {}).length > 0 &&
				Object.prototype.hasOwnProperty.call(classNames, "container")
				? classNames.container
				: ""
				} avatar-container`}
			style={Object.keys(styles || {}).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'container') ? styles.container : {}}
		>
			{imageUrl && (
				<img
					className={`${Object.keys(classNames || {}).length > 0 &&
						Object.prototype.hasOwnProperty.call(classNames, "image")
						? `${classNames.image} `
						: ""
						} avatar-image`}
					alt="avatar"
					src={imageUrl}
					style={Object.keys(styles || {}).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'image') ? styles.image : {}}
				/>
			)}
			{imageUrl === undefined && (
				<div
					className={`${Object.keys(classNames || {}).length > 0 &&
						Object.prototype.hasOwnProperty.call(classNames, "text")
						? `${classNames.text} `
						: ""
						} avatar-text`}
					style={Object.keys(styles || {}
					).length > 0 && Object.prototype.hasOwnProperty.call(styles, 'text') ? styles.text : {}}
				>
					{(typeof name === "string" && name !== "")
						? `${name.split(' ')[0][0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ''}`
						: name
					}
				</div>
			)}
		</div>
	);
};

Avatar.propTypes = {
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imageUrl: PropTypes.string,
	classNames: PropTypes.object,
	styles: PropTypes.object
}

export default Avatar;

import React, { useEffect, useState } from 'react';
import IconButton from "../../components/common/IconButton";
import PropTypes from 'prop-types';

import { dateToTimeAgo } from '../../utils/timeSince';

import notificationcircle from '../../assets/notificationcircle.svg';
import notificationcircleGrey from '../../assets/notification-circle-grey.svg';
import notificationsRead from '../../assets/notifications-read.png';

const ReadNotificationTab = (props) => {
	const { notifications } = props;

	const [readNotifications, setReadNotifications] = useState([]);

	useEffect(() => {
		const newReadNotifications = [];
		notifications.forEach((item) => {
			if (item.status === 'Read') {
				newReadNotifications.push(item);
			}
		});
		setReadNotifications([...newReadNotifications]);
	}, [notifications]);

	return (
		<>
			{
				readNotifications.length > 0 ? (readNotifications.map((item) => (
					<div className='notification-card' key={item.notificationId}>
						<div className='notification-card-topbar'>
							<div style={{ display: "flex", alignItems: "center" }}>
								<img src={item.status == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: 20, height: 20 }} />
								<p style={{ marginLeft: 10, fontSize: 16, fontWeight: "600" }}>
									{
										item.type === 'candidateSubmit' ? 'Submission Received' : 'New Message'
									}
								</p>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<p style={{ marginRight: 20, fontSize: 14 }}>{dateToTimeAgo(item.createdDate)}</p>
								<IconButton style={{ width: 36, height: 36 }}>
									<img src={notificationsRead} style={{ width: 20 }} alt="" />
								</IconButton>
							</div>
						</div>
						<div className='notification-card-body'>
							<p style={{ fontSize: 12 }}>{item.message || item.text}</p>
						</div>
					</div>
				))) : (
					<div>No notifications</div>
				)
			}
		</>
	)
}

ReadNotificationTab.propTypes = {
	notifications: PropTypes.array,
	updateNotificationStatus: PropTypes.func
}

export default ReadNotificationTab;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import AppRouter from "./AppRouter";
import store from "./store";

import "./styles/App.scss";
import "./styles/Common.scss";

const App = () => {
    return (
        <Provider store={store}>
            <div className="container">
                <Router>
                    <AppRouter />
                </Router>
            </div>
        </Provider>
    );
};

export default App;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Button from "../common/Button";
import Snackbar from "../common/Snackbar";
import Loader from "../common/Loader";

import { redirectUnauthorisedUser } from "../../helper/helperFunction";

const VideoCard = (props) => {
    const {
        ques,
        src,
        score,
        cid,
        notes,
        candidateData,
        mediaType,
        updateVideoSubmissionScore,
    } = props;

    const navigate = useNavigate();

    const [videoData, setVideoData] = useState({
        score: 0,
        notes: "",
    });
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [greenSnackOpen, setGreenSnackOpen] = useState(false);

    useEffect(() => {
        setVideoData({
            score: score,
            notes: notes,
        });
    }, [score, notes]);

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleErrSnackClose = (_, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrSnackOpen(false);
    };

    const handleGreenSnackClick = () => {
        setGreenSnackOpen(true);
    };

    const handleGreenSnackClose = (_, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setGreenSnackOpen(false);
    };

    const handleSuccessSaveNotes = () => {
        if (score != videoData.score) {
            setSuccessMsg("Score saved successfully");
        } else {
            setSuccessMsg("Notes saved successfully");
        }
        handleGreenSnackClick();
        setLoading(false);
    };

    const handleErrorSaveNotes = (err) => {
        setErrMsg("Couldn't save changes at the moment");
        handleErrSnackClick();
        setLoading(false);
        redirectUnauthorisedUser(err, navigate);
    };

    const handleSaveNotes = function () {
        if (videoData.score > 100) {
            setErrMsg("Please enter a valid score");
            handleErrSnackClick();
            return;
        }

        setLoading(true);
        updateVideoSubmissionScore(
            candidateData.id,
            videoData.notes,
            videoData.score,
            cid,
            candidateData.test._id,
            handleSuccessSaveNotes,
            handleErrorSaveNotes
        );
    };

    return (
        <div className="videobox">
            <div className="questionbox">
                <p>
                    {Array.isArray(ques) ? (
                        <>
                            {ques[ques.length - 1]
                                .split("::")
                                .map(function (item, key) {
                                    return (
                                        <span key={key}>
                                            {item
                                                .split("***")
                                                .map(function (item2, key2) {
                                                    return (
                                                        <span key={key2}>
                                                            {
                                                                item2.split(
                                                                    "**"
                                                                )[0]
                                                            }
                                                            <b>
                                                                {
                                                                    item2.split(
                                                                        "**"
                                                                    )[1]
                                                                }
                                                            </b>
                                                        </span>
                                                    );
                                                })}
                                            <br />
                                        </span>
                                    );
                                })}
                        </>
                    ) : (
                        <>{ques.replaceAll("::", " ").replaceAll("*", "")}</>
                    )}
                </p>
            </div>
            {mediaType === "audio" ? (
                <div
                    className="video"
                    dangerouslySetInnerHTML={{
                        __html: `<audio controls='true' playsinline src="${src}" type="audio/mpeg" ></audio>`,
                    }}
                />
            ) : (
                <div
                    className="video"
                    dangerouslySetInnerHTML={{
                        __html: `<video controls='true' playsinline src="${src}" type="video/mp4" ></video>`,
                    }}
                />
            )}
            <div className="card-container">
                <div className="items">
                    <p className="score">Total Score</p>
                    <p className="input percent">
                        <input
                            value={videoData.score}
                            onChange={(e) =>
                                setVideoData({
                                    ...videoData,
                                    score: e.target.value,
                                })
                            }
                            type="number"
                            className="score-input"
                        />
                        %
                    </p>
                </div>
                <hr />
                <div className="items">
                    <textarea
                        placeholder="Notes"
                        value={videoData.notes}
                        onChange={(e) =>
                            setVideoData({
                                ...videoData,
                                notes: e.target.value,
                            })
                        }
                    />
                </div>
                <div
                    style={{
                        paddingLeft: "5px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        style={{
                            fontWeight: "bold",
                            fontFamily: "inherit",
                            letterSpacing: "0.01em",
                            borderRadius: "25px",
                            padding: "5px 20px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={handleSaveNotes}
                        disabled={loading}
                    >
                        Save
                    </Button>
                    {loading && <Loader size={25} />}
                </div>
            </div>
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={errMsg}
                type="error"
            />
            <Snackbar
                open={greenSnackOpen}
                autoHideDuration={2500}
                onClose={handleGreenSnackClose}
                msg={successMsg}
                type="success"
            />
        </div>
    );
};

VideoCard.propTypes = {
    ques: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    src: PropTypes.string,
    score: PropTypes.number,
    cid: PropTypes.number,
    notes: PropTypes.string,
    candidateData: PropTypes.object,
    mediaType: PropTypes.string,
    updateVideoSubmissionScore: PropTypes.func,
};

export default VideoCard;

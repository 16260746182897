import React from "react";
import PropTypes from "prop-types";

import "../../styles/Accordion.scss";

const Accordion = ({ title, subtitle, icon, children, variant = "normal", styles = {}, canOpen }) => {
  const [isOpen, setOpen] = React.useState(canOpen || false);
  return (
    <div
      className={`accordion-wrapper ${
        variant === "analytics-right"
          ? "analytics-right"
          : variant === "analytics-wrong"
          ? "analytics-wrong"
          : variant === 'normal'
          ? "normal"
          : variant === 'chats'
          ? "chats" : ''
      } ${variant === "analytics-right" || variant === 'analytics-wrong' && (isOpen) ? "analytics-open" : ""}`}
    >
      <div
        className={`accordion-header ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        <div className="accordion-header-main">
          {variant === "analytics" ? (
            <img src={icon} alt={title} className="accordion-image" />
          ) : null}
          <div className="accordion-header-wrapper">
            <p className="accordion-header-title" style={styles.title}>
              {variant === 'chats' ? (
                !isOpen && title.length > 200 ? `${title.substring(0, 200)}...` : title
              ) : title}
            </p>
            {variant === "analytics" ? (
              <p className="accordion-header-subtitle">{subtitle}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content" style={styles.content}>{children}</div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.string,
  styles: PropTypes.object,
  canOpen: PropTypes.bool
};

export default Accordion;
import React, { useEffect, useState } from "react";
import IconButton from "../../components/common/IconButton";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { dateToTimeAgo } from "../../utils/timeSince";

import notificationcircle from "../../assets/notificationcircle.svg";
import notificationcircleGrey from "../../assets/notification-circle-grey.svg";
import notificationsRead from "../../assets/notifications-read.png";

const AllNotificationTab = (props) => {
    const { notifications, updateNotificationStatus } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [submittedCount, setSubmittedCount] = useState(0);
    // const [invitedCount, setInvitedCount] = useState(0);
    const [submittedNotificationId, setSubmittedNotificationId] = useState();
    // const [invitedNotificationId, setInvitedNotificationId] = useState();
    const [submittedNotificationStatus, setSubmittedNotificationStatus] =
        useState("Unread");
    // const [invitedNotificationStatus, setInvitedNotificationStatus] = useState('Unread');
    const [submittedNotificationTime, setSubmittedNotificationTime] = useState(
        new Date()
    );
    // const [invitedNotificationTime, setInvitedNotificationTime] = useState(new Date());
    const [allNotifications, setAllNotifications] = useState([]);
    const [roundUpNotifications, setRoundUpNotifications] = useState([]);

    useEffect(() => {
        const newAllNotifications = [];
        const roundUpNotifications = [];
        notifications.forEach((item) => {
            if (item.type == "submitCount") {
                setSubmittedCount(item.text);
                setSubmittedNotificationId(item.notificationId);
                setSubmittedNotificationStatus(item.status);
                setSubmittedNotificationTime(item.createdDate);
                roundUpNotifications.push(item);
            } else if (item.type == "inviteCount") {
                // setInvitedCount(item.text);
                // setInvitedNotificationStatus(item.status);
                // setInvitedNotificationTime(item.createdDate);
                // setInvitedNotificationId(item.notificationId);
            }
            if (item.status !== "Read") {
                newAllNotifications.push(item);
            }
            setRoundUpNotifications([...roundUpNotifications]);
            setAllNotifications([...newAllNotifications]);
        });
    }, []);

    const updateNotification = (notificationId) => {
        let tmp = allNotifications.map((item) => {
            if (item.notificationId != notificationId) return item;
            else
                return {
                    ...item,
                    status: "Read",
                };
        });
        setAllNotifications([...tmp]);
        updateNotificationStatus(notificationId);
    };

    const renderSubmittedNotification = () => {
        return submittedNotificationId ? (
            <div className="notification-card">
                <div className="notification-card-topbar">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={
                                submittedNotificationStatus == "read"
                                    ? notificationcircleGrey
                                    : notificationcircle
                            }
                            alt=""
                            style={{ width: 20, height: 20 }}
                        />
                        <p
                            style={{
                                marginLeft: 10,
                                fontSize: 16,
                                fontWeight: "600",
                            }}
                        >
                            Round up
                        </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 20, fontSize: 14 }}>
                            {dateToTimeAgo(submittedNotificationTime)}
                        </p>
                        {submittedNotificationStatus === "Read" ? (
                            <IconButton>
                                <img
                                    src={notificationsRead}
                                    style={{ width: 20 }}
                                    alt=""
                                />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() =>
                                    updateNotification(submittedNotificationId)
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    width={20}
                                    height={20}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4.5 12.75l6 6 9-13.5"
                                    />
                                </svg>
                            </IconButton>
                        )}
                    </div>
                </div>
                <div className="notification-card-body">
                    <p style={{ fontSize: 12 }}>{submittedCount}</p>
                    {location.state && location.state.workflowId ? (
                        <Link
                            to="/review-applicants"
                            state={{
                                workflowId: location.state.workflowId,
                                workflowName: location.state.workflowName,
                                sidebar: "reviewApplicants",
                            }}
                        >
                            View full list
                        </Link>
                    ) : null}
                </div>
            </div>
        ) : (
            ""
        );
    };

    // const renderInvitedNotification = () => {
    // 	return (
    // 		location.state && location.state.workflowId ? (
    // 			<div className='notification-card'>
    // 				<div className='notification-card-topbar'>
    // 					<div style={{ display: "flex", alignItems: "center" }}>
    // 						<img src={submittedNotificationStatus == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: "20px", height: "20px" }} />
    // 						<p style={{ marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>Invitations</p>
    // 					</div>
    // 					<div style={{ display: "flex", alignItems: "center" }}>
    // 						<p style={{ marginRight: 50 }}>{dateToTimeAgo(invitedNotificationTime)}</p>
    // 						{
    // 							invitedNotificationStatus === 'Read' ? (
    // 								<IconButton>
    // 									<img src={notificationsRead} style={{ width: 20, height: 20 }} alt="" />
    // 								</IconButton>
    // 							) : (
    // 								<IconButton onClick={() => updateNotification(invitedNotificationId)}>
    // 									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
    // 										<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    // 									</svg>
    // 								</IconButton>
    // 							)
    // 						}
    // 					</div>
    // 				</div>
    // 				<div className='notification-card-body'>
    // 					<p>{invitedCount}</p>
    // 					<Link
    // 						to={{
    // 							pathname: "/new-applicants",
    // 							state: location.state ? (
    // 								{
    // 									workflowId: location.state.workflowId,
    // 									workflowName: location.state.workflowName,
    // 									sidebar: "invitations"
    // 								}
    // 							) : {}
    // 						}}>
    // 						View full list
    // 					</Link>
    // 				</div>
    // 			</div>
    // 		) : ''
    // 	)
    // }

    const onClickNotification = (workflowId) => {
        navigate("/review-applicants", {
            state: {
                workflowId,
                sidebar: "reviewApplicants",
            },
        });
    };

    return (
        <>
            {roundUpNotifications.length > 0
                ? renderSubmittedNotification()
                : null}
            {allNotifications.length > 0 ? (
                allNotifications.map((item) =>
                    item.type === "submitCount" ? (
                        ""
                    ) : item.type === "inviteCount" ? (
                        ""
                    ) : (
                        <div
                            className="notification-card"
                            key={item.notificationId}
                        >
                            <div className="notification-card-topbar">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        onClickNotification(item.workflow)
                                    }
                                >
                                    <img
                                        src={
                                            item.status == "read"
                                                ? notificationcircleGrey
                                                : notificationcircle
                                        }
                                        alt=""
                                        style={{ width: 20, height: 20 }}
                                    />
                                    <p
                                        style={{
                                            marginLeft: 10,
                                            fontSize: 16,
                                            fontWeight: "600",
                                        }}
                                    >
                                        {item.type === "candidateSubmit"
                                            ? "Submission Received"
                                            : "New Message"}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginRight: 20,
                                            fontSize: 14,
                                        }}
                                    >
                                        {dateToTimeAgo(item.createdDate)}
                                    </p>
                                    {item.status === "Read" ? (
                                        <IconButton
                                            style={{ width: 36, height: 36 }}
                                        >
                                            <img
                                                src={notificationsRead}
                                                style={{ width: 20 }}
                                                alt=""
                                            />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={() =>
                                                updateNotification(
                                                    item.notificationId
                                                )
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                width={20}
                                                height={20}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4.5 12.75l6 6 9-13.5"
                                                />
                                            </svg>
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                            <div className="notification-card-body">
                                <p style={{ fontSize: 12 }}>
                                    {item.message || item.text}
                                </p>
                            </div>
                        </div>
                    )
                )
            ) : (
                <div>No notifications</div>
            )}
        </>
    );
};

AllNotificationTab.propTypes = {
    notifications: PropTypes.array,
    updateNotificationStatus: PropTypes.func,
};

export default AllNotificationTab;

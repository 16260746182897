import React from "react";
import PropTypes from "prop-types";
import {
    Document,
    Image,
    Page,
    Text,
    View,
    Font,
    StyleSheet,
    Link,
} from "@react-pdf/renderer";

import { create_UUID } from "../utils/validations";

import ReportBg from "../assets/report-bg.jpg";
import WorkFunction3 from "../assets/work-function-3.png";

Font.register({
    family: "Open Sans",
    fonts: [
        {
            src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        },
        {
            src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
            fontWeight: 700,
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        position: "relative",
        padding: "80pt 0 40pt",
        fontFamily: "Open Sans",
    },
    pageWrapper: {
        position: "relative",
        zIndex: 2,
        lineHeight: 1.5,
    },
    pageContent: { padding: "0 80pt 20t", fontSize: "12pt" },
    header: {
        height: "60pt",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
    },
    headerWrapper: {
        marginRight: "30pt",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    headerTextOne: {
        color: "#fff",
        fontSize: "12pt",
    },
    headerTextTwo: {
        color: "#fff",
        fontSize: "12pt",
    },
    pageNumber: {
        position: "absolute",
        left: 0,
        right: "60pt",
        bottom: "30pt",
        zIndex: 1,
    },
    backgroundImage: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3,
    },
    interviewTranscript: {
        marginTop: "35pt",
        color: "#134f5c",
        fontSize: "14pt",
        fontWeight: 700,
        textAlign: "center",
    },
    attemptDuration: {
        width: "25%",
        color: "#000",
        padding: "5pt",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderRightWidth: "1pt",
    },
    startTimeWrapper: {
        display: "flex",
        flexDirection: "row",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderBottomWidth: "1pt",
    },
    startTimeLabel: {
        width: "25%",
        color: "#000",
        padding: "5pt",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderRightWidth: "1pt",
    },
    startTimeValue: {
        width: "25%",
        color: "#7d7d7d",
        padding: "5pt",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderRightWidth: "1pt",
    },
    testDate: {
        width: "25%",
        color: "#000",
        padding: "5pt",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderRightWidth: "1pt",
    },
    actualValue: {
        width: "75%",
        color: "#7d7d7d",
        padding: "5pt",
    },
    pageWrapperHead: {
        marginTop: "30pt",
        borderStyle: "solid",
        borderColor: "#f1f1f1",
        borderWidth: "1pt",
    },
    scenarioWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "40pt",
    },
    scenarioImage: { width: "24pt", marginRight: "16pt" },
    scenarioLabel: { fontWeight: 700, color: "#134f5c" },
    chatWrapper: { padding: "0 40pt", marginTop: "30pt" },
    singleChatHeaderTop: {
        fontWeight: 700,
        color: "#134f5c",
    },
    singleChatHeader: {
        fontWeight: 700,
        color: "#134f5c",
        marginTop: "30pt",
    },
    singleChatImage: {
        marginTop: "20pt",
        width: "100%",
        height: "auto",
        maxHeight: "60%",
    },
});

const renderChatContent = (chatData) => {
    return chatData.map((tc) => {
        const quesArr = Array.isArray(tc.ques) ? tc.ques : tc.ques.split(";;");
        const ansArr = tc.ans.toLowerCase().includes("excel submitted")
            ? tc["raw-ans"]?.sheetlink?.split(";;") || "Excel submitted"
            : tc.ans.split(";;");
        const respArr = tc.resp.split(";;");

        return (
            <View
                key={`${tc.cid}${create_UUID()}`}
                style={{ marginTop: "40pt" }}
                wrap
            >
                <Text style={styles.singleChatHeaderTop}>
                    Virtual Trainer :{" "}
                </Text>
                {renderContentArray(quesArr, true)}
                <Text style={styles.singleChatHeader}>Your response : </Text>
                {renderContentArray(ansArr)}
                <Text style={styles.singleChatHeader}>
                    Virtual Trainer Feedback :{" "}
                </Text>
                {renderContentArray(respArr)}
            </View>
        );
    });
};

const renderContentArray = (contentArray, isQuestion = false) => {
    return contentArray.map((ps) => {
        if (ps.includes("data:image/")) {
            return (
                <Image
                    key={`${ps}${create_UUID()}`}
                    src={ps}
                    style={styles.singleChatImage}
                />
            );
        } else if (ps.includes("spreadsheet")) {
            return (
                <Link
                    src={ps}
                    key={create_UUID()}
                    style={{ marginTop: "20pt" }}
                >
                    <Text>Excel Link</Text>
                </Link>
            );
        } else {
            const allLine = ps.split("::");
            return (
                <View
                    key={`${ps}${create_UUID()}`}
                    style={{ marginTop: "20pt" }}
                >
                    {allLine.map((al) => (
                        <View key={`${al}${create_UUID()}`}>
                            {al.split("***").map((item) => (
                                <Text
                                    key={`${item}${create_UUID()}`}
                                    style={{ marginTop: "10pt" }}
                                >
                                    {item.split("**")[0]}
                                    {isQuestion && (
                                        <Text style={{ fontWeight: 700 }}>
                                            {item.split("**")[1]}
                                        </Text>
                                    )}
                                </Text>
                            ))}
                        </View>
                    ))}
                </View>
            );
        }
    });
};

const PDFTranscript = (props) => {
    const { candidateData } = props;
    const driveName = candidateData.title;
    const probStat = candidateData.problem.split(";;");
    const isCandidateTestChatPresent = candidateData?.test?.chat?.length;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header */}
                <View style={styles.header} fixed>
                    <View style={styles.headerWrapper}>
                        <Text style={styles.headerTextOne}>{driveName}</Text>
                        <Text style={styles.headerTextTwo}>Consulting</Text>
                    </View>
                </View>

                {/* Page Number */}
                <View style={styles.pageNumber} fixed>
                    <Text
                        style={{ textAlign: "right", fontSize: "12pt" }}
                        render={({ pageNumber }) => `${pageNumber}`}
                    />
                </View>

                {/* Background Image */}
                <View style={styles.backgroundImage} fixed>
                    <Image
                        src={ReportBg}
                        style={{ width: "100%", height: "100%" }}
                    />
                </View>

                {/* Main Content */}
                <View style={styles.pageWrapper}>
                    <View style={styles.pageContent}>
                        <Text style={styles.interviewTranscript}>
                            Interview Transcript
                        </Text>
                        <View style={styles.pageWrapperHead}>
                            <View style={styles.startTimeWrapper}>
                                <View style={styles.startTimeValue}>
                                    <Text>Name</Text>
                                </View>
                                <View style={styles.actualValue}>
                                    <Text>{candidateData.name}</Text>
                                </View>
                            </View>
                            <View style={styles.startTimeWrapper}>
                                <View style={styles.startTimeLabel}>
                                    <Text>Candidate ID</Text>
                                </View>
                                <View style={styles.actualValue}>
                                    <Text>{candidateData.id}</Text>
                                </View>
                            </View>
                            <View style={styles.startTimeWrapper}>
                                <View style={styles.startTimeLabel}>
                                    <Text>Start time</Text>
                                </View>
                                <View style={styles.startTimeValue}>
                                    <Text>
                                        {new Date(
                                            candidateData.test.createdDate
                                        )
                                            .toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })
                                            .toUpperCase()}
                                    </Text>
                                </View>
                                <View style={styles.testDate}>
                                    <Text>Date</Text>
                                </View>
                                <View
                                    style={{
                                        width: "25%",
                                        color: "#7d7d7d",
                                        padding: "5pt",
                                    }}
                                >
                                    <Text>
                                        {new Date(
                                            candidateData.test.endDate
                                        ).toDateString()}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <View style={styles.attemptDuration}>
                                    <Text>Attempt duration</Text>
                                </View>
                                <View style={styles.actualValue}>
                                    <Text>
                                        {parseInt(
                                            (new Date(
                                                candidateData.test.endDate
                                            ).getTime() -
                                                new Date(
                                                    candidateData.test.createdDate
                                                ).getTime()) /
                                                60000
                                        ) + " minutes"}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.scenarioWrapper}>
                            <Image
                                src={WorkFunction3}
                                style={styles.scenarioImage}
                            />
                            <Text style={styles.scenarioLabel}>Scenario</Text>
                        </View>
                        <View style={styles.chatWrapper}>
                            <View>
                                {probStat.map((ps) => {
                                    const allLine = ps.split("::");
                                    return (
                                        <View
                                            key={`${allLine}${create_UUID()}`}
                                        >
                                            {allLine.map((al) => (
                                                <Text
                                                    key={`${al}${create_UUID()}`}
                                                >
                                                    {al.replace(/\*/g, "")}
                                                </Text>
                                            ))}
                                        </View>
                                    );
                                })}
                            </View>
                            {isCandidateTestChatPresent
                                ? renderChatContent(candidateData.test.chat)
                                : null}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

PDFTranscript.propTypes = {
    candidateData: PropTypes.object,
};

export default PDFTranscript;

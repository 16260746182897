import React from "react";
import PropTypes from "prop-types";

import "../../styles/IconButton.scss";

const IconButton = (props) => {
    const { className, children, ...rest } = props;

    return (
        <button
            className={`icon-button ripple-effect ${
                className ? className : ""
            }`}
            {...rest}
        >
            {children}
        </button>
    );
};

IconButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

export default IconButton;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";

import AccountMenu from "../components/AccountMenu";
import axios from "../utils/axios";
import BottomBar from "../components/common/BottomBar";
import Button from "../components/common/Button";
import CCListEditor from "../components/settings/CCListEditor";
import ChangePassword from "../components/common/ChangePassword";
import DriveDetails from "../components/settings/DriveDetails";
import EmailNotificationsEditor from "../components/settings/EmailNotificationsEditor";
import Header from "../components/header/header";
import Modal from "../components/common/CustomModal";
import NotificationBell from "../components/notifications/NotificationBell";
import Snackbar from "../components/common/Snackbar";
import Switch from "../components/common/SwitchBtn";
import WalkthroughOverlay from "../components/common/WalkthroughOverlay";
import WalkthroughWelcome from "../components/common/WalkthroughWelcome";
import {
    checkCompanyFeatureAccess,
    checkUserPlanAccess,
} from "../utils/featureAccess";
import {
    fetchBots as fetchBotsRequest,
    getCandidatesByType as getCandidatesByTypeRequest,
    updateUserSettings as updateUserSettingsRequest,
    fetchDriveDetails as fetchDriveDetailsRequest,
    updateAssessmentBotLogo as updateAssessmentBotLogoRequest,
    updateDashboardBotLogo as updateDashboardBotLogoRequest,
    updateCurrentWalkthroughStep as updateCurrentWalkthroughStepRequest,
    updateCurrentWalkthroughType as updateCurrentWalkthroughTypeRequest,
    updateWalkthroughFlows as updateWalkthroughFlowsRequest,
} from "../actions/app";
import {
    getConvoList,
    getCompanyFeatures,
    getSettingsLoading,
    getSaasPlanDetails,
    getUserPlan,
    getDriveDetails,
    getUserRole,
    getCurrentWalkthroughStep,
    getCurrentWalkthroughType,
} from "../selectors/app";
import { redirectUnauthorisedUser } from "../helper/helperFunction";

import DashboardPreview from "../assets/dashboard-preview.png";
import AssessmentPreview from "../assets/assessment-preview.png";

import "../styles/settings.scss";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const Settings = (props) => {
    const {
        getCandidatesByType,
        notification,
        updateUserSettings,
        userPlan,
        saasPlanDetails,
        fetchDriveDetails,
        driveDetails,
        userRole,
        updateAssessmentBotLogo,
        updateDashboardBotLogo,
        bots,
        fetchBots,
        companyFeatures,
        currentWalkthroughStep,
        updateCurrentWalkthroughStep,
        currentWalkthroughType,
        updateCurrentWalkthroughType,
        updateWalkthroughFlows,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [header, setHeader] = useState("");
    const [img, setImg] = useState("");
    const [file, setFile] = useState("");
    const [subject, setsubject] = useState("");
    const [path, setPath] = useState("");
    const [preEditorState, setPreEditorState] = useState("");
    const [postAssessmentModal, setPostAssessmentModal] = useState(false);
    const [errSnackOpen, setErrSnackOpen] = useState(false);
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [snackErrMsg, setSnackErrMsg] = useState("");
    const [snackSuccessMsg, setSnackSuccessMsg] = useState("");
    const [mailChange, setMailChange] = useState(0);
    const [userSettings, setUserSettings] = useState({});
    const [changePwdOpen, setChangePwdOpen] = useState(false);
    const [showCustomizePostMail, setShowCustomizePostMail] = useState(false);
    const [showEmailNotificationsEditor, setShowEmailNotificationsEditor] =
        useState(false);
    const [videoProctoring, setVideoProctoring] = useState(false);
    const [dashboardLogo, setDashboardLogo] = useState();
    const [assessmentLogo, setAssessmentLogo] = useState();
    const [dashboardLogoModal, setDashboardLogoModal] = useState(false);
    const [assessmentLogoModal, setAssessmentLogoModal] = useState(false);
    const [showDashboardLogoPreview, setShowDashboardLogoPreview] =
        useState(false);
    const [showAssessmentBotLogoPreview, setShowAssessmentBotLogoPreview] =
        useState(false);
    const [allowCC] = useState(
        checkCompanyFeatureAccess(companyFeatures, "ccList", null) &&
            checkUserPlanAccess(userPlan.name, saasPlanDetails, "ccList", null)
    );
    const mediaQueryMobile = window.matchMedia("(max-width:768px)");
    const [openPostAssessMail, setOpenPostAssessMail] = useState(false);
    const [showWalkthrough, setShowWalkthrough] = useState(false);
    // const [nextWalkthroughStep, setNextWalkthroughStep] = useState(2);

    const onOpenDashboardLogoModal = () => {
        setDashboardLogoModal(true);
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "whitelabelling"
        ) {
            updateCurrentWalkthroughStep(4);
        }
    };

    const onCloseDashboardLogoModal = () => setDashboardLogoModal(false);

    const onOpenAssessmentLogoModal = () => {
        setAssessmentLogoModal(true);
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "whitelabelling"
        ) {
            updateCurrentWalkthroughStep(4);
        }
    };

    const onCloseAssessmentLogoModal = () => setAssessmentLogoModal(false);

    const changeLogo = function (e, type) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            if (type === "dashboard") setDashboardLogo(reader.result);
            else setAssessmentLogo(reader.result);
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "whitelabelling"
            ) {
                updateCurrentWalkthroughStep(5);
            }
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const onDrop = useCallback((acceptedFiles) => {
        const dname = location.state.workflowId + "completeEmail0";
        const files2 = acceptedFiles.find((file) => file);
        const files = acceptedFiles.map((file) => {
            setPath(dname + file.name.slice(-4));
            return new File([file], dname + file.name.slice(-4), {
                type: file.type,
            });
        });

        setFile(files);
        setImg(files[0]);
        getBase64(files2);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ accept: "image/*", onDrop });

    const handlePreEditorChange = (content, delta, source, editor) => {
        setPreEditorState(editor.getHTML());
    };

    const onOpenPostAssessmentModal = () => {
        if (mediaQueryMobile.matches) {
            setOpenPostAssessMail(true);
        } else {
            if (
                currentWalkthroughStep > 0 &&
                currentWalkthroughType === "automaticemails"
            ) {
                updateCurrentWalkthroughStep(0);
                updateCurrentWalkthroughType("");
                let wArr = [];
                if (localStorage.getItem("walkthroughFlows")) {
                    wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
                }
                wArr.push("automaticemails");
                localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
                updateWalkthroughFlows(wArr);
            }
            setPostAssessmentModal(true);
        }
    };

    const onClosePostAssessmentModal = () => {
        setPostAssessmentModal(false);
    };

    const handleErrSnackClick = () => {
        setErrSnackOpen(true);
    };

    const handleSuccessSnackClick = () => {
        setSuccessSnackOpen(true);
    };

    const handleSuccessSnackClose = () => {
        setSuccessSnackOpen(false);
    };

    const handleErrSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrSnackOpen(false);
    };

    useEffect(() => {
        axios
            .post("/workflow/getemail", {
                workflowId: location.state.workflowId,
                key: "completeEmail",
            })
            .then((res) => {
                let mail = res.data.mails[0];

                setName(mail.name ? mail.name : "");
                setImg(mail.image ? mail.image : "");
                setFile(
                    mail.image
                        ? process.env.REACT_APP_STORAGE_BUCKET_URL + mail.image
                        : ""
                );
                setsubject(mail.subject ? mail.subject : "");
                setPath(mail.image ? mail.image : "");
                setHeader(mail.header ? mail.header : "");
                setPreEditorState(mail.body);
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
            });
    }, [mailChange]);

    const updateEmail = () => {
        let render = `<div style="width:100%;background:#bbb">
        <div style="width:500px;margin:auto;background:#fff;padding:20px;">
          <div style="width: 500px;text-align: center; ">
            <img src="${process.env.REACT_APP_STORAGE_BUCKET_URL}${path}" style=" max-width: 40%; height: auto;"/>
          </div>
          <p><span style="font-size:24px; text-align: left;">${header}</span></p>
          <br/><br/>
        <p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
        </div>`;
        var formData = new FormData();
        formData.append("workflowId", location.state.workflowId);
        formData.append("key", "completeEmail");
        formData.append("index", 0);
        formData.append("name", name);
        formData.append("image", img);
        formData.append("path", path);
        formData.append("body", preEditorState);
        formData.append("subject", subject);
        formData.append("render", render);
        formData.append("header", header);
        axios
            .post("/workflow/setemail", formData, {
                "Content-Type": "multipart/form-data",
            })
            .then(() => {
                setMailChange(mailChange + 1);
                handleSuccessSnackClick();
                setSnackSuccessMsg("Your settings have been saved");
            })
            .catch((err) => {
                console.log(err);
                redirectUnauthorisedUser(err, navigate);
            });
    };

    useEffect(() => {
        const reqData = {
            status: "review",
            id: location.state.workflowId,
        };

        getCandidatesByType({ reqData, navigate, pageType: "settings" });
        fetchDriveDetails({ workflowId: location.state.workflowId, navigate });
    }, []);

    const handleChange = (event, key) => {
        if (key == "completeEmail") {
            if (!userRole.includes("Admin")) {
                setSnackErrMsg("You are not authorised to do this action");
                handleErrSnackClick();
            } else {
                if (
                    currentWalkthroughStep > 0 &&
                    currentWalkthroughType === "automaticemails"
                ) {
                    updateCurrentWalkthroughStep(4);
                }
                const newUserSettings = { ...userSettings };
                newUserSettings[key] = event.target.checked;

                setUserSettings(newUserSettings);
                if (event.target.checked) {
                    setShowCustomizePostMail(true);
                } else {
                    setShowCustomizePostMail(false);
                }
                submitChange(newUserSettings);
            }
        }
        if (key === "submitNotification") {
            const newUserSettings = { ...userSettings };
            newUserSettings[key] = event.target.checked;
            setUserSettings(newUserSettings);
            if (event.target.checked) {
                setShowEmailNotificationsEditor(true);
            } else {
                setShowEmailNotificationsEditor(false);
            }
            submitChange(newUserSettings);
        }
    };

    const submitChange = (newUserSettings) => {
        localStorage.setItem("settings", JSON.stringify(newUserSettings));

        updateUserSettings(newUserSettings);

        axios
            .post("/workflow/setting", {
                workflowid: location.state.workflowId,
                settings: newUserSettings,
            })
            .then((res) => {
                console.log(res);
                handleSuccessSnackClick();
                setSnackSuccessMsg("Your settings have been saved");
            })
            .catch((err) => console.log(err));
    };

    const getBase64 = (file1) => {
        var reader = new FileReader();
        var reader2 = new FileReader();
        reader.readAsArrayBuffer(file1);
        reader2.readAsDataURL(file1);
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader2.onload = function () {
            setFile(reader2.result);
        };
        reader2.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const changeSubject = (e) => {
        setsubject(e.target.value);
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ color: [] }, { background: [] }],
            ],
        }),
        []
    );

    const formats = useMemo(
        () => [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "background",
        ],
        []
    );

    const onCloseChangePwd = () => {
        setChangePwdOpen(false);
    };

    const handleVideoProctoring = (e) => {
        if (!userRole.includes("Admin")) {
            setSnackErrMsg("You are not authorised to do this action");
            handleErrSnackClick();
        } else {
            setVideoProctoring(e.target.checked);
            axios
                .post("/workflow/update-video-proctoring", {
                    workflowid: location.state.workflowId,
                    videoProctoring: e.target.checked,
                })
                .then(() => {
                    handleSuccessSnackClick();
                    setSnackSuccessMsg("Video Proctoring updated successfully");
                })
                .catch((err) => console.log(err));
        }
        updateCurrentWalkthroughStep(0);
        updateCurrentWalkthroughType("");
    };

    useEffect(() => {
        setVideoProctoring(
            Object.prototype.hasOwnProperty.call(
                driveDetails,
                "videoProctoring"
            )
                ? driveDetails.videoProctoring
                : false
        );
        if (
            Object.prototype.hasOwnProperty.call(
                driveDetails,
                "videoProctoring"
            )
        ) {
            setShowWalkthrough(true);
        }
        if (
            currentWalkthroughStep === 3 &&
            currentWalkthroughType === "whitelabelling"
        ) {
            document.getElementById("whitelabellingStep3").scrollIntoView();
        }
    }, [driveDetails.videoProctoring]);

    useEffect(() => {
        if (driveDetails.settings) {
            setUserSettings({ ...driveDetails.settings });
            if (driveDetails.settings.completeEmail) {
                setShowCustomizePostMail(true);
            }
        }
    }, [driveDetails.settings]);

    useEffect(() => {
        setShowEmailNotificationsEditor(
            Object.prototype.hasOwnProperty.call(
                userSettings,
                "submitNotification"
            )
                ? userSettings.submitNotification
                : false
        );
    }, [userSettings]);

    const onSaveAssessmentBotLogo = () => {
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "whitelabelling"
        ) {
            updateCurrentWalkthroughStep(0);
            updateCurrentWalkthroughType("");
            let wArr = [];
            if (localStorage.getItem("walkthroughFlows")) {
                wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
            }
            wArr.push("whitelabelling");
            localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
            updateWalkthroughFlows(wArr);
        }
        if (assessmentLogo) {
            const formData = new FormData();
            fetch(assessmentLogo)
                .then((res) => res.blob())
                .then((blob) => {
                    const dname =
                        location.state.workflowId +
                        "_logo_" +
                        new Date().getTime();
                    const file = new File([blob], dname + ".png", {
                        type: "image/png",
                    });
                    formData.append("image", file);
                    formData.append("workflowId", location.state.workflowId);
                    handleSuccessSnackClick();
                    setSnackSuccessMsg("Your settings have been saved");
                    updateAssessmentBotLogo({ formData, navigate });
                    onCloseAssessmentLogoModal();
                });
        } else {
            handleErrSnackClick();
            setSnackErrMsg("Upload assessment logo");
        }
    };

    const onSaveDashboardLogo = () => {
        if (
            currentWalkthroughStep > 0 &&
            currentWalkthroughType === "whitelabelling"
        ) {
            updateCurrentWalkthroughStep(0);
            updateCurrentWalkthroughType("");
            let wArr = [];
            if (localStorage.getItem("walkthroughFlows")) {
                wArr = JSON.parse(localStorage.getItem("walkthroughFlows"));
            }
            wArr.push("whitelabelling");
            localStorage.setItem("walkthroughFlows", JSON.stringify(wArr));
            updateWalkthroughFlows(wArr);
        }
        if (dashboardLogo) {
            const formData = new FormData();
            fetch(dashboardLogo)
                .then((res) => res.blob())
                .then((blob) => {
                    const dname =
                        driveDetails.company + "_logo_" + new Date().getTime();
                    const file = new File([blob], dname + ".png", {
                        type: "image/png",
                    });
                    formData.append("image", file);
                    handleSuccessSnackClick();
                    setSnackSuccessMsg("Your settings have been saved");
                    updateDashboardBotLogo({ formData, navigate });
                    onCloseDashboardLogoModal();
                });
        } else {
            handleErrSnackClick();
            setSnackErrMsg("Upload dashboard logo");
        }
    };

    return (
        <div className="quick-menu-handler settings">
            {mediaQueryMobile.matches && <Header />}
            <div className="inner-div" style={{ marginBottom: 15 }}>
                <div className="top-icon-box">
                    <WalkthroughWelcome />
                    <NotificationBell notification={notification} />
                    <AccountMenu />
                </div>
            </div>
            {!mediaQueryMobile.matches && (
                <DriveDetails
                    driveDetails={driveDetails}
                    bots={bots}
                    fetchBots={fetchBots}
                />
            )}
            {mediaQueryMobile.matches && !openPostAssessMail && (
                <DriveDetails
                    driveDetails={driveDetails}
                    bots={bots}
                    fetchBots={fetchBots}
                />
            )}
            {mediaQueryMobile.matches && !openPostAssessMail && (
                <div className="user-settings__mobile">
                    <div className="user-settings__row extra-padding">
                        <p>Post-assessment mails</p>
                        <Switch
                            checked={
                                Object.prototype.hasOwnProperty.call(
                                    userSettings,
                                    "completeEmail"
                                )
                                    ? userSettings.completeEmail
                                    : false
                            }
                            onChange={(e) => handleChange(e, "completeEmail")}
                        />
                    </div>
                    {showCustomizePostMail ? (
                        <div
                            className="user-settings__btn"
                            onClick={onOpenPostAssessmentModal}
                        >
                            Customise
                        </div>
                    ) : null}
                    <div className="user-settings__description extra-padding">
                        Customise and send mails to candidates to acknowledge
                        submission of assessment
                    </div>
                    <div className="user-settings__row extra-padding">
                        <p>Video Proctoring</p>
                        <Switch
                            checked={videoProctoring}
                            onChange={handleVideoProctoring}
                        />
                    </div>
                    <div className="user-settings__description">
                        Change your preference of video proctoring
                    </div>
                    <div className="user-settings__row extra-padding">
                        <p>Dashboard whitelabelling</p>
                        <div
                            className="user-settings__btn"
                            onClick={onOpenDashboardLogoModal}
                        >
                            Change logo
                        </div>
                    </div>
                    <div className="user-settings__description">
                        Re-brand the dashboard by adding your company’s logo
                    </div>
                    <div className="user-settings__row extra-padding">
                        <p>Virtual Interviewer whitelabelling</p>
                        <div
                            className="user-settings__btn"
                            onClick={onOpenAssessmentLogoModal}
                        >
                            Change logo
                        </div>
                    </div>
                    <div className="user-settings__description">
                        Add your company’s branding to the virtual interviewer
                        bots shared with candidates
                    </div>
                    {allowCC ? (
                        <>
                            <div className="user-settings__row extra-padding">
                                <p>Add Cc and Bcc recipients</p>
                                <div className="user-settings__btn">
                                    <CCListEditor
                                        setSnackErrMsg={setSnackErrMsg}
                                        handleErrSnackClick={
                                            handleErrSnackClick
                                        }
                                        handleSuccessSnackClick={
                                            handleSuccessSnackClick
                                        }
                                        setSnackSuccessMsg={setSnackSuccessMsg}
                                    />
                                </div>
                            </div>
                            <div className="user-settings__description">
                                Add yourself and other Cc and Bcc recipients to
                                the mails sent from dashboard
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            )}
            {!mediaQueryMobile.matches && (
                <div className="user-settings">
                    <div className="wrapper" style={{ position: "relative" }}>
                        <div className="settings-header">
                            <div>Settings</div>
                            <div>Description</div>
                            <div>Action</div>
                        </div>
                        <div
                            className="settings-item"
                            style={{ position: "relative" }}
                        >
                            <div
                                className="settings-name"
                                style={{ position: "relative" }}
                            >
                                <div>Post-assessment mails</div>
                                <div
                                    style={{
                                        position: "relative",
                                        background: "#fff",
                                        zIndex:
                                            currentWalkthroughStep === 3 &&
                                            currentWalkthroughType ===
                                                "automaticemails"
                                                ? 1000
                                                : 1,
                                    }}
                                    id="automaticemailsStep3"
                                >
                                    <Switch
                                        checked={
                                            Object.prototype.hasOwnProperty.call(
                                                userSettings,
                                                "completeEmail"
                                            )
                                                ? userSettings.completeEmail
                                                : false
                                        }
                                        onChange={(e) =>
                                            handleChange(e, "completeEmail")
                                        }
                                        id="settingsFlow-selector-6"
                                    />
                                </div>
                            </div>
                            <div className="settings-description">
                                Customise and send mails to candidates to
                                acknowledge submission of assessment
                            </div>
                            <div
                                className="settings-action"
                                id="automaticemailsStep4"
                            >
                                {showCustomizePostMail ? (
                                    <div
                                        onClick={onOpenPostAssessmentModal}
                                        id="settingsFlow-selector-7"
                                        style={{
                                            position: "relative",
                                            zIndex:
                                                currentWalkthroughStep === 4 &&
                                                currentWalkthroughType ===
                                                    "automaticemails"
                                                    ? 1000
                                                    : 1,
                                        }}
                                    >
                                        Customise
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/* <div className='settings-item'>
							<div className='settings-name'>Change your password</div>
							<div className='settings-description'>Change your login password here</div>
							<div className='settings-action'>
								<div onClick={onOpenChangePwd} id="settingsFlow-selector-2">Reset</div>
							</div>
						</div> */}
                        <div
                            className="settings-item"
                            style={{ position: "relative" }}
                            id="videoproctoringStep3"
                        >
                            <div className="settings-name">
                                Video Proctoring
                            </div>
                            <div className="settings-description">
                                Change your preference of video proctoring
                            </div>
                            <div
                                className="settings-action"
                                style={{
                                    position: "relative",
                                    background: "#fff",
                                    zIndex:
                                        currentWalkthroughStep === 3 &&
                                        currentWalkthroughType ===
                                            "videoproctoring"
                                            ? 1000
                                            : 1,
                                }}
                            >
                                <Switch
                                    checked={videoProctoring}
                                    onChange={handleVideoProctoring}
                                />
                            </div>
                        </div>
                        <div
                            id="whitelabellingStep3"
                            style={{
                                position: "relative",
                                background: "#fff",
                                zIndex:
                                    currentWalkthroughStep === 3 &&
                                    currentWalkthroughType === "whitelabelling"
                                        ? 1000
                                        : 1,
                            }}
                        >
                            <div className="settings-item">
                                <div className="settings-name">
                                    Dashboard whitelabelling
                                </div>
                                <div className="settings-description">
                                    Re-brand the dashboard by adding your
                                    company’s logo
                                </div>
                                <div className="settings-action">
                                    <div onClick={onOpenDashboardLogoModal}>
                                        Change logo
                                    </div>
                                </div>
                            </div>
                            <div className="settings-item">
                                <div className="settings-name">
                                    Virtual Interviewer whitelabelling
                                </div>
                                <div className="settings-description">
                                    Add your company’s branding to the virtual
                                    interviewer bots shared with candidates
                                </div>
                                <div className="settings-action">
                                    <div onClick={onOpenAssessmentLogoModal}>
                                        Change logo
                                    </div>
                                </div>
                            </div>
                        </div>
                        {allowCC ? (
                            <div className="settings-item">
                                <div className="settings-name">
                                    Add Cc and Bcc recipients
                                </div>
                                <div className="settings-description">
                                    Add yourself and other Cc and Bcc recipients
                                    to the mails sent from dashboard
                                </div>
                                <div className="settings-action">
                                    <CCListEditor
                                        setSnackErrMsg={setSnackErrMsg}
                                        handleErrSnackClick={
                                            handleErrSnackClick
                                        }
                                        handleSuccessSnackClick={
                                            handleSuccessSnackClick
                                        }
                                        setSnackSuccessMsg={setSnackSuccessMsg}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="settings-item">
                            <div className="settings-name">
                                <div>Email Notifications</div>
                                <Switch
                                    checked={
                                        Object.prototype.hasOwnProperty.call(
                                            userSettings,
                                            "submitNotification"
                                        )
                                            ? userSettings.submitNotification
                                            : false
                                    }
                                    onChange={(e) =>
                                        handleChange(e, "submitNotification")
                                    }
                                />
                            </div>
                            <div className="settings-description">
                                Get notified on your email ID when candidates
                                submit the assessment
                            </div>
                            <div className="settings-action">
                                {showEmailNotificationsEditor ? (
                                    <EmailNotificationsEditor
                                        setSnackErrMsg={setSnackErrMsg}
                                        handleErrSnackClick={
                                            handleErrSnackClick
                                        }
                                        handleSuccessSnackClick={
                                            handleSuccessSnackClick
                                        }
                                        setSnackSuccessMsg={setSnackSuccessMsg}
                                        emailList={
                                            driveDetails.emailNotification
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ChangePassword open={changePwdOpen} onClose={onCloseChangePwd} />
            {/* <div className="container-div" style={{ padding: "20px 0" }}>
				<div className="all-applicant-div" style={{ height: "max-content" }}>
					<div className="all-applicants-top-div">
						<div className="all-applicant-title">
							<div className="title">
								<h3>Settings</h3>
							</div>
						</div>
						<div className="all-applicants-no">
							<Button
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "inherit",
									letterSpacing: "0.01em",
									fontSize: '16px',
									borderRadius: '25px',
									padding: '5px 25px'
								}}
								size="small"
								variant="contained"
								onClick={(e) => submitChange(e)}
							>
								Save
							</Button>
						</div>
					</div>
					<div className="all-applicants-bottom-div">
						<TableContainer component={Paper} style={{ border: "none" }}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Settings</TableCell>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Description</TableCell>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Enable/Disable</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading ? '' : updateTable()}
								</TableBody>
							</Table>
						</TableContainer>
						{loading ? <Loader /> : null}
					</div>
				</div>
			</div> */}
            {openPostAssessMail && (
                <div style={{ display: "flex", flex: 1 }}>
                    <div className="email-editing-container">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={25}
                                height={25}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#ADADAD"
                                strokeWidth="2"
                                onClick={() => setOpenPostAssessMail(false)}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                            <h2
                                style={{
                                    color: "rgb(150,150,150)",
                                    marginLeft: 8,
                                }}
                            >
                                Post-assessment mail
                            </h2>
                        </div>
                        <div className="update-logo-container">
                            <div {...getRootProps({ style })} style={baseStyle}>
                                <img
                                    src={file}
                                    style={{
                                        maxWidth: "40%",
                                        width: "300px",
                                        maxHeight: "180px",
                                    }}
                                    alt=""
                                />
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>
                                        Change Logo
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                                top: "4px",
                                                position: "relative",
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 4.5v15m7.5-7.5h-15"
                                            />
                                        </svg>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="modal-input">
                            <div
                                style={{
                                    marginLeft: mediaQueryMobile.matches
                                        ? 0
                                        : "2%",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "unset",
                                }}
                            >
                                <input
                                    type="text"
                                    onChange={changeSubject}
                                    value={subject}
                                    placeholder="Subject"
                                    style={{
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                    id="settingsFlow-selector-8"
                                />
                            </div>
                            <div
                                style={{
                                    marginLeft: mediaQueryMobile.matches
                                        ? 0
                                        : "2%",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "unset",
                                }}
                            >
                                <input
                                    type="text"
                                    onChange={(e) => setHeader(e.target.value)}
                                    value={header}
                                    placeholder="Message header"
                                    style={{
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                    id="settingsFlow-selector-9"
                                />
                            </div>
                        </div>
                        <div className="quill-container">
                            <p
                                style={{
                                    margin: "0 0 10px 10px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                Body
                            </p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={preEditorState}
                                onChange={handlePreEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                id="settingsFlow-selector-10"
                                size="small"
                                style={{
                                    color: "#fff",
                                    background: "orange",
                                    margin: "10px 20px",
                                    borderRadius: "20px",
                                    fontFamily: "inherit",
                                }}
                                onClick={() => {
                                    updateEmail();
                                    onClosePostAssessmentModal();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="preview-email-container">
                        <h2
                            style={{
                                marginBottom: "20px",
                                color: "rgb(150,150,150)",
                                textAlign: "left",
                            }}
                        >
                            Preview
                        </h2>
                        <div
                            id="preview"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "30px 0 0 0",
                                background: "#fff",
                                padding: "20px",
                            }}
                        >
                            <img
                                src={file}
                                style={{
                                    maxWidth: "40%",
                                    width: "300px",
                                    maxHeight: "180px",
                                }}
                                alt=""
                            />
                            <hr
                                style={{
                                    margin: "20px 0",
                                    width: "80%",
                                    border: 0,
                                    height: 0,
                                    borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                                }}
                            />
                            <div>
                                <p
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        textAlign: "center",
                                    }}
                                >
                                    {header}
                                </p>
                            </div>
                            <hr
                                style={{
                                    margin: "20px 0",
                                    width: "80%",
                                    border: 0,
                                    height: 0,
                                    borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                                }}
                            />
                            <div
                                style={{
                                    overflow: "wrap",
                                    textAlign: "left",
                                    width: "100%",
                                    overflowWrap: "break-word",
                                    margin: "10px 0",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: preEditorState,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                open={postAssessmentModal}
                onClose={onClosePostAssessmentModal}
                classNames={{ modal: "customModal" }}
                center
            >
                <div
                    style={{
                        display: "flex",
                        height: mediaQueryMobile.matches ? "100%" : "unset",
                    }}
                >
                    <div className="email-editing-container">
                        <div
                            style={{
                                marginBottom: "20px",
                                color: "rgb(150,150,150)",
                                fontSize: "24px",
                                fontWeight: "700",
                            }}
                        >
                            Post-assessment mail
                        </div>
                        <div className="update-logo-container">
                            <div {...getRootProps({ style })} style={baseStyle}>
                                <img
                                    src={file}
                                    style={{
                                        maxWidth: "40%",
                                        width: "300px",
                                        maxHeight: "180px",
                                    }}
                                    alt=""
                                />
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>
                                        Change Logo
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                                top: "4px",
                                                position: "relative",
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 4.5v15m7.5-7.5h-15"
                                            />
                                        </svg>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="modal-input">
                            <div
                                style={{
                                    marginLeft: mediaQueryMobile.matches
                                        ? 0
                                        : "2%",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "unset",
                                }}
                            >
                                <input
                                    type="text"
                                    onChange={changeSubject}
                                    value={subject}
                                    placeholder="Subject"
                                    style={{
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                    id="settingsFlow-selector-8"
                                />
                            </div>
                            <div
                                style={{
                                    marginLeft: mediaQueryMobile.matches
                                        ? 0
                                        : "2%",
                                    width: mediaQueryMobile.matches
                                        ? "100%"
                                        : "unset",
                                }}
                            >
                                <input
                                    type="text"
                                    onChange={(e) => setHeader(e.target.value)}
                                    value={header}
                                    placeholder="Message header"
                                    style={{
                                        width: mediaQueryMobile.matches
                                            ? "100%"
                                            : "90%",
                                        padding: "8px",
                                        marginBottom: "10px",
                                        boxShadow: "0px 1px 1px #EDEFF1",
                                        border: "none",
                                        boxSizing: "border-box",
                                    }}
                                    id="settingsFlow-selector-9"
                                />
                            </div>
                        </div>
                        <div className="quill-container">
                            <p
                                style={{
                                    margin: "0 0 10px 10px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                Body
                            </p>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={preEditorState}
                                onChange={handlePreEditorChange}
                                style={{ height: "100%" }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                id="settingsFlow-selector-10"
                                style={{
                                    color: "#fff",
                                    background: "orange",
                                    margin: "10px 20px",
                                    borderRadius: "20px",
                                    lineHeight: 0,
                                }}
                                onClick={() => {
                                    updateEmail();
                                    onClosePostAssessmentModal();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="preview-email-container">
                        <h2
                            style={{
                                marginBottom: "20px",
                                color: "rgb(150,150,150)",
                                textAlign: "left",
                            }}
                        >
                            Preview
                        </h2>
                        <div
                            id="preview"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "30px 0 0 0",
                                background: "#fff",
                                padding: "20px",
                            }}
                        >
                            <img
                                src={file}
                                style={{
                                    maxWidth: "40%",
                                    width: "300px",
                                    maxHeight: "180px",
                                }}
                                alt=""
                            />
                            <hr
                                style={{
                                    margin: "20px 0",
                                    width: "80%",
                                    border: 0,
                                    height: 0,
                                    borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                                }}
                            />
                            <div>
                                <p
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        textAlign: "center",
                                    }}
                                >
                                    {header}
                                </p>
                            </div>
                            <hr
                                style={{
                                    margin: "20px 0",
                                    width: "80%",
                                    border: 0,
                                    height: 0,
                                    borderTop: "1px solid rgb(0, 0, 0, 0.12)",
                                }}
                            />
                            <div
                                style={{
                                    overflow: "wrap",
                                    textAlign: "left",
                                    width: "100%",
                                    overflowWrap: "break-word",
                                    margin: "10px 0",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: preEditorState,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={dashboardLogoModal}
                onClose={onCloseDashboardLogoModal}
                classNames={{ modal: "settings__logomodal" }}
            >
                <div className="modal__heading">Add logo to dashboard</div>
                <div
                    className="user-settings__logobox"
                    style={{ position: "relative" }}
                >
                    <img
                        className="user-settings__newlogo"
                        src={dashboardLogo}
                        alt=""
                    />
                    <div
                        className="user-settings__logoinputbox"
                        id="whitelabellingStep4"
                        style={{
                            position: "relative",
                            background: "#fff",
                            zIndex:
                                currentWalkthroughStep === 4 &&
                                currentWalkthroughType === "whitelabelling"
                                    ? 1000
                                    : 1,
                        }}
                    >
                        <div className="user-settings__logoinputbox-text">
                            <p>Choose logo file</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                width={24}
                                height={24}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <input
                            className="user-settings__logoinputbox-input"
                            type="file"
                            onChange={(e) => changeLogo(e, "dashboard")}
                            accept="image/*"
                        />
                        <p className="user-settings__logoinputbox-label">
                            Upload .Png file with transparent background
                            recommended logo size 500kb ()
                        </p>
                    </div>
                </div>
                <div
                    className="modal__btnbox"
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onSaveDashboardLogo}
                        id={dashboardLogoModal ? "whitelabellingStep5" : ""}
                        style={{
                            position: "relative",
                            zIndex:
                                currentWalkthroughStep === 5 &&
                                currentWalkthroughType === "whitelabelling"
                                    ? 1000
                                    : 1,
                        }}
                    >
                        Save changes
                    </Button>
                    {dashboardLogo ? (
                        <div
                            onClick={() => setShowDashboardLogoPreview(true)}
                            style={{
                                color: "#9A9A9A",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                        >
                            Preview
                        </div>
                    ) : null}
                </div>
                {(currentWalkthroughStep === 4 ||
                    currentWalkthroughStep === 5) &&
                currentWalkthroughType === "whitelabelling" ? (
                    <WalkthroughOverlay
                        showWalkthroughBackBtn={currentWalkthroughStep !== 5}
                        onClickBack={() => {
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep - 1
                            );
                            if (currentWalkthroughStep === 4)
                                onCloseDashboardLogoModal();
                        }}
                    />
                ) : null}
            </Modal>
            <Modal
                open={assessmentLogoModal}
                onClose={onCloseAssessmentLogoModal}
                classNames={{ modal: "settings__logomodal" }}
            >
                <div className="modal__heading">
                    Add logo to{mediaQueryMobile.matches ? <br /> : null}{" "}
                    assessment bot
                </div>
                <div
                    className="user-settings__logobox"
                    style={{ position: "relative" }}
                >
                    <img
                        className="user-settings__newlogo"
                        src={assessmentLogo}
                        alt=""
                    />
                    <div
                        className="user-settings__logoinputbox"
                        id="whitelabellingStep4"
                        style={{
                            position: "relative",
                            background: "#fff",
                            zIndex:
                                currentWalkthroughStep === 4 &&
                                currentWalkthroughType === "whitelabelling"
                                    ? 1000
                                    : 1,
                        }}
                    >
                        <div className="user-settings__logoinputbox-text">
                            <p>Choose logo file</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                width={24}
                                height={24}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <input
                            className="user-settings__logoinputbox-input"
                            type="file"
                            onChange={(e) => changeLogo(e, "assessment")}
                            accept="image/*"
                        />
                        <p className="user-settings__logoinputbox-label">
                            Upload .Png file with transparent background
                            recommended logo size 500kb ()
                        </p>
                    </div>
                </div>
                <div
                    className="modal__btnbox"
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={onSaveAssessmentBotLogo}
                        id={assessmentLogoModal ? "whitelabellingStep5" : ""}
                        style={{
                            position: "relative",
                            zIndex:
                                currentWalkthroughStep === 5 &&
                                currentWalkthroughType === "whitelabelling"
                                    ? 1000
                                    : 1,
                        }}
                    >
                        Save changes
                    </Button>
                    {assessmentLogo ? (
                        <div
                            onClick={() =>
                                setShowAssessmentBotLogoPreview(true)
                            }
                            style={{
                                color: "#9A9A9A",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                        >
                            Preview
                        </div>
                    ) : null}
                </div>
                {(currentWalkthroughStep === 4 ||
                    currentWalkthroughStep === 5) &&
                currentWalkthroughType === "whitelabelling" ? (
                    <WalkthroughOverlay
                        showWalkthroughBackBtn={currentWalkthroughStep !== 5}
                        onClickBack={() => {
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep - 1
                            );
                            if (currentWalkthroughStep === 4)
                                onCloseAssessmentLogoModal();
                        }}
                    />
                ) : null}
            </Modal>
            <Modal
                open={showDashboardLogoPreview}
                onClose={() => setShowDashboardLogoPreview(false)}
            >
                <div
                    style={{
                        minWidth: mediaQueryMobile.matches ? "unset" : 800,
                        padding: 20,
                        boxSizing: "border-box",
                        position: "relative",
                        minHeight: mediaQueryMobile.matches ? "unset" : 460,
                    }}
                >
                    <img
                        src={DashboardPreview}
                        alt=""
                        style={{ width: "100%" }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 20,
                            left: 20,
                            width: 145,
                            height: 50,
                            background: "#fff",
                            zIndex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <img
                            src={dashboardLogo}
                            alt=""
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={showAssessmentBotLogoPreview}
                onClose={() => setShowAssessmentBotLogoPreview(false)}
            >
                <div
                    style={{
                        minWidth: mediaQueryMobile.matches ? "unset" : 800,
                        padding: 20,
                        boxSizing: "border-box",
                        position: "relative",
                        minHeight: mediaQueryMobile.matches ? "unset" : 526,
                    }}
                >
                    <img
                        src={AssessmentPreview}
                        alt=""
                        style={{ width: "100%" }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 20,
                            left: 20,
                            width: 98,
                            height: 50,
                            background: "#fff",
                            zIndex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <img
                            src={assessmentLogo}
                            alt=""
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                    </div>
                </div>
            </Modal>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={2500}
                onClose={handleSuccessSnackClose}
                msg={snackSuccessMsg ? snackSuccessMsg : null}
                type="success"
            />
            <Snackbar
                open={errSnackOpen}
                autoHideDuration={2500}
                onClose={handleErrSnackClose}
                msg={snackErrMsg ? snackErrMsg : null}
                type="error"
            />
            {mediaQueryMobile.matches && <BottomBar />}
            {showWalkthrough &&
            currentWalkthroughStep > 0 &&
            (currentWalkthroughType === "videoproctoring" ||
                currentWalkthroughType === "automaticemails") ? (
                <WalkthroughOverlay
                    showWalkthroughNextBtn={
                        (currentWalkthroughType === "videoproctoring" &&
                            currentWalkthroughStep !== 3) ||
                        (currentWalkthroughType === "automaticemails" &&
                            currentWalkthroughStep !== 4)
                    }
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        if (currentWalkthroughStep === 3) {
                            navigate(
                                `/workflows/${location.state.workflowName}`,
                                {
                                    state: {
                                        sidebar: "quickLook",
                                        workflowId: location.state.workflowId,
                                        workflowName:
                                            location.state.workflowName,
                                    },
                                }
                            );
                        }
                    }}
                    onClickDone={() => {
                        if (currentWalkthroughStep < 4) {
                            updateCurrentWalkthroughStep(
                                currentWalkthroughStep + 1
                            );
                        } else {
                            updateCurrentWalkthroughStep(0);
                            updateCurrentWalkthroughType("");
                            let wArr = [];
                            if (localStorage.getItem("walkthroughFlows")) {
                                wArr = JSON.parse(
                                    localStorage.getItem("walkthroughFlows")
                                );
                            }
                            wArr.push("videoproctoring");
                            localStorage.setItem(
                                "walkthroughFlows",
                                JSON.stringify(wArr)
                            );
                            updateWalkthroughFlows(wArr);
                        }
                    }}
                />
            ) : null}
            {currentWalkthroughStep > 0 &&
            currentWalkthroughStep < 4 &&
            currentWalkthroughType === "whitelabelling" ? (
                <WalkthroughOverlay
                    onClickBack={() => {
                        updateCurrentWalkthroughStep(
                            currentWalkthroughStep - 1
                        );
                        if (currentWalkthroughStep === 3) {
                            navigate(
                                `/workflows/${location.state.workflowName}`,
                                {
                                    state: {
                                        sidebar: "quickLook",
                                        workflowId: location.state.workflowId,
                                        workflowName:
                                            location.state.workflowName,
                                    },
                                }
                            );
                        }
                    }}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    loading: getSettingsLoading(),
    userPlan: getUserPlan(),
    driveDetails: getDriveDetails(),
    userRole: getUserRole(),
    bots: getConvoList(),
    saasPlanDetails: getSaasPlanDetails(),
    companyFeatures: getCompanyFeatures(),
    currentWalkthroughStep: getCurrentWalkthroughStep(),
    currentWalkthroughType: getCurrentWalkthroughType(),
});

const mapDispatchToProps = (dispatch) => ({
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
    updateUserSettings: (data) => dispatch(updateUserSettingsRequest(data)),
    fetchDriveDetails: (data) => dispatch(fetchDriveDetailsRequest(data)),
    updateAssessmentBotLogo: (data) =>
        dispatch(updateAssessmentBotLogoRequest(data)),
    updateDashboardBotLogo: (data) =>
        dispatch(updateDashboardBotLogoRequest(data)),
    fetchBots: (data) => dispatch(fetchBotsRequest(data)),
    updateCurrentWalkthroughStep: (data) =>
        dispatch(updateCurrentWalkthroughStepRequest(data)),
    updateCurrentWalkthroughType: (data) =>
        dispatch(updateCurrentWalkthroughTypeRequest(data)),
    updateWalkthroughFlows: (data) =>
        dispatch(updateWalkthroughFlowsRequest(data)),
});

Settings.propTypes = {
    candidates: PropTypes.array,
    getCandidatesByType: PropTypes.func,
    notification: PropTypes.object,
    settings: PropTypes.object,
    updateUserSettings: PropTypes.func,
    userPlan: PropTypes.object,
    saasPlanDetails: PropTypes.object,
    fetchDriveDetails: PropTypes.func,
    driveDetails: PropTypes.object,
    userRole: PropTypes.string,
    updateAssessmentBotLogo: PropTypes.func,
    updateDashboardBotLogo: PropTypes.func,
    bots: PropTypes.array,
    fetchBots: PropTypes.func,
    companyFeatures: PropTypes.object,
    currentWalkthroughStep: PropTypes.number,
    updateCurrentWalkthroughStep: PropTypes.func,
    currentWalkthroughType: PropTypes.string,
    updateCurrentWalkthroughType: PropTypes.func,
    updateWalkthroughFlows: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

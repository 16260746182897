import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "../utils/axios";
import IconButton from "../components/common/IconButton";
import NoVideoCard from "../components/videoSubmission/NoVideoCard";
import NotificationBell from "../components/notifications/NotificationBell";
import VideoCard from "../components/videoSubmission/VideoCard";

import Edit from "../assets/Icons/Edit.png";

import "../styles/videoSubmission.scss";

const VideoSubmission = (props) => {
    const { notification } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const { candidateData } = location.state;
    const [videoCardData, setVideoCardData] = useState([]);
    const [audioCardData, setAudioCardData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        if (!candidateData.test) return;

        setLoading(true);
        let tempVideoArr = [];
        let tempAudioArr = [];
        for (let i = 0; i < candidateData.test.chat.length; i++) {
            const val = candidateData.test.chat[i];
            const res = await axios.post("/cand-video", { case: val.ans });
            if (val.ans.includes(".mp4") || val.ans.includes(".webm")) {
                let tempData = {
                    ques: val.ques,
                    score: val.score,
                    src: res.data.signedUrl,
                    cid: val.cid,
                    notes: val.notes,
                };
                tempVideoArr.push(tempData);
            }

            if (val.ans.includes(".mp3")) {
                let tempData = {
                    ques: val.ques,
                    score: val.score,
                    src: res.data.signedUrl,
                };
                tempAudioArr.push(tempData);
            }
        }

        setVideoCardData(tempVideoArr);
        setAudioCardData(tempAudioArr);
        setLoading(false);
    }, [candidateData]);

    return (
        <div className="productList">
            <div
                className="inner-div"
                style={{ justifyContent: "space-between" }}
            >
                <div>
                    <div className="tooltip">
                        <IconButton
                            style={{ color: "rgb(180,180,180)" }}
                            aria-label="back"
                            onClick={navigate(-1)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width={24}
                                height={24}
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </IconButton>
                        <span className="tooltiptext">Go Back</span>
                    </div>
                </div>
                <div className="top-icon-box">
                    <div className="tooltip">
                        <IconButton aria-label="edit">
                            <img src={Edit} alt="" width={30} height={30} />
                        </IconButton>
                        <span className="tooltiptext">Edit</span>
                    </div>
                    {/* <IconButton aria-label="delete"><img src={Trash} alt="" width={28} height={28}/></IconButton> */}
                    <NotificationBell notification={notification} />
                </div>
            </div>

            <div className="container-div">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                    }}
                >
                    <div className="inner-div-details">
                        <h2>
                            {audioCardData.length > 0 ? "Audio" : "Video"}{" "}
                            Submission
                        </h2>
                    </div>
                </div>

                <div className="videobox-container">
                    <div className="videobox-header">
                        <p>Name: {candidateData.name}</p>
                    </div>

                    {loading ? (
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div className="videobox-loader" />
                        </div>
                    ) : (
                        <div
                            className="videobox-wrapper"
                            style={{
                                justifyContent:
                                    videoCardData.length > 0 ||
                                    audioCardData.length > 0
                                        ? "normal"
                                        : "center",
                            }}
                        >
                            {videoCardData.length == 0 ? (
                                audioCardData.length == 0 ? (
                                    <NoVideoCard mediaType="video" />
                                ) : (
                                    audioCardData.map((val) => (
                                        <VideoCard
                                            key={val.cid}
                                            ques={val.ques}
                                            src={val.src}
                                            score={val.score}
                                            mediaType="audio"
                                        />
                                    ))
                                )
                            ) : (
                                videoCardData.map((val) => (
                                    <VideoCard
                                        key={val.cid}
                                        ques={val.ques}
                                        src={val.src}
                                        score={val.score}
                                        cid={val.cid}
                                        notes={val.notes}
                                        candidateData={candidateData}
                                    />
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

VideoSubmission.propTypes = {
    notification: PropTypes.object,
};

export default VideoSubmission;

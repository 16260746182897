import React from 'react';
import logo from '../../assets/Logo.png'
import hamburger from '../../assets/Icons/hamburger.png'
import options from '../../assets/Icons/options.png'

import './header.css'
import { useState } from 'react';
import PropTypes from 'prop-types';
import Sidebar1 from '../sidebar/Sidebar1';
import Sidebar from '../sidebar/Sidebar';
import { Menu, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const Header = (props) => {
    const { MenuItems } = props;

    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openEl = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className='headerContainer'>
                <div className='sidebarContainer' onClick={() => setOpen(true)}>
                    <img src={hamburger} alt="sidebar navigation" className='imgContain' />
                </div>
                <div className='hqLogoContainer'>
                    <img src={logo} alt="HQ logo" className='imgContain' />
                </div>
                {MenuItems && 
                    <div
                        className='optionsContainer'
                        id="options-btn"
                        aria-controls={openEl ? 'options-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openEl ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <img src={options} alt="more options" className='imgContain' />
                    </div>
                }
                {MenuItems && 
                    <Menu
                        id="options-menu"
                        anchorEl={anchorEl}
                        open={openEl}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'options-btn',
                        }}
                    >
                        {MenuItems.map((item, index) => {
                            if(item.name === 'Export') {
                                return (
                                    <CSVLink
                                        filename="Data.csv"
                                        data={item.CSVData && item.CSVData}
                                        target="_blank"
                                        style={{ textDecoration: 'none', color: 'black' }}
                                        key={index}
                                    >
                                        <MenuItem onClick={handleClose}>{item.name}</MenuItem>
                                    </CSVLink>
                                )
                            }
                            return (
                                <MenuItem 
                                    onClick={() => {
                                        handleClose();
                                        item.action();
                                    }}
                                    key={index}
                                >
                                    {item.name}
                                </MenuItem>
                            )
                            })}
                    </Menu>
                }
            </div>
            {
                location.state && location.state.workflowId ? (
                    <Sidebar openDrawer={open} closeDrawer={() => setOpen(false)} />
                ) : (
                    <Sidebar1 openDrawer={open} closeDrawer={() => setOpen(false)} />
                )
            }
        </>
    );
}

Header.propTypes = {
    MenuItems: PropTypes.array,
}

export default Header;
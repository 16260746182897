import React from "react";
import PropTypes from "prop-types";
import { Doughnut, Bar } from "react-chartjs-2";

import Button from "../../components/common/Button";
import {
    barChartData,
    doughnutOptionsAnimated,
    percentageColor,
    reportGrade,
} from "../../constants/reports";
import { doughnutData, getBucket } from "../../utils/reports";

import icon1 from "../../assets/icons1.png";
import icon2 from "../../assets/icons2.png";

function getBarChartData(barChartData, candidateData) {
    const tmpBarData = { ...barChartData };
    tmpBarData.labels = [];
    tmpBarData.datasets[0].data = [];
    tmpBarData.datasets[1].data = [];

    if (
        Object.prototype.hasOwnProperty.call(
            candidateData?.test?.chat[0],
            "idealqtime"
        )
    ) {
        const newChatArr = [];
        const cids = [];
        candidateData?.test?.chat.forEach((item) => {
            if (!cids.includes(item.cid)) {
                cids.push(item.cid);
                newChatArr.push(item);
            }
        });

        newChatArr.forEach((item, index) => {
            tmpBarData.labels.push("Q" + (1 + index));
            tmpBarData.datasets[0].data.push(item.idealqtime);
            tmpBarData.datasets[1].data.push(item.userqtime);
        });
    }
    return tmpBarData;
}

const TimeMapBarChart = ({ barData }) => {
    if (!barData?.labels?.length) return null;
    return (
        <>
            <h2
                style={{
                    borderBottom: "1px solid rgb(150,150,150)",
                    padding: "15px 0",
                    marginTop: "40px",
                    marginBottom: "20px",
                }}
            >
                Time map
            </h2>
            <Bar data={barData} options={barData.options} redraw={true} />
        </>
    );
};

TimeMapBarChart.propTypes = {
    barData: PropTypes.object,
};

const InfoBlock = ({ text }) => {
    if (!text) return null;

    return (
        <div
            style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
            <div style={{ marginRight: "15px", color: "#479BD2" }}>
                &#10148;
            </div>
            <p style={{ fontSize: "12px" }}>{text}</p>
        </div>
    );
};

InfoBlock.propTypes = {
    text: PropTypes.string,
};

const ReportTab = (props) => {
    const { setCurrentReportTab, candidateData, workFunctions } = props;
    const totalScore = Math.round(
        (candidateData?.test?.result?.total || 0) * 100
    );
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");
    const barData = getBarChartData(barChartData, candidateData);

    const getSkills = (wf) => {
        if (wf.associatedSkills) {
            const associatedSkills = wf.associatedSkills.map((as) => as.title);
            return associatedSkills.join(", ");
        } else if (wf.skills) {
            return wf.skills;
        } else return "";
    };

    return (
        <div className="report-tab-box">
            <div id="scroll-down-animation">
                <p>scroll down</p>
            </div>
            <div
                style={{
                    borderBottom: "1px solid rgb(150,150,150)",
                    padding: "15px 0",
                }}
            >
                <h2>Performance overview</h2>
            </div>
            <div className="performance-overview-box">
                <div className="doughnut-chart-box">
                    <p
                        style={{
                            marginBottom: "20px",
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#7d7d7d",
                        }}
                    >
                        Total Score
                    </p>
                    <div
                        style={{
                            position: "relative",
                            width: 200,
                            height: 200,
                            margin: mediaQueryMobile.matches
                                ? "0 auto"
                                : "unset",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                textAlign: "center",
                                transform: "translate(-50%,-50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "25px",
                                    fontWeight: "800",
                                    color: percentageColor[
                                        getBucket(totalScore)
                                    ],
                                }}
                            >
                                {totalScore}%
                            </p>
                            <p
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#696969",
                                }}
                            >
                                {reportGrade[getBucket(totalScore)]}
                            </p>
                        </div>
                        <Doughnut
                            data={doughnutData(totalScore)}
                            options={doughnutOptionsAnimated}
                        />
                    </div>
                </div>
                <div className="performance-stats">
                    {/* <div style={{ color: "#7d7d7d", fontSize: "14px", fontWeight: "700", display: "flex", justifyContent: "flex-end" }}>
						<div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", margin: "0 10px" }}>
							<div style={{ width: "15px", height: "15px", background: "#479BD2", marginRight: "5px" }}>&nbsp;</div>
							<span>Functional skill</span>
						</div>
						<div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", margin: "0 10px" }}>
							<div style={{ width: "15px", height: "15px", background: "#F88E2A", marginRight: "5px" }}>&nbsp;</div>
							<span>Core skill</span>
						</div>
					</div> */}
                    {candidateData?.test?.result &&
                    Object.keys(candidateData?.test?.result).length
                        ? Object.keys(candidateData?.test?.result).map(
                              (value, index) => {
                                  if (
                                      value !== "total" &&
                                      Object.prototype.hasOwnProperty.call(
                                          workFunctions,
                                          value.toLowerCase()
                                      )
                                  ) {
                                      const title =
                                          workFunctions[value.toLowerCase()]
                                              .label;
                                      const skillScore = parseInt(
                                          candidateData?.test?.result[value] *
                                              100
                                      );
                                      return (
                                          <div
                                              style={{
                                                  marginTop:
                                                      index === 0 ? 0 : "20px",
                                              }}
                                              key={value.title}
                                          >
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      justifyContent:
                                                          "flex-end",
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          width: "400px",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                              "flex-end",
                                                      }}
                                                  >
                                                      <img
                                                          src={
                                                              index % 2 === 0
                                                                  ? icon1
                                                                  : icon2
                                                          }
                                                          alt=""
                                                          style={{
                                                              width: "35px",
                                                              height: "35px",
                                                              marginRight:
                                                                  "20px",
                                                          }}
                                                      />
                                                      <div
                                                          style={{ flex: "1" }}
                                                      >
                                                          <p>{title}</p>
                                                          <div
                                                              style={{
                                                                  display:
                                                                      "flex",
                                                                  alignItems:
                                                                      "center",
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      position:
                                                                          "relative",
                                                                      width: "80%",
                                                                      height: "10px",
                                                                  }}
                                                              >
                                                                  <div
                                                                      style={{
                                                                          position:
                                                                              "absolute",
                                                                          width: "100%",
                                                                          height: "10px",
                                                                          background:
                                                                              "#d4d4d4",
                                                                          borderRadius:
                                                                              "15px",
                                                                      }}
                                                                  ></div>
                                                                  <div
                                                                      style={{
                                                                          position:
                                                                              "absolute",
                                                                          width: `${skillScore}%`,
                                                                          height: "10px",
                                                                          background:
                                                                              percentageColor[
                                                                                  getBucket(
                                                                                      skillScore
                                                                                  )
                                                                              ],
                                                                          borderRadius:
                                                                              "15px",
                                                                      }}
                                                                  ></div>
                                                              </div>
                                                              <span
                                                                  style={{
                                                                      fontSize:
                                                                          "18px",
                                                                      fontWeight:
                                                                          "500",
                                                                      color: "#7d7d7d",
                                                                      paddingLeft:
                                                                          "15px",
                                                                      maxWidth:
                                                                          "20%",
                                                                      boxSizing:
                                                                          "border-box",
                                                                  }}
                                                              >
                                                                  {skillScore}%
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return "";
                                  }
                              }
                          )
                        : null}
                </div>
            </div>
            {candidateData?.productId &&
            candidateData?.productId.customId &&
            candidateData?.productId.customId
                .toLowerCase()
                .includes("chatbotbcgriseai") ? (
                ""
            ) : (
                <>
                    <div
                        style={{
                            borderBottom: "1px solid rgb(150,150,150)",
                            padding: "15px 0",
                            marginTop: "20px",
                        }}
                    >
                        <h2>Category breakdown</h2>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <h2 style={{ color: "#479BD2" }}>Functional skills</h2>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        {candidateData?.test?.result &&
                        Object.keys(candidateData?.test?.result).length
                            ? Object.keys(candidateData?.test?.result).map(
                                  (val) => {
                                      if (
                                          val !== "total" &&
                                          Object.prototype.hasOwnProperty.call(
                                              workFunctions,
                                              val.toLowerCase()
                                          )
                                      ) {
                                          const title =
                                              workFunctions[val.toLowerCase()]
                                                  .label;
                                          const skillScore = parseInt(
                                              candidateData?.test?.result[val] *
                                                  100
                                          );
                                          const performance =
                                              reportGrade[
                                                  getBucket(skillScore)
                                              ];
                                          const remarks = workFunctions[
                                              val.toLowerCase()
                                          ].remarks
                                              ? workFunctions[val.toLowerCase()]
                                                    .remarks[
                                                    getBucket(skillScore)
                                                ]
                                              : "";
                                          const skills = getSkills(
                                              workFunctions[val.toLowerCase()]
                                          );

                                          return (
                                              <div
                                                  style={{
                                                      width: mediaQueryMobile.matches
                                                          ? "100%"
                                                          : "45%",
                                                      boxSizing: "border-box",
                                                      padding: "20px",
                                                      margin: "15px 0",
                                                      borderRadius: "30px",
                                                      boxShadow:
                                                          "5px 5px 10px 5px rgba(0,0,0,0.2)",
                                                  }}
                                                  key={title}
                                              >
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "space-between",
                                                          alignItems: "center",
                                                          marginBottom: "20px",
                                                      }}
                                                  >
                                                      <p
                                                          style={{
                                                              color: "#479BD2",
                                                              fontWeight: "600",
                                                              fontSize: "14px",
                                                          }}
                                                      >
                                                          {title}
                                                      </p>
                                                      <div
                                                          style={{
                                                              position:
                                                                  "relative",
                                                              width: 80,
                                                              height: 80,
                                                              flexShrink: 0,
                                                          }}
                                                      >
                                                          <p
                                                              style={{
                                                                  position:
                                                                      "absolute",
                                                                  top: "50%",
                                                                  left: "50%",
                                                                  textAlign:
                                                                      "center",
                                                                  transform:
                                                                      "translate(-45%,-30%)",
                                                                  fontSize:
                                                                      "16px",
                                                                  color: "#504848",
                                                                  fontWeight:
                                                                      "600",
                                                              }}
                                                          >
                                                              {skillScore}%
                                                          </p>
                                                          <Doughnut
                                                              data={doughnutData(
                                                                  skillScore
                                                              )}
                                                              options={
                                                                  doughnutOptionsAnimated
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                                  <InfoBlock text={skills} />
                                                  <InfoBlock
                                                      text={`Candidate's performance: ${performance}`}
                                                  />
                                                  <InfoBlock text={remarks} />
                                              </div>
                                          );
                                      } else if (val.toLowerCase() === "typ") {
                                          const title =
                                              workFunctions[val.toLowerCase()]
                                                  .label;
                                          const skillScore = parseInt(
                                              candidateData?.test?.result[val] *
                                                  100
                                          );
                                          const performance =
                                              reportGrade[
                                                  getBucket(skillScore)
                                              ];

                                          let accuracy = 0;
                                          if (
                                              candidateData?.test?.result
                                                  ?.typing &&
                                              candidateData?.test?.result.typing
                                                  ?.acc
                                          ) {
                                              accuracy = parseInt(
                                                  candidateData?.test?.result
                                                      .typing.acc * 100
                                              );
                                          }

                                          let wordsPerMinute = 0;
                                          if (
                                              candidateData?.test?.result
                                                  ?.typing &&
                                              candidateData?.test?.result.typing
                                                  ?.wpm
                                          ) {
                                              wordsPerMinute = parseInt(
                                                  candidateData?.test?.result
                                                      .typing.wpm
                                              );
                                          }

                                          return (
                                              <div
                                                  style={{
                                                      width: mediaQueryMobile.matches
                                                          ? "100%"
                                                          : "45%",
                                                      boxSizing: "border-box",
                                                      padding: "20px",
                                                      margin: "15px 0",
                                                      borderRadius: "30px",
                                                      boxShadow:
                                                          "5px 5px 10px 5px rgba(0,0,0,0.2)",
                                                  }}
                                                  key={title}
                                              >
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "space-between",
                                                          alignItems: "center",
                                                          marginBottom: "20px",
                                                      }}
                                                  >
                                                      <p
                                                          style={{
                                                              color: "#479BD2",
                                                              fontWeight: "600",
                                                              fontSize: "14px",
                                                          }}
                                                      >
                                                          {title}
                                                      </p>
                                                      <div
                                                          style={{
                                                              position:
                                                                  "relative",
                                                              width: 80,
                                                              height: 80,
                                                              flexShrink: 0,
                                                          }}
                                                      >
                                                          <p
                                                              style={{
                                                                  position:
                                                                      "absolute",
                                                                  top: "50%",
                                                                  left: "50%",
                                                                  textAlign:
                                                                      "center",
                                                                  transform:
                                                                      "translate(-45%,-30%)",
                                                                  fontSize:
                                                                      "16px",
                                                                  color: "#504848",
                                                                  fontWeight:
                                                                      "600",
                                                              }}
                                                          >
                                                              {skillScore}%
                                                          </p>
                                                          <Doughnut
                                                              data={doughnutData(
                                                                  skillScore
                                                              )}
                                                              options={
                                                                  doughnutOptionsAnimated
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                                  <InfoBlock
                                                      text={`Accuracy: ${accuracy}%`}
                                                  />
                                                  <InfoBlock
                                                      text={`Words per minute: ${wordsPerMinute}`}
                                                  />
                                                  <InfoBlock
                                                      text={`Candidate's performance: ${performance}`}
                                                  />
                                              </div>
                                          );
                                      } else {
                                          return "";
                                      }
                                  }
                              )
                            : null}
                    </div>
                </>
            )}
            <TimeMapBarChart barData={barData} />
            <div className="interviewers-guide-btn-box">
                <Button
                    style={{
                        backgroundColor: "#479BD2",
                        letterSpacing: "0.01em",
                        borderRadius: "25px",
                        padding: "6px 20px",
                        fontSize: "16px",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => setCurrentReportTab(3)}
                >
                    Interviewer&apos;s guide
                </Button>
            </div>
        </div>
    );
};

ReportTab.propTypes = {
    setCurrentReportTab: PropTypes.func,
    candidateData: PropTypes.object,
    workFunctions: PropTypes.object,
};

export default ReportTab;

import React, { useEffect, useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
import Loader from '../../common/Loader';

import axios from "../../../utils/axios"

export default function CheckoutForm() {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const submitSuccessfulPayemnt = (clientSecret) => {
		let data = {
			clientSecret,
			companyId: localStorage.getItem('company')
		}
		axios.post("/user-plan/update-company-cycle", data).then((res) => {
			console.log(res.data)
		})
	}
	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					submitSuccessfulPayemnt(clientSecret)
					setMessage("Payment succeeded!");

					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					setMessage("Your payment was not successful, please try again.");
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: process.env.REACT_APP_BASE_URL[process.env.REACT_APP_BASE_URL.length - 1] == "/" ? process.env.REACT_APP_BASE_URL + 'my-account' : process.env.REACT_APP_BASE_URL + 'my-account',
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit} style={{ padding: '16px 20px' }}>
			<PaymentElement />
			{isLoading ?
				<Loader /> :
				<button
					disabled={!stripe || !elements}
					style={{ backgroundColor: '#479BD2', color: '#fff', padding: '0.5rem 2rem', width: 'fit-content', margin: '0 auto', marginTop: '1rem', display: 'block', border: 'none', borderRadius: 24 }}
				>
					Pay Now
				</button>
			}
			{/* Show any error or success messages */}
			{message && <div>{message}</div>}
		</form>
	);
}
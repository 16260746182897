import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { connect } from "react-redux";

import Button from "../../components/common/Button";
import IconButton from "../../components/common/IconButton";
import Modal from "../../components/common/CustomModal";
import Snackbar from "../../components/common/Snackbar";
import SwitchBtn from "../../components/common/SwitchBtn";
import { updatePreScreeningDetails as updatePreScreeningDetailsRequest } from "../../actions/app";

import Edit from "../../assets/Icons/Edit.png";

const WelcomePageModal = (props) => {
    const {
        welcomePageModal,
        onCloseWelcomePageModal,
        updatePreScreeningDetails,
        companyDetails,
        checkPrescreeningDetails,
    } = props;
    const [editTitle, setEditTitle] = useState(false);
    const [companyTitle, setCompanyTitle] = useState("Welcome {{name}}");
    const [companyOverview, setCompanyOverview] = useState("");
    const [endPrescreen, setEndPrescreen] = useState(false);
    const [logoModal, setLogoModal] = useState(false);
    const [linksModal, setLinksModal] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [companyOverviewEditable, setCompanyOverviewEditable] =
        useState(false);
    const [linksStatus, setLinksStatus] = useState(false);
    const [videoLink, setVideoLink] = useState(
        companyDetails.prescreeningVideo || ""
    );
    const [newVideoLink, setNewVideoLink] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("success");
    const [socialLinks, setSocialLinks] = useState({
        website: companyDetails.socialLinks?.website || "",
        linkedin: companyDetails.socialLinks?.linkedin || "",
        twitter: companyDetails.socialLinks?.twitter || "",
        instagram: companyDetails.socialLinks?.instagram || "",
        facebook: companyDetails.socialLinks?.facebook || "",
        youtube: companyDetails.socialLinks?.youtube || "",
    });
    const [prescreeningWelcomeType, setPrescreeningWelcomeType] =
        useState("image");

    const [logoFile, setLogoFile] = useState(
        `${process.env.REACT_APP_STORAGE_BUCKET_URL}default.png`
    );
    const [newLogoFile, setNewLogoFile] = useState("");

    const [companyImage, setCompanyImage] = useState(
        `${process.env.REACT_APP_STORAGE_BUCKET_URL}website_videos/prescreening-welcome.png`
    );
    const [newCompanyImage, setNewCompanyImage] = useState("");

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "indent",
    ];

    const onChangeEndPrescreen = (e) => {
        setEndPrescreen(e.target.checked);
    };

    const onChangeCompanyOverview = (content, delta, source, editor) => {
        setCompanyOverview(editor.getHTML());
    };

    const onCloseModal = () => {
        setEditTitle(false);
        setCompanyOverviewEditable(false);
        onCloseWelcomePageModal();
    };

    useEffect(() => {
        setCompanyTitle(
            companyDetails.prescreeningTitle
                ? companyDetails.prescreeningTitle
                : "Welcome {{name}}"
        );
        setCompanyOverview(companyDetails.prescreeningAbout);
        setEndPrescreen(companyDetails.prescreeningWelcomePage);
        setCompanyImage(
            companyDetails.prescreeningImage
                ? `${process.env.REACT_APP_STORAGE_BUCKET_URL}${companyDetails.prescreeningImage}`
                : `${process.env.REACT_APP_STORAGE_BUCKET_URL}website_videos/prescreening-welcome.png`
        );
        setLogoFile(
            companyDetails.prescreeningLogo
                ? `${process.env.REACT_APP_STORAGE_BUCKET_URL}${companyDetails.prescreeningLogo}`
                : `${process.env.REACT_APP_STORAGE_BUCKET_URL}default.png`
        );
        setVideoLink(companyDetails.prescreeningVideo);
        setPrescreeningWelcomeType(
            companyDetails.prescreeningWelcomeType
                ? companyDetails.prescreeningWelcomeType
                : "image"
        );

        if (companyDetails.socialLinks) {
            const newLinkStatus = Object.values(
                companyDetails.socialLinks
            ).some((val) => val === "");
            setLinksStatus(newLinkStatus);
        }

        const newSocialLinks = {
            website: companyDetails.socialLinks?.website || "",
            linkedin: companyDetails.socialLinks?.linkedin || "",
            twitter: companyDetails.socialLinks?.twitter || "",
            instagram: companyDetails.socialLinks?.instagram || "",
            facebook: companyDetails.socialLinks?.facebook || "",
            youtube: companyDetails.socialLinks?.youtube || "",
        };
        setSocialLinks({ ...newSocialLinks });
    }, [companyDetails]);

    const handleLogoUpload = (e) => {
        try {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
                setNewLogoFile(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleCompanyImgUpload = (e) => {
        try {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
                setNewCompanyImage(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleImageVideoUpload = () => {
        if (newCompanyImage !== "" && newVideoLink !== "") {
            setSnackOpen(true);
            setSnackSeverity("error");
            setSnackMsg("You can only upload one image or video");
        } else if (newCompanyImage !== "" && newVideoLink === "") {
            setCompanyImage(newCompanyImage);
            setNewCompanyImage("");
            setVideoLink("");
            setImageModal(false);
            setPrescreeningWelcomeType("image");
        } else if (newCompanyImage === "" && newVideoLink !== "") {
            if (newVideoLink.includes("embed")) {
                setVideoLink(newVideoLink);
            } else if (newVideoLink.includes("youtu.be")) {
                const ytId = newVideoLink.split("youtu.be/")[1];
                setVideoLink(`https://www.youtube.com/embed/${ytId}`);
            } else {
                const ytUrl = new URLSearchParams(newVideoLink.split("?")[1]);
                const ytId = ytUrl.get("v");
                setVideoLink(`https://www.youtube.com/embed/${ytId}`);
            }
            setCompanyImage("");
            setImageModal(false);
            setPrescreeningWelcomeType("video");
        } else {
            setSnackOpen(true);
            setSnackSeverity("error");
            setSnackMsg("Please select an image or video");
        }
    };

    const handleSaveWelcomePage = async () => {
        const formData = new FormData();

        if (!logoFile.includes(".png")) {
            const logoBlob = await fetch(logoFile);
            const logoBlobData = await logoBlob.blob();
            const dname1 =
                localStorage.getItem("company") +
                "_prescreening_logo_" +
                new Date().getTime();
            const file1 = new File([logoBlobData], dname1 + ".png", {
                type: "image/png",
            });
            formData.append("prescreening-welcome", file1);
        }
        if (!companyImage.includes(".png")) {
            const companyImageBlob = await fetch(companyImage);
            const companyImageBlobData = await companyImageBlob.blob();
            const dname2 =
                localStorage.getItem("company") +
                "_prescreening_company_image_" +
                new Date().getTime();
            const file2 = new File([companyImageBlobData], dname2 + ".png", {
                type: "image/png",
            });
            formData.append("prescreening-welcome", file2);
        }

        formData.append("prescreeningWelcomePage", endPrescreen);
        formData.append("prescreeningTitle", companyTitle);
        formData.append("prescreeningAbout", companyOverview);
        formData.append("socialLinks", JSON.stringify(socialLinks));
        formData.append("prescreeningVideo", videoLink);
        // if (newVideoLink !== "") {
        formData.append("prescreeningWelcomeType", "video");
        // } else {
        //     formData.append("prescreeningWelcomeType", "image");
        // }

        updatePreScreeningDetails({ formData });
        onCloseWelcomePageModal();
        setSnackOpen(true);
        setSnackSeverity("success");
        setSnackMsg("Welcome page updated successfully");
    };

    return (
        <>
            <Modal
                open={welcomePageModal}
                onClose={onCloseModal}
                center
                classNames={{
                    modal: "welcome-page-modal",
                }}
            >
                <p className="welcome-page-modal-title">
                    {checkPrescreeningDetails ? "Edit" : "Create"} Welcome Page
                </p>
                <div className="welcome-page-modal-flex-box">
                    <div>
                        <div className="welcome-page-modal-logo-box">
                            <img
                                src={logoFile}
                                style={{ width: "153px", maxHeight: "45px" }}
                                alt=""
                            />
                            <IconButton
                                aria-label="edit"
                                onClick={() => setLogoModal(true)}
                            >
                                <img
                                    src={Edit}
                                    alt="edit-icon"
                                    width={30}
                                    height={30}
                                />
                            </IconButton>
                        </div>
                        <div className="welcome-page-modal-participant-box">
                            {editTitle ? (
                                <input
                                    type="text"
                                    className="participant-input"
                                    value={companyTitle}
                                    onChange={(e) =>
                                        setCompanyTitle(e.target.value)
                                    }
                                />
                            ) : (
                                <p className="participant-heading">
                                    {companyTitle}
                                </p>
                            )}
                            {editTitle ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        padding: "4px 8px",
                                        color: "#479BD2",
                                        fontSize: 14,
                                        fontWeight: "600",
                                        cursor: "pointer",
                                        marginLeft: 8,
                                        border: "1px solid #479BD2",
                                    }}
                                    onClick={() => setEditTitle(false)}
                                >
                                    Save
                                </Button>
                            ) : (
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => setEditTitle(true)}
                                >
                                    <img
                                        src={Edit}
                                        alt="edit-icon"
                                        width={30}
                                        height={30}
                                    />
                                </IconButton>
                            )}
                        </div>
                        <div className="welcome-page-modal-company-box">
                            <p className="company-heading">Company Overview</p>
                            {companyOverviewEditable ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        padding: "4px 8px",
                                        color: "#479BD2",
                                        fontSize: 14,
                                        fontWeight: "600",
                                        cursor: "pointer",
                                        marginLeft: 8,
                                        border: "1px solid #479BD2",
                                    }}
                                    onClick={() =>
                                        setCompanyOverviewEditable(false)
                                    }
                                >
                                    Save
                                </Button>
                            ) : (
                                <IconButton
                                    aria-label="edit"
                                    onClick={() =>
                                        setCompanyOverviewEditable(true)
                                    }
                                >
                                    <img
                                        src={Edit}
                                        alt="edit-icon"
                                        width={30}
                                        height={30}
                                    />
                                </IconButton>
                            )}
                        </div>
                        {companyOverviewEditable ? (
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={companyOverview || ""}
                                onChange={onChangeCompanyOverview}
                                style={{ height: 150, paddingBottom: 43 }}
                                preserveWhitespace={true}
                            />
                        ) : (
                            <div
                                className="welcome-page-modal-textarea"
                                dangerouslySetInnerHTML={{
                                    __html: companyOverview,
                                }}
                            />
                        )}
                        <div className="welcome-page-modal-links-box">
                            <p
                                className="links-heading"
                                onClick={() => setLinksModal(true)}
                            >
                                {linksStatus ? "Edit" : "Add"} links
                            </p>
                            <div className="tooltip">
                                <div className="welcome-page-modal-info-icon">
                                    i
                                </div>
                                <span
                                    className="tooltiptext"
                                    style={{ width: 200 }}
                                >
                                    You can add the links to the company’s
                                    website and social media handles here
                                </span>
                            </div>
                        </div>
                        <div className="welcome-page-modal-endprescreen-box">
                            <p className="end-prescreen-heading">
                                Show this screen after ending pre-screening as
                                well
                            </p>
                            <SwitchBtn
                                checked={endPrescreen}
                                onChange={onChangeEndPrescreen}
                            />
                        </div>
                    </div>
                    <div className="welcome-page-modal-img-box">
                        {prescreeningWelcomeType === "image" ? (
                            <img src={companyImage} alt="default-welcome-img" />
                        ) : (
                            <iframe
                                src={videoLink}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="100%"
                                height="auto"
                            />
                        )}
                        <button
                            className="add-btn"
                            onClick={() => setImageModal(true)}
                        >
                            {companyDetails.prescreeningImage !== "" ||
                            companyDetails.prescreeningVideo !== ""
                                ? "Update"
                                : "Add"}{" "}
                            Image/Video
                        </button>
                    </div>
                </div>
                <div className="welcome-page-modal-create-box">
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            backgroundColor: "#479BD2",
                            color: "#fff",
                            boxShadow: "0px 4px 10px 0px #109CF13D",
                            fontSize: 14,
                            fontWeight: "600",
                            padding: "12px 32px",
                            borderRadius: 24,
                            cursor: "pointer",
                        }}
                        onClick={handleSaveWelcomePage}
                    >
                        {checkPrescreeningDetails ? "Update" : "Create"}
                    </Button>
                </div>
            </Modal>
            <Modal
                open={logoModal}
                onClose={() => setLogoModal(false)}
                center
                classNames={{ modal: "add-logo-modal" }}
            >
                <p className="heading">Add Logo</p>
                <input
                    type="file"
                    className="fileInput"
                    onChange={handleLogoUpload}
                />
                <div className="btn-box">
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            backgroundColor: "#479BD2",
                            color: "#fff",
                            boxShadow: "0px 4px 10px 0px #109CF13D",
                            fontSize: 14,
                            fontWeight: "600",
                            padding: "12px 32px",
                            borderRadius: 24,
                            cursor: "pointer",
                            marginTop: 16,
                        }}
                        onClick={() => {
                            setLogoModal(false);
                            setLogoFile(newLogoFile);
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
            <Modal
                open={linksModal}
                onClose={() => setLinksModal(false)}
                center
                classNames={{ modal: "add-links-modal" }}
            >
                <p className="heading">{linksStatus ? "Edit" : "Add"} Links</p>
                {[
                    "website",
                    "linkedin",
                    "twitter",
                    "instagram",
                    "facebook",
                    "youtube",
                ].map((val, idx) => (
                    <div className="links-box" key={idx}>
                        <p>{val.charAt(0).toUpperCase() + val.slice(1)}</p>
                        <input
                            type="text"
                            value={socialLinks[val]}
                            onChange={(e) => {
                                setSocialLinks({
                                    ...socialLinks,
                                    [val]: e.target.value,
                                });
                            }}
                        />
                    </div>
                ))}
                <div className="btn-box">
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            backgroundColor: "#479BD2",
                            color: "#fff",
                            boxShadow: "0px 4px 10px 0px #109CF13D",
                            fontSize: 14,
                            fontWeight: "600",
                            padding: "12px 32px",
                            borderRadius: 24,
                            cursor: "pointer",
                            marginTop: 16,
                        }}
                        onClick={() => {
                            let checkLinks = Object.values(socialLinks).some(
                                (val) => val !== ""
                            );
                            if (checkLinks) {
                                setLinksModal(false);
                                setLinksStatus(true);
                            } else {
                                alert("Please enter atleast one link");
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
            <Modal
                open={imageModal}
                onClose={() => setImageModal(false)}
                center
                classNames={{ modal: "add-image-modal" }}
            >
                <p className="heading">
                    {companyDetails.prescreeningImage !== "" ||
                    companyDetails.prescreeningVideo !== ""
                        ? "Update"
                        : "Add"}{" "}
                    Image/Video
                </p>
                <input
                    type="file"
                    className="fileInput"
                    onChange={handleCompanyImgUpload}
                />
                <p className="divider">OR</p>
                <input
                    type="text"
                    value={newVideoLink}
                    className="fileInput"
                    placeholder="Paste the youtube link of the video"
                    onChange={(e) => setNewVideoLink(e.target.value)}
                />
                <div className="btn-box">
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            backgroundColor: "#479BD2",
                            color: "#fff",
                            boxShadow: "0px 4px 10px 0px #109CF13D",
                            fontSize: 14,
                            fontWeight: "600",
                            padding: "12px 32px",
                            borderRadius: 24,
                            cursor: "pointer",
                            marginTop: 16,
                        }}
                        onClick={handleImageVideoUpload}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
            <Snackbar
                type={snackSeverity}
                open={snackOpen}
                autoHideDuration={2000}
                onClose={() => setSnackOpen(false)}
                msg={snackMsg ? snackMsg : ""}
            />
        </>
    );
};

WelcomePageModal.propTypes = {
    welcomePageModal: PropTypes.bool,
    onCloseWelcomePageModal: PropTypes.func,
    updatePreScreeningDetails: PropTypes.func,
    companyDetails: PropTypes.object,
    checkPrescreeningDetails: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    updatePreScreeningDetails: (data) =>
        dispatch(updatePreScreeningDetailsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePageModal);

import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import emptyRowImg from "../../assets/emptyrow2.png";

export default function NoRowsDataGridOverlay() {
    return (
        <Stack
            height={"100%"}
            alignItems="center"
            justifyContent="center"
            spacing={1}
        >
            <Box
                component="img"
                src={emptyRowImg}
                alt="No rows"
                sx={{
                    height: "60%",
                    width: "auto",
                }}
            />
            <Typography
                color="#334d6e"
                fontSize="1.25rem"
                fontWeight={700}
                fontFamily="inherit"
            >
                No data to show
            </Typography>
        </Stack>
    );
}
